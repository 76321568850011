import React from "react";
import TextField from "Components/TextField.js";

export default ({li, onChange, required=true}) => (
	<TextField
		clearable={true}
		dataType="float"
		fullWidth
		InputProps={{inputProps: {min: (required ? 0.01 : undefined), step: 0.01}}}
		onChange={v => onChange((v || null))}
		placeholder="Price"
		precision={2}
		required={required}
		type="number"
		value={(li.Amount || 0)} />
);
