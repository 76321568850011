import DataRequestService from "Services/DataRequestService.js";
import DialogAction from "Components/DialogAction.js";
import withSnackbar from "Hoc/withSnackbar.js";
import {DataRequestsSendDialog as Strings} from "Resources/Strings.js";

/**
 * Data requests send dialog
 *
 * @package SEC
 * @subpackage Views
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class DataRequestsSendDialog extends DialogAction {

	/**
	 * Submit.
	 *
	 * @return {Promise}
	 */
	submit() {
		return DataRequestService.send(this.props.request.id);
	}


	/**
	 * Strings.
	 *
	 * @return {Object}
	 */
	get strings() {
		return Strings;
	}

}

export default withSnackbar(DataRequestsSendDialog);
