import {api, uris} from "api.js";

/**
 * Enquiry service
 *
 * @package SEC
 * @subpackage Services
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class EnquiryService {

	/**
	 * Get an enquiry by ID.
	 *
	 * @param {Integer} id
	 * @return {void}
	 */
	static get(id) {
		return api.call(`${uris.enquiries.get}/${id}`).then(({data}) => data);
	}


	/**
	 * Search for enquiries.
	 *
	 * @param {String} query
	 * @return {void}
	 */
	static search(query) {
		return api.call(`${uris.enquiries.search}/${query}`).then(({data}) => data);
	}

}

export default EnquiryService;
