import React from "react";
import Component from "App/Component.js";
import Container from "Components/Containerx.js";
import DatePicker from "Components/DatePicker.js";
import DuplicatesAlert from "./TimesheetSubmissionErrorCheckingDuplicatesAlert.js";
import Fab from "Components/Fab.js";
import InstallerPicker from "Components/InstallerPicker.js";
import Navigator from "App/Navigator.js";
import String from "Components/Stringx.js";
import SubmissionErrorAlert from "./TimesheetSubmissionErrorAlert.js";
import TimesheetSubmissionCard from "./TimesheetSubmissionCard.js";
import TimesheetSubmissionCardState from "./TimesheetSubmissionCardState.js";
import TimesheetSubmissionViewDialog from "./TimesheetSubmissionViewDialog.js";
import TimesheetService from "./TimesheetService.js";
import View from "App/View.js";
import dayjs from "dayjs";
import withSnackbar from "Hoc/withSnackbar.js";
import scss from "./TimesheetSubmissionView.module.scss";

/**
 * Timesheet submission view
 * 
 * @package SEC
 * @subpackage Timesheets
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class TimesheetSubmissionView extends Component {

	/**
	 * Form ref.
	 * 
	 * @type {ReactRef}
	 */
	formRef = React.createRef();

	/**
	 * State
	 *
	 * @type {Object}
	 */
	state = {

		/**
		 * Data
		 *
		 * @type {Object}
		 */
		data: {

			/**
			 * Date
			 * 
			 * @type {String}
			 */
			Date: (new dayjs()).format("YYYY-MM-DD"),

			/**
			 * Installer
			 * 
			 * @type {Object}
			 */
			Installer: null,

			/**
			 * AM data
			 *
			 * @type {Object}
			 */
			AM: TimesheetSubmissionCardState(),

			/**
			 * PM data
			 *
			 * @type {Object}
			 */
			PM: TimesheetSubmissionCardState()

		},

		/**
		 * Checking duplicates
		 *
		 * @type {Boolean}
		 */
		checkingDuplicates: true,

		/**
		 * Error while checking duplicates
		 * 
		 * @type {Boolean}
		 */
		checkingDuplicatesError: false,

		/**
		 * Duplicate status
		 * 
		 * @type {Object}
		 */
		duplicates: {

			/**
			 * AM is duplicate?
			 * 
			 * @type {Boolean|null}
			 */
			AM: null,

			/**
			 * PM is duplicate?
			 * 
			 * @type {Boolean|null}
			 */
			PM: null

		},

		/**
		 * Submission status
		 * 
		 * @type {Object}
		 */
		submitted: {

			/**
			 * AM submitted?
			 * 
			 * @type {Boolean|null}
			 */
			AM: null,

			/**
			 * PM submitted?
			 * 
			 * @type {Boolean|null}
			 */
			PM: null

		},

		/**
		 * Submitting?
		 * 
		 * @type {Boolean}
		 */
		submitting: false,

		/**
		 * Submit dialog?
		 * 
		 * @type {Boolean}
		 */
		submitDialog: false

	};


	/**
	 * Check duplicates.
	 *
	 * @return {void}
	 */
	checkForDuplicates = () => {

		const Date = this.state.data.Date;
		const Installer = this.state.data.Installer?.InstallerId;
		if (!(Date && Installer)) return;

		this.setState({
			checkingDuplicates: true,
			duplicates: {AM: null, PM: null}
		});

		TimesheetService.checkSubmitted(Date, Installer).then(dups => {
			this.setState({
				checkingDuplicatesError: false,
				duplicates: dups,
				data: {
					...this.state.data,
					AM: (dups.AM ? TimesheetSubmissionCardState() : this.state.data.AM),
					PM: (dups.PM ? TimesheetSubmissionCardState() : this.state.data.PM)
				}
			});
		}).catch(() => {
			this.setState({
				checkingDuplicatesError: true
			});
		}).finally(() => {
			this.setState({
				checkingDuplicates: false
			});
		});

	};


	/**
	 * Date changed.
	 *
	 * @param {String} Date
	 * @return {void}
	 */
	handleDateChange = Date => {

		const NmpisReset = {Nmpis: [], NmpisDeclaration: false};

		this.setState(
			{
				data: {
					...this.state.data,
					Date,
					AM: {...this.state.data.AM, ...NmpisReset},
					PM: {...this.state.data.PM, ...NmpisReset}
				}
			},
			this.checkForDuplicates
		);

	};


	/**
	 * Installer changed.
	 *
	 * @param {Object} Installer
	 * @return {void}
	 */
	handleInstallerChange = Installer => {
		this.setState(
			{data: {...this.state.data, Installer}},
			this.checkForDuplicates
		);
	};


	/**
	 * Installers loaded.
	 * 
	 * @return {void}
	 */
	handleInstallersLoad = installers => {
		if (installers?.length === 1) {
			this.handleInstallerChange(installers[0]);
		}
		else this.setState({checkingDuplicates: false});
	};


	/**
	 * Segment values updated.
	 *
	 * @param {Object} values Values to change
	 * @param {String} segment Segment ID (AM/PM)
	 * @return {void}
	 */
	handleSegmentUpdate = (values, segment) => {

		/**
		 * Update the segment
		 */
		const segmentData = {
			...this.state.data[segment],
			...values
		};

		/**
		 * Determine the "other" segment as we want to sync Project/NMPIs
		 */
		const otherSegment = ((segment === "AM") ? "PM" : "AM");

		/**
		 * Get the data from the other segment
		 */
		const otherSegmentData = {...this.state.data[otherSegment]};

		/**
		 * Manage NMPIs when Project changes
		 */
		if (values.Project !== undefined) {
			if (values.Project !== this.state.data[segment].Project) {
				if (values.Project?.Id === otherSegmentData.Project?.Id) {
					segmentData.Nmpis = otherSegmentData.Nmpis;
					segmentData.NmpisDeclaration = otherSegmentData.NmpisDeclaration;
				}
				else {
					segmentData.Nmpis = [];
					segmentData.NmpisDeclaration = false;
				}
			}
		}

		/**
		 * Sync data to the other segment
		 */
		if (this.canSyncToSegment(otherSegment)) {

			/**
			 * Synchronise the Project when not set in the other segment
			 */
			if ((values.Project !== undefined) && !otherSegmentData.Project) {
				otherSegmentData.Project = values.Project;
			}

			/**
			 * Synchronise NMPIs/declaration when the Project is the same
			 */
			if (segmentData.Project === otherSegmentData.Project) {
				if (values.Nmpis !== undefined) {
					otherSegmentData.Nmpis = values.Nmpis;
				}
				if (values.NmpisDeclaration !== undefined) {
					otherSegmentData.NmpisDeclaration = values.NmpisDeclaration;
				}
			}

		}

		/**
		 * Update the state
		 */
		this.setState({
			data: {
				...this.state.data,
				[segment]: segmentData,
				[otherSegment]: otherSegmentData
			}
		});

	};


	/**
	 * Submit clicked.
	 * 
	 * @return {void}
	 */
	handleSubmit = e => {
		e.preventDefault();
		this.setState({submitDialog: true});
	};


	/**
	 * Closing the submit dialog.
	 * 
	 * @return {void}
	 */
	handleSubmitDialogClose = () => {
		this.setState({submitDialog: false});
	};


	/**
	 * Submitting.
	 * 
	 * @return {void}
	 */
	submit = () => {

		this.handleSubmitDialogClose();
		this.setState({submitting: true, submitDialog: false});

		const segments = ["AM", "PM"].filter(segment => {
			return this.isSegmentSubmittable(segment);
		});

		const promises = segments.map(segment => {

			const submission = this.getSegmentSubmission(segment);

			return new Promise(resolve => {
				TimesheetService.submit(submission).then(() => {
					resolve({segment, status: true});
				}).catch(() => {
					resolve({segment, status: false});
				});
			});

		});

		Promise.all(promises).then(results => {

			const submittedState = {};

			results.forEach(r => {
				submittedState[r.segment] = r.status;
			});

			this.setState({
				submitted: {
					...this.state.submitted,
					...submittedState
				},
				submitting: false
			});

			if (Object.values(submittedState).every(v => v)) {
				Navigator.navigate("/timesheets");
				this.props.snackbar("Timesheet submitted.", "success");
			}

		});

	};


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<View>
				<form onSubmit={this.handleSubmit}>
					<Container af="row" fullWidth={true}>
						<String str="Submit Timesheet" variant="h5" />
						{(this.hasSubmitted && <SubmissionErrorAlert disabled={this.state.submitting} onRetry={this.submit} />)}
						{(this.state.checkingDuplicatesError && <DuplicatesAlert disabled={this.state.checkingForDuplicates} onRetry={this.checkForDuplicates} />)}
						<Container fullWidth={true} mt={1}>
							<DatePicker
								disabled={this.disabled}
								label="Timesheet Date"
								onChange={this.handleDateChange}
								required={true}
								value={this.state.data.Date} />
							<InstallerPicker
								disabled={this.disabled}
								onChange={this.handleInstallerChange}
								onLoad={this.handleInstallersLoad}
								required={true}
								value={this.state.data.Installer} />
							<Container className={scss.cardsContainer} mt={1}>
								<TimesheetSubmissionCard
									date={this.state.data.Date}
									disabled={this.disabled}
									duplicate={this.state.duplicates.AM}
									error={(this.state.submitted.AM === false)}
									loading={this.isSegmentLoading("AM")}
									onChange={this.handleSegmentUpdate}
									segmentId="AM"
									submitted={this.state.submitted.AM}
									values={this.state.data.AM} />
								<TimesheetSubmissionCard
									date={this.state.data.Date}
									disabled={this.disabled}
									duplicate={this.state.duplicates.PM}
									error={(this.state.submitted.PM === false)}
									loading={this.isSegmentLoading("PM")}
									onChange={this.handleSegmentUpdate}
									segmentId="PM"
									submitted={this.state.submitted.PM}
									values={this.state.data.PM} />
							</Container>
						</Container>
					</Container>
					{(!this.hasSubmitted && this.renderFab())}
					<TimesheetSubmissionViewDialog
						open={this.state.submitDialog}
						onClose={this.handleSubmitDialogClose}
						onSubmit={this.submit} />
				</form>
			</View>
		);
	}


	/**
	 * Render the action button.
	 * 
	 * @return {ReactNode}
	 */
	renderFab() {
		return <Fab disabled={(!this.canSubmit || this.disabled)} type="submit" />;
	}


	/**
	 * Get whether we can sync data to a given segment ID.
	 *
	 * @param {String} segmentId
	 * @return {Boolean}
	 */
	canSyncToSegment(segmentId) {
		return (!this.state.duplicates[segmentId] && !this.state.submitted[segmentId]);
	}


	/**
	 * Get the submission object for a segment.
	 *
	 * @param {String} segment Segment ID
	 * @return {Object}
	 */
	getSegmentSubmission(segment) {
		return {
			ProjectID: this.state.data[segment].Project.Id,
			InstallerID: this.state.data.Installer.InstallerId,
			SheetDate: this.state.data.Date,
			SheetSegment: segment,
			StartTime: this.state.data[segment].StartTime.format("HH:mm"),
			FinishTime: this.state.data[segment].FinishTime.format("HH:mm"),
			WorkedHours: this.state.data[segment].HoursWorked,
			WorkedHoursAdditional: this.state.data[segment].HoursWorkedAdditional,
			NmpisDeclaration: this.state.data[segment].NmpisDeclaration
		};
	}


	/**
	 * Get whether to show a given segment in the loading state.
	 *
	 * @param {String} segment Segment ID
	 * @return {Boolean}
	 */
	isSegmentLoading(segment) {
		const submitted = this.state.submitted[segment];
		const submitting = (this.state.submitting && !this.state.duplicates[segment]);
		const submittable = this.isSegmentSubmittable(segment);
		return (!submitted && (this.state.checkingDuplicates || (submitting && submittable)));
	}


	/**
	 * Get whether a segment object can be submitted.
	 * 
	 * @param {String} segment Segment ID
	 * @return {Boolean}
	 */
	isSegmentSubmittable(segment) {
		const data = this.state.data[segment];
		const values = Object.values(data).every(v => (![null, undefined].includes(v)));
		const isNotDuplicate = (this.state.duplicates[segment] === false);
		const isNotSubmitted = !this.state.submitted[segment];
		return (data.NmpisDeclaration && values && isNotDuplicate && isNotSubmitted);
	}


	/**
	 * Get whether we can currently submit.
	 * 
	 * @return {Boolean}
	 */
	get canSubmit() {
		return (this.isSegmentSubmittable("AM") || this.isSegmentSubmittable("PM"));
	}


	/**
	 * Get whether to disable inputs.
	 * 
	 * @return {Boolean}
	 */
	get disabled() {
		return (this.state.checkingDuplicates || this.state.submitting || this.hasSubmitted);
	}


	/**
	 * Get whether we've made a submission.
	 * 
	 * @return {Boolean}
	 */
	get hasSubmitted() {
		return Object.values(this.state.submitted).some(v => v);
	}

}

export default withSnackbar(TimesheetSubmissionView);
