import React from "react";
import Container from "Components/Container.js";

/**
 * `Container` with more sensible defaults
 *
 * This will replace `Container` in the future.
 * 
 * @package SEC
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class Containerx extends React.PureComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Container
				af={(!this.props.columnar ? "row" : (this.props.disableAutoMobileRows ? "column" : undefined))}
				fullWidth={true}
				{...this.props}>
				{this.props.children}
			</Container>
		);
	}

}

export default Containerx;
