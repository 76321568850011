import React from "react";
import ProjectService from "Services/ProjectService.js";
import SearchablePicker from "Components/SearchablePicker.js";
import SECColour from "Helpers/SECColour.js";
import withSnackbar from "Hoc/withSnackbar";
import {connect} from "react-redux";
import {Typography} from "@material-ui/core";
import {ProjectPicker as Strings} from "Resources/Strings.js";

/**
 * Project picker
 *
 * @package SEC
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class ProjectPicker extends SearchablePicker {

	/**
	 * Get option label.
	 *
	 * @param {Object} option
	 * @return {String}
	 */
	getOptionLabel(option) {
		return [
			option.Id,
			option.Customer.Name,
			option.Description
		].join(" – ");
	}


	/**
	 * Render an option.
	 *
	 * @param {Object} option
	 * @return {void}
	 */
	renderOption(option) {
		return (
			<Typography style={{color: (!this.props.dark ? SECColour(option.BusinessArea) : undefined)}}>
				<span>{option.Id}</span>
				<span> – </span>
				<span style={this.constructor.stylesCustomerName}>{option.Customer.Name}</span>
				<span> – </span>
				<span>{option.Description}</span>
			</Typography>
		);
	}


	/**
	 * Find the option defining a value in an array of options.
	 *
	 * @param {mixed} value
	 * @param {Array} options
	 * @return {mixed}
	 */
	findOptionByValue(value, options) {
		return options.find(o => ((o === value) || (o?.Id === value?.Id)));
	}


	/**
	 * Get whether an option's selected.
	 *
	 * @param {mixed} value
	 * @param {mixed} option
	 * @return {Boolean}
	 */
	getOptionSelected(value, option) {
		return ((value === option) || (value?.Id === option?.Id));
	}


	/**
	 * Changed.
	 *
	 * @param {Event} e
	 * @param {Object} value
	 * @return {void}
	 */
	handleChange(e, value) {
		this.onChangeUpdate((value?.Id || null));
	}


	/**
	 * Changed.
	 *
	 * Invokes `props.onChange` with the project ID and object.
	 *
	 * @param {Integer} id
	 * @return {void}
	 */
	onChangeUpdate(id) {

		let project;
		if (!id) project = null;
		else project = this.state.options.find(e => (e.Id === id));

		this.setState({value: project});

		if (this.props.useNewChangeHandler) {
			this.props.onChange(project, this.props.name);
		}
		else this.props.onChange(id, project);

	}


	/**
	 * Search query promise.
	 *
	 * @param {String} query
	 * @return {Promise} Resolves with array of options
	 */
	searchPromise(query) {
		return ProjectService.search(query.trim()).then(({objects}) => objects);
	}


	/**
	 * Strings.
	 *
	 * @return {Object}
	 */
	get strings() {
		return {...super.strings, ...Strings};
	}


	/**
	 * Styles for the customer's name.
	 * 
	 * @type {Object}
	 */
	static stylesCustomerName = {fontWeight: "bold"};

}

export default connect(({dark}) => ({dark}))(withSnackbar(ProjectPicker));
