import React from "react";
import {connect} from "react-redux";
import {MenuItem} from "@material-ui/core";

/**
 * Data requests context menu
 *
 * @package SEC
 * @subpackage Views
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class DataRequestsContextMenu extends React.PureComponent {

	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<React.Fragment>
				{(this.canView ? this.renderView() : null)}
				{(this.canSend ? this.renderSend() : null)}
				{(this.canDelete ? this.renderDelete() : null)}
			</React.Fragment>
		);
	}


	/**
	 * Render "View".
	 *
	 * @return {ReactNode}
	 */
	renderView() {
		return (
			<MenuItem onClick={() => this.props.onView(this.request)}>
				View
			</MenuItem>
		);
	}


	/**
	 * Render "Send".
	 *
	 * @return {ReactNode}
	 */
	renderSend() {
		return (
			<MenuItem onClick={() => this.props.onSend(this.request)}>
				Send
			</MenuItem>
		);
	}


	/**
	 * Render "Delete".
	 *
	 * @return {ReactNode}
	 */
	renderDelete() {
		return (
			<MenuItem onClick={() => this.props.onDelete(this.request)}>
				Delete
			</MenuItem>
		);
	}


	/**
	 * Get whether the user has permission for the "View" option.
	 *
	 * @return {Boolean}
	 */
	get canView() {
		return this.props.permissions.includes("sec.drequests.get");
	}


	/**
	 * Get whether the request is sendable and the user has permission.
	 *
	 * @return {Boolean}
	 */
	get canSend() {
		if (this.props.request?.sent || this.props.request?._apid) {
			return false;
		}
		else return this.props.permissions.includes("sec.drequests.send");
	}


	/**
	 * Get whether the user has permission for the "Delete" option.
	 *
	 * @return {Boolean}
	 */
	get canDelete() {
		if (this.props.request?.sent) return false;
		else return this.props.permissions.includes("sec.drequests.delete");
	}


	/**
	 * Get the active data request.
	 *
	 * @return {Object|null} [description]
	 */
	get request() {
		return this.props.request;
	}

}

export default connect(
	({permissions}) => ({permissions}), null, null, {forwardRef: true}
)(DataRequestsContextMenu);
