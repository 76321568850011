import React from "react";
import Component from "App/Component.js";
import rem from "Helpers/Rem.js";
import withMobile from "Hoc/withMobile.js";
import withSnackbar from "Hoc/withSnackbar.js";
import UserService from "Services/UserService.js";
import * as mui from "@material-ui/core";
import {connect} from "react-redux";
import {UserEmailEditable as Strings} from "Resources/Strings.js";

/**
 * User email display with editor
 *
 * @package SEC
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class UserEmailEditable extends Component {

	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * State
		 *
		 * @type {Object}
		 */
		this.state = {

			/**
			 * Email
			 *
			 * @type {String}
			 */
			email: "",

			/**
			 * Editing
			 *
			 * @type {Boolean}
			 */
			editing: false,

			/**
			 * Disabled
			 *
			 * @type {Boolean}
			 */
			disabled: false

		};

		/**
		 * Input reference
		 *
		 * @type {ReactRef}
		 */
		this.input = React.createRef();

		/**
		 * Method binds
		 */
		this.cancel = this.cancel.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.save = this.save.bind(this);
		this.start = this.start.bind(this);

	}


	/**
	 * Component mounted.
	 *
	 * @return {void}
	 */
	componentDidMount() {
		this.update();
	}


	/**
	 * Component updated.
	 *
	 * @param {Object} prevProps
	 * @return {void}
	 */
	componentDidUpdate(prevProps) {
		if (prevProps.user !== this.props.user) this.update();
	}


	/**
	 * Value changed.
	 *
	 * @param {Event} e
	 * @return {void}
	 */
	handleChange(e) {
		this.setState({email: e.target.value});
	}


	/**
	 * Cancel.
	 *
	 * @return {void}
	 */
	cancel() {
		this.setState({editing: false, email: this.props.user.EmailAddress});
	}


	/**
	 * Save.
	 *
	 * @return {void}
	 */
	save() {
		if (this.input.current.reportValidity()) {

			const email = this.state.email;
			const profile = (this.user === this.props.activeUser.Username);
			this.setState({disabled: true});

			UserService.updateEmail(this.user, email, profile).then(() => {
				this.props.snackbar(Strings.success, "success");
				this.setState({editing: false, disabled: false});
				if (this.props.onSave) this.props.onSave();
			}).catch(() => {
				this.setState({disabled: false});
				this.props.snackbar(Strings.error, "error");
			});

		}
	}


	/**
	 * Start editing.
	 * 
	 * @return {void}
	 */
	start() {
		this.setState({editing: true});
	}


	/**
	 * Update.
	 *
	 * @return {void}
	 */
	update() {
		this.setState({
			editing: false,
			disabled: false,
			email: this.props.user.EmailAddress
		});
	}


	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (this.state.editing ? this.renderEditing() : this.renderMain());
	}


	/**
	 * Render main view.
	 *
	 * @return {ReactNode}
	 */
	renderMain() {
		return (
			<React.Fragment>
				<mui.Typography>
					<span style={this.props.textStyle}>
						{this.state.email}
					</span>
					{(!this.props.disabled ? this.renderEditLink() : null)}
				</mui.Typography>
			</React.Fragment>
		);
	}


	/**
	 * Render the edit link.
	 * 
	 * @return {ReactNode}
	 */
	renderEditLink() {
		return (
			<mui.Link
				onClick={this.start}
				style={this.constructor.editLinkStyles}>
				[Edit]
			</mui.Link>
		);
	}


	/**
	 * Render editing view.
	 *
	 * @return {ReactNode}
	 */
	renderEditing() {
		return (
			<mui.Box style={this.editorStyles}>
				<mui.TextField
					color="primary"
					inputRef={this.input}
					label="Email address"
					onChange={this.handleChange}
					required={true}
					type="email"
					value={this.state.email} />
				<mui.ButtonGroup>
					<mui.Button
						color="primary"
						disabled={this.state.disabled}
						onClick={this.save}
						variant="outlined">
						Save
					</mui.Button>
					<mui.Button
						color="primary"
						disabled={this.state.disabled}
						onClick={this.cancel}
						variant="outlined">
						Cancel
					</mui.Button>
				</mui.ButtonGroup>
			</mui.Box>
		);
	}


	/**
	 * Get username.
	 *
	 * @return {String}
	 */
	get user() {
		return this.props.user.Username;
	}


	/**
	 * Get styles for editor component.
	 * 
	 * @return {Object}
	 */
	get editorStyles() {
		return {
			display: "grid",
			alignItems: "center",
			gridAutoFlow: (this.props.isMobile ? "row" : "column"),
			gridColumnGap: rem(1),
			gridRowGap: rem(1),
			maxWidth: rem(40)
		};
	}


	/**
	 * Edit link styles.
	 * 
	 * @type {Object}
	 */
	static editLinkStyles = {marginLeft: rem(1)};

}

export default connect(({user}) => ({activeUser: user}))(withMobile(withSnackbar(UserEmailEditable)));
