/**
 * Snackbar service
 *
 * Imperative API wrapper for Notistack.
 *
 * Requires a `SnackbarHost` to be rendered while used.
 */
class SnackbarService {

	/**
	 * Notistack reference
	 *
	 * (Set by `SnackbarHost`)
	 * 
	 * @type {Object|null}
	 */
	static notistack = null;


	/**
	 * Display a snackbar.
	 *
	 * When `variant` is `undefined`, an appropriate default variant 
	 * is assigned to match the type of `msg`. Note that `options.variant` 
	 * will always override `variant` and its default assignation when set.
	 * 
	 * @param {String} msg Automatically stringified when not already one
	 * @param {String} variant optional Notistack variant
	 * @param {Object} options optional Notistack `enqueueSnackbar()` options
	 * @return {void}
	 */
	static snack(msg, variant=undefined, options={}) {

		if (variant === undefined) {
			variant = ((msg instanceof Error) ? "error" : "default");
		}

		if (SnackbarService.notistack && SnackbarService.notistack.enqueueSnackbar) {
			SnackbarService.notistack.enqueueSnackbar(`${msg}`, {variant, ...options});
		}

	}

}

export default SnackbarService;
