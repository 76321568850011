import React from "react";
import Container from "Components/Container.js";
import CustomerService from "Services/CustomerService.js";
import SearchablePicker from "Components/SearchablePicker.js";
import withSnackbar from "Hoc/withSnackbar.js";
import {CustomerPicker as Strings} from "Resources/Strings.js";

/**
 * Customer picker
 *
 * @package SEC
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class CustomerPicker extends SearchablePicker {

	/**
	 * Get option label.
	 *
	 * @param {Object} option
	 * @return {String}
	 */
	getOptionLabel(option) {
		return `${option.CustomerID} – ${option.CustomerName}`;
	}


	/**
	 * Render an option.
	 *
	 * @param {Object} option
	 * @return {void}
	 */
	renderOption(option) {
		return (
			<Container af="row" gap={0.25}>
				<span>{option.CustomerID}</span>
				<span style={this.constructor.customerNameSpanStyles}>
					{option.CustomerName}
				</span>
			</Container>
		);
	}


	/**
	 * Search query promise.
	 * 
	 * @param {String} query
	 * @return {Promise} Resolve with array of options
	 */
	searchPromise(query) {
		return CustomerService.search(query);
	}


	/**
	 * Strings.
	 *
	 * @return {Object}
	 */
	get strings() {
		return {...super.strings, ...Strings};
	}


	/**
	 * Customer name span styles
	 * 
	 * @type {Object}
	 */
	static customerNameSpanStyles = {fontWeight: "bold"};

}

export default withSnackbar(CustomerPicker);
