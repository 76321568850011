import React from "react";
import Component from "App/Component.js";
import Link from "Components/Link.js";
import SpoiService from "../SpoiService.js";

/**
 * SPOI support link
 *
 * @package SEC
 * @subpackage Spoi
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class SpoiSupportLink extends Component {

	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * State
		 *
		 * @type {Object}
		 */
		this.state = {

			/**
			 * Accounts URI
			 * 
			 * @type {String}
			 */
			uri: null,

			/**
			 * Error getting contact username
			 * 
			 * @type {String}
			 */
			error: false

		};

	}


	/**
	 * Component mounted.
	 * 
	 * @return {void}
	 */
	componentDidMount() {
		SpoiService.getAdminMessagesUriNetworked().then(uri => {
			this.setState({uri});
		}).catch(() => this.setState({error: true}));
	}


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Link
				disabled={(this.state.uri === null)}
				title={this.title}
				uri={this.state.uri}>
				{(this.props.children || "Message Accounts")}
			</Link>
		);
	}


	/**
	 * Get the title.
	 *
	 * @return {String}
	 */
	get title() {
		if (!this.state.error) return null;
		else return "Disabled; error loading messages";
	}

}

export default SpoiSupportLink;
