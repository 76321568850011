import Domain from "Domain/Domain.js";
import Time from "Helpers/Time.js";
import ItemCollection from "./SpoiPurchaseInvoiceRaiseItemCollection";

/**
 * SPOI Purchase invoice (raise)
 *
 * An invoice object to be sent in a raise request.
 *
 * @package SECGroup
 * @subpackage Spoi
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class SpoiPurchaseInvoiceRaise extends Domain {

	/**
	 * Constructor.
	 * 
	 * @param {Object} obj optional Construction
	 * @return {self}
	 */
	constructor(obj={}) {
		super(obj);

		/**
		 * `SupplierInvoiceRef`
		 *
		 * @type {String}
		 */
		this.SupplierInvoiceRef = null;

		/**
		 * Invoice date
		 *
		 * @type {String} YYYY-MM-DD
		 */
		this.InvoiceDate = Time.todayYMD();

		/**
		 * Due date (agreement request)
		 *
		 * @type {String|null} YYYY-MM-DD
		 */
		this.DueDatePreagreedRequested = null;

		/**
		 * Raise items
		 *
		 * @type {SpoiPurchaseInvoiceRaiseItemCollection}
		 */
		this.Items = new ItemCollection();

		Object.assign(this, obj);
	}

}

export default SpoiPurchaseInvoiceRaise;
