import React from "react";
import {TableCell, Typography} from "@material-ui/core";

const TABLE_LABEL_STYLES = {fontWeight: "bold"};

export default ({children}) => (
	<TableCell>
		<Typography style={TABLE_LABEL_STYLES}>
			{children}
		</Typography>
	</TableCell>
);
