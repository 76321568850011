import ChartColours from "Resources/ChartColours.json";

export default i => {

	let nth = true;
	const colours = ChartColours;

	/**
	 * We need to make `colours` length match the target index
	 */
	while (colours.length < i) {

		nth = !nth;

		/**
		 * Get lower and upper bounds
		 *
		 * This is here so that after exhausting all the available 
		 * colours, we traverse the list backwards to the start, so 
		 * ensuring a nice smooth gradient in lists and charts.
		 */
		const x = (nth ? ChartColours.length : 0);
		const y = (nth ? 0 : ChartColours.length);

		/**
		 * Add each colour in sequence onto our preparatory list of colours
		 */
		for (let i = x; (nth ? (i > y) : (i < y)); (nth ? i-- : i++)) {
			colours.push(ChartColours[i]);
		}

	}

	return colours[i];
};
