import React from "react";
import Component from "App/Component.js";
import View from "App/View.js";
import String from "Components/String.js";
import Pagination from "Helpers/Pagination.js";
import withSnackbar from "Hoc/withSnackbar.js";
import {Box} from "@material-ui/core";

/**
 * Searchable view
 *
 * A base view intended to render a heading caption, filter widget and 
 * results table, with customisable filter widget and results table 
 * to be passed in as props.
 * 
 * The `searchComponent` prop defines the component type to render as 
 * the filter widget and will be rendered with `disabled` and `onChange` 
 * props added - call `onChange` passing an object of search filter 
 * property/value pairs to update when the user changes the value of 
 * a search filter.
 * 
 * The `tableComponent` prop defines the component type to render as 
 * the results table and will be rendered with props applied as for 
 * a paginated `TableData` component.
 *
 * You must pass a `getResults()` prop as a method which accepts an 
 * object with `page`/`limit`/`filters` properties (as defined in our 
 * state, with `filters` filtered to only include applicable filters 
 * given by `appliedFilters`) and returns a Promise resolving with the 
 * search results for those constraints; the results should be an object 
 * with `count` (as defined by `count` in our state) and `objects` properties 
 * (where the objects` property is an array of objects representing the 
 * results); more details of this prop can be found in `getCurrentResults()`.
 *
 * Define initial search filter values as for `filters` in our state by 
 * passing an `filters` prop defined as given by `filters` in our state.
 * 
 * Please refer to the source for details of all the available props.
 * 
 * @package SEC
 * @subpackage Spoi
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class SearchView extends Component {

	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * State
		 *
		 * @type {Object}
		 */
		this.state = {

			/**
			 * Result objects
			 *
			 * These are passed to the table component for rendering.
			 * 
			 * @type {Array}
			 */
			results: [],

			/**
			 * Current pagination page (zero-indexed)
			 *
			 * @type {Integer}
			 */
			page: 0,

			/**
			 * "Limit"/rows value for pagination purposes
			 *
			 * @type {Integer}
			 */
			limit: 10,

			/**
			 * Delimited count of results available (paginated)
			 *
			 * This should be provided by the root backend response object.
			 * 
			 * @type {Integer}
			 */
			count: null,

			/**
			 * Loading state
			 *
			 * @type {Integer}
			 */
			loading: true,

			/**
			 * Filters to apply when searching
			 *
			 * Property/value key-pairs to be interpreted by `getResults()`.
			 * 
			 * @type {Object}
			 */
			filters: (props.filters || {})

		};
	}


	/**
	 * Component mounted.
	 *
	 * We need to get the initial results.
	 *
	 * @return {void}
	 */
	componentDidMount() {
		this.getCurrentResults();
	}


	/**
	 * Component updated.
	 *
	 * Refresh when search filters change.
	 *
	 * @param {Object} prevProps
	 * @param {Object} prevState
	 * @return {void}
	 */
	componentDidUpdate(prevProps, prevState) {
		const page = (prevState.page !== this.state.page);
		const limit = (prevState.limit !== this.state.limit);
		const filters = (prevState.filters !== this.state.filters);
		if (page || limit || filters) this.getCurrentResults();
	}


	/**
	 * Get results matching the current constraints.
	 *
	 * We invoke the `getResults()` prop passing an object defining 
	 * the constraints, with `page` (1-indexed pagination page number 
	 * to get from), `offset` (pagination computed result object 
	 * repository offset to get from), `limit` (the pagination limit 
	 * value used to compute `offset`) and `filters` (the applicable 
	 * filters given by `appliedFilters`).
	 *
	 * The `getResults()` prop when invoked should return a Promise 
	 * which resolves with an object with `count` (defined as per the 
	 * same-named property in an our state) and `objects` properties, 
	 * where `objects` is an array of objects to render as the results.
	 *
	 * When the Promise is rejected, we automatically display an error 
	 * toast with the text given by the `errorSnack` prop when given.
	 *
	 * @return {void}
	 */
	getCurrentResults() {

		/**
		 * Constraints
		 */
		const obj = {
			page: (this.state.page + 1),
			offset: Pagination((this.state.page + 1), this.state.limit),
			limit: this.state.limit,
			filters: this.appliedFilters
		};
		this.setState({loading: true});

		/**
		 * Get the results
		 */
		this.props.getResults(obj).then(results => {
			this.setState({results: results.objects, count: results.count});
		}).catch(() => {
			this.setState({results: [], count: null});
			this.props.snackbar((this.props.errorSnack || "Error."), "error");
		}).finally(() => this.setState({loading: false}));
	}


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<View {...this.props.viewProps}>
				<Box mb={-0.5}>
					<String str={this.props.label} variant="h5" />
					<String gap={0.5} noParagraph={true} str={this.props.caption} />
				</Box>
				{this.props.extraComponent ? this.props.extraComponent : null}
				{this.renderSearch()}
				{this.renderTable()}
				{this.props.children}
			</View>
		);
	}


	/**
	 * Render the search widget.
	 *
	 * @return {ReactNode}
	 */
	renderSearch() {
		return this.renderSearchComponent(this.props.searchComponent);
	}


	/**
	 * Render a component as the search widget.
	 * 
	 * @param {ReactNode} TableComponent Component type to render
	 * @return {ReactNode}
	 */
	renderSearchComponent(SearchComponent) {
		return (
			<SearchComponent
				{...this.state.filters}
				disabled={this.state.loading}
				onChange={filters => this.updateFilters(filters)} />
		);
	}


	/**
	 * Render the results table.
	 *
	 * @return {ReactNode}
	 */
	renderTable() {
		return this.renderTableComponent(this.props.tableComponent);
	}


	/**
	 * Render a component as the results table.
	 * 
	 * @param {ReactNode} TableComponent Component type to render
	 * @return {ReactNode}
	 */
	renderTableComponent(TableComponent) {
		return (
			<TableComponent
				count={this.state.count}
				loading={this.state.loading}
				onChangePage={page => this.setState({page})}
				onChangeRows={limit => this.setState({page: 0, limit})}
				page={this.state.page}
				rows={this.state.limit}
				values={this.state.results} />
		);
	}


	/**
	 * Update filters.
	 *
	 * As defined in the component-level docblock, this must be 
	 * passed an object of filter name/value pairs as given in 
	 * `filters` within our current state; each named filter will 
	 * then have its value updated to match the value given in 
	 * the passed object. We also reset to the first page so that 
	 * the results now display as expected for the new constraints.
	 * 
	 * @param {Object} filters
	 * @return {void}
	 */
	updateFilters(filters) {
		this.setState({
			page: 0,
			filters: {...this.state.filters, ...filters}
		});
	}


	/**
	 * Get applied filters.
	 *
	 * This defines the subset of filters from `filters` in our 
	 * state which should actually be applied when getting results.
	 *
	 * We automatically exclude any `null` or `undefined` filter values.
	 * 
	 * @return {Object}
	 */
	get appliedFilters() {
		const filters = {};
		Object.keys(this.state.filters).forEach(k => {
			const value = this.state.filters[k];
			if (![null, undefined].includes(value)) {
				filters[k] = value;
			}
		});
		return filters;
	}

}

export default withSnackbar(SearchView);
