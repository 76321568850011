import Store from "App/Store.js";
import dispatchMessages from "Dispatchers/Messages.js";
import dispatchMessagesRead from "Dispatchers/MessagesRead.js";
import dispatchPolling from "Dispatchers/Polling.js";
import MessageHelper from "Messages/MessageHelper.js";
import MessageService from "Services/MessageService.js";

/**
 * Messenger
 *
 * A daemon to refresh the unread messages in the app's state.
 *
 * @package SEC
 * @subpackage App
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class Messenger {

	/**
	 * Poll.
	 *
	 * Polling is cancelled if we're offline or already polling.
	 *
	 * We make sure not to request messages we already have.
	 * 
	 * @return {void}
	 */
	poll() {
		if (Store.getState().polling) return;
		else if (!navigator.onLine) return;
		else this.pollMain(this.threshold);
	}


	/**
	 * Run the actual poll.
	 *
	 * Do not call directly - use `poll()`!
	 *
	 * Only messages sent after the most recent message we have are got.
	 *
	 * We also cull any messages which are marked read on the server.
	 * 
	 * @param {Integer} sentmin optional Minimum sent time
	 * @return {void}
	 */
	pollMain(sentmin=null) {

		dispatchPolling(true);
		const read = MessageService.getRead(this.ids).catch(() => null);
		const unread = MessageService.getUnread(sentmin).catch(() => null);

		Promise.all([unread, read]).then(([msgs, read]) => {
			dispatchMessages([
				...this.messages.filter(m => !read.includes(m.Id)),
				...msgs.filter(m => !this.ids.includes(m.Id))
			]);
		}).catch(() => null).finally(() => dispatchPolling(false));

	}


	/**
	 * Mark all stored messages as read.
	 *
	 * @return {Promise}
	 */
	markAllRead() {
		return MessageService.markReadIds(this.ids).then(() => {
			dispatchMessagesRead();
		});
	}


	/**
	 * Mark a conversation with a contact as read.
	 *
	 * @param {String} contact Conversation contact name
	 * @return {void}
	 */
	markConversationRead(contact) {
		return MessageService.markConversationRead(contact).then(() => {
			this.markConversationReadLocally(contact);
		});
	}


	/**
	 * Mark a conversation read locally, so its messages are removed.
	 *
	 * This does not mark the conversation read on the server!
	 *
	 * @param {String} c Conversation contact name
	 * @return {void}
	 */
	markConversationReadLocally(c) {
		dispatchMessages(this.messages.filter(msg => msg.Sender !== c));
	}


	/**
	 * Mark a message as read.
	 *
	 * @param {String} id
	 * @return {Promise}
	 */
	markRead(id) {
		return MessageService.markReadIds([id]).then(() => {
			dispatchMessages(this.messages.filter(msg => msg.Id !== id));
		});
	}


	/**
	 * Mark an array of message IDs as read.
	 *
	 * @param {String} id
	 * @return {Promise}
	 */
	markReadIds(ids) {
		return MessageService.markReadIds(ids).then(() => {
			dispatchMessages(this.messages.filter(m => !ids.includes(m.Id)));
		});
	}


	/**
	 * Delete a conversation with a contact.
	 *
	 * @param {String} c Conversation contact name
	 * @return {void}
	 */
	deleteConversation(contact) {
		return MessageService.deleteConversation(contact).then(() => {
			this.markConversationReadLocally(contact);
		});
	}


	/**
	 * Get array of all stored message IDs.
	 *
	 * @return {Array}
	 */
	get ids() {
		return this.messages.map(m => m.Id);
	}


	/**
	 * Get array of all currently stored messages.
	 *
	 * @return {Array}
	 */
	get messages() {
		return Store.getState().messages;
	}


	/**
	 * Get time threshold for known messages.
	 *
	 * This is the highest `SentTime` of all the messages we have.
	 *
	 * @return {Integer|null}
	 */
	get threshold() {
		const msgs = MessageHelper.sortNetworked(this.messages);
		return ((msgs.length > 0) ? msgs[0].SentTime : null);
	}

}

export default new Messenger();
