import React from "react";
import DashboardCard from "Components/DashboardCard.js";

/**
 * "My Purchase Orders" dashboard card
 *
 * @package SEC
 * @subpackage Spoi
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class SpoiPosDashboardCard extends React.PureComponent {

	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<DashboardCard
				buttonLbl="My Purchase Orders"
				caption="Purchase Orders"
				text={this.text}
				title="My Purchase Orders"
				uri="/spoi/orders" />
		);
	}


	/**
	 * Get our text.
	 *
	 * @return {Array}
	 */
	get text() {
		return [
			"View orders we've placed with you.",
			"Raise invoices against live orders."
		];
	}

}

export default SpoiPosDashboardCard;
