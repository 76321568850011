import api from "api.js";

/**
 * Push service (Web Push)
 *
 * @package SEC
 * @subpackage Services
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class PushService {

	/**
	 * Subscribe to push notifications.
	 *
	 * @param {PushSubscription} pushSubscription
	 * @return {Promise}
	 */
	static subscribe(pushSubscription) {
		return api.call({
			url: `/api/push/subscribe`,
			method: "POST",
			data: {
				auth: (pushSubscription.toJSON().keys?.auth || null),
				endpoint: pushSubscription.endpoint,
				expirationTime: (pushSubscription.expirationTime ? Math.floor((pushSubscription.expirationTime / 1000)) : null),
				p256dh: (pushSubscription.toJSON().keys?.p256dh || null)
			}
		});
	}


	/**
	 * Unsubscribe push notifications.
	 *
	 * @param {PushSubscription} pushSubscription
	 * @return {Promise}
	 */
	static unsubscribe(pushSubscription) {
		return api.call({
			url: `/api/push/unsubscribe`,
			method: "POST",
			data: {endpoint: pushSubscription.endpoint}
		});
	}


	/**
	 * Get the server's public VAPID key.
	 *
	 * @return {Promise} Resolves with key string
	 */
	static getServerVapidKey() {
		return api.call({url: `/api/push/vapid`}).then(({data}) => data);
	}

}

export default PushService;
