import React from "react";
import Dialog from "Components/Dialog.js";
import Strings from "./TimesheetsSubmissionView.strings.json";

/**
 * Timesheet submission view dialog
 * 
 * @package SEC
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class TimesheetSubmissionViewDialog extends React.PureComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Dialog
				content={Strings.submitDialog}
				onClose={this.props.onClose}
				onOk={this.props.onSubmit}
				open={this.props.open}
				title="Submit Timesheet" />
		);
	}

}

export default TimesheetSubmissionViewDialog;
