/**
 * Collection
 *
 * This is intended for use with class instances.
 * 
 * @package SEC
 * @subpackage Domain
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class Collection {

	/**
	 * Constructor.
	 *
	 * @type {Array} items optional Items
	 * @return {self}
	 */
	constructor(items=[]) {
		this.items = items;
	}


	/**
	 * Add an item.
	 *
	 * @param {mixed} item
	 * @return {void}
	 */
	add(item) {
		this.items.push(item);
	}


	/**
	 * Clone the collection.
	 *
	 * This will also clone all the items in the collection.
	 *
	 * A new instance of each item will be created, using the type 
	 * given by the `type` getter (to be defined by children), and 
	 * the properties from the existing instance will then be assigned 
	 * to the new instance, effectively creating a cloned class instance.
	 * 
	 * @return {Collection}
	 */
	clone() {
		const type = this.constructor.type;
		const items = this.items.map(i => Object.assign(new type(), i));
		return new this.constructor(items);
	}


	/**
	 * Delete an item from the collection.
	 *
	 * Matches the item's identity exactly within the collection.
	 *
	 * @param {mixed} item
	 * @return {void}
	 */
	delete(item) {
		this.items = this.items.filter(i => (i !== item));
	}


	/**
	 * Get all items.
	 *
	 * @return {Array}
	 */
	get() {
		return this.items;
	}


	/**
	 * Update an item's properties using `Object.assign()`.
	 * 
	 * @param {mixed} item
	 * @param {Object} obj
	 * @return {void}
	 */
	update(item, obj) {
		if (this.items.includes(item)) {
			Object.assign(this.items.filter(i => (i === item))[0], obj);
		}
	}


	/**
	 * Get count of items.
	 *
	 * @return {Integer}
	 */
	get length() {
		return this.items.length;
	}


	/**
	 * Construct a new collection instance.
	 * 
	 * Accepts an array of items which will automatically be 
	 * transformed into instances of the object type given 
	 * by our `type` static getter (for items which are not 
	 * already instances of that type); the item will be 
	 * passed as the sole argument to the type's constructor.
	 *
	 * The constructor signature of our collection is assumed 
	 * to be the same as that given by this base class.
	 *
	 * @param {Array} items optional See above
	 * @return {Collection}
	 */
	static construct(items=[]) {
		const type = this.type;
		return new this(items.map(i => {
			if (!(i instanceof type)) {
				return new type(i);
			}
			else return i;
		}));
	}


	/**
	 * Children can implement this to declare the type of object to work with.
	 *
	 * This will be followed by some interfaces and required by some others.
	 * 
	 * @return {Object}
	 */
	static get type() {
		return null;
	}

}

export default Collection;
