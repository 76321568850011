import React from "react";
import Component from "App/Component.js";
import SECIcon from "Components/SECIcon.js";
import rem from "Helpers/Rem.js";
import withSnackbar from "Hoc/withSnackbar.js";
import Message from "./Message.js";
import MessageComposer from "./MessageComposer.js";
import MessageThreadChat from "./MessageThreadChat.js";
import MessageThreadHeader from "./MessageThreadHeader.js";
import MessageService from "Services/MessageService.js";
import Messenger from "App/Messenger.js";
import {connect} from "react-redux";
import {Box, withWidth} from "@material-ui/core";
import {MessageThread as Strings} from "Resources/Strings.js";

/**
 * Message thread
 *
 * @package SEC
 * @subpackage Messages
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class MessageThread extends Component {

	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * Messages to retrieve
		 *
		 * Dynamically set from `limitInitial`.
		 *
		 * @type {Integer}
		 */
		this.limit = null;

		/**
		 * Messages to retrieve
		 *
		 * Defines the initial limit for the conversation
		 *
		 * Dynamically adjusted so we always fetch all unread messages.
		 *
		 * @type {Integer}
		 */
		this.limitInitial = 15;

		/**
		 * State
		 *
		 * @type {Object}
		 */
		this.state = this.constructor.initialState;

	}


	/**
	 * Component mounted.
	 *
	 * @return {void}
	 */
	componentDidMount() {
		if (this.props.convo) this.update();
	}


	/**
	 * Component updated.
	 *
	 * @param {Object} prevProps
	 * @return {void}
	 */
	componentDidUpdate(prevProps) {
		if (this.props.contact !== prevProps.contact) {
			if (this.props.convo) this.update();
			this.setState(this.constructor.initialState);
		}
		else if (this.props.messages !== prevProps.messages) {
			this.updateLocal();
		}
	}


	/**
	 * Get messages on page from server.
	 *
	 * @param {Integer} page optional (`1`)
	 * @param {Integer} offsetExtra optional Optionally extend `offsetExtra`
	 * @return {Promise}
	 */
	getMessages(page=1, offsetExtra=null) {
		const contact = this.props.contact;
		const offset = (((page - 1) * this.limit) + (offsetExtra || 0));
		return MessageService.getConversation(contact, this.limit, offset);
	}


	/**
	 * Load more messages.
	 *
	 * @param {Integer} offset optional Offset to add to calculated offset
	 * @return {void}
	 */
	loadMore(offset=null) {
		const contact = this.props.contact;
		const messagesPage = (this.state.messagesPage + 1);
		this.setState({loadingMore: true, messagesPage});

		/**
		 * Get the messages
		 */
		this.getMessages(messagesPage, offset).then(msgs => {
			if (this.props.contact === contact) {
				if (msgs.length === 0) {
					this.setState({nomore: true});
					this.props.snackbar(Strings.nomore, "info");
				}
				this.setState({messages: [...msgs, ...this.state.messages]});
			}
		}).catch(() => {
			this.props.snackbar(Strings.error, "error");
		}).finally(() => this.setState({loadingMore: false}));
	}


	/**
	 * Update the view for current conversation.
	 *
	 * @return {void}
	 */
	update() {
		this.limit = this.limitInitial;
		this.setState({loading: true});
		const user = this.props.contact;

		/**
		 * Determine message count to get
		 */
		MessageService.getConversationUnreadCount(user).then(unread => {
			this.limit = (((unread || 0) < this.limit) ? this.limit : (unread || 0));

			/**
			 * Actually get the messages
			 */
			return this.getMessages().then(messages => {
				if (this.props.contact === user) {
					this.setState({messages});
					return Messenger.markConversationRead(user).catch(() => {
						this.props.snackbar(Strings.markerror, "error");
					});
				}
				else return undefined;
			});
		}).catch(() => {
			this.setState({error: true});
		}).finally(() => this.setState({loading: false}));

	}


	/**
	 * Update messages from locally cached messages from `Messenger`.
	 *
	 * This means we properly handle new incoming pushed messages.
	 *
	 * We only want the messages relevant to this conversation, 
	 * and which we are not already displaying (by ID).
	 *
	 * @return {void}
	 */
	updateLocal() {
		const m = this.props.messages;
		const i = this.state.messages.map(m => m.Id).filter(m => (m !== null));

		/**
		 * Find our messages
		 */
		const msgs = m.filter(msg => (msg.Sender === this.props.contact));
		const msgsValid = msgs.filter(msg => !i.includes(msg.Id));

		/**
		 * Create our messages
		 */
		const messages = msgsValid.map(msg => {
			return new Message(
				msg.Message,
				msg.Sender,
				msg.Recipient,
				msg.SentTime,
				((msg.ReadTime !== null)),
				msg.Id
			);
		});

		/**
		 * Mark conversation read
		 */
		if (msgsValid.length > 0) {
			Messenger.markConversationRead(this.props.contact).catch(() => {
				this.props.snackbar(Strings.markerror, "error");
			});
		}
		this.setState({messages: [...messages, ...this.state.messages]});
	}


	/**
	 * A message was sent.
	 *
	 * @param {String} msg
	 * @param {String} contact
	 * @return {void}
	 */
	handleSent(msg, contact) {

		/**
		 * We might have changed thread while send was happening
		 */
		if (contact !== this.props.contact) return;

		/**
		 * Create the message
		 */
		const message = new Message(msg, this.props.username, contact);
		this.setState({messages: [message, ...this.state.messages]});

		/**
		 * Notify subscribers via props
		 */
		this.props.onSent(message);

	}


	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (this.convo ? this.renderMain() : <SECIcon />);
	}


	/**
	 * Render main.
	 * 
	 * @return {ReactNode}
	 */
	renderMain() {
		return (
			<Box style={this.stylesMain}>
				<MessageThreadHeader
					convo={this.props.convo}
					onBack={() => this.props.onHide()} />
				<MessageThreadChat
					contact={this.props.contact}
					done={this.state.nomore}
					error={this.state.error}
					loading={this.state.loadingMore}
					ready={!this.state.loading}
					messages={this.state.messages}
					onWantsMore={offset => this.loadMore(offset)} />
				<MessageComposer
					contact={this.props.contact}
					onSent={(m, contact) => this.handleSent(m, contact)} />
			</Box>
		);
	}


	/**
	 * Get conversation.
	 *
	 * @return {Object}
	 */
	get convo() {
		return this.props.convo;
	}


	/**
	 * Get whether we have messages.
	 *
	 * @return {Boolean}
	 */
	get msgs() {
		return (this.state.messages.length > 0);
	}


	/**
	 * Mobile view.
	 *
	 * @return {Boolean}
	 */
	get mobile() {
		return (this.props.width === "xs");
	}


	/**
	 * Main styles.
	 *
	 * @return {Object}
	 */
	get stylesMain() {
		return {
			display: "flex",
			flexDirection: "column",
			paddingLeft: (this.mobile ? 0 : rem())
		};
	}


	/**
	 * Map state to props.
	 *
	 * @param {Object} state
	 * @return {Object}
	 */
	static mapStateToProps(state) {
		return {
			messages: state.messages,
			username: state.user.Username
		};
	}


	/**
	 * Get the initial state.
	 *
	 * @return {Object}
	 */
	static get initialState() {
		return {

			/**
			 * Error
			 *
			 * @type {Boolean}
			 */
			error: false,

			/**
			 * Loading
			 *
			 * @type {Boolean}
			 */
			loading: true,

			/**
			 * Loading more messages
			 *
			 * @type {Boolean}
			 */
			loadingMore: false,

			/**
			 * Messages
			 *
			 * @type {Array}
			 */
			messages: [],

			/**
			 * Messages page offset
			 *
			 * @type {Integer}
			 */
			messagesPage: 1,

			/**
			 * No more messages available in conversation
			 * 
			 * @type {Boolean}
			 */
			nomore: false

		};
	}

}

export default connect(MessageThread.mapStateToProps)(
	withSnackbar(withWidth()(MessageThread))
);
