import React from "react";
import CaptionedDetailsContainer from "./CaptionedDetailsContainer.js";
import LabelledCaption from "./LabelledCaption.js";

/**
 * Captioned details component
 *
 * A component which renders a set of "details" as `LabelledCaption` 
 * instances within a vertically aligned container.
 *
 * You must pass a `details` prop as an array of objects with `label`,
 * `tooltip` and `value` properties for passing to `LabelledCaption`.
 *
 * Any objects in the `details` array with a `hidden` property with 
 * a `true` value will be omitted from rendering.
 * 
 * @package SEC
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class CaptionedDetails extends React.PureComponent {

	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<CaptionedDetailsContainer>
				{
					this.details.map((detail, k) => (
						<LabelledCaption
							label={detail.label}
							key={k}
							tooltip={detail.tooltip}
							value={detail.value} />
					))
				}
			</CaptionedDetailsContainer>
		);
	}


	/**
	 * Get details for rendering.
	 *
	 * @return {Array}
	 */
	get details() {
		return this.props.details.filter(detail => (detail.hidden !== true));
	}

}

export default CaptionedDetails;
