import React from "react";
import DialogLoader from "Components/DialogLoader.js";
import SpoiService from "../SpoiService.js";
import withSnackbar from "Hoc/withSnackbar.js";

/**
 * SPOI PO document viewer
 *
 * @package SECGroup
 * @subpackage Spoi
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class SpoiPoDocumentViewer extends React.PureComponent {

	/**
	 * Constructor.
	 * 
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * Cached URL
		 *
		 * @type {String|null}
		 */
		this.url = null;

	}


	/**
	 * Component mounted.
	 * 
	 * @return {void}
	 */
	componentDidMount() {
		if (this.props.po && this.props.open) {
			this.update();
		}
	}


	/**
	 * Component updated.
	 *
	 * @param {Object} prevProps
	 * @return {void}
	 */
	componentDidUpdate(prevProps) {

		if (prevProps.po !== this.props.po) {
			this.url = null;
		}

		if ((prevProps.open !== this.props.open) || (prevProps.po !== this.props.po)) {
			if (this.props.po && this.props.open) {
				this.update();
			}
		}

	}


	/**
	 * Update URL.
	 * 
	 * @return {void}
	 */
	update() {
		if (this.url) {
			window.open(this.url);
			this.props.onClose();
		}
		else this.updateNetworked();
	}


	/**
	 * Update URL over network.
	 * 
	 * @return {void}
	 */
	updateNetworked() {
		SpoiService.getPoDocumentUri(this.props.po.Id).then(url => {
			this.url = url;
			window.open(this.url);
		}).catch(e => {
			this.props.snackbar(e);
		}).finally(() => {
			this.props.onClose();
		});
	}


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return <DialogLoader open={this.props.open} />;
	}

}

export default withSnackbar(SpoiPoDocumentViewer);
