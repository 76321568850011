import React from "react";
import Container from "Components/Container.js";
import DatePicker from "Components/DatePicker.js";
import SearchCard from "Components/SearchCard.js";
import TextField from "Components/TextField.js";
import StatusPicker from "./SpoiStatusPicker.js";

/**
 * Purchase orders search view
 *
 * @package SEC
 * @subpackage Spoi
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class SpoiPurchaseOrdersSearch extends React.PureComponent {

	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<SearchCard>
				<Container
					fullWidth={true}
					noFullWidth100={true}>
					<StatusPicker
						disabled={this.props.disabled}
						onChange={Statuses => this.props.onChange({Statuses})}
						po={true}
						pi={false}
						value={this.props.Statuses} />
				</Container>
				<Container
					columns="max-content 10.5rem 10.5rem"
					fullWidth={true}
					marginBottom={null}
					marginBottomMobileOnly={true}
					noFullWidth100={true}>

					<TextField
						disabled={this.props.disabled}
						helperText="Order ID, Project ID or Customer Name"
						label="Search"
						placeholder="Search"
						onChange={search => this.props.onChange({search})}
						value={(this.props.search || "")} />
					<DatePicker
						clearable={true}
						disabled={this.props.disabled}
						label="Date (start)"
						onChange={d => this.props.onChange({RaisedDateMin: d})}
						value={this.props.RaisedDateMin} />
					<DatePicker
						clearable={true}
						disabled={this.props.disabled}
						label="Date (end)"
						onChange={d => this.props.onChange({RaisedDateMax: d})}
						value={this.props.RaisedDateMax} />
				</Container>
			</SearchCard>
		);
	}

}

export default SpoiPurchaseOrdersSearch;
