import React from "react";
import CaptionedDetails from "Components/CaptionedDetails.js";
import Container from "Components/CaptionedDetailsContainer.js";
import Currency from "Helpers/Currency.js";
import Time from "Helpers/Time.js";
import Link from "../Atoms/SpoiPoLink.js";
import SpoiPurchaseOrderDetail from "./SpoiPurchaseOrderDetail.js";

/**
 * Purchase invoice view detail
 *
 * @package SEC
 * @subpackage Spoi
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class SpoiPurchaseInvoiceDetail extends React.PureComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Container>
				<CaptionedDetails details={this.details} />
				<SpoiPurchaseOrderDetail po={this.props.pi.PurchaseOrder} />
			</Container>
		);
	}


	/**
	 * Get details for rendering.
	 *
	 * @return {Array}
	 */
	get details() {
		return [
			{
				label: "Submitted",
				tooltip: "The date you submitted this invoice",
				value: Time.dmyFromYmd(this.props.pi.ReceivedDate, "/"),
				hidden: !this.props.pi.hasUuid
			},
			{
				label: "VAT",
				value: Currency.format(this.props.pi.VATCurrent),
				hidden: !this.props.pi.hasUuid
			},
			{
				label: "Order",
				value: <Link poid={this.props.pi.po.Id} noTypography={true} />
			}
		];
	}

}

export default SpoiPurchaseInvoiceDetail;
