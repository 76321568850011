import React from "react";
import Currency from "Helpers/Currency.js";
import SpoiTotal from "../Atoms/SpoiTotalTypography.js";
import {Typography} from "@material-ui/core";

const TYPOGRAPHY_PROPS = {style: {wordBreak: "break-word"}};

export default ((po, pis, items) => {
	return [
		{
			label: <Typography>Order</Typography>,
			value: <Typography {...TYPOGRAPHY_PROPS}>{Currency.format(po?.Total)}</Typography>
		},
		{
			label: <Typography>Invoiced</Typography>,
			value: <Typography {...TYPOGRAPHY_PROPS}>{Currency.format(pis?.total)}</Typography>
		},
		{
			label: <Typography>This Invoice</Typography>,
			value: <SpoiTotal error={((((items?.total || 0) + (pis?.total || 0)) > po?.Total) && (items.total > 0))} total={items?.total} />
		},
		{
			label: <Typography>Invoice VAT</Typography>,
			value: <Typography {...TYPOGRAPHY_PROPS}>{Currency.format(items?.totalVat)}</Typography>
		}
	];
});
