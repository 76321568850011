import React from "react";
import Container from "Components/Containerx.js";
import Loader from "Components/Loader.js";
import ReactHelper from "Helpers/ReactHelper.js";
import String from "Components/Stringx.js";
import Strings from "./TimesheetsSubmissionView.strings.json";
import {Tooltip} from "@material-ui/core";
import {Check as CheckIcon, Close as CrossIcon} from "@material-ui/icons";

/**
 * Timesheet submission card status item
 *
 * @package SEC
 * @subpackage Timesheets
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class TimesheetSubmissionCardStatus extends React.PureComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Container
				alignItems="center"
				columnar={true}
				gap={0.5}
				gridAutoColumns="max-content">
				{(this.loading && <Loader size={20} />)}
				{(!this.loading && this.renderMain())}
			</Container>
		);
	}


	/**
	 * Render main content.
	 * 
	 * @return {ReactNode}
	 */
	renderMain() {

		const status = this.status;
		const color = (status?.color || "primary");

		if (status) {
			return <>
				{ReactHelper.render((status.icon || CheckIcon), {color})}
				<Tooltip arrow={true} title={(status.tooltip || "")}>
					<div><String color={color} str={status.label} /></div>
				</Tooltip>
			</>;
		}
		else return null;

	}


	/**
	 * Get whether to render the loading state.
	 * 
	 * @return {Boolean}
	 */
	get loading() {
		return (this.props.loading && !this.props.submitted);
	}


	/**
	 * Get the current status object.
	 *
	 * @return {Object}
	 */
	get status() {
		if (this.props.submitted) return this.constructor.statuses.submitted;
		else if (this.props.duplicate) return this.constructor.statuses.duplicate;
		else if (this.props.error) return this.constructor.statuses.error;
		else if (this.props.ready) return this.constructor.statuses.ready;
		else return null;
	}


	/**
	 * Statuses.
	 * 
	 * @type {Object}
	 */
	static statuses = {
		duplicate: {
			label: Strings.statuses.duplicate,
			tooltip: Strings.statuses.duplicateCaption
		},
		error: {
			color: "error",
			icon: CrossIcon,
			label: Strings.statuses.error
		},
		ready: {
			label: Strings.statuses.ready
		},
		submitted: {
			label: Strings.statuses.submitted,
			tooltip: Strings.statuses.submittedCaption
		}
	};

}

export default TimesheetSubmissionCardStatus;
