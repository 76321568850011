import React from "react";
import Navigator from "App/Navigator.js";
import PushBanner from "Components/PushBanner.js";
import SpoiNpdtBanner from "Spoi/Components/SpoiNextPaymentDateTotalBanner.js";
import SpoiPisDashboardCard from "Spoi/Components/SpoiPisDashboardCard.js";
import SpoiPosDashboardCard from "Spoi/Components/SpoiPosDashboardCard.js";
import TimesheetsDashboardCard from "Timesheets/TimesheetsDashboardCard.js";
import View from "App/View.js";
import rem from "Helpers/Rem.js";
import withMobile from "Hoc/withMobile.js";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {Box, Typography} from "@material-ui/core";

/**
 * Dashboard
 *
 * @package SEC
 * @subpackage Views
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class Dashboard extends React.PureComponent {

	/**
	 * State
	 *
	 * @type {Object}
	 */
	state = {

		/**
		 * Cards to display
		 * 
		 * @type {Array|null}
		 */
		cards: this.cards

	};


	/**
	 * Component mounted.
	 *
	 * @return {void}
	 */
	componentDidMount() {
		if (this.props.location.pathname !== "/") {
			Navigator.home();
		}
		this.updateCards();
	}


	/**
	 * Component updated.
	 * 
	 * @param {Object} prevProps
	 * @return {void}
	 */
	componentDidUpdate(prevProps) {
		if (prevProps.permissions !== this.props.permissions) {
			this.updateCards();
		}
	}


	/**
	 * Update cards.
	 *
	 * @return {void}
	 */
	updateCards() {
		this.setState({cards: this.cards});
	}


	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<View>
				<Typography variant="h6">
					Welcome to SEC Hub
					{(this.props.user.Xorg ? ` - ${this.props.user.Xorg.Name}` : null)}
				</Typography>
				{(!!this.state.cards?.length && this.renderCards())}
				<Typography color="textSecondary" variant="body1">&copy;SEC Group.</Typography>
			</View>
		);
	}


	/**
	 * Render the cards.
	 * 
	 * @return {ReactNode}
	 */
	renderCards() {
		return (
			<Box style={this.stylesGrid}>
				{
					this.state.cards.map((card, key) => {
						const Component = card.component;
						const props = card.props;
						return <Component {...props} key={key} />;
					})
				}
			</Box>
		);
	}


	/**
	 * Get card components to render.
	 *
	 * @return {Array}
	 */
	get cards() {
		return this.constructor.cards.filter(card => {
			return (this.props.permissions.includes(card.permission) || !card.permission);
		});
	}


	/**
	 * Styles (cards).
	 * 
	 * @return {Object}
	 */
	get stylesGrid() {
		return {
			display: "grid",
			gap: rem(2),
			gridAutoRows: "max-content",
			gridTemplateColumns: (this.props.isMobile ? "100%" : "repeat(auto-fit, 30rem)")
		};
	}


	/**
	 * Card definitions.
	 * 
	 * @type {Array}
	 */
	static cards = [
		{
			component: PushBanner,
			props: {style: {gridColumn: "1/-1"}}
		},
		{
			component: SpoiNpdtBanner,
			permission: "sec.spoi.GetPurchaseInvoices",
			props: {style: {gridColumn: "1/-1"}}
		},
		{
			component: SpoiPosDashboardCard,
			permission: "sec.spoi.GetPurchaseOrders"
		},
		{
			component: SpoiPisDashboardCard,
			permission: "sec.spoi.GetPurchaseInvoices"
		},
		{
			component: TimesheetsDashboardCard,
			permission: "sec.Timesheets"
		}
	];

}

export default connect(({permissions, user}) => ({permissions, user}))(withMobile(withRouter(Dashboard)));
