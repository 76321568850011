import {api, uris} from "api.js";

/**
 * User service
 *
 * @package SEC
 * @subpackage Services
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class UserService {

	/**
	 * Cached requests
	 *
	 * URI => Promise
	 *
	 * @type {Object}
	 */
	static cache = {};


	/**
	 * Get users sorted alphabetically.
	 *
	 * Requests are cached for the duration of the session.
	 *
	 * The cache is invalidated when an error occurs for a request, or 
	 * if `force` is set (to force reset the cache now).
	 * 
	 * @param {string} customer optional Customer ID (defaults to SEC)
	 * @param {boolean} force optional Force invalidate cache (`false`)
	 * @return {Promise}
	 */
	static get(customer=null, force=false) {
		let u = uris.users.get;
		if (customer) u += `?customer=${customer}`;
		if (!(u in this.cache) || force) {
			this.cache[u] = api.call(u).then(({data}) => data).catch(e => {
				delete this.cache[u];
				throw e;
			});
		}
		return this.cache[u];
	}


	/**
	 * Create a user.
	 *
	 * @param {Object} user
	 * @return {Promise}
	 */
	static create(user) {
		if (user.CustomerId === null) delete user.CustomerId;
		return api.call({method: "POST", data: user, url: uris.users.post});
	}


	/**
	 * Reset a user's password.
	 *
	 * @param {String} user Username
	 * @return {Promise}
	 */
	static pwr(user) {
		return api.call({method: "POST", url: `${uris.users.post}/${user}/pwr`});
	}


	/**
	 * Disable a user account.
	 *
	 * @param {String} user Username
	 * @return {Promise}
	 */
	static disable(user) {
		return api.call({method: "POST", url: `${uris.users.post}/${user}/disable`});
	}


	/**
	 * Enable a user account.
	 *
	 * @param {String} user Username
	 * @return {Promise}
	 */
	static enable(user) {
		return api.call({method: "POST", url: `${uris.users.post}/${user}/enable`});
	}


	/**
	 * Delete a user account
	 * 
	 * @param {String} user Username
	 * @return {Promise}
	 */
	static delete(user) {
		return api.call({method: "DELETE", url: `${uris.users.post}/${user}`});
	}


	/**
	 * Update a user's email address.
	 *
	 * @param {String} username
	 * @param {String} email
	 * @param {Boolean} profile optional Update current profile (`false`)
	 * @return {Promise}
	 */
	static updateEmail(username, email, profile=false) {
		let url = `${uris.users.post}/${username}`;
		if (profile) url = uris.profile.post;
		return api.call({
			method: "POST",
			data: {EmailAddress: email},
			url
		});
	}

}

export default UserService;
