import Base from "./HealthSafetyNmpisStatisticsEnumInsight.js";

/**
 * Health and safety NMPIs incidents statistic
 *
 * @package SEC
 * @subpackage Views
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class HealthSafetyNmpisStatisticsIncidents extends Base {

	/**
	 * Label.
	 *
	 * @return {String}
	 */
	get label() {
		return "Near Miss Potential Incidents";
	}


	/**
	 * Statistics for data.
	 *
	 * @return {Object}
	 */
	get stats() {
		return this.props.stats.incidents;
	}

}

export default HealthSafetyNmpisStatisticsIncidents;
