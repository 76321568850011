import React from "react";
import {DatePicker as MaterialDatePicker} from "@material-ui/pickers";

/**
 * Date picker
 *
 * An abstraction of Material UI Pickers' `DatePicker`.
 *
 * Most props are forwarded to the Material `DatePicker`.
 *
 * Please refer to the source for details of the available props.
 *
 * @package SEC
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class DatePicker extends React.PureComponent {

	/**
	 * Constructor.
	 * 
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * Method binds
		 */
		this.handleChange = this.handleChange.bind(this);

	}


	/**
	 * Render the component.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<MaterialDatePicker
				autoOk
				clearable={this.props.clearable}
				disabled={this.props.disabled}
				disableFuture={!this.props.enableFuture}
				disablePast={this.props.disablePast}
				DialogProps={this.constructor.DialogProps}
				emptyLabel={this.props.emptyLabel}
				format={this.displayFormat}
				fullWidth={this.props.fullWidth}
				helperText={this.props.helperText}
				label={this.props.label}
				maxDate={this.props.max}
				minDate={this.props.min}
				onChange={this.handleChange}
				required={this.props.required}
				value={this.props.value} />
		);
	}


	/**
	 * Handle date change.
	 *
	 * @param {DayJS|null} date
	 * @return {void}
	 */
	handleChange(date) {
		if (!date) this.props.onChange(null);
		else this.props.onChange(date.format(this.internalFormat));
	}


	/**
	 * Get the format to display the date in.
	 *
	 * @return {String}
	 */
	get displayFormat() {
		return (this.props.displayFormat || "DD-MM-YYYY");
	}


	/**
	 * Get the internal format to handle the date in.
	 *
	 * @return {String}
	 */
	get internalFormat() {
		return (this.props.internalFormat || "YYYY-MM-DD");
	}


	/**
	 * `DialogProps`
	 * 
	 * @type {Object}
	 */
	static DialogProps = {disableScrollLock: true, transitionDuration: 0, PaperProps: {elevation: 0}};

}

export default DatePicker;
