import React from "react";
import {TextField} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";

/**
 * Email picker
 *
 * @package SEC
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class EmailPicker extends React.PureComponent {

	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * Textfield
		 *
		 * @type {ReactRef}
		 */
		this.textfield = React.createRef();

		/**
		 * Method binds
		 */
		this.handleChange = this.handleChange.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleKeyDown = this.handleKeyDown.bind(this);
		this.renderInput = this.renderInput.bind(this);

	}


	/**
	 * Value changed.
	 *
	 * @param {Event} e
	 * @param {mixed} v
	 * @return {void}
	 */
	handleChange(e, v) {
		this.onChange(v);
	}


	/**
	 * Input value changed.
	 *
	 * @param {Event} e
	 * @return {void}
	 */
	handleInputChange(e) {
		(this.props.onChange || this.handleChange)(e, (e?.target.value || null));
	}


	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Autocomplete
				autoComplete={true}
				disabled={this.props.disabled}
				disableCloseOnSelect={!this.props.singular}
				freeSolo={true}
				getOptionLabel={(this.props.getOptionLabel || null)}
				loading={(this.props.loading || false)}
				loadingText="Loading"
				multiple={!this.props.singular}
				onChange={(this.props.onChange || this.handleChange)}
				onInputChange={(this.props.singular ? this.handleInputChange : undefined)}
				options={(this.props.options || [])}
				renderInput={this.renderInput}
				renderOption={(this.props.renderOption || null)}
				value={this.props.value} />
		);
	}


	/**
	 * Render the input.
	 * 
	 * @param {Object} props
	 * @return {ReactNode}
	 */
	renderInput(props) {

		const label = this.label;

		return (
			<TextField
				{...props}
				InputLabelProps={{shrink: true}}
				fullWidth
				helperText={(!this.props.singular ? "Press Enter to add" : undefined)}
				inputRef={this.textfield}
				label={label}
				onKeyDown={this.handleKeyDown}
				placeholder={label}
				required={this.props.required}
				type="email" />
		);

	}


	/**
	 * On change.
	 *
	 * @param {Array} values
	 * @return {void}
	 */
	onChange(values) {
		if (!this.props.disabled) this.props.onChange(values);
	}


	/**
	 * Make sure we have a valid email address.
	 *
	 * @param {Event} e
	 * @return {void}
	 */
	handleKeyDown(e) {

		if (e.which === 13) {
			e.preventDefault();
			if (!this.textfield.current.reportValidity()) {
				e.stopPropagation();
			}
		}

	}


	/**
	 * Get the control's label.
	 *
	 * @return {String}
	 */
	get label() {
		return (this.props.singular ? "Email address" : "Email addresses");
	}

}

export default EmailPicker;
