import React from "react";
import TabView from "Components/TabView.js";
import Forms from "Resources/DocuSign.json";
import DocuSignForm from "./DocuSignForm.js";
import {connect} from "react-redux";
import {Typography} from "@material-ui/core";

/**
 * DocuSign view
 *
 * @package SEC
 * @subpackage Views
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class DocuSign extends React.PureComponent {

	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<TabView
				url={this.props.location.pathname}
				urlBase="/docusign"
				history={this.props.history}
				tabs={this.tabs} />
		);
	}


	/**
	 * Create a component for a form object.
	 * 
	 * @param {Object} form
	 * @return {ReactNode}
	 */
	createFormComponent(form) {
		return <DocuSignForm form={form} history={this.props.history} />;
	}


	/**
	 * Tab definitions.
	 *
	 * @return {Array}
	 */
	get tabs() {
		const tabs = this.forms.map(form => {
			return {
				label: form.label,
				url: `/${form.name}`,
				component: this.createFormComponent(form)
			};
		});
		return ((tabs.length !== 0) ? tabs : this.constructor.tabs403);
	}


	/**
	 * Get forms visible for the user.
	 *
	 * @return {Array}
	 */
	get forms() {
		return Forms.filter(f => {
			return this.props.permissions.includes(f.permission);
		});
	}


	/**
	 * Tab definitions (403).
	 * 
	 * @type {Array}
	 */
	static tabs403 = [
		{
			label: "DocuSign",
			url: "",
			component: <Typography>Error 403</Typography>
		}
	];

}

export default connect(({permissions}) => ({permissions}))(DocuSign);
