import React from "react";
import Component from "App/Component.js";
import Navigator from "App/Navigator.js";
import Post from "App/Post.js";
import Store from "App/Store.js";
import View from "App/View.js";
import Fab from "Components/Fab.js";
import EnquiryPicker from "Components/EnquiryPicker.js";
import rem from "Helpers/Rem.js";
import withSnackbar from "Hoc/withSnackbar.js";
import DataRequestService from "Services/DataRequestService.js";
import DataRequestsTable from "./DataRequestsTable.js";
import strftime from "strftime";
import {DataRequests as Strings} from "Resources/Strings.js";
import {DatePicker} from "@material-ui/pickers";
import * as mui from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

/**
 * Data requests
 *
 * @package SEC
 * @subpackage Views
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class DataRequests extends Component {

	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @return {void}
	 */
	constructor(props) {
		super(props);

		/**
		 * State
		 *
		 * @type {Object}
		 */
		this.state = {

			/**
			 * Active tab
			 *
			 * @type {Number}
			 */
			tab: 0,

			/**
			 * Data requests
			 *
			 * @type {Array}
			 */
			requests: null,

			/**
			 * Loading network requests
			 *
			 * @type {Boolean}
			 */
			loading: false,

			/**
			 * Earliest sent date for sent requests
			 *
			 * @type {String}
			 */
			datemin: strftime("%Y-%m-01", new Date()),

			/**
			 * Latest sent date for sent requests
			 *
			 * @type {String}
			 */
			datemax: strftime("%Y-%m-%d", new Date())

		};

		/**
		 * Pending sync queue length
		 *
		 * (We want to be alerted to changes so that 
		 * we can reload if e.g. a draft is uploaded.)
		 *
		 * @type {Integer}
		 */
		this.pending = Store.getState().sync.pending;

		/**
		 * Redux unsubscription method
		 *
		 * @type {Function}
		 */
		this.reduxUnsubscribe = null;

	}


	/**
	 * Component mounted.
	 *
	 * @return {void}
	 */
	componentDidMount() {
		this.renderTab(this.state.tab);
		this.reduxUnsubscribe = Store.subscribe(() => {
			const {pending} = Store.getState().sync;
			if (pending !== this.pending) {
				this.pending = pending;
				this.renderTab(this.state.tab);
			}
		});
	}


	/**
	 * Component unmounting.
	 *
	 * @return {void}
	 */
	componentWillUnmount() {
		super.componentWillUnmount();
		this.reduxUnsubscribe();
	}


	/**
	 * Component updated.
	 * 
	 * @param {Object} prevProps
	 * @param {Object} prevState
	 * @return {void}
	 */
	componentDidUpdate(prevProps, prevState) {
		const min = (this.state.datemin !== prevState.datemin);
		const max = (this.state.datemax !== prevState.datemax);
		if (min || max) this.renderTab(this.state.tab);
	}


	/**
	 * Handle creation button click.
	 *
	 * @return {void}
	 */
	handleCreate() {
		Navigator.navigate("/data-requests/create");
	}


	/**
	 * Handle an error.
	 *
	 * Errors are not displayed if there is an active error time timer.
	 *
	 * @return {void}
	 */
	handleError() {
		this.setState({requests: []});
		this.props.snackbar(Strings.fetchFailure, "error");
	}


	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<View>
				<mui.Tabs
					indicatorColor="primary"
					value={this.state.tab}
					scrollButtons="on"
					onChange={(e, tab) => this.updateTab(tab)}>

					<mui.Tab
						disabled={this.state.requests === null}
						label={Strings.tabDrafts} />
					<mui.Tab
						disabled={this.state.requests === null}
						label={Strings.tabSent} />
					<mui.Tab
						disabled={this.state.requests === null}
						label={Strings.tabSearch} />
				</mui.Tabs>

				<mui.Box display={(this.state.tab === 2 ? "block" : "none")}>
					<EnquiryPicker
						disabled={this.state.requests === null}
						onChange={e => this.searchEnquiry(e)} />
				</mui.Box>

				<mui.Box style={this.stylesDatePickers} >
					<DatePicker
						autoOk
						DialogProps={this.constructor.DialogProps}
						disableFuture
						disabled={this.state.loading}
						label="Earliest sent date"
						format="DD-MM-YYYY"
						value={this.state.datemin}
						onChange={d => this.updateDate(d, "datemin")} />
					<DatePicker
						autoOk
						DialogProps={this.constructor.DialogProps}
						disableFuture
						disabled={this.state.loading}
						label="Latest sent date"
						format="DD-MM-YYYY"
						value={this.state.datemax}
						onChange={d => this.updateDate(d, "datemax")} />
				</mui.Box>

				<DataRequestsTable
					history={this.props.history}
					requests={this.state.requests}
					onWantsRefresh={() => this.updateTab(this.state.tab)} />

				<Fab onClick={() => this.handleCreate()}>
					<AddIcon />
				</Fab>
			</View>
		);
	}


	/**
	 * Render a tab by index.
	 *
	 * @param {Integer} tab
	 * @return {void}
	 */
	renderTab(tab) {
		this.tabRenderers[tab]();
	}


	/**
	 * Render drafts view.
	 *
	 * @return {void}
	 */
	renderDrafts() {

		let requests = [];

		const local = Post.getType("drequest").then(r => {
			requests = requests.concat(r.map(r => r.data));
		}).catch(() => {
			this.props.snackbar(Strings.fetchFailureDrafts, "error");
		});

		const server = DataRequestService.getUnsent().then(r => {
			requests = requests.concat(r);
		}).catch(() => {
			this.props.snackbar(Strings.fetchFailure, "error");
		});

		Promise.allSettled([local, server]).then(() => {
			this.setState({requests});
		});

	}


	/**
	 * Render sent view.
	 *
	 * @return {void}
	 */
	renderSent() {
		this.setState({loading: true});
		const args = [this.state.datemin, this.state.datemax];
		DataRequestService.getSent(...args).then(requests => {
			this.setState({requests});
		}).catch(() => {
			this.handleError();
		}).finally(() => this.setState({loading: false}));
	}


	/**
	 * Render search view.
	 *
	 * @return {void}
	 */
	renderSearch() {
		this.setState({requests: []});
	}


	/**
	 * Search for data requests by enquiry.
	 *
	 * @param {Integer} enquiry Enquiry ID
	 * @return {void}
	 */
	searchEnquiry(enquiry) {
		this.setState({requests: null});
		DataRequestService.getByEnquiry(enquiry).then(requests => {
			this.setState({requests});
		}).catch(() => this.handleError());
	}


	/**
	 * Update date in state.
	 *
	 * @param {DayJS} d Date
	 * @param {String} state State property to update
	 * @return {void}
	 */
	updateDate(d, state) {
		this.setState({[state]: (d ? d.format("YYYY-MM-DD") : null)});
	}


	/**
	 * Update active tab.
	 *
	 * @param {Integer} tab
	 * @return {void}
	 */
	updateTab(tab) {
		this.setState({tab, requests: null});
		this.renderTab(tab);
	}


	/**
	 * Tab renderer methods.
	 *
	 * @return {void}
	 */
	get tabRenderers() {
		return [
			this.renderDrafts.bind(this),
			this.renderSent.bind(this),
			this.renderSearch.bind(this)
		];
	}


	/**
	 * Styles for date pickers container.
	 *
	 * @return {Object}
	 */
	get stylesDatePickers() {
		return {
			display: ((this.state.tab === 1) ? "grid" : "none"),
			gridColumnGap: rem(2),
			gridTemplateColumns: `repeat(2, auto)`,
			maxWidth: rem(30)
		};
	}


	/**
	 * `DialogProps`
	 * 
	 * @type {Objectg}
	 */
	static DialogProps = {
		disableScrollLock: true,
		PaperProps: {elevation: 0},
		transitionDuration: 0
	};

}

export default withSnackbar(DataRequests);
