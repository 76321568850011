import dompurify from "dompurify";

/**
 * HTML helper
 *
 * @package SEC
 * @subpackage Helpers
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class Html {

	/**
	 * Sanitize HTML for use as a template, 
	 * permitting `body` only with `style` tags.
	 *
	 * @param {String} tpl
	 * @return {String}
	 */
	static sanitizeTemplate(tpl) {
		return dompurify.sanitize(tpl, {
			ADD_TAGS: ["style"],
			ADD_ATTR: ["style"],
			FORCE_BODY: true
		});
	}

}

export default Html;
