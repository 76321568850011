import React from "react";
import Alert from "Components/Alert.js";
import Messages from "../Resources/SpoiPurchaseInvoiceStatusAlerts.json";
import Navigator from "App/Navigator.js";
import mobile from "Helpers/Mobile.js";
import {Email as MessageIcon} from "@material-ui/icons";
import {Box, Button, IconButton, withWidth} from "@material-ui/core";

/**
 * SPOI purchase invoice status alert
 *
 * @package SEC
 * @subpackage Spoi
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class SpoiPurchaseInvoiceStatusAlert extends React.PureComponent {

	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (this.shouldRender ? this.renderAlert() : null);
	}


	/**
	 * Render the alert.
	 *
	 * @return {ReactNode}
	 */
	renderAlert() {
		return (
			<Alert
				action={this.shouldRenderAction ? this.renderAction() : null}
				title={this.message.title}
				severity={this.message.severity}>

				{this.message.alert}
				{(this.disputedReason || null)}
			</Alert>
		);
	}


	/**
	 * Render the action button.
	 *
	 * @return {ReactNode}
	 */
	renderAction() {
		const btn = this.renderActionButton();
		const icn = this.renderActionIconButton();
		return (!this.mobile ? btn : icn);
	}


	/**
	 * Render the action button as a button.
	 *
	 * @return {ReactNode}
	 */
	renderActionButton() {
		return (
			<Button
				color="inherit"
				onClick={this.onClick.bind(this)}
				variant="outlined">
				Message Accounts
			</Button>
		);
	}


	/**
	 * Render the action button as an icon button.
	 * 
	 * @return {ReactNode}
	 */
	renderActionIconButton() {
		return (
			<IconButton
				color="inherit"
				onClick={this.onClick.bind(this)}
				size="small">
				<MessageIcon />
			</IconButton>
		);
	}


	/**
	 * Render the disputed reason text.
	 * 
	 * @return {ReactNode}
	 */
	renderDisputedReason() {
		return (
			<Box mt={1}>
				<span>
					<span style={{fontWeight: "bold"}}>Reason: </span>
					{this.props.pi.DisputedReason}
				</span>
			</Box>
		);
	}


	/**
	 * Clicked.
	 * 
	 * @return {void}
	 */
	onClick() {
		Navigator.navigate(this.props.uri);
	}


	/**
	 * Get the disputed reason node.
	 *
	 * This will return `null` if the PI is not disputed 
	 * or if the PI is disputed but with no reason given.
	 * 
	 * @return {ReactNode|null}
	 */
	get disputedReason() {
		if (this.props.pi.disputed) {
			if (this.props.pi.hasDisputedReason) {
				return this.renderDisputedReason();
			}
		}
		return null;
	}


	/**
	 * Get the message object for the PI.
	 *
	 * @return {Object}
	 */
	get message() {
		return Messages[this.props.pi.Status];
	}


	/**
	 * Get whether we've a mobile viewport.
	 *
	 * @return {Boolean}
	 */
	get mobile() {
		return mobile(this.props.width);
	}


	/**
	 * Get whether we should render for this PI.
	 *
	 * @return {Boolean}
	 */
	get shouldRender() {
		return Object.keys(Messages).includes(this.props.pi.Status);
	}


	/**
	 * Get whether to render the action button.
	 *
	 * @return {Boolean}
	 */
	get shouldRenderAction() {
		return (this.props.uri && !this.message.noAction);
	}

}

export default withWidth()(SpoiPurchaseInvoiceStatusAlert);
