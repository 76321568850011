import React from "react";
import AdminIcon from "@material-ui/icons/Settings";
import AppIcon from "@material-ui/icons/InfoOutlined";
import DataRequestsIcon from "@material-ui/icons/Assessment";
import DocuSignIcon from "@material-ui/icons/AssignmentReturned";
import HealthSafetyIcon from "@material-ui/icons/Healing";
import HomeIcon from "@material-ui/icons/Home";
import ItpAutomationIcon from "@material-ui/icons/AssignmentTurnedIn";
import LogoutIcon from "@material-ui/icons/ExitToApp";
import MessagesIcon from "@material-ui/icons/Email";
import Navigator from "App/Navigator.js";
import ProfileIcon from "@material-ui/icons/PersonOutline";
import SpoiPurchaseInvoicesIcon from "@material-ui/icons/Description";
import SpoiPurchaseOrdersIcon from "@material-ui/icons/Receipt";
import Styles from "Resources/Styles.json";
import Theme from "Resources/Theme.json";
import TimesheetsIcon from "@material-ui/icons/AccessTime";
import * as mui from "@material-ui/core";
import {connect} from "react-redux";

/**
 * Drawer
 *
 * @package SEC
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class Drawer extends React.PureComponent {

	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * State
		 *
		 * @type {Object}
		 */
		this.state = {

			/**
			 * Open
			 *
			 * @type {Boolean}
			 */
			open: false

		};

	}


	/**
	 * Component mounted.
	 *
	 * @return {void}
	 */
	componentDidMount() {
		this.setState({open: this.props.open});
	}


	/**
	 * Component updated.
	 *
	 * @param {Object} prevProps
	 * @return {void}
	 */
	componentDidUpdate(prevProps) {
		if (prevProps.open !== this.props.open) {
			this.setState({open: this.props.open});
		}
	}


	/**
	 * Handle click.
	 *
	 * @param {Object} item
	 * @return {void}
	 */
	onclick(item) {
		this.handleClose();
		if (item.onClick) item.onClick();
		else Navigator.navigate(item.uri);
	}


	/**
	 * Get whether we have permission to display an item.
	 *
	 * @param {Object} item
	 * @return {void}
	 */
	permit(item) {
		if (!item.permission) return true;
		else return this.props.permissions.includes(item.permission);
	}


	/**
	 * Closed.
	 * 
	 * @return {void}
	 */
	handleClose = () => {
		this.props.onChange(false);
		this.setState({open: false});
	};


	/**
	 * Opened.
	 * 
	 * @return {void}
	 */
	handleOpen = () => {
		this.props.onChange(true);
		this.setState({open: true});
	};


	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<React.Fragment>
				<mui.Hidden mdUp>
					<mui.SwipeableDrawer
						disableBackdropTransition={true}
						disableDiscovery={true}
						hysteresis={0.25}
						minFlingVelocity={250}
						open={this.state.open}
						onClose={this.handleClose}
						onOpen={this.handleOpen}
						ModalProps={{onBackdropClick: this.handleClose}}
						PaperProps={{style: this.constructor.styles}}
						swipeAreaWidth={30}
						variant="temporary">
						{this.renderDrawer()}
					</mui.SwipeableDrawer>
				</mui.Hidden>
				<mui.Hidden smDown>
					<mui.Drawer
						open
						PaperProps={{style: this.constructor.stylesDesktop}}
						variant="permanent">
						{this.renderDrawer()}
					</mui.Drawer>
				</mui.Hidden>
			</React.Fragment>
		);
	}


	/**
	 * Get drawer content.
	 *
	 * @return {ReactNode}
	 */
	renderDrawer() {
		return (
			<React.Fragment>
				<mui.List>
					{this.renderDrawerSection("dash")}
				</mui.List>
				<mui.List>
					{this.renderDrawerSection("main")}
				</mui.List>
				<mui.List style={this.constructor.stylesAppSection}>
					{this.renderDrawerSection("app")}
				</mui.List>
			</React.Fragment>
		);
	}


	/**
	 * Get the content for an item.
	 *
	 * @param {Object} item
	 * @param {Integer} i Index
	 * @return {ReactNode}
	 */
	renderDrawerItem(item, i) {
		return (
			<mui.ListItem
				button
				key={i}
				onClick={() => this.onclick(item)}>
				<mui.ListItemIcon style={this.constructor.stylesIcon}>
					{item.icon}
				</mui.ListItemIcon>
				<mui.ListItemText>{item.label}</mui.ListItemText>
			</mui.ListItem>
		);
	}


	/**
	 * Get the content for a named section.
	 *
	 * @param {String} section
	 * @return {ReactNode}
	 */
	renderDrawerSection(section) {
		return this.items[section].map((item, i) => {
			if (!this.permit(item)) return null;
			else return this.renderDrawerItem(item, i);
		});
	}


	/**
	 * Items.
	 *
	 * @return {Object}
	 */
	get items() {
		return {
			dash: [
				{
					label: "Home",
					icon: <HomeIcon />,
					uri: "/"
				},
				{
					label: "Messages",
					icon: (
						<mui.Badge
							badgeContent={this.props.messages?.length}
							color="error">
							<MessagesIcon />
						</mui.Badge>
					),
					uri: "/messages"
				},
				{
					label: "Administration",
					icon: <AdminIcon />,
					permission: "sec.admin",
					uri: "/admin"
				}
			],
			main: [
				{
					label: "Data Requests",
					icon: <DataRequestsIcon />,
					permission: "sec.drequests.get",
					uri: "/data-requests"
				},
				{
					label: "DocuSign",
					icon: <DocuSignIcon />,
					permission: "sec.docusign",
					uri: "/docusign"
				},
				{
					label: "Health & Safety",
					icon: <HealthSafetyIcon />,
					permission: "sec.healthsafety",
					uri: "/health-safety"
				},
				{
					label: "ITP Automation",
					icon: <ItpAutomationIcon />,
					permission: "sec.itp.send",
					uri: "/itp"
				},
				{
					label: "Purchase Orders",
					icon: <SpoiPurchaseOrdersIcon />,
					permission: "sec.spoi.GetPurchaseOrders",
					uri: "/spoi/orders"
				},
				{
					label: "Raised Invoices",
					icon: <SpoiPurchaseInvoicesIcon />,
					permission: "sec.spoi.GetPurchaseInvoices",
					uri: "/spoi/invoices"
				},
				{
					label: "Timesheets",
					icon: <TimesheetsIcon />,
					permission: "sec.Timesheets",
					uri: "/timesheets"
				},
				{
					label: "Timesheets Admin",
					icon: <TimesheetsIcon />,
					permission: "sec.Timesheets.Admin",
					uri: "/timesheets/admin"
				}
			],
			app: [
				{
					label: "Profile",
					icon: <ProfileIcon />,
					uri: "/profile"
				},
				{
					label: "Logout",
					icon: <LogoutIcon />,
					onClick: this.props.onLogout
				},
				{
					label: "Application",
					icon: <AppIcon />,
					uri: "/app"
				}
			]
		};
	}


	/**
	 * Styles.
	 *
	 * @type {Object}
	 */
	static styles = {
		background: Theme.sec.palette.primary.main,
		color: "white",
		height: `calc(100% - ${Styles.bar})`,
		marginTop: Styles.bar,
		paddingLeft: `1.2rem`,
		width: Styles.drawer
	};

	/**
	 * Styles for the application section.
	 * 
	 * @type {Object}
	 */
	static stylesAppSection = {
		marginTop: "auto"
	};

	/**
	 * Styles for icons.
	 * 
	 * @type {Object}
	 */
	static stylesIcon = {
		color: "white"
	};

	/**
	 * Styles (desktop).
	 * 
	 * @type {Object}
	 */
	static stylesDesktop = {
		...this.styles,
		zIndex: 1000
	};

}

export default connect(({messages, permissions}) => ({messages, permissions}))(Drawer);
