/**
 * Domain
 *
 * @package SEC
 * @subpackage Domain
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class Domain {

	/**
	 * Constructor.
	 *
	 * Assigns properties to the instance.
	 * 
	 * @param {Object} obj optional
	 * @return {self}
	 */
	constructor(obj={}) {
		Object.assign(this, obj);
	}

}

export default Domain;
