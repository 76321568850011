import MessageHelper from "./MessageHelper.js";

/**
 * Message conversation object
 *
 * @package SEC
 * @subpackage Messages
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class MessageConversation {

	/**
	 * Constructor.
	 *
	 * @param {String} contact Contact username (relative to active user)
	 * @param {String} message Last message content
	 * @param {Integer} ts optional Timestamp (Unix seconds) (now)
	 * @param {Boolean} received optional User received message (`false`)
	 * @param {Boolean} read optional User read all received messages (`true`)
	 * @return {self}
	 */
	constructor(contact, message, ts=null, received=false, read=true) {

		/**
		 * Contact username (relative to active user)
		 *
		 * @type {String}
		 */
		this.Contact = contact;

		/**
		 * Last message content
		 *
		 * @type {String}
		 */
		this.Message = message;

		/**
		 * Last message timestamp (Unix epoch seconds)
		 * 
		 * @type {Integer}
		 */
		this.Timestamp = ((ts !== null) ? ts : (Date.now() / 1000));

		/**
		 * Active user received last message
		 *
		 * @type {Boolean}
		 */
		this.Received = received;

		/**
		 * Active user has read all received messages
		 *
		 * @return {self}
		 */
		this.Read = read;

	}


	/**
	 * Get the message timestamp as an interface string.
	 *
	 * @return {String}
	 */
	get TimestampString() {
		if (!this.Message) return null;
		else return MessageHelper.getTimestampUIString(this.Timestamp);
	}


	/**
	 * Create a new conversation instance from an existing one.
	 *
	 * @param {Object} Conversation-like object to get properties from
	 * @return {MessageConversation}
	 */
	static newFromExisting({Contact, Message, Timestamp, Received, Read}) {
		return new MessageConversation(
			Contact,
			Message,
			Timestamp,
			(!!Received),
			(!!Read)
		);
	}


	/**
	 * Create a new conversation instance from a message (network object).
	 *
	 * @param {Object} msg Message
	 * @return {MessageConversation}
	 */
	static newFromMessage(msg) {
		return new MessageConversation(
			msg.Sender,
			msg.Message,
			msg.SentTime,
			true,
			false
		);
	}


	/**
	 * Create a new conversation instance from a `Message`.
	 *
	 * As we're trying to reconcile different object types here, 
	 * some properties of the `Message` are ignored or overwritten 
	 * by parameters passed here.
	 *
	 * In particular, as `Message` describes a two-way exchange and 
	 * we are always one-way from the perspective of the user, you must 
	 * nominate whether to create the conversation with the contact as 
	 * either the message sender or recipient (`received`), and the value 
	 * of `received` becomes the `Received` property here.
	 *
	 * @param {String} msg Message object
	 * @param {Boolean} received optional Message received
	 * @return {MessageConversation}
	 */
	static newFromMessageObject(msg, received=false) {
		return new MessageConversation(
			(received ? msg.Sender : msg.Recipient),
			msg.Message,
			msg.Timestamp,
			received,
			msg.Read
		);
	}


	/**
	 * Create a new conversation from the user to the given contact.
	 *
	 * @param {String} contact
	 * @return {MessageConversation}
	 */
	static newToContact(contact) {
		return new MessageConversation(contact, null, null, false, true);
	}

}

export default MessageConversation;
