import React from "react";
import Container from "Components/Containerx.js";
import TimesheetsTable from "./TimesheetsTable.js";
import View from "App/View.js";

/**
 * Timesheets admin view
 * 
 * @package SEC
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class TimesheetsAdminView extends React.PureComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<View>
				<Container singleColumn={true}>
					<TimesheetsTable
						pageSize={25}
						initialStatuses={[null]}
						withApprovalActions={true} />
				</Container>
			</View>
		);
	}

}

export default TimesheetsAdminView;
