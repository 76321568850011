import React from "react";
import SearchablePicker from "Components/SearchablePicker.js";
import SECColour from "Helpers/SECColour.js";
import withSnackbar from "Hoc/withSnackbar";
import EnquiryService from "Services/EnquiryService.js";
import * as mui from "@material-ui/core";
import {connect} from "react-redux";
import {EnquiryPicker as Strings} from "Resources/Strings.js";

/**
 * Enquiry picker
 *
 * @package SEC
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class EnquiryPicker extends SearchablePicker {

	/**
	 * Get option label.
	 *
	 * @param {Object} option
	 * @return {String}
	 */
	getOptionLabel(option) {
		return [
			option.EnquiryID,
			option.CustomerName,
			option.ProjectDescription
		].join(" – ");
	}


	/**
	 * Render an option.
	 *
	 * @param {Object} option
	 * @return {void}
	 */
	renderOption(option) {
		return (
			<mui.Typography style={{color: (!this.props.dark ? SECColour(option.BusinessArea) : undefined)}}>
				<span>{option.EnquiryID}</span>
				<span> – </span>
				<span style={this.constructor.stylesCustomerName}>
					{option.CustomerName}
				</span>
				<span> – </span>
				<span>{option.ProjectDescription}</span>
			</mui.Typography>
		);
	}


	/**
	 * Changed.
	 *
	 * @param {Event} e
	 * @param {Object} value
	 * @return {void}
	 */
	handleChange(e, value) {
		this.onChangeUpdate((value ? value.EnquiryID : null));
	}


	/**
	 * Changed.
	 *
	 * Invokes `props.onChange` with the ID and full Enquiry object.
	 *
	 * @param {Integer} id
	 * @return {void}
	 */
	onChangeUpdate(id) {

		let enq;
		if (!id) enq = null;
		else enq = this.state.options.find(e => (e.EnquiryID === id));

		this.setState({value: enq});
		this.props.onChange(id, enq);

	}


	/**
	 * Search query promise.
	 *
	 * @param {String} query
	 * @return {Promise} Resolve with array of options
	 */
	searchPromise(query) {
		if (this.props.specific) {
			return EnquiryService.get(query.trim()).then(enq => [enq]);
		}
		else return EnquiryService.search(query.trim());
	}


	/**
	 * Get whether a query is valid.
	 *
	 * @param {String} query
	 * @return {Boolean}
	 */
	isValidQuery(query) {
		if (!super.isValidQuery(query)) return false;
		else return (!this.props.specific || !isNaN(query));
	}


	/**
	 * Get minimum query length before searching occurs.
	 *
	 * @return {Boolean}
	 */
	get minQueryLength() {
		return (this.props.specific ? 6 : super.minQueryLength);
	}


	/**
	 * Strings.
	 *
	 * @return {Object}
	 */
	get strings() {
		let str = {...super.strings, ...Strings};
		if (this.props.specific) str = Object.assign(str, Strings.specific);
		return str;
	}


	/**
	 * Styles for the customer's name.
	 * 
	 * @type {Object}
	 */
	static stylesCustomerName = {fontWeight: "bold"};

}

export default connect(({dark}) => ({dark}))(withSnackbar(EnquiryPicker));
