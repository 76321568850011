import Row from "Components/Row.js";
import Text from "Components/Text.js";
import TextField from "@material-ui/core/TextField";
import {memo, useCallback} from "react";

export default memo(props => {

	const {id, onChange} = props;

	const handleChange = useCallback(e => {
		onChange((e.target.value || null), id);
	}, [id, onChange]);

	return (
		<Row>
			<Text
				bold={true}
				content={props.label} />
			<TextField
				disabled={props.disabled}
				label={props.label}
				onChange={handleChange}
				value={(props.value || "")} />
		</Row>
	);

});
