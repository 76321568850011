import React from "react";
import Row from "./Row.js";
import {Typography} from "@material-ui/core";

/**
 * Row with label
 *
 * A component which automatically renders a label and its children 
 * using a `Row` component with props being passed to the `Row`.
 * 
 * Please refer to the source for details of the available props.
 * 
 * @package SEC
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class RowLabelled extends React.PureComponent {

	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Row columns={this.props.columns} noPy={this.props.noPy}>
				<Typography variant="body1" style={this.styles}>
					{this.props.label}
				</Typography>
				{this.props.children}
			</Row>
		);
	}


	/**
	 * Styles.
	 *
	 * @return {Object}
	 */
	get styles() {
		return {
			fontWeight: "bold",
			alignSelf: (this.props.labelAlignment || "auto")
		};
	}

}

export default RowLabelled;
