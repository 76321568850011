import dayjs from "dayjs";
import strftime from "strftime";

/**
 * Time helper
 *
 * @package SEC
 * @subpackage Helpers
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class Time {

	/**
	 * Get DD-MM-YYYY from YYYY-MM-DD string.
	 *
	 * @param {String} ymd
	 * @param {String} d optional Delimiter (`-`)
	 * @return {String}
	 */
	static dmyFromYmd(ymd, d="-") {
		return dayjs(ymd).format(`DD${d}MM${d}YYYY`);
	}


	/**
	 * Get DD-MM-YYYY @ HH:MM from a datetime string.
	 *
	 * @param {String} datetime
	 * @return {String}
	 */
	static dmyhmFromDatetime(datetime) {
		return dayjs(datetime).format("DD-MM-YYYY @ HH:mm");
	}


	/**
	 * Get DD-MM-YYYY @ HH:MM:SS from a datetime string.
	 *
	 * @param {String} datetime
	 * @return {String}
	 */
	static dmyhmsFromDatetime(datetime) {
		return dayjs(datetime).format("DD-MM-YYYY @ HH:mm:ss");
	}


	/**
	 * Get YYYY-MM-DD HH:MM from a datetime string.
	 *
	 * @param {String} datetime
	 * @return {String}
	 */
	static ymdhmFromDt(datetime) {
		return dayjs(datetime).format("YYYY-MM-DD HH:mm");
	}


	/**
	 * Get DD/MM/YYYY from seconds since Unix epoch.
	 *
	 * @param {Integer} date
	 * @return {String|null}
	 */
	static dmyFromSec(date) {
		if (!date) return null;
		return strftime("%d/%m/%Y", new Date(date * 1000));
	}


	/**
	 * Get DD/MM/YYYY @ HH:MM:SS from seconds since Unix epoch.
	 * 
	 * @param {Integer} date
	 * @return {String|null}
	 */
	static dmyhmsFromSec(date) {
		if (!date) return null;
		return strftime("%d/%m/%Y @ %H:%M:%S", new Date(date * 1000));
	}


	/**
	 * Get date of first of current month as YYYY-MM-DD.
	 *
	 * @return {String}
	 */
	static monthYMD() {
		return strftime("%Y-%m-01", new Date());
	}


	/**
	 * Get today's date as YYYY-MM-DD.
	 * 
	 * @return {String}
	 */
	static todayYMD() {
		return this.formatYmd(new Date());
	}


	/**
	 * Add days to the date, or a given date.
	 *
	 * @param {Integer} days
	 * @param {Date} d optional (today)
	 * @return {String} YYYY-MM-D
	 */
	static diffDaysYmd(days, d=null) {
		d = (d || new Date(Date.parse(this.todayYMD())));
		d.setDate(d.getDate() + days);
		return this.formatYmd(d);
	}


	/**
	 * Format a given datetime as a given format.
	 *
	 * @param {String} format
	 * @param {mixed} date optional Now
	 * @return {String}
	 */
	static format(format, date=null) {
		return dayjs(date || Date.now()).format(format);
	}


	/**
	 * Format a `Date` instance to a YYYY-MM-DD string.
	 *
	 * @param {Date} date
	 * @return {String} YYYY-MM-DD
	 */
	static formatYmd(date) {
		return strftime("%Y-%m-%d", date);
	}

}

export default Time;
