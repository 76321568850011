import Base from "./HealthSafetyNmpisStatisticsEnumInsight.js";

/**
 * Health and safety NMPIs probables statistic
 *
 * @package SEC
 * @subpackage Views
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class HealthSafetyNmpisStatisticsProbables extends Base {

	/**
	 * Label.
	 *
	 * @return {String}
	 */
	get label() {
		return "Probable Causes";
	}


	/**
	 * Statistics for data.
	 *
	 * @return {Object}
	 */
	get stats() {
		return this.props.stats.probables;
	}

}

export default HealthSafetyNmpisStatisticsProbables;
