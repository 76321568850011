import Accordion from "Components/Accordion.js";
import ContentEditable from "react-contenteditable";
import EditorPlaceholders from "./HtmlTemplateEditorPlaceholders.js";
import Flex from "Components/Flexx.js";
import config from "./HtmlTemplateEditorConfig.js";
import scss from "./HtmlTemplateEditor.module.scss";
import useToggle from "Hooks/useToggle.js";
import {memo, useCallback, useEffect, useRef, useState} from "react";

export default memo(props => {

	const editorRef = useRef();
	const open = useToggle();

	const {name, onChange, onChangePlaceholders, value} = props;
	const [statePlaceholders, setStatePlaceholders] = useState({});
	const placeholders = (props.placeholders || statePlaceholders);


	/**
	 * Update our placeholder values.
	 *
	 * @param {Object} placeholders
	 * @return {void}
	 */
	const setPlaceholders = useCallback(placeholders => {
		onChangePlaceholders?.(placeholders);
		setStatePlaceholders(placeholders);
	}, [onChangePlaceholders]);


	/**
	 * Get the current placeholder DOM nodes from the HTML editor.
	 *
	 * @return {Array<DOMNode>}
	 */
	const getEditorPlaceholderNodes = useCallback(() => {
		return Array.from((editorRef?.current?.querySelectorAll?.(`[${config.PLACEHOLDER_ATTR}]`) || []));
	}, [editorRef]);


	/**
	 * Contenteditable's value changed.
	 *
	 * @param {Event} e
	 * @return {void}
	 */
	const handleChange = useCallback(e => {
		onChange((e.target.value || null), name);
	}, [name, onChange]);


	/**
	 * Updating a placeholder's value.
	 *
	 * @param {mixed} value
	 * @param {String} placeholder ID
	 * @return {void}
	 */
	const handleChangePlaceholder = useCallback((value, placeholder) => {

		// Update the placeholders state

		setPlaceholders({
			...placeholders,
			[placeholder]: {
				...placeholders[placeholder],
				value
			}
		});

		// Update the HTML content with the new placeholder's value

		let html = (editorRef?.current?.innerHTML || "");

		const placeholderNode = getEditorPlaceholderNodes().find(node => (node.getAttribute(config.PLACEHOLDER_ATTR) === placeholder));

		if (placeholderNode) {

			const placeholderNodeClone = placeholderNode.cloneNode(true);
			const placeholderAttr = placeholders[placeholder].attr;

			if (placeholderAttr) placeholderNodeClone.setAttribute(placeholderAttr, value);
			else placeholderNodeClone.innerHTML = value;

			html = html.replace(placeholderNode.outerHTML, placeholderNodeClone.outerHTML);

		}

		onChange((html || null), name);

	}, [editorRef, getEditorPlaceholderNodes, name, onChange, placeholders, setPlaceholders]);


	/**
	 * Detect changes to controlled placeholders and update our HTML
	 */
	useEffect(() => {
		for (const placeholder in placeholders) {
			if (placeholders.hasOwnProperty(placeholder)) {
				if (placeholders[placeholder] !== statePlaceholders[placeholder]) {
					handleChangePlaceholder(placeholders[placeholder].value, placeholder);
				}
			}
		}
	}, [placeholders, statePlaceholders, handleChangePlaceholder]);


	/**
	 * Update our placeholders state using the definitions 
	 * in the current editor HTML, when our value changes.
	 */
	useEffect(() => {

		const placeholders = {};
		const placeholdersNodes = getEditorPlaceholderNodes();

		placeholdersNodes.forEach(node => {

			const placeholder = node.getAttribute(config.PLACEHOLDER_ATTR);
			const label = node.getAttribute(config.PLACEHOLDER_ATTR_LABEL);
			const attr = node.getAttribute(config.PLACEHOLDER_ATTR_ATTR);

			placeholders[placeholder] = {
				label,
				attr,
				value: (attr ? node.getAttribute(attr) : node.innerHTML)
			};

		});

		setPlaceholders(placeholders);

	}, [getEditorPlaceholderNodes, setPlaceholders, value]);


	/**
	 * Render!
	 */
	return (
		<Flex gap={2}>
			{((!props.disabled || props.showPlaceholdersWhenDisabled) && <EditorPlaceholders disabled={props.disabled} onChange={handleChangePlaceholder} placeholders={placeholders} />)}
			<Accordion
				label={props.label}
				open={open.value}
				onOpenToggle={open.toggle}>
				<ContentEditable
					className={scss.contenteditable}
					disabled={props.disabled}
					html={(value || "")}
					innerRef={editorRef}
					onChange={handleChange} />
			</Accordion>
		</Flex>
	);

});
