import React from "react";
import Component from "App/Component.js";
import View from "App/View.js";
import CustomerPicker from "Components/CustomerPicker.js";
import Fab from "Components/Fab.js";
import CreateDialog from "./AdminUsersCreateDialog.js";
import UserTable from "./AdminUsersTable.js";
import UserService from "Services/UserService.js";
import AddIcon from "@material-ui/icons/Add";

/**
 * Admin users view
 *
 * @package SEC
 * @subpackage Views
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class AdminUsers extends Component {

	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * State
		 *
		 * @type {Object}
		 */
		this.state = {

			/**
			 * Users
			 *
			 * @type {Array}
			 */
			users: [],

			/**
			 * Creation dialog
			 *
			 * @type {Boolean}
			 */
			create: false,

			/**
			 * Customer
			 *
			 * @type {Object}
			 */
			customer: null,

			/**
			 * Loading
			 *
			 * @type {Boolean}
			 */
			loading: true

		};

	}


	/**
	 * Component mounted.
	 *
	 * @return {void}
	 */
	componentDidMount() {
		this.update();
	}


	/**
	 * Component updated.
	 *
	 * @param {Object} prevProps
	 * @param {Object} prevState
	 * @return {void}
	 */
	componentDidUpdate(prevProps, prevState) {
		if (prevState.customer !== this.state.customer) {
			this.update();
		}
	}


	/**
	 * Update view for current customer.
	 *
	 * @return {void}
	 */
	update() {
		this.setState({loading: true});
		UserService.get(this.customerId, true).then(users => {
			this.setState({users, loading: false});
		}).catch(() => {
			this.setState({loading: false});
		});
	}


	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<View child={true}>
				<CustomerPicker
					disabled={this.state.loading}
					value={this.state.customer}
					onChange={customer => this.setState({customer})} />
				<UserTable
					loading={this.state.loading}
					users={this.state.users}
					onWantsRefresh={() => this.update()} />

				<CreateDialog
					open={this.state.create}
					customer={this.state.customer}
					onDone={() => this.update()}
					onClose={() => this.setState({create: false})} />

				<Fab onClick={() => this.setState({create: true})}>
					<AddIcon />
				</Fab>
			</View>
		);
	}


	/**
	 * Get customer ID.
	 *
	 * @return {String|null}
	 */
	get customerId() {
		return (this.state.customer ? this.state.customer.CustomerID : null);
	}

}

export default AdminUsers;
