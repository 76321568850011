import React from "react";
import Container from "Components/Containerx.js";
import {Alert as MuiAlert, AlertTitle} from "@material-ui/lab";

/**
 * Alert component
 *
 * A modern version of `Alert` to eventually replace it.
 * 
 * @package SEC
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class Alert extends React.PureComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<MuiAlert
				onClose={this.props.onClose}
				severity={this.props.severity}
				style={this.props.style}
				variant={this.props.variant}>
				<AlertTitle style={this.constructor.stylesTitle}>
					{this.props.title}
				</AlertTitle>
				<Container gap={0.5} mt={0.5}>
					{this.props.children}
				</Container>
			</MuiAlert>
		);
	}

}

export default Alert;
