import React from "react";
import Loader from "./Loader.js";
import Table from "./Table.js";
import TableCell from "./TableCell.js";
import bp from "Helpers/Bp.js";
import rem from "Helpers/Rem.js";
import SECColour from "Helpers/SECColour.js";
import * as mui from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import {TableData as Strings} from "Resources/Strings.js";

/**
 * Data table component, wrapping `Table`
 *
 * This supports specifying fields and values dynamically, rendering 
 * via prop-given renderer methods, editable and mobile states and 
 * automatically rendered information dialog alerts (via prop-provided 
 * dialog components to render per-item).
 *
 * Please refer to the source for details of all the available props.
 * 
 * @package SEC
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class TableData extends React.PureComponent {

	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * State
		 *
		 * @type {Object}
		 */
		this.state = {

			/**
			 * Info active
			 *
			 * @type {Boolean}
			 */
			info: false,

			/**
			 * Active item
			 *
			 * @type {void}
			 */
			active: null

		};

		/**
		 * Method binds
		 */
		this.handleCloseInfo = this.handleCloseInfo.bind(this);
		this.renderDataInfoBtn = this.renderDataInfoBtn.bind(this);

	}


	/**
	 * Close information.
	 * 
	 * @return {void}
	 */
	handleCloseInfo() {
		this.setState({info: false});
	}


	/**
	 * Info click handler.
	 * 
	 * @param {Event} e
	 * @param {Object} item
	 * @return {void}
	 */
	onClickInfo(e, item) {
		e.stopPropagation();
		this.setState({info: true, active: item});
	}


	/**
	 * Row clicked.
	 *
	 * @param {Object} value
	 * @return {void}
	 */
	onClickRow(value) {
		if (this.props.onClick) {
			this.props.onClick(value);
		}
	}


	/**
	 * Display context menu.
	 *
	 * @param {Event} e
	 * @param {Object} value Value which context menu was opened on
	 * @return {void}
	 */
	onContextMenu(e, value) {
		if (this.props.contextMenu) {
			e.preventDefault();
			this.props.onContextMenu(value, e);
		}
	}


	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<React.Fragment>
				<Table
					header={this.labels}
					pagination={this.props.pagination}
					paginationCount={this.paginationCount}
					paginationPage={this.paginationPage}
					paginationRows={this.props.paginationRows}
					onChangePage={this.props.onChangePage}
					onChangeRows={this.props.onChangeRows}
					size="small"
					style={this.styles}>
					{(!this.loading() ? this.renderData() : this.renderMsg())}
					{(this.props.children || null)}
				</Table>
				{this.props.contextMenu}
				{(this.state.info ? this.renderInfo() : null)}
			</React.Fragment>
		);
	}


	/**
	 * Render data items.
	 * 
	 * @return {ReactNode}
	 */
	renderData() {
		return this.values.map((value, i) => (
			<React.Fragment key={i}>
				<mui.TableRow
					hover
					onClick={() => this.onClickRow(value)}
					onContextMenu={e => this.onContextMenu(e, value)}
					style={this.getRowStyles(value)}>
					{this.renderDataValue(value)}
				</mui.TableRow>
			</React.Fragment>
		));
	}


	/**
	 * Render data item information button.
	 *
	 * @param {Object} item
	 * @return {void}
	 */
	renderDataInfoBtn(item) {
		return (
			<mui.IconButton
				color="primary"
				onClick={e => this.onClickInfo(e, item)}
				size="small"
				style={{width: "4.5rem", height: "4.5rem"}}>
				<InfoIcon />
			</mui.IconButton>
		);
	}


	/**
	 * Render single data item.
	 *
	 * @param {mixed} val When not using renderers, must be an object
	 * @return {ReactNode}
	 */
	renderDataValue(val) {
		return this.fields.map(({className, label, padding, style}, i) => {
			return (
				<TableCell
					className={className}
					key={i}
					padding={padding}
					style={style}>
					{(this.renderField(label, val) || val[label])}
				</TableCell>
			);
		});
	}


	/**
	 * Run a renderer prop to render a field.
	 *
	 * When a renderer returns a value which is 
	 * not a React node, we will automatically 
	 * display the value as text in `Typography`.
	 *
	 * @param {String} label Field label
	 * @param {mixed} value Value being rendered
	 * @return {mixed} `null` if no renderer defined
	 */
	renderField(label, value) {

		const renderer = this.getFieldRenderer(label);

		if (renderer) {
			const rendered = renderer(value);
			if (React.isValidElement(rendered)) return rendered;
			else return <mui.Typography>{rendered}</mui.Typography>;
		}
		else return null;

	}


	/**
	 * Get the renderer to use for a given field.
	 *
	 * We use the editable renderer if we're editable and the field 
	 * is editable; otherwise, we'll use the mobile renderer if mobile.
	 *
	 * @param {String} label Field label
	 * @return {Function|null}
	 */
	getFieldRenderer(label) {
		const field = this.getField(label);
		const mobile = (field.rendererMobile);
		const editable = (field.editable && field.rendererEditable);
		if (this.props.editable && editable) return field.rendererEditable;
		else if (this.mobile && mobile) return field.rendererMobile;
		else return (field.renderer || null);
	}


	/**
	 * Render info component.
	 *
	 * @return {ReactNode}
	 */
	renderInfo() {
		return this.renderInfoComponent(this.props.infoComponent);
	}


	/**
	 * Render an actual info component instance.
	 *
	 * @param {ReactNode} InfoComponent Component to render
	 * @return {ReactNode}
	 */
	renderInfoComponent(InfoComponent) {
		return (
			<InfoComponent
				infoItem={this.state.active}
				onClose={this.handleCloseInfo}
				open={this.state.info} />
		);
	}


	/**
	 * Render message state.
	 *
	 * @return {ReactNode}
	 */
	renderMsg() {
		return (
			<mui.TableRow>
				<mui.TableCell
					colSpan={this.fields.length}
					size="medium"
					style={this.constructor.msgStyles}>
					{(this.props.loading ? <Loader /> : this.renderMsgEmpty())}
				</mui.TableCell>
			</mui.TableRow>
		);
	}


	/**
	 * Render empty message.
	 *
	 * @return {ReactNode}
	 */
	renderMsgEmpty() {
		return <mui.Typography>{Strings.empty}</mui.Typography>;
	}


	/**
	 * Get field by label.
	 *
	 * @param {String} label
	 * @return {void}
	 */
	getField(label) {
		return this.fields.filter(f => (f.label === label))[0];
	}


	/**
	 * Invoke prop to get styles for a row.
	 *
	 * Automatically sets the row's border accent colour to the 
	 * relevant SEC business area accent colour for values with 
	 * an `enquiry` or `Enquiry` object.
	 *
	 * @param {Object} value
	 * @return {Object}
	 */
	getRowStyles(value) {
		const props = (this.props.onGetRowStyles?.(value) || {});
		const enquiry = (value.enquiry || value.Enquiry);
		const business = enquiry?.BusinessArea;
		if (business) Object.assign(props, this.constructor.getSecBorderAccent(business));
		return props;
	}


	/**
	 * Get whether we have values.
	 *
	 * @return {Boolean}
	 */
	hasValues() {
		return (!!this.props.values?.length);
	}


	/**
	 * Get whether we're in a loading state.
	 *
	 * @return {Boolean}
	 */
	loading() {
		return (!this.hasValues() || this.props.loading);
	}


	/**
	 * Fields.
	 *
	 * @return {Array}
	 */
	get fields() {
		const edit = this.props.editable;
		let fields = this.fieldsDefault.concat(this.props.fields);
		if (this.mobile) fields = fields.filter(f => (f.mobile !== false));
		if (!edit) fields = fields.filter(f => (f.editableOnly !== true));
		return fields.filter(f => !f.hidden);
	}


	/**
	 * Fields (default).
	 *
	 * @return {Array}
	 */
	get fieldsDefault() {
		return ((this.props.info === false) ? [] : [this.fieldsDefaultInfo]);
	}


	/**
	 * Info field definition.
	 * 
	 * @return {Object}
	 */
	get fieldsDefaultInfo() {
		return {
			label: "",
			style: {
				minWidth: rem(4),
				textAlign: "center",
				width: rem(4)
			},
			renderer: this.renderDataInfoBtn
		};
	}


	/**
	 * Get field labels.
	 *
	 * @return {Array}
	 */
	get labels() {
		return this.fields.map(f => f.label);
	}


	/**
	 * Get whether we've a mobile viewport.
	 */
	get mobile() {
		return !bp(this.props.width, (this.props.mobileBp || 2));
	}


	/**
	 * Get the pagination count to use.
	 *
	 * This is set to 0 when we're loading so as to disable the controls.
	 *
	 * @return {Integer|null}
	 */
	get paginationCount() {
		return (this.loading() ? 0 : this.props.paginationCount);
	}


	/**
	 * Get the pagination page to use.
	 *
	 * This is set to 0 when we're loading so as to disable the controls.
	 *
	 * @return {Integer|null}
	 */
	get paginationPage() {
		return (this.loading() ? 0 : this.props.paginationPage);
	}


	/**
	 * Get values.
	 * 
	 * Returns an empty array if no values are specified.
	 *
	 * @return {Array}
	 */
	get values() {
		return (this.props.values || []);
	}


	/**
	 * Table styles.
	 *
	 * @return {Object}
	 */
	get styles() {
		return {
			tableLayout: (this.props.tableLayout || "auto")
		};
	}


	/**
	 * Styles to apply to a row when using SEC business area accent colours.
	 * 
	 * @param {String} BusinessArea SEC business area name
	 * @return {Object}
	 */
	static getSecBorderAccent(BusinessArea) {
		return {
			borderBottom: "0.2rem solid",
			borderBottomColor: SECColour(BusinessArea)
		};
	}


	/**
	 * Message styles.
	 * 
	 * @type {Object}
	 */
	static msgStyles = {textAlign: "center"};

}

export default mui.withWidth()(TableData);
