import React from "react";
import dF12 from "Dispatchers/F12.js";
import {Link, Typography} from "@material-ui/core";

export default ({variant}) => (
	<Link color="primary" onClick={dF12}>
		<Typography variant={variant}>
			{(process.env.REACT_APP_APP ? `#${process.env.REACT_APP_APP}` : "(Undefined)")}
		</Typography>
	</Link>
);
