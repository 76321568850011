import React from "react";
import Component from "App/Component.js";
import Navigator from "App/Navigator.js";
import Post from "App/Post.js";
import View from "App/View.js";
import Dialog from "Components/Dialog.js";
import Fab from "Components/Fab.js";
import Loader from "Components/Loader.js";
import AbstractError from "Errors/AbstractError.js";
import {EditorBase as Strings} from "Resources/Strings.js";
import * as mui from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Save";

/**
 * Editor base
 *
 * Abstract methods to implement:
 * - `createDomainObjectFromState()`
 * - `getDomainObjectFromNetwork(id)`
 * - `performActiveDomainObjectNetworkUpdate(domain)`
 * - `populateStateFromDomainObject(domain)`
 *
 * Children should override/implement:
 * - `uris`
 * - `strings`
 * - `localPostDomainType`
 *
 * Children may override:
 * - `shouldRenderFab()`
 * - `validate()`
 *
 * Usage:
 * - To create, route without an `:id` parameter.
 * - To update from network, route with an `:id` parameter.
 * - To update from cache, route with `:id` and `?apid` in query string.
 * (When updating from cache, `:id` is the cached domain's `apid`.)
 *
 * @package SEC
 * @subpackage Views
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class EditorBase extends Component {

	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @param {Object} state optional Initial state
	 * @return {self}
	 */
	constructor(props, state={}) {
		super(props);

		/**
		 * State
		 *
		 * @type {Object}
		 */
		this.state = {

			/**
			 * Domain object
			 *
			 * @type {Object}
			 */
			domain: null,

			/**
			 * Loading
			 *
			 * @type {Boolean}
			 */
			loading: true,

			/**
			 * Submitting
			 *
			 * @type {Boolean}
			 */
			submitting: false,

			/**
			 * Submitting confirmation dialog
			 *
			 * @type {Boolean}
			 */
			submittingConfirm: false,

			/**
			 * Merge child state
			 */
			...state

		};

	}


	/**
	 * Component mounted.
	 *
	 * @return {void}
	 */
	componentDidMount() {
		this.update();
		if (this.props.onMountRef) {
			this.props.onMountRef(this);
		}
	}


	/**
	 * Component unmounting.
	 *
	 * @return {void}
	 */
	componentWillUnmount() {
		super.componentWillUnmount();
		if (this.local) Post.unlock(this.state.domain._apid);
	}


	/**
	 * Update if URL changed.
	 *
	 * @param {Object} prevProps
	 * @return {void}
	 */
	componentDidUpdate(prevProps) {
		const location = this.props.location;
		const prevLocation = prevProps.location;

		// Update if location changed
		if (location && prevLocation) {
			if (location.pathname !== prevLocation.pathname) {
				this.update();
			}
		}
	}


	/**
	 * Handle a load error.
	 *
	 * Displays a toast message from `strings.loadErrMsg`.
	 *
	 * Redirects to `uris.loadErr`.
	 *
	 * @return {void}
	 */
	handleLoadError() {
		this.redirect(this.uris.loadErr);
		this.snackbar(this.strings.loadErrMsg, "error");
	}


	/**
	 * Update.
	 *
	 * @return {void}
	 */
	update() {
		window.scrollTo(0, 0);
		this.setState({loading: true});
		if (this.props.match.params.id) {
			if (this.props.location.search.startsWith("?apid")) {
				this.loadFromCache(this.props.match.params.id);
			}
			else this.loadFromNetwork(this.props.match.params.id);
		}
		else this.loadNew();
	}


	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<View child={true} pb={0}>
				{this.renderSubmit()}
				{this.shouldRenderFab() ? this.renderFab() : null}
				{!this.state.loading ? this.renderForm() : <Loader />}
			</View>
		);
	}


	/**
	 * Render action button.
	 *
	 * When clicked we attempt to submit.
	 *
	 * @return {ReactNode}
	 */
	renderFab() {
		return (
			<Fab onClick={() => this.submitRequest()}>
				<CheckIcon />
			</Fab>
		);
	}


	/**
	 * Render form.
	 *
	 * Must be implemented by child!
	 *
	 * @return {ReactNode}
	 */
	renderForm() {
		return (
			<mui.Typography>
				<code>renderForm()</code> not implemented!
			</mui.Typography>
		);
	}


	/**
	 * Render submit dialog.
	 *
	 * @return {ReactNode}
	 */
	renderSubmit() {
		return (
			<Dialog
				open={this.state.submittingConfirm}
				loading={this.state.submitting}
				title={this.strings.submitConfirmTitle}
				content={this.strings.submitConfirmMsg}
				onOk={() => this.submit()}
				onClose={() => this.setState({submittingConfirm: false})}>
			</Dialog>
		);
	}


	/**
	 * Submit.
	 *
	 * @return {void}
	 */
	submit() {

		let promise;
		const domain = this.createDomainObjectFromState();
		this.setState({submitting: true});

		/**
		 * We're submitting
		 */
		if (this.props.onSubmitting) {
			this.props.onSubmitting(domain);
		}

		/**
		 * We're either updating a local draft, 
		 * creating a new local draft (as it will 
		 * be saved to the sync queue for submission), 
		 * or updating an existing network domain (we 
		 * don't use the sync queue in this case).
		 */
		if (this.local) {
			promise = Post.update(this.state.domain._apid, domain);
		}
		else if (this.draft && !this.props.noSyncQueue) {
			promise = Post.add(this.localPostDomainType, domain);
		}
		else if (this.draft) {
			promise = this.performActiveDomainObjectNetworkCreate(domain);
		}
		else {
			promise = this.performActiveDomainObjectNetworkUpdate(domain);
		}

		/**
		 * Wait for the promise to resolve
		 */
		promise.then(() => {

			if (!this.props.inhibitSuccessHandler) {
				if (this.uris.submitSuccess) {
					this.redirect(this.uris.submitSuccess);
				}
				else this.update();
			}

			if (this.props.onSubmitDone) {
				this.props.onSubmitDone(domain);
			}

			const snack = this.snackbarSubmitVariant;
			this.snackbar(this.strings.submitSuccessMsg, snack);
			if (this.local) Post.unlock(this.state.domain._apid);

		}).catch(() => {
			this.snackbar(this.strings.submitFailureMsg, "error");
		}).finally(() => {
			this.setState({submitting: false, submittingConfirm: false});
		});

	}


	/**
	 * Submit requested by user.
	 *
	 * We invoke `validate(...)` which the child should override 
	 * to perform validation and verification logic and return a 
	 * boolean to indicate if submission can continue.
	 *
	 * When `validate(...)` returns `true`, we set `submittingConfirm` 
	 * to `true` in order to display the confirmation prompt, which 
	 * should invoke the real `submit(...)` method when confirmed by 
	 * the user at the prompt.
	 *
	 * @return {void}
	 */
	submitRequest() {
		if (this.validate()) this.setState({submittingConfirm: true});
	}


	/**
	 * Get whether to render the action button.
	 *
	 * @return {Boolean}
	 */
	shouldRenderFab() {
		return (!this.state.loading && !this.props.hideFabs);
	}


	/**
	 * Create a domain object from the state.
	 *
	 * @return {Object}
	 */
	createDomainObjectFromState() {
		throw new AbstractError();
	}


	/**
	 * Get a domain object from the network.
	 *
	 * @param {String} id Domain ID given by the route `id` parameter.
	 * @return {Promise}
	 */
	getDomainObjectFromNetwork(id) {
		throw new AbstractError(id);
	}


	/**
	 * Create the active domain object over the network.
	 *
	 * The object for submission is provided as `domain`.
	 *
	 * The child must perform the network request and return 
	 * a Promise to represent it; they should submit `domain` 
	 * as the domain object to create.
	 *
	 * @param {Object} domain
	 * @return {Promise}
	 */
	performActiveDomainObjectNetworkCreate(domain) {
		throw new AbstractError(domain);
	}


	/**
	 * Update the active domain object over the network.
	 *
	 * The object for submission is provided as `domain`.
	 *
	 * The child must perform the network request and return 
	 * a Promise to represent it; they should submit `domain` 
	 * as the domain object but will need to identify it using 
	 * a meaningful identifier for our domain type.
	 *
	 * @param {Object} domain
	 * @return {Promise}
	 */
	performActiveDomainObjectNetworkUpdate(domain) {
		throw new AbstractError(domain);
	}


	/**
	 * Populate the state from a given domain object.
	 *
	 * @param {Object} domain
	 * @param {Object} state optional Optional state merge
	 * @param {Object} loading optional State `loading` new value (`false`)
	 * @return {void}
	 */
	populateStateFromDomainObject(domain, state={}, loading=false) {
		this.setState({domain, loading, ...state});
	}


	/**
	 * Validate whether the user can submit the domain object now.
	 *
	 * Children should override this to display validation warnings 
	 * prior to submit – if `false` is returned, submit is cancelled.
	 *
	 * @return {Boolean}
	 */
	validate() {
		return true;
	}


	/**
	 * Load for a new domain object (blank slate).
	 *
	 * @return {void}
	 */
	loadNew() {
		this.setState({loading: false, domain: null});
	}


	/**
	 * Load for a cached (draft) domain object.
	 *
	 * @param {String} apid Domain APID to get from `Post`
	 * @return {void}
	 */
	loadFromCache(apid) {
		Post.lock(apid);
		Post.get(apid).then(domain => {
			this.populateStateFromDomainObject(domain.data);
		}).catch(e => this.handleLoadError(e));
	}


	/**
	 * Load a domain object to edit over the network.
	 *
	 * @param {String} id Domain ID given by the route `id` parameter
	 * @return {void}
	 */
	loadFromNetwork(id) {
		this.getDomainObjectFromNetwork(id).then(domain => {
			this.populateStateFromDomainObject(domain);
		}).catch(e => this.handleLoadError(e));
	}


	/**
	 * Redirect to a given URI.
	 *
	 * @param {String} uri
	 * @return {void}
	 */
	redirect(uri) {
		Navigator.navigate(uri);
	}


	/**
	 * Display a snackbar.
	 *
	 * @param {String} msg
	 * @param {String} variant optional (`default`)
	 * @return {void}
	 */
	snackbar(msg, variant="default") {
		if (this.props.snackbar) this.props.snackbar(msg, variant);
	}


	/**
	 * Get whether is a draft – that is, there is no domain 
	 * being edited, or it is just a local domain retrieved 
	 * from the pending sync queue.
	 *
	 * @return {Boolean}
	 */
	get draft() {
		return (!this.state.domain ? true : this.local);
	}


	/**
	 * Get whether creating a new draft (not from cache).
	 *
	 * @return {Boolean}
	 */
	get draftNew() {
		return (this.draft && !this.local);
	}


	/**
	 * Get whether a domain is currently being edited, including 
	 * domains which are locally cached and retrieved from sync queue.
	 *
	 * @return {Boolean}
	 */
	get editing() {
		return (!!this.state.domain);
	}


	/**
	 * Get whether editing an existing domain loaded from the network.
	 *
	 * @return {Boolean}
	 */
	get exists() {
		return (!this.draft && !this.local);
	}


	/**
	 * Get whether we are editing a locally cached draft domain.
	 *
	 * @return {Boolean}
	 */
	get local() {
		if (!this.state.domain) return false;
		else return (this.state.domain.hasOwnProperty("_apid"));
	}


	/**
	 * Get the name of the type to give to `Post` for new draft saves.
	 *
	 * @return {String}
	 */
	get localPostDomainType() {
		throw new AbstractError();
	}


	/**
	 * Get the variant to use for the successfully submitted snackbar.
	 *
	 * @return {String}
	 */
	get snackbarSubmitVariant() {
		return "success";
	}


	/**
	 * Strings.
	 *
	 * @return {Object}
	 */
	get strings() {
		return Strings;
	}


	/**
	 * Redirect URIs.
	 *
	 * @return {Object}
	 */
	get uris() {
		return {loadErr: "/", submitSuccess: "/"};
	}

}

export default EditorBase;
