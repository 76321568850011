import React from "react";
import {Typography} from "@material-ui/core";

export default ({children, style}) => {
	return (
		<Typography style={{fontWeight: "bold", ...style}}>
			{children}
		</Typography>
	);
};
