import React from "react";
import Component from "App/Component.js";
import Container from "Components/Container.js";
import CustomerPicker from "./CustomerPicker.js";
import rem from "Helpers/Rem.js";
import withSnackbar from "Hoc/withSnackbar";
import UserService from "Services/UserService.js";
import {UserPicker as Strings} from "Resources/Strings.js";
import {TextField} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

/**
 * User picker
 *
 * @package SEC
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class UserPicker extends Component {

	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * State
		 *
		 * @type {Object}
		 */
		this.state = {

			/**
			 * Users
			 *
			 * @type {Array}
			 */
			users: [],

			/**
			 * Error
			 *
			 * @type {Boolean}
			 */
			error: false,

			/**
			 * Loading
			 *
			 * @type {Boolean}
			 */
			loading: true,

			/**
			 * Value
			 *
			 * @type {Object}
			 */
			value: null,

			/**
			 * Customer
			 *
			 * @type {Object}
			 */
			customer: null

		};

		/**
		 * Method binds
		 */
		this.getOptionDisabled = this.getOptionDisabled.bind(this);
		this.onChange = this.onChange.bind(this);
		this.renderInput = this.renderInput.bind(this);
		this.update = this.update.bind(this);
		this.updateCustomer = this.updateCustomer.bind(this);

	}


	/**
	 * Component mounted.
	 *
	 * @return {void}
	 */
	componentDidMount() {

		/**
		 * Initial
		 */
		if (this.props.initial) {
			this.setState({
				users: [this.props.initial],
				value: this.props.initial
			});
		}

		/**
		 * Update
		 */
		this.update();

	}


	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<div style={this.containerStyles}>
				{(this.props.customer ? this.renderCustomer() : null)}
				<Autocomplete
					autoComplete={true}
					autoHighlight={true}
					autoSelect={true}
					disabled={this.props.disabled}
					getOptionDisabled={this.getOptionDisabled}
					getOptionLabel={this.constructor.getOptionLabel}
					getOptionSelected={this.constructor.getOptionSelected}
					loading={this.state.loading}
					noOptionsText={this.noOptionsText}
					onChange={this.onChange}
					options={this.state.users}
					renderInput={this.renderInput}
					renderOption={this.constructor.renderOption}
					value={this.value} />
			</div>
		);
	}


	/**
	 * Render customer picker.
	 *
	 * @return {ReactNode}
	 */
	renderCustomer() {
		return (
			<CustomerPicker
				disabled={this.props.disabled}
				onChange={this.updateCustomer}
				value={this.state.customer} />
		);
	}


	/**
	 * Render input.
	 *
	 * @param {Object} props
	 * @return {ReactNode}
	 */
	renderInput(props) {
		return (
			<TextField
				{...props}
				fullWidth
				label={(this.props.label || Strings.label)} />
		);
	}


	/**
	 * Changed.
	 *
	 * Invokes `props.onchange` with the user object (may be `null`).
	 *
	 * @param {Event} e
	 * @param {Object} user
	 * @return {void}
	 */
	onChange(e, user) {
		this.setState({value: user, error: false});
		this.props.onChange(user);
	}


	/**
	 * Update.
	 *
	 * @return {void}
	 */
	update() {

		let users = [];
		this.setState({loading: true});

		this.getUsers().then(u => {
			users = u;
		}).catch(() => {
			this.setState({error: true});
			this.props.snackbar(Strings.getFailure, "error");
		}).finally(() => {
			this.setState({users, loading: false});
		});

	}


	/**
	 * Update active customer.
	 *
	 * @param {Object} customer
	 * @return {void}
	 */
	updateCustomer(customer) {
		this.onChange(null);
		this.setState({customer});
		setTimeout(this.update);
	}


	/**
	 * Get whether a user option should be disabled.
	 *
	 * It will be disabled if the username is in the `disabledUsers` prop.
	 *
	 * @param {String} user Username
	 * @return {Boolean}
	 */
	getOptionDisabled(user) {
		if (!this.props.disabledUsers) return false;
		else return this.props.disabledUsers.includes(user.Username);
	}


	/**
	 * Get users.
	 *
	 * @return {Promise}
	 */
	getUsers() {
		return UserService.get(this.customer);
	}


	/**
	 * Get columns definition.
	 *
	 * @return {String}
	 */
	get columns() {
		if (this.props.vertical || !this.props.customer) return "100%";
		else return "45% 55%";
	}


	/**
	 * Get customer ID.
	 *
	 * @return {String|null}
	 */
	get customer() {
		return (this.state.customer ? this.state.customer.CustomerID : null);
	}


	/**
	 * Get empty text.
	 *
	 * @return {String}
	 */
	get noOptionsText() {
		return (this.state.error ? Strings.getFailure : Strings.empty);
	}


	/**
	 * Get value for user autocomplete.
	 *
	 * @return {Object|null}
	 */
	get value() {
		return (this.props.value || this.state.value);
	}


	/**
	 * Container styles.
	 *
	 * @return {Object}
	 */
	get containerStyles() {
		return {
			display: "grid",
			gridAutoFlow: (this.props.vertical ? "row" : "column"),
			gridColumnGap: rem(),
			gridRowGap: rem(),
			gridTemplateColumns: this.columns,
			maxWidth: (this.props.mwIndent ? `calc(100% - ${rem()})` : "100%")
		};
	}


	/**
	 * Get option label.
	 *
	 * @param {Object} user
	 * @return {String}
	 */
	static getOptionLabel(user) {
		return `${user.Username} <${user.EmailAddress}>`;
	}


	/**
	 * Get whether an option should be selected.
	 * 
	 * @param {Object} option
	 * @param {Object} value
	 * @return {Boolean}
	 */
	static getOptionSelected(option, value) {
		return ((option === value) || (option?.Username === value?.Username));
	}


	/**
	 * Render an option.
	 *
	 * @param {Object} user
	 * @return {void}
	 */
	static renderOption(user) {
		return (
			<Container af="row" gap={0.25}>
				<span style={UserPicker.labelStyles}>
					{user.Username}
				</span>
				<span>{user.EmailAddress}</span>
			</Container>
		);
	}


	/**
	 * Label styles.
	 * 
	 * @type {Object}
	 */
	static labelStyles = {fontWeight: "bold"};

}

export {UserPicker};
export default withSnackbar(UserPicker);
