import React from "react";
import Container from "Components/Containerx.js";
import DatePicker from "Components/DatePicker.js";
import InstallerPicker from "Components/InstallerPicker.js";
import StatusPicker from "./TimesheetsStatusPicker.js";
import scss from "./TimesheetsTableToolbar.module.scss";

/**
 * Timesheets table toolbar
 * 
 * @package SEC
 * @subpackage Timesheets
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class TimesheetsTableToolbar extends React.PureComponent {

	/**
	 * Installer changed.
	 *
	 * @param {Object} Installer
	 * @return {void}
	 */
	handleChangeInstaller = Installer => {
		this.props.onChangeFilter(Installer, "Installer");
	};

	/**
	 * `SheetDateMax` changed.
	 *
	 * @param {String} SheetDateMax
	 * @return {void}
	 */
	handleChangeSheetDateMax = SheetDateMax => {
		this.props.onChangeFilter(SheetDateMax, "SheetDateMax");
	};

	/**
	 * `SheetDateMin` changed.
	 *
	 * @param {String} SheetDateMin
	 * @return {void}
	 */
	handleChangeSheetDateMin = SheetDateMin => {
		this.props.onChangeFilter(SheetDateMin, "SheetDateMin");
	};

	/**
	 * Statuses changed.
	 * 
	 * @param {Array} Statuses
	 * @return {void}
	 */
	handleChangeStatuses = Statuses => {
		this.props.onChangeFilter(Statuses, "Statuses");
	};


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Container
				alignItems="flex-end"
				className={scss.wrapper}
				justifyContent="space-between">
				<StatusPicker
					disabled={this.props.disabled}
					onChange={this.handleChangeStatuses}
					value={this.props.Statuses} />
				<Container
					alignItems="flex-end"
					className={scss.right}>
					<InstallerPicker
						disabled={this.props.disabled}
						disableWhenOnlyOne={true}
						onChange={this.handleChangeInstaller}
						value={this.props.Installer} />
					<DatePicker
						clearable={true}
						disabled={this.props.disabled}
						label="Date (start)"
						onChange={this.handleChangeSheetDateMin}
						value={this.props.SheetDateMin} />
					<DatePicker
						clearable={true}
						disabled={this.props.disabled}
						label="Date (end)"
						onChange={this.handleChangeSheetDateMax}
						value={this.props.SheetDateMax} />
				</Container>
			</Container>
		);
	}

}

export default TimesheetsTableToolbar;
