import React from "react";
import SearchView from "Views/SearchView.js";
import SpoiView from "./SpoiView.js";
import SpoiService from "../SpoiService.js";
import Search from "../Components/SpoiPurchaseOrdersSearch.js";
import Table from "../Components/SpoiPurchaseOrdersTable.js";
import Strings from "../Resources/SpoiPurchaseOrdersViewStrings.json";
import {connect} from "react-redux";

/**
 * Purchase orders view
 *
 * @package SEC
 * @subpackage Spoi
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class SpoiPurchaseOrdersView extends React.PureComponent {

	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<SpoiView>
				<SearchView
					caption={Strings.caption}
					errorSnack={Strings.errorSnack}
					filters={this.constructor.filters}
					getResults={this.getCurrentPOs.bind(this)}
					label={`${Strings.label} - ${this.props.user.Xorg ? this.props.user.Xorg.Name : "SEC"}`}
					searchComponent={Search}
					tableComponent={Table}
					viewProps={{child: true, p: 0}} />
			</SpoiView>
		);
	}


	/**
	 * Load POs using current filters.
	 *
	 * @return {void}
	 */
	getCurrentPOs({filters, limit, page}) {
		return SpoiService.getPOs(filters, page, limit, true);
	}


	/**
	 * Get initial filter values.
	 * 
	 * @return {Object}
	 */
	static get filters() {
		return {

			/**
			 * Raised date max YYYY-MM-DD
			 *
			 * @type {String|null}
			 */
			RaisedDateMax: null,

			/**
			 * Raised date min YYYY-MM-DD
			 *
			 * @type {String|null}
			 */
			RaisedDateMin: null,

			/**
			 * PO statuses
			 * 
			 * @type {Array} None = Any
			 */
			Statuses: ["Live"],

			/**
			 * Search query
			 *
			 * @type {String|null}
			 */
			search: null

		};
	}

}

export default connect(({user}) => ({user}))(SpoiPurchaseOrdersView);
