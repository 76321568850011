import React from "react";
import Select from "Components/Select.js";
import Statuses from "../Resources/SpoiStatuses.json";
import {MenuItem} from "@material-ui/core";

/**
 * SPOI status picker
 *
 * Enables selection of status values in `SpoiStatuses`.
 *
 * You must provide `pi` and `po` props to indicate whether to include 
 * or exclude purchase invoice and purchase order statuses respectively; 
 * statuses to render are always matched exactly on both of these values.
 * 
 * An "Any" option is automatically rendered with a value of `null`.
 * 
 * The default selection value is the "Any" option (`null`).
 * 
 * Please refer to the source for details of all the available props.
 * 
 * @package SEC
 * @subpckage Spoi
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class SpoiStatusPicker extends React.PureComponent {

	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Select
				disabled={this.props.disabled}
				label="Status"
				multiple={true}
				onChange={this.props.onChange}
				value={this.props.value}>
				{
					this.statuses.map((s, k) => (
						<MenuItem key={k} value={s}>{s}</MenuItem>
					))
				}
			</Select>
		);
	}


	/**
	 * Get the statuses to render.
	 *
	 * We filter to get statuses which apply to the resource type(s) 
	 * to render for, as indicated by the `pi` and `po` prop values.
	 * 
	 * @return {Array}
	 */
	get statuses() {
		return Statuses.filter(status => {
			const pi = (this.props.pi === status.pi);
			const po = (this.props.po === status.po);
			return (pi && po);
		}).map(status => (status.name_local || status.name)).sort();
	}

}

export default SpoiStatusPicker;
