import React from "react";
import Dialog from "Components/DialogAlert.js";
import Table from "Components/Table.js";
import TableLabel from "Components/TableLabel.js";
import {TableRow, TableCell, Typography} from "@material-ui/core";
import strftime from "strftime";

/**
 * Data request dialog
 *
 * @package SEC
 * @subpackage Views
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class DataRequestsDialog extends React.PureComponent {

	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Dialog
				open={this.props.open}
				onClose={this.props.onClose}
				title="Data request"
				childrenPure={true}>

				<Table>
					<TableRow>
						<TableLabel>Enquiry</TableLabel>
						<TableCell>
							<Typography>
								{this.props.infoItem.enquiry.EnquiryID}
							</Typography>
						</TableCell>
					</TableRow>
					<TableRow>
						<TableLabel>Sent</TableLabel>
						<TableCell>
							<Typography>{this.sent}</Typography>
						</TableCell>
					</TableRow>
					<TableRow>
						<TableLabel>Created</TableLabel>
						<TableCell>
							<Typography>{this.created}</Typography>
						</TableCell>
					</TableRow>
					<TableRow>
						<TableLabel>Updated</TableLabel>
						<TableCell>
							<Typography>{this.updated}</Typography>
						</TableCell>
					</TableRow>
					<TableRow>
						<TableLabel>Archived</TableLabel>
						<TableCell>
							<Typography>
								{this.archivedDate ? this.archivedDate : "–"}
							</Typography>
						</TableCell>
					</TableRow>
					<TableRow>
						<TableLabel>Recipients</TableLabel>
						<TableCell>
							<Typography>
								{this.props.infoItem.recipients.length}
							</Typography>
						</TableCell>
					</TableRow>
					<TableRow>
						<TableLabel>Saved</TableLabel>
						<TableCell>
							<Typography>
								{this.local ? "Locally" : "Server"}
							</Typography>
						</TableCell>
					</TableRow>
				</Table>
			</Dialog>
		);
	}


	/**
	 * Get sent string.
	 *
	 * @return {String}
	 */
	get sent() {
		const t = this.sentDate;
		return t ? `${this.props.infoItem.sentBy.Username} / ${t}` : "–";
	}


	/**
	 * Get sent date as string.
	 *
	 * @return {String|null}
	 */
	get sentDate() {
		const sent = this.props.infoItem.sent;
		return sent ? this.constructor.strf(sent) : null;
	}


	/**
	 * Get created string.
	 *
	 * @return {String}
	 */
	get created() {
		const t = this.createdDate;
		return t ? `${this.props.infoItem.createdBy.Username} / ${t}` : "–";
	}


	/**
	 * Get created date as string.
	 *
	 * @return {String|null}
	 */
	get createdDate() {
		const created = this.props.infoItem.created;
		return created ? this.constructor.strf(created) : null;
	}


	/**
	 * Get updated string.
	 *
	 * @return {String}
	 */
	get updated() {
		const t = this.updatedDate;
		return t ? `${this.props.infoItem.updatedBy.Username} / ${t}` : "–";
	}


	/**
	 * Get updated date as string.
	 *
	 * @return {String|null}
	 */
	get updatedDate() {
		const updated = this.props.infoItem.updated;
		return updated ? this.constructor.strf(updated) : null;
	}


	/**
	 * Get archived date as string.
	 *
	 * @return {String|null}
	 */
	get archivedDate() {
		const archived = this.props.infoItem.archived;
		return archived ? this.constructor.strf(archived) : null;
	}


	/**
	 * Get whether the data request is saved locally.
	 *
	 * @return {Boolean}
	 */
	get local() {
		return this.props.infoItem.hasOwnProperty("_apid");
	}


	/**
	 * Format a timestamp as a string date.
	 *
	 * @param {String} time s
	 * @return {String}
	 */
	static strf(time) {
		return strftime("%d-%m-%Y", new Date((time * 1000)));
	}

}

export default DataRequestsDialog;
