import React from "react";
import Dialog from "Components/Dialog.js";
import String from "Components/String.js";
import Strings from "../Resources/SpoiPurchaseInvoiceRaiseConfirmDialogStrings.json";
import * as mui from "@material-ui/core";

/**
 * SPOI purchase invoice raise confirmation dialog
 * 
 * Please refer to the source for details of the available props.
 * 
 * @package SEC
 * @subpackage Spoi
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class SpoiPurchaseInvoiceRaiseConfirmDialog extends React.PureComponent {

	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * State
		 *
		 * @type {Object}
		 */
		this.state = {

			/**
			 * Final PI for PO?
			 *
			 * @type {Boolean}
			 */
			final: null,

			/**
			 * Error state (finalisation)
			 *
			 * @type {Boolean}
			 */
			error: false

		};

		/**
		 * Values
		 *
		 * @type {object}
		 */
		this.finals = {

			/**
			 * "Yes" value
			 * 
			 * @type {String}
			 */
			yes: "1",

			/**
			 * "No" value
			 *
			 * @type {String}
			 */
			no: "0"

		};

	}


	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Dialog
				loading={this.props.loading}
				open={this.props.open}
				onClose={this.props.onClose}
				onOk={this.handleSubmit.bind(this)}
				title={Strings.title}>


				<mui.Box style={this.constructor.contentStyles}>
					<String str={Strings.content} />

					<mui.Box mt={1}>
						<mui.FormControl
							component="fieldset"
							error={this.state.error}>
							<mui.FormLabel
								component="legend">
								{Strings.completePo.label}
							</mui.FormLabel>
							<mui.RadioGroup
								onChange={this.handleFinalChange.bind(this)}
								row={true}
								value={this.state.final}>
								<mui.FormControlLabel
									control={<mui.Radio color="primary" />}
									label={Strings.completePo.yes}
									value={this.finals.yes} />
								<mui.FormControlLabel
									control={<mui.Radio color="primary" />}
									label={Strings.completePo.no}
									value={this.finals.no} />
							</mui.RadioGroup>
							<mui.FormHelperText>
								{this.finalHelperText}
							</mui.FormHelperText>
						</mui.FormControl>
					</mui.Box>

					{(this.toleranceExceeded ? this.renderTolerance() : null)}
				</mui.Box>

			</Dialog>
		);
	}


	/**
	 * Render the tolerance exceeded warning.
	 * 
	 * @return {ReactNode}
	 */
	renderTolerance() {
		return (
			<React.Fragment>
				<mui.Box mt={1}>
					<mui.Divider />
				</mui.Box>
				<mui.Box mt={1}>
					<String
						fontWeight="bold"
						str={Strings.toleranceWarning[0]} />
				</mui.Box>
				<mui.Box mt={-0.5} mb={-1}>
					<String
						fontWeight="bold"
						str={Strings.toleranceWarning[1]} />
				</mui.Box>
			</React.Fragment>
		);
	}


	/**
	 * We're submitting.
	 *
	 * @return {void}
	 */
	handleSubmit() {
		if (this.state.final !== null) {
			this.props.onOk((this.state.final !== this.finals.no));
		}
		else this.setState({error: true});
	}


	/**
	 * User changed their "final invoice" selection.
	 *
	 * @param {Event} e
	 * @return {void}
	 */
	handleFinalChange(e) {
		this.setState({final: e.target.value, error: false});
	}


	/**
	 * Get the helper text for the "final invoice" controls.
	 *
	 * @return {String}
	 */
	get finalHelperText() {
		const str = Strings.completePo;
		return (!this.state.error ? str.helperText : str.helperTextError);
	}


	/**
	 * Get whether to display the tolerance exceeded warning.
	 *
	 * @return {Boolean}
	 */
	get toleranceExceeded() {
		return this.props.toleranceExceeded;
	}


	/**
	 * Content container styles.
	 * 
	 * @type {Object}
	 */
	static contentStyles = {overflow: "hidden"};

}

export default SpoiPurchaseInvoiceRaiseConfirmDialog;
