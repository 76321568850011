export const About = {
	appVersion: "App",
	authenticate: "Permissions",
	authButton: "Refresh Permissions",
	cacheButton: "Clear cache",
	cacheClear: "Cache",
	cacheClearWarning: "All resources which have been cached for offline use will be removed – some features may not work properly when offline until you've used them at least once while online.",
	toastAuthFailure: "Failed to refresh your permissions.",
	toastAuthSuccess: "Successfully refreshed your permissions.",
	toastCacheClearSuccess: "Cleared all cached resources.",
	toastUploadsClearFailure: "Unhandled error.",
	toastUploadsClearSuccess: "Cleared the sync queue.",
	uploadButton: "Clear",
	uploadsClear: "Clear uploads",
	uploadsClearWarning: [
		"All pending uploads will be deleted.",
		"They will not get saved to the server.",
		"Any in-progress uploads will complete."
	],
	uploadsPending: "Sync",
	help: {
		authenticate: "Refresh your user permissions to access newly assigned features without logging out.",
		cache: "Clear resources which have been cached on your device for offline use.",
		uploads: "Uploads which are awaiting synchronisation to the server."
	}
};

export const AdminAuditsSearch = {
	title: "Audit log",
	error: "Failed to retrieve audit events."
};

export const AdminInternals = {
	sec: "SEC Hub administration",
	help: [
		"Administrative controls for SEC Hub are provided here.",
		"These settings either affect the entirety of SEC Hub or are potentially sensitive operations."
	],
	docusign: {
		title: "DocuSign",
		win: "DocuSign login",
		auth: {
			button: "Authenticate to DocuSign",
			help: [
				"Click to login to DocuSign and configure the account which SEC Hub will masquerade as when creating new signature requests.",
				"SEC Hub will always masquerade as the DocuSign account you login to, irrespective of the SEC Hub user who creates the signature request."
			],
			error: "Failed to get required DocuSign configuration details."
		}
	},
	nmpiReportees: {
		title: "NMPI Reportees",
		help: [
			"Choose usernames who should be candidates for the \"Report to\" field on NMPI forms.",
			"Write a comma-delimited list of valid SEC staff usernames who will be available as reportees.",
			"SEC Hub app users will need to clear the app's cache (Application > Clear Cache) before this list is utilised by their app instance."
		],
		error: "Error saving setting.",
		error400: "An invalid username was specified.",
		loadError: "Getting NMPI reportees failed - return to this view to try again.",
		saved: "Saved NMPI Reportees."
	}
};

export const AdminPermissionsTable = {
	help: "SEC Hub users will need to clear their permissions cache (Application > Refresh Permissions) to apply changes to existing signed-in sessions.",
	error: "Failed to change permission status.",
	empty: "Failed to load the permissions matrix.",
	emptyPermissions: "No permissions to display.",
	emptyReal: "Nothing to display."
};

export const AdminUsersCreateDialog = {
	title: "Create user",
	help: [
		"Usernames must be unique across all customers and SEC staff.",
		"The user's password will be automatically generated; an email will be sent to the user detailing their credentials and how to login.",
		"To create a customer user account, view the customer's existing user accounts on the Administration > Users page before using this dialog.",
		"Use the Administration > Permissions page to assign permissions after account creation – users do not receive any permissions by default."
	],
	error: "User account creation failed.",
	success: "Created user account successfully."
};

export const AdminUsersDeleteDialog = {
	title: "Delete user",
	content: [
		"This user account will be permanently and irreversibly deleted.",
		"This may result in the deletion of database items which were created, edited or otherwise interacted with by this user - data loss may occur.",
		"For this reason, deleting a user account is generally discouraged. You should consider using the \"Disable\" option instead to prevent the user from logging in, without risking the integrity of data in the system.",
		"Proceed with this deletion only after acknowledging the above warning."
	],
	error: "Account deletion failed.",
	success: "User account successfully deleted."
};

export const AdminUsersPwrDialog = {
	title: "Reset user password",
	content: "A new password will be automatically generated and emailed to the user.",
	error: "Password reset failed.",
	success: "Successfully reset the user's password."
};

export const AdminUsersStatusDialog = {
	actioned: {
		enabled: "The user account has been disabled.",
		disabled: "The user account has been enabled."
	},
	error: {
		enabled: "Failed to disable the user account.",
		disabled: "Failed to enable the user account."
	},
	title: {
		enabled: "Disable user",
		disabled: "Enable user"
	},
	content: {
		enabled: "The user account will be marked as deactivated. All permission assignations made for the user will be unavailable. The user will still be able to login and use features which are not gated behind permissions.",
		disabled: "The user account will be reactivated with all its permissions."
	}
};

export const CustomerPicker = {
	error: "Failed to list organisations.",
	helperText: "ID or Name",
	label: "Organisation"
};

export const DataRequests = {
	fetchFailure: "Failed to get data requests.",
	fetchFailureDrafts: "Failed to load your drafts.",
	tabDrafts: "Drafts",
	tabSent: "Sent",
	tabSearch: "Search..."
};

export const DataRequestsEditor = {
	deleteAttachError: "Failed to delete attachment.",
	deleteAttachOk: "Successfully deleted attachment.",
	emailLoadFailure: "Failed to load email template.",
	enquiryValidate: "You must add an enquiry before you can save.",
	recipientValidate: "You must add a recipient before you can save.",
	loadErrMsg: "Failed to load data request.",
	submitConfirmMsg: "Are you sure you want to save this data request?",
	submitConfirmTitle: "Save data request",
	submitSuccessMsg: "Saved data request.",
	submitFailureMsg: "Failed to save data request.",
	sentAlert: "Read only mode enabled – this data request has been sent."
};

export const DataRequestsTable = {
	alerts: {
		archived: {
			title: "Data request archived",
			content: "This data request has been archived and cannot be viewed."
		}
	}
};

export const DataRequestsDeleteDialog = {
	title: "Delete data request",
	content: "Are you sure you want to delete this request?",
	error: "Failed to delete data request.",
	success: "Deleted data request."
};

export const DataRequestsSendDialog = {
	title: "Send data request",
	content: "Are you sure you want to send this request?",
	error: "Failed to send data request.",
	success: "Sent data request."
};

export const DocuSignForm = {
	confirmTitle: "Send DocuSign request",
	confirmContent: "Are you sure you want to send this request?",
	toastSuccess: "Sent DocuSign request.",
	toastFailure: "Failed to send DocuSign request."
};

export const EditorBase = {
	loadErrMsg: "Failed to load domain object for editing.",
	submitConfirmTitle: "Save",
	submitConfirmMsg: "Are you sure you want to save this item?",
	submitSuccessMsg: "Saved successfully.",
	submitFailureMsg: "Failed to save item."
};

export const EmailPickerContacts = {
	error: "Failed to get customer contacts."
};

export const EnquiryPicker = {
	error: "Failed to get enquiries.",
	helperText: "Enquiry ID, Customer or Project Description",
	label: "Enquiry",
	specific: {
		error: "Couldn't retrieve enquiry details.",
		helperText: "Enquiry ID",
		noOptionsText: "Please type a valid Enquiry ID"
	}
};

export const ErrorView = {
	title: "Unhandled error",
	text: [
		"Please try again in a little while."
	]
};

export const HealthSafetyNmpisComplianceDialog = {
	title: "Near Miss / Potential Incident",
	warnings: [
		"Please acknowledge before completing this report:",
		"\u00a0",
		"Have you corrected all hazards related to this event?",
		"- If not, do so immediately.",
		"Did an injury, illness or property damage occur?",
		"- If so, please use an accident report form.",
		"\u00a0",
		"Thank you for being a safety champion. Please use the text space on this form or contact us directly to provide any additional details for this report."
	]
};

export const HealthSafetyNmpisDeleteDialog = {
	title: "Delete NMPI",
	content: "Are you sure you want to permanently delete this NMPI?",
	error: "NMPI deletion failed.",
	success: "Deleted NMPI report."
};

export const HealthSafetyNmpisForm = {
	loadErrMsg: "Failed to load NMPI report.",
	submitConfirmTitle: "Save NMPI",
	submitConfirmMsg: ["Are you sure you want to save this NMPI report?"],
	submitSuccessMsg: "Saved NMPI report.",
	submitFailureMsg: "Failed to save NMPI report.",
	errors: {
		enquiry: "You must complete the required field \"Enquiry\"!",
		contractor: "You must complete the required field \"Contractor\"!",
		reportedTo: "You must complete the required field \"Report to\"!"
	},
	fields: {
		details: {
			help: "Please use this space to provide any extra information which is relevant to this report."
		}
	},
	incidents: {
		others: {
			caption: "No suitable option?",
			warning: "Please describe what happened in the Details field below."
		}
	},
	tooltips: {
		enquiry_sec: "Check if this incident occurred on SEC premises (e.g. while in the office)",
		enquiry_sec_assigned: "NMPI occurred on SEC premises",
		contractor_sec: "Check if SEC was the project contractor",
		contractor_sec_assigned: "SEC was the project contractor"
	}
};

export const HealthSafetyNmpisSearch = {
	csv: {
		download: {
			start: "CSV download started...",
			error: "Failed to download the result set's CSV file."
		}
	},
	error: "Failed to get search results.",
	label: "Near Miss Potential Incidents (NMPIs)"
};

export const Login = {
	e403: "Wrong password",
	e404: "Unknown username",
	failed: "Please check your connection and try again.",
	failedTitle: "Login failed"
};

export const Logout = {
	title: "Logout",
	content: [
		"Are you sure you want to logout?",
		"Ongoing data submissions may still complete."
	]
};

export const MessageComposer = {
	label: "Type a message...",
	labelSend: "Send",
	error: "Failed to send message."
};

export const MessageConversationCard = {
	delete: {
		label: "Delete"
	}
};

export const MessageConversationComposer = {
	title: "New Conversation",
	cancel: "Cancel",
	create: "Create",
	closed: "+ New Conversation"
};

export const MessageConversationDeleteDialog = {
	title: "Delete conversation",
	content: "Are you sure you want to delete this conversation?",
	success: "Conversation deleted successfully.",
	error: "Failed to delete conversation."
};

export const MessageDrawer = {
	empty: "No Conversations",
	loadError: "Failed to load conversations."
};

export const MessageThread = {
	error: "Failed to load messages.",
	markerror: "Couldn't mark conversation read.",
	nomore: "No more messages available."
};

export const MessageThreadChat = {
	empty: "No messages to display.",
	error: "Failed to load messages.",
	loadMore: "Load More",
	unread: "Unread Messages"
};

export const MultiSelectEnum = {
	fetchFailure: "Failed to enumerate possible selection values."
};

export const Notification = {
	markReadError: "Failed to mark notification as read.",
	tooltip: "Click/tap to mark as read",
	tooltipRead: "Click/tap to view"
};

export const NotificationsCentre = {
	title: "Notifications",
	empty: "No unread notifications.",
	clear: "Mark All As Read",
	clearError: "Failed to mark notifications as read."
};

export const ProfilePasswordDialog = {
	title: "Change your password",
	msg: "Please use a secure password of 8+ characters, including a mixture of alphanumeric and special characters. Your password will be changed immediately but you will not be signed out of SEC Hub on any device.",
	e: "This must match your new password.",
	e403: "Wrong password.",
	saved: "Saved new password successfully.",
	savedError: "Failed to save new password."
};

export const ProfileSettings = {
	title: "Profile settings",
	empty: "No settings available.",
	error: "Failed to retrieve settings.",
	setDone: "Saved setting value.",
	setError: "Failed to save setting value."
};

export const ProjectPicker = {
	error: "Failed to get projects.",
	helperText: "Project ID, Customer or Project Description",
	label: "Project"
};

export const SearchablePicker = {
	label: "Search",
	helperText: "Type to search...",
	loadingText: "Loading...",
	noOptionsText: "Type to search...",
	noOptionsTextSearched: "No results",
	error: "Failed to get search results."
};

export const TableData = {
	empty: "Nothing to display."
};

export const UserEmailEditable = {
	error: "Failed to update email address.",
	success: "Saved new email address."
};

export const UserPicker = {
	empty: "No results",
	label: "Select a user...",
	getFailure: "Failed to retrieve users."
};

