import React from "react";
import HighlightTile from "Components/HighlightTile.js";
import {Typography} from "@material-ui/core";

export default ({stats}) => (
	<HighlightTile label="Total Reports">
		<Typography color="primary" variant="h2">
			{stats.count}
		</Typography>
	</HighlightTile>
);
