import cloneDeep from "lodash.clonedeep";
import dispatchSyncing from "Dispatchers/Syncing.js";
import DataRequestService from "Services/DataRequestService.js";
import NmpiService from "Services/NmpiService.js";
import Post from "./Post.js";
import Store from "./Store.js";

/**
 * Postmaster
 *
 * @package SEC
 * @subpackage Services
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class Postmaster {

	/**
	 * Constructor.
	 *
	 * @return {self}
	 */
	constructor() {

		/**
		 * Previous pending count
		 *
		 * @type {Integer}
		 */
		this.pending = Store.getState().sync.pending;

		/**
		 * Subscribe to store changes
		 */
		Store.subscribe(() => {
			const pending = Store.getState().sync.pending;
			if (pending !== this.pending) {
				this.pending = pending;
				if (this.pending > 0) this.post();
			}
		});

	}


	/**
	 * Post all postable submissions.
	 *
	 * @return {void}
	 */
	post() {

		const promises = [];
		if (Store.getState().sync.syncing) return;
		else dispatchSyncing(true);

		Post.getPostable().then(postable => {

			postable.forEach(item => {

				const i = cloneDeep(item);

				promises.push(this.postItem(i).then(() => {
					Post.delete(item.apid);
				}).catch(e => {

					/**
					 * Sent to server and got an error response
					 *
					 * We don't want to automatically retry this item.
					 */
					if (e?.response?.status) {
						Post.put(item.apid, {error: 1});
					}
					else {

						/**
						 * Not sent to server (e.g. offline)
						 *
						 * We can retry automatically ASAP
						 */

					}

				}));

			});

			Promise.allSettled(promises).then(() => {
				dispatchSyncing(false);
			});

		});
	}


	/**
	 * Post a single item.
	 *
	 * The posting service defined for the item's `type` in `services` 
	 * is invoked and passed the item's `data`; it should return a 
	 * promise which resolves when the post is complete.
	 *
	 * @param {Object} item
	 * @return {void}
	 */
	postItem(item) {
		return this.constructor.services[item.type](item.data);
	}


	/**
	 * Services.
	 *
	 * Maps item types to URL to submit data to.
	 * 
	 * @type {Object}
	 */
	static services = {
		drequest: DataRequestService.create,
		nmpi: NmpiService.create
	};

}

export default new Postmaster();
