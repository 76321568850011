import React from "react";
import withMobile from "Hoc/withMobile.js";
import {Box, Typography} from "@material-ui/core";

/**
 * Form section
 *
 * @package SEC
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class FormSection extends React.PureComponent {

	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Box py={(this.props.isMobile ? 1 : 2)}>
				<Typography paragraph={this.props.isMobile} variant="h5">
					{this.props.label}
				</Typography>
				{this.props.children}
			</Box>
		);
	}

}

export default withMobile(FormSection);
