import React from "react";
import Navigator from "App/Navigator.js";
import View from "App/View.js";
import {Box, Tab, Tabs} from "@material-ui/core";

/**
 * Tab view
 *
 * Renders a set of components in a tabbed interface.
 *
 * Please refer to the source for details of all the available props.
 *
 * @package SEC
 * @subpackage Views
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class TabView extends React.PureComponent {

	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * State
		 *
		 * @type {Object}
		 */
		this.state = {

			/**
			 * Active tab
			 *
			 * @type {Number}
			 */
			tab: 0

		};

		/**
		 * Method binds
		 */
		this.onChange = this.onChange.bind(this);

	}


	/**
	 * Component mounted.
	 *
	 * @return {void}
	 */
	componentDidMount() {
		this.update();
	}


	/**
	 * Component updated.
	 *
	 * @param {Object} prevProps
	 * @return {void}
	 */
	componentDidUpdate(prevProps) {
		if (this.props.url !== prevProps.url) {
			this.update();
		}
	}


	/**
	 * Tab changed.
	 *
	 * @param {Event} e
	 * @param {Number} tab
	 * @return {void}
	 */
	onChange(e, tab) {
		this.pushHistory(this.props.tabs[tab].url);
	}


	/**
	 * Push history.
	 *
	 * @param {String} url
	 * @return {void}
	 */
	pushHistory(url) {
		Navigator.navigate(`${this.props.urlBase}${url}`);
	}


	/**
	 * Update the current tab.
	 * 
	 * @return {void}
	 */
	update() {

		let tab;
		const route = this.props.url.replace(this.props.urlBase, "");

		if (route && (route !== "/")) {
			tab = this.props.tabs.filter(t => route.startsWith(t.url))[0];
			if (tab) this.setState({tab: this.props.tabs.indexOf(tab)});
		}

		if (!tab) this.pushHistory(this.props.tabs[0].url);

	}


	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<View>
				<Tabs
					indicatorColor="primary"
					onChange={this.onChange}
					scrollButtons="on"
					value={this.state.tab}>
					{this.renderTabs()}
				</Tabs>
				<Box>{this.props.tabs[this.state.tab].component}</Box>
			</View>
		);
	}


	/**
	 * Render tabs.
	 *
	 * @return {ReactNode}
	 */
	renderTabs() {
		const tabs = [];
		this.props.tabs.forEach((tab, i) => {
			tabs.push(<Tab key={i} label={tab.label} />);
		});
		return tabs;
	}

}

export default TabView;
