import React from "react";
import Statuses from "../Resources/SpoiStatuses.json";
import StatusHelper from "../Helpers/SpoiStatusHelper.js";
import {Chip} from "@material-ui/core";

/**
 * SPOI status chip
 *
 * @package SEC
 * @subpackage Spoi
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class SpoiStatusChip extends React.PureComponent {

	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Chip
				label={StatusHelper.resolveGlobalToLocal(this.props.status)}
				size={this.props.size}
				style={this.styles} />
		);
	}


	/**
	 * Get the colour for our status.
	 * 
	 * @return {String}
	 */
	get colour() {
		return Statuses.filter(s => ((s.name === this.props.status) || (s.name_local === this.props.status)))[0]?.colour;
	}


	/**
	 * Styles.
	 *
	 * @return {Object}
	 */
	get styles() {
		return {
			color: "white",
			fontWeight: "normal",
			background: this.colour,
			...this.props.style
		};
	}

}

export default SpoiStatusChip;
