import React from "react";
import * as mui from "@material-ui/core";

export default ({label, children}) => (
	<mui.Card>
		<mui.CardContent>
			<mui.Typography variant="caption" paragraph gutterBottom>
				{label}
			</mui.Typography>
			{children}
		</mui.CardContent>
	</mui.Card>
);
