import React from "react";
import Component from "App/Component.js";
import Dialog from "Components/Dialog.js";
import withSnackbar from "Hoc/withSnackbar";
import NmpiService from "Services/NmpiService.js";

/**
 * Health and safety NMPIs close/open dialog
 *
 * @package SEC
 * @subpackage Views
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class HealthSafetyNmpisCloseDialog extends Component {

	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * State
		 *
		 * @type {Object}
		 */
		this.state = {

			/**
			 * Loading
			 *
			 * @type {Boolean}
			 */
			loading: false

		};

		/**
		 * Method binds
		 */
		this.onOk = this.onOk.bind(this);

	}


	/**
	 * Close/open.
	 *
	 * @return {void}
	 */
	onOk() {

		let promise;
		this.setState({loading: true});

		/**
		 * Close/open
		 */
		if (this.closed) {
			promise = NmpiService.open(this.props.nmpi.Name);
		}
		else promise = NmpiService.close(this.props.nmpi.Name);

		/**
		 * Wait for promise
		 */
		promise.then(() => {
			this.setState({loading: false});
			this.props.snackbar(this.actioned, "success");
			this.props.onClose();
			this.props.onChange();
		}).catch(e => {
			this.setState({loading: false});
			this.props.snackbar(e);
			this.props.onClose();
		});

	}


	/**
	 * Render.
	 *
	 * @return {ReactNNode}
	 */
	render() {
		return (
			<Dialog
				title={(`${this.closed ? "Reopen" : "Close"} NMPI`)}
				content={`Are you sure you want to ${this.verb} this NMPI?`}
				open={this.props.open}
				loading={this.state.loading}
				onClose={this.props.onClose}
				onOk={this.onOk}>
			</Dialog>
		);
	}


	/**
	 * Get whether closed.
	 *
	 * @return {Boolean}
	 */
	get closed() {
		return !!this.props.nmpi.ClosedTime;
	}


	/**
	 * Get action verb string.
	 *
	 * @return {String}
	 */
	get verb() {
		return (this.closed ? "reopen" : "close");
	}


	/**
	 * Get name of action which was performed on success.
	 *
	 * @return {String}
	 */
	get actioned() {
		return (this.closed ? "Reopened NMPI." : "Closed NMPI.");
	}

}

export default withSnackbar(HealthSafetyNmpisCloseDialog);
