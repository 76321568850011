import React from "react";
import {connect} from "react-redux";

export default Component => {

	return connect(({permissions}) => ({permissions}))(

		/**
		 * Authentication wrapper HOC
		 *
		 * @package SEC
		 * @subpackage Hoc
		 * @author Heron Web Ltd
		 * @copyright SEC Group
		 */
		class extends React.PureComponent {

			/**
			 * Render.
			 * 
			 * @return {ReactNode}
			 */
			render() {
				return (
					<Component
						{...this.props}
						hasPermission={this.hasPermission} />
				);
			}


			/**
			 * Get whether the user has a permission.
			 *
			 * @param {String} permission
			 * @return {Boolean}
			 */
			hasPermission = p => this.props.permissions.includes(p);

		}

	);

};
