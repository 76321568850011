import {Typography} from "@material-ui/core";
import {memo} from "react";

export default memo(props => {

	const styles = {
		...props.style,
		fontWeight: (props.bold ? "bold" : props.fontWeight)
	};

	return (
		<Typography
			color={props.color}
			style={styles}
			variant={props.variant}>
			{(props.content || props.children)}
		</Typography>
	);

});
