import React from "react";
import Component from "App/Component.js";
import Dialog from "Components/Dialog.js";
import HealthSafetyNmpisForm from "./HealthSafetyNmpisForm.js";

/**
 * `HealthSafetyNmpisForm` in a `Dialog`
 * 
 * @package SEC
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class HealthSafetyNmpisFormDialog extends Component {

	/**
	 * State
	 *
	 * @type {Object}
	 */
	state = {

		/**
		 * Submitting?
		 * 
		 * @type {Boolean}
		 */
		submitting: false

	};

	/**
	 * `HealthSafetyNmpisForm`
	 *
	 * @type {HealthSafetyNmpisForm|null}
	 */
	formRef = null;

	/**
	 * `HealthSafetyNmpisForm` changed.
	 *
	 * @param {HealthSafetyNmpisForm} ref
	 * @return {void}
	 */
	handleFormMountRef = ref => (this.formRef = ref);

	/**
	 * Submitting!
	 * 
	 * @return {void}
	 */
	handleSubmit = () => {
		if (this.formRef) {
			this.formRef.submitRequest();
		}
	};

	/**
	 * Submitting.
	 *
	 * @return {void}
	 */
	handleSubmitting = () => {
		this.setState({submitting: true});
	};

	/**
	 * Submitting done.
	 *
	 * @param {Object} nmpi
	 * @return {void}
	 */
	handleSubmitDone = nmpi => {
		this.props.onClose();
		this.props.onSubmit(nmpi);
		this.setState({submitting: false});
	};


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Dialog
				disabled={this.state.submitting}
				fullWidth={true}
				maxWidth="md"
				onClose={this.props.onClose}
				onOk={this.handleSubmit}
				open={this.props.open}
				title="Submit NMPI">
				<HealthSafetyNmpisForm
					date={this.props.date}
					enquiry={this.props.enquiry}
					hideFabs={true}
					inhibitSuccessHandler={true}
					noComplianceDialog={this.props.noComplianceDialog}
					noDrafts={this.props.noDrafts}
					noSyncQueue={this.props.noSyncQueue}
					onMountRef={this.handleFormMountRef}
					onSubmitting={this.handleSubmitting}
					onSubmitDone={this.handleSubmitDone}
					useProject={this.props.useProject} />
			</Dialog>
		);
	}

}

export default HealthSafetyNmpisFormDialog;
