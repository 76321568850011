import Collection from "Domain/Collection.js";
import SpoiLineItem from "./SpoiLineItem.js";

/**
 * SPOI line item collection
 *
 * @package SEC
 * @subpackage Spoi
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class SpoiLineItemCollection extends Collection {

	/**
	 * Get the total value of all items in the collection.
	 * 
	 * @return {Float}
	 */
	get total() {
		return this.items.map(li => li.total).reduce((a, b) => (a + b), 0);
	}


	/**
	 * Construct a new collection instance.
	 *
	 * We override the base implementation to enable setting `TaxCode` 
	 * on each of the given items (which at this stage is just a basic 
	 * object) as this is not given by the API but is required locally.
	 *
	 * @param {Array} items optional See base
	 * @param {Object} TaxCode optional Tax code object to assign to items
	 * @return {SpoiLineItemCollection}
	 */
	static construct(items=[], TaxCode=null) {

		items.forEach(item => {
			item.TaxCode = TaxCode;
		});

		return super.construct(items);

	}


	/**
	 * Get the type of item used in the collection.
	 *
	 * @return {Object}
	 */
	static get type() {
		return SpoiLineItem;
	}

}

export default SpoiLineItemCollection;
