import React from "react";
import Component from "App/Component.js";
import Navigator from "App/Navigator.js";
import ActionBar from "Components/ActionBar.js";
import Details from "Components/Details.js";
import Fab from "Components/Fab.js";
import mobile from "Helpers/Mobile.js";
import SpoiService from "../SpoiService.js";
import SpoiView from "./SpoiView.js";
import Detail from "../Components/SpoiPurchaseOrderDetail.js";
import DocumentViewer from "../Components/SpoiPoDocumentViewer.js";
import Header from "../Components/SpoiDomainHeader.js";
import LineItemsTable from "../Components/SpoiPoItemsTable.js";
import PiTable from "../Components/SpoiPurchaseInvoicesTable.js";
import SpoiPermissions from "../Resources/SpoiPermissions.json";
import {connect} from "react-redux";
import {NavigateNext as NextIcon} from "@material-ui/icons";
import {Button, withWidth} from "@material-ui/core";

/**
 * Purchase order view
 *
 * @package SEC
 * @subpackage Spoi
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class SpoiPurchaseOrderView extends Component {

	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * State
		 *
		 * @type {Object}
		 */
		this.state = {

			/**
			 * Purchase order
			 *
			 * @type {Object}
			 */
			po: null,

			/**
			 * Purchase invoices raised against the PO
			 *
			 * @type {Array}
			 */
			pis: [],

			/**
			 * Error state
			 *
			 * @type {Boolean}
			 */
			error: false,

			/**
			 * Loading state
			 *
			 * @type {Boolean}
			 */
			loading: true,

			/**
			 * Display document viewer
			 * 
			 * @type {Boolean}
			 */
			viewDocument: false

		};

		/**
		 * Method binds
		 */
		this.handleViewDocumentClose = this.handleViewDocumentClose.bind(this);

	}


	/**
	 * Component mounted.
	 *
	 * @return {void}
	 */
	componentDidMount() {
		this.getPoPromise().then(popis => {
			popis.po = SpoiService.preparePo(popis.po);
			this.setState(popis);
		}).catch(() => {
			this.setState({error: true});
		}).finally(() => this.setState({loading: false}));
	}


	/**
	 * Get the promise to get the purchase order object for rendering.
	 *
	 * This will resolve with an object with `po` (the purchase order 
	 * object) and `pis` (when the user has permission to view purchase 
	 * invoices, the PIs raised against the PO) properties with values 
	 * described as respectively as previously.
	 * 
	 * @return {Promise}
	 */
	getPoPromise() {
		if (this.canViewInvoices) {
			return SpoiService.getPOWithPIs(this.uriPoid);
		}
		else return SpoiService.getPO(this.uriPoid).then(po => ({po}));
	}


	/**
	 * Handle invoice raise clicked.
	 *
	 * @return {void}
	 */
	handleRaiseInvoice() {
		Navigator.navigate(`/spoi/invoices/raise/${this.state.po.Id}`);
	}


	/**
	 * Handle view document clicked.
	 * 
	 * @return {void}
	 */
	handleViewDocument() {
		this.setState({viewDocument: true});
	}


	/**
	 * Document viewer closed.
	 * 
	 * @return {void}
	 */
	handleViewDocumentClose() {
		this.setState({viewDocument: false});
	}


	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<SpoiView err={this.state.error} loading={this.state.loading}>
				<ActionBar main={this.renderHeader()} noMarginBottom={false}>
					{this.renderActions()}
				</ActionBar>

				<Detail po={this.state.po} />
				<LineItemsTable lis={this.state.po?.Items} />

				{(this.canViewInvoices ? this.renderInvoices() : null)}

				<DocumentViewer
					onClose={this.handleViewDocumentClose}
					open={this.state.viewDocument}
					po={this.state.po} />
			</SpoiView>
		);
	}


	/**
	 * Render actions.
	 * 
	 * @return {ReactNode}
	 */
	renderActions() {
		return (
			<React.Fragment>
				<Button
					color="primary"
					onClick={this.handleViewDocument.bind(this)}
					variant="outlined">
					View PDF
				</Button>
				{(this.canRaiseInvoice ? this.renderRaiseButton() : null)}
				{(this.canRaiseInvoice && this.mobile) ? this.renderRaiseFab() : null}
			</React.Fragment>
		);
	}


	/**
	 * Render the raise invoice button.
	 * 
	 * @return {ReactNode}
	 */
	renderRaiseButton() {
		return (
			<Button
				color="primary"
				disabled={!this.canRaiseInvoice}
				onClick={this.handleRaiseInvoice.bind(this)}
				style={{order: (this.mobile ? -1 : null)}}
				variant="contained">
				Raise Invoice
			</Button>
		);
	}


	/**
	 * Render the raise invoice button as an action button.
	 *
	 * @return {void}
	 */
	renderRaiseFab() {
		return (
			<Fab
				disabled={!this.canRaiseInvoice}
				onClick={() => this.handleRaiseInvoice()}>
				<NextIcon />
			</Fab>
		);
	}


	/**
	 * Render the header component.
	 *
	 * @return {ReactNode}
	 */
	renderHeader() {
		return (
			<Header
				id={this.state.po?.Id}
				label="Purchase Order"
				margin={this.mobile}
				status={this.state.po?.Status} />
		);
	}


	/**
	 * Render the invoices table.
	 *
	 * @return {ReactNode}
	 */
	renderInvoices() {
		return (
			<Details open={false} label="Raised Invoices">
				<PiTable disablePagination={true} values={this.state.pis} />
			</Details>
		);
	}


	/**
	 * Get whether we can raise an invoice against the PO.
	 *
	 * @return {Boolean}
	 */
	get canRaiseInvoice() {
		if (!this.state.po) return false;
		else if (!SpoiService.poInvoicable(this.state.po)) return false;
		else return this.props.permissions.includes(SpoiPermissions.pi_raise);
	}


	/**
	 * Get whether the user can view purchase invoices.
	 *
	 * @return {Boolean}
	 */
	get canViewInvoices() {
		return this.props.permissions.includes(SpoiPermissions.pi_get);
	}


	/**
	 * Get whether we've a mobile viewport.
	 */
	get mobile() {
		return mobile(this.props.width);
	}


	/**
	 * Get the purchase order ID from the URI.
	 * 
	 * @return {String}
	 */
	get uriPoid() {
		return this.props.match.params.id;
	}

}

export default connect(({permissions}) => ({permissions}))(
	withWidth()(SpoiPurchaseOrderView)
);
