/**
 * Abstract error
 *
 * @package SEC
 * @subpackage Errors
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class AbstractError extends Error {

	/**
	 * Constructor.
	 *
	 * @return {self}
	 */
	constructor() {
		super("Abstract method requires implementation by child!");

		/**
		 * Name
		 *
		 * @type {String}
		 */
		this.name = this.constructor.name;

	}

}

export default AbstractError;
