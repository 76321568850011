import Domain from "Domain/Domain.js";
import Time from "Helpers/Time.js";
import SpoiLineItemCollection from "./SpoiLineItemCollection.js";

/**
 * SPOI Purchase invoice
 *
 * @package SEC
 * @subpackage Spoi
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class SpoiPurchaseInvoice extends Domain {

	/**
	 * Constructor.
	 *
	 * @param {Object} obj optional
	 * @return {self}
	 */
	constructor(obj={}) {
		super(obj);

		/**
		 * Invoice UUID
		 * 
		 * This will always be `null` if this purchase invoice 
		 * only exists locally and has not been fetched from the API.
		 * 
		 * @type {String}
		 */
		this.Uuid = null;

		/**
		 * Purchase order
		 *
		 * This may be an integer purchase order ID if this PI was 
		 * created from a PIs API fetch call and its PO object 
		 * was not available or not set against this instance.
		 * 
		 * @type {Object|Integer}
		 */
		this.PurchaseOrder = null;

		/**
		 * Purchase order domain
		 *
		 * When set, this is guaranteed to be the PI's PO domain object.
		 *
		 * This may not be available if the PI was fetched from the network.
		 *
		 * @type {Object}
		 */
		this.PurchaseOrderDomain = null;

		/**
		 * Supplier invoice reference
		 * 
		 * @type {String}
		 */
		this.SupplierInvoiceRef = null;

		/**
		 * Invoice date
		 * 
		 * @type {String} YYYY-MM-DD
		 */
		this.InvoiceDate = Time.todayYMD();

		/**
		 * Received date
		 *
		 * @type {String} YYYY-MM-DD
		 */
		this.ReceivedDate = Time.todayYMD();

		/**
		 * Due date
		 *
		 * NOTE: This will only be set when the purchase invoice 
		 * has been fetched from the backend; to get the computed 
		 * due date for use when raising this invoice (computed 
		 * automatically from the invoice date), use `DueDateLive`.
		 * 
		 * @type {String} YYYY-MM-DD
		 */
		this.DueDate = null;

		/**
		 * Due date (agreement request)
		 *
		 * @type {String|null} YYYY-MM-DD
		 */
		this.DueDatePreagreedRequested = null;

		/**
		 * Tax code object
		 *
		 * This may be a string tax code ID if this PI was 
		 * created from a PIs API fetch call and its TC object 
		 * was not available or not set against this instance.
		 * 
		 * @type {Object|String}
		 */
		this.TaxCode = null;

		/**
		 * Nominal code
		 *
		 * @type {Integer}
		 */
		this.NominalCode = null;

		/**
		 * CIS status
		 *
		 * We always assume this CIS status to be true for all 
		 * of the line items of this invoice and we do not check 
		 * the individual line items to ensure it is so.
		 *
		 * @type {Boolean}
		 */
		this.Cis = false;

		/**
		 * Items
		 *
		 * @type {SpoiLineItemCollection}
		 */
		this.Items = null;

		/**
		 * Status
		 *
		 * This will always be `null` when this invoice only 
		 * exists locally and has not been fetched from the network.
		 * 
		 * @type {String}
		 */
		this.Status = null;

		/**
		 * Disputed reason
		 *
		 * @type {String}
		 */
		this.DisputedReason = null;

		/**
		 * Assign values
		 */
		if (!(obj.Items instanceof SpoiLineItemCollection)) {
			obj.Items = SpoiLineItemCollection.construct(obj.Items, obj.TaxCode);
		}
		Object.assign(this, obj);
	}


	/**
	 * Get whether this invoice is currently disputed.
	 *
	 * @return {Boolean}
	 */
	get disputed() {
		return (this.Status === "Disputed");
	}


	/**
	 * Get whether this purchase invoice has any "disputed reason" text.
	 *
	 * @return {Boolean}
	 */
	get hasDisputedReason() {
		return (!!this.DisputedReason);
	}


	/**
	 * Get whether the purchase invoice has a UUID assigned.
	 *
	 * This will never be `true` for instances which only exist 
	 * locally and which have never been fetched from the network.
	 *
	 * @return {Boolean}
	 */
	get hasUuid() {
		return (this.Uuid !== null);
	}


	/**
	 * Get the purchase invoice's purchase order.
	 * 
	 * This is an alias for `PurchaseOrder`.
	 * 
	 * @return {Object}
	 */
	get po() {
		return this.PurchaseOrder;
	}


	/**
	 * Get the purchase order's ID.
	 *
	 * @return {Integer|null}
	 */
	get poid() {
		return (this.PurchaseOrder?.Id || this.PurchaseOrder);
	}


	/**
	 * Get whether this invoice is designated as CIS or if any 
	 * of its items are designated as CIS. This is only needed because 
	 * when PIs fetched from the API do not indicate their CIS status 
	 * but we only support creating invoices with all-CIS or all non-CIS 
	 * items and so if any single item is CIS we can assume the entire 
	 * invoice is to be treated as CIS for our purposes.
	 *
	 * @return {Boolean}
	 */
	get CisOrCisItems() {
		const i = this.Items.get();
		return (this.Cis ? true : (i.filter(i => i.CISItem).length > 0));
	}


	/**
	 * Get the due date based on the invoice date and the PO terms.
	 *
	 * @return {String} YYYY-MM-DD
	 */
	get DueDateCurrent() {

		// We might have a due date
		if (this.DueDate) {
			return this.DueDate;
		}

		// Get the next available date
		let date = new Date(Date.parse(this.InvoiceDate));
		const year = date.getFullYear();
		const month = (date.getMonth() + 1);
		const days = this.PurchaseOrder?.PaymentTermsDays;

		// Return the actual date
		if (this.PurchaseOrder?.PaymentTermsEndOfMonth) {
			date = new Date(year, month, 0);
		}
		return Time.diffDaysYmd(days, date);

	}


	/**
	 * Get the current total of all enabled line items.
	 * 
	 * @return {Float}
	 */
	get TotalCurrent() {
		return this.Items.total;
	}


	/**
	 * Get current VAT total amount based on current items total.
	 * 
	 * @return {Float}
	 */
	get VATCurrent() {
		return (this.TotalCurrent * (this.TaxCode?.Value || 1));
	}


	/**
	 * Get whether this invoice's value exceeds the purchase order's value.
	 *
	 * You may optionally pass a collection of purchase invoices to get 
	 * whether the entire collection, including this invoice, exceeds 
	 * the purchase order's value collectively (generally this is desired).
	 *
	 * When doing this, there is no verification that all the PIs are 
	 * raised against our PO; it is expected this should always be true.
	 * 
	 * @param {SpoiPurchaseInvoiceCollection} pis optional
	 * @return {Boolean}
	 */
	poExceeded(pis=null) {
		const pisTotal = (pis.total || 0);
		return ((this.TotalCurrent + pisTotal) > this.PurchaseOrder?.Total);
	}


	/**
	 * Get whether this invoice can be raised against its PO, based on its 
	 * current total and the total of a `SpoiPurchaseInvoiceCollection` 
	 * which is assumed to consist of purchase invoices raised against 
	 * the same purchase order as our order.
	 * 
	 * @param {SpoiPurchaseInvoiceCollection} pis optional See `poExceeded()`.
	 * @return {Boolean}
	 */
	raisableAgainstPo(pis=null) {
		return !this.poExceeded(pis);
	}

}

export default SpoiPurchaseInvoice;
