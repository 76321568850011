import {api} from "api.js";

/**
 * Download service
 *
 * @package SEC
 * @subpackage Services
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class DownloadService {

	/**
	 * Download a file as a blob.
	 *
	 * Returns a Promise which resolves with an object:
	 * - `blob` – Blob
	 * - `blobUrl` – Object URL for the blob
	 * - `mime` – Mime type string (from `content-type` response header)
	 *
	 * @param {String} url
	 * @return {Promise}
	 */
	static downloadBlob(url) {
		return api.call({url, responseType: "blob"}).then(({data, headers}) => {
			return {
				blob: data,
				blobUrl: URL.createObjectURL(data),
				mime: headers["Content-Type"]
			};
		});
	}

}

export default DownloadService;
