import React from "react";
import Time from "Helpers/Time.js";
import {Typography} from "@material-ui/core";

export default ({user, time}) => (
	<React.Fragment>
		<Typography paragraph>{(user ? user.Username : "–")}</Typography>
		<Typography>{Time.dmyFromSec(time)}</Typography>
	</React.Fragment>
);
