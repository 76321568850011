import React from "react";
import Dialog from "./DialogAlert.js";
import Table from "./Table.js";
import TableLabel from "./TableLabel.js";
import {TableRow, TableCell, Typography} from "@material-ui/core";

/**
 * Dialog for information
 *
 * @package SEC
 * @subpackage Views
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class DialogInfo extends React.PureComponent {

	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Dialog
				childrenPure={true}
				onClose={this.props.onClose}
				open={this.props.open}
				title={this.props.title}>
				<Table>
					{
						this.props.fields.map((f, i) => (
							<TableRow key={i}>
								<TableLabel>{f.label}</TableLabel>
								<TableCell>
									<Typography>{f.value}</Typography>
								</TableCell>
							</TableRow>
						))
					}
				</Table>
			</Dialog>
		);
	}

}

export default DialogInfo;
