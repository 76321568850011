import React from "react";
import Component from "App/Component.js";
import rem from "Helpers/Rem.js";
import withSnackbar from "Hoc/withSnackbar.js";
import MessageService from "Services/MessageService.js";
import {MessageComposer as Strings} from "Resources/Strings.js";
import {Box, Button, TextField} from "@material-ui/core";

/**
 * Messages composer widget
 *
 * @package SEC
 * @subpackage Messages
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class MessageComposer extends Component {

	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * State
		 *
		 * @type {Object}
		 */
		this.state = {

			/**
			 * Message
			 *
			 * @type {String}
			 */
			message: "",

			/**
			 * Sending
			 *
			 * @type {Boolean}
			 */
			sending: false

		};

		/**
		 * Text field
		 *
		 * @type {ReactRef}
		 */
		this.textField = React.createRef();

	}


	/**
	 * Send message.
	 *
	 * @return {void}
	 */
	send() {
		const msg = this.state.message;
		const contact = this.props.contact;
		this.setState({sending: true});

		/**
		 * Send the message
		 */
		MessageService.send(msg, contact).then(() => {
			this.setState({message: ""});
			this.props.onSent(msg, contact);
		}).catch(() => {
			this.props.snackbar(Strings.error, "error");
		}).finally(() => {
			this.setState({sending: false});
			if (this.textField.current) this.textField.current.focus();
		});
	}


	/**
	 * Send on Ctrl+Enter.
	 *
	 * @param {Event} e
	 * @return {void}
	 */
	handleKeyDown(e) {
		if (e.ctrlKey && (e.which === 13) && this.sendable) {
			this.send();
		}
	}


	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Box style={{display: "flex"}}>
				<TextField
					disabled={this.state.sending}
					inputRef={this.textField}
					label={Strings.label}
					multiline
					onChange={e => this.setState({message: e.target.value})}
					onKeyDown={e => this.handleKeyDown(e)}
					rows={1}
					rowsMax={5}
					style={{flexGrow: 1, marginRight: rem()}}
					value={this.state.message}
					variant="outlined" />
				<Button
					color="primary"
					disabled={(!this.sendable || this.state.sending)}
					onClick={() => this.send()}
					style={{alignSelf: "center", height: `5rem`}}
					variant="contained">
					{Strings.labelSend}
				</Button>
			</Box>
		);
	}


	/**
	 * Get whether the message is currently sendable.
	 *
	 * @return {Boolean}
	 */
	get sendable() {
		return (this.state.message.trim() !== "");
	}

}

export default withSnackbar(MessageComposer);
