import React from "react";
import View from "App/View.js";
import ProfileCard from "./ProfileCard.js";
import ProfileSettings from "./ProfileSettings.js";
import ProfileSettingsApp from "./ProfileSettingsApp.js";
import {connect} from "react-redux";

/**
 * Profile screen
 *
 * @package SEC
 * @subpackage Views
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class Profile extends React.PureComponent {

	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<View>
				<ProfileCard user={this.props.user} />
				<ProfileSettingsApp />
				<ProfileSettings />
			</View>
		);
	}

}

export default connect(({user}) => ({user}))(Profile);
