import {api} from "api.js";

/**
 * Installer service
 *
 * @package SEC
 * @subpackage Services
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class InstallerService {

	/**
	 * Get the user's installers list.
	 *
	 * @return {Promise} Resolves with array of API installer objects
	 */
	static getUserInstallers() {
		return api.call({url: "/api/installers/mine"}).then(({data}) => data);
	}

}

export default InstallerService;
