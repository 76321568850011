import "./index.scss";
import sw from "./worker.js";
import App from "./App/App.js";
import DayJs from "dayjs";
import DayJsIsoWeek from "dayjs/plugin/isoWeek";
import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";

if (process.env.REACT_APP_SENTRY_DSN) {
	Sentry.init({
		dsn: process.env.REACT_APP_SENTRY_DSN,
		release: process.env.REACT_APP_APP
	});
}

DayJs.extend(DayJsIsoWeek);

sw();

ReactDOM.render(<App />, document.getElementById("react"));
