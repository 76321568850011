import React from "react";
import Containerx from "Components/Containerx.js";

/**
 * Flex container
 * 
 * @package SEC
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class Flex extends React.PureComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Containerx
				display="flex"
				flexDirection={(!this.props.columnar ? "column" : "row")}
				{...this.props}>
				{this.props.children}
			</Containerx>
		);
	}

}

export default Flex;
