import React from "react";
import Time from "Helpers/Time.js";
import Dialog from "Components/DialogInfo.js";

/**
 * Health and safety NMPI dialog
 *
 * @package SEC
 * @subpackage Views
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class HealthSafetyNmpisDialog extends React.PureComponent {

	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Dialog
				title="NMPI"
				open={this.props.open}
				onClose={this.props.onClose}
				fields={this.fields} />
		);
	}


	/**
	 * Fields.
	 *
	 * @return {Array}
	 */
	get fields() {
		return [
			{
				label: "Name",
				value: this.nmpi.Name ? this.nmpi.Name.substring(0, 6) : "–"
			},
			{
				label: "Enquiry",
				value: (this.nmpi.Enquiry?.EnquiryID || "(SEC Office)")
			},
			{
				label: "Contractor",
				value: (this.nmpi.Contractor || "SEC")
			},
			{
				label: "Incident",
				value: Time.dmyhmFromDatetime(this.nmpi.IncidentDateTime)
			},
			{
				label: "Reported",
				value: this.reportedValue
			},
			{
				label: "Closed",
				value: this.closedValue
			},
			{
				label: "Saved",
				value: (this.local ? "Local" : "Server")
			}
		];
	}


	/**
	 * NMPI.
	 *
	 * @return {Object}
	 */
	get nmpi() {
		return this.props.infoItem;
	}


	/**
	 * Get "Closed" value.
	 *
	 * @return {String}
	 */
	get closedValue() {
		if (!this.nmpi.ClosedTime) return "–";
		const time = Time.dmyhmFromDatetime(this.nmpi.ClosedTime);
		return `${this.nmpi.ClosedBy.Username} / ${time}`;
	}


	/**
	 * Get "Reported" value.
	 *
	 * @return {String}
	 */
	get reportedValue() {
		const time = Time.dmyhmFromDatetime(this.nmpi.CreatedTime);
		if (this.local) return this.nmpi.ReportedBy;
		else return `${this.nmpi.ReportedBy} / ${time}`;
	}


	/**
	 * Local draft.
	 *
	 * @return {Boolean}
	 */
	get local() {
		return this.nmpi.hasOwnProperty("_apid");
	}

}

export default HealthSafetyNmpisDialog;
