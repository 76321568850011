import React from "react";
import Theme from "Resources/Theme.json";
import {Chip as MuiChip} from "@material-ui/core";

/**
 * Chip component
 * 
 * @package SEC
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class Chip extends React.PureComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<MuiChip
				label={this.props.label}
				size={(this.props.size || "small")}
				style={this.styles} />
		);
	}


	/**
	 * Colour code.
	 *
	 * @return {String} Hex code
	 */
	get colour() {
		if (!this.props.background) {
			switch (this.props.colour) {
				case "blue":
					return "#2196f3";
				case "green":
					return "#4caf50";
				case "red":
					return "#f44336";
				default:
					return Theme.sec.palette.primary.main;
			}
		}
		else return this.props.background;
	}


	/**
	 * Styles.
	 *
	 * @return {Object}
	 */
	get styles() {
		return {
			background: this.colour,
			color: "white",
			fontWeight: "normal",
			...this.props.style
		};
	}

}

export default Chip;
