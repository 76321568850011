import Collection from "Domain/Collection.js";
import SpoiPurchaseInvoice from "./SpoiPurchaseInvoice.js";

/**
 * SPOI purchase invoice collection
 *
 * @package SEC
 * @subpackage Spoi
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class SpoiPurchaseInvoiceCollection extends Collection {

	/**
	 * Get the total value of all the invoices in the collection.
	 * 
	 * @return {Float}
	 */
	get total() {
		return this.items.map(pi => pi.TotalCurrent).reduce((a, b) => (a + b), 0);
	}


	/**
	 * Get the type of object referenced in the collection.
	 * 
	 * @return {Object}
	 */
	static get type() {
		return SpoiPurchaseInvoice;
	}

}

export default SpoiPurchaseInvoiceCollection;
