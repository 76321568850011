import React from "react";
import Dialog from "Components/Dialog.js";
import * as mui from "@material-ui/core";

/**
 * Dialog alert
 *
 * @package SEC
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class DialogAlert extends React.PureComponent {

	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Dialog
				nocancel={true}
				onClose={this.props.onClose}
				onOk={this.props.onClose}
				open={this.props.open}
				title={this.props.title}>
				{this.content}
			</Dialog>
		);
	}


	/**
	 * Content.
	 *
	 * @return {ReactNode}
	 */
	get content() {
		if (!this.props.childrenPure) {
			let content = this.props.content;
			content = ((content instanceof Array) ? content : [content]);
			return content.map((c, k) => <mui.DialogContentText key={k}>{c}</mui.DialogContentText>);
		}
		else return this.props.children;
	}

}

export default DialogAlert;
