import React from "react";
import Component from "App/Component.js";
import * as mui from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {SearchablePicker as Strings} from "Resources/Strings.js";

/**
 * Searchable picker
 *
 * @package SEC
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class SearchablePicker extends Component {

	/**
	 * Constructor.
	 * 
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * State
		 *
		 * @type {Object}
		 */
		this.state = {

			/**
			 * Options
			 *
			 * @type {Array}
			 */
			options: (props.initial ? [props.initial] : []),

			/**
			 * Query
			 *
			 * @type {String}
			 */
			query: "",

			/**
			 * Error
			 *
			 * @type {Boolean}
			 */
			error: false,

			/**
			 * Loading
			 *
			 * @type {Boolean}
			 */
			loading: false,

			/**
			 * Value
			 *
			 * @type {mixed}
			 */
			value: (props.initial || null)

		};

		/**
		 * Characters required for searching
		 *
		 * @type {Number}
		 */
		this.chars = 3;

		/**
		 * Timer ID for cancellation
		 *
		 * @type {Integer}
		 */
		this.timer = null;

		/**
		 * Timeout while typing
		 *
		 * @type {Number}
		 */
		this.timeout = 500;

		/**
		 * Method binds
		 */
		this.getOptionLabel = this.getOptionLabel.bind(this);
		this.getOptionSelected = this.getOptionSelected.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.renderInput = this.renderInput.bind(this);
		this.renderOption = this.renderOption.bind(this);
		this.search = this.search.bind(this);
		this.update = this.update.bind(this);

	}


	/**
	 * Component updated.
	 *
	 * We update initial options.
	 * 
	 * @param {Object} prevProps
	 * @return {void}
	 */
	componentDidUpdate(prevProps) {
		if (this.state.options === prevProps.initial) {
			if (this.props.initial !== prevProps.initial) {
				this.setState({options: [this.props.initial]});
			}
		}
	}


	/**
	 * Handle value change.
	 *
	 * @param {Event} e
	 * @param {mixed} value
	 * @return {void}
	 */
	handleChange(e, value) {
		if (this.props.onChange) this.props.onChange(value);
	}


	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Autocomplete
				autoComplete={true}
				autoHighlight={true}
				autoSelect={true}
				disabled={this.props.disabled}
				getOptionLabel={this.getOptionLabel}
				getOptionSelected={this.getOptionSelected}
				loading={this.state.loading}
				loadingText={this.strings.loadingText}
				noOptionsText={this.noOptionsText}
				onChange={this.handleChange}
				options={this.options}
				renderInput={this.renderInput}
				renderOption={this.renderOption}
				value={this.value} />
		);
	}


	/**
	 * Render input.
	 * 
	 * @param {Object} props
	 * @return {ReactNode}
	 */
	renderInput(props) {

		const label = this.strings.label;

		return (
			<mui.TextField
				{...props}
				autoFocus={this.props.autoFocus}
				fullWidth={true}
				helperText={this.strings.helperText}
				label={label}
				onChange={this.update}
				placeholder={label}
				required={this.props.required}
				value={this.state.query}
				InputLabelProps={{shrink: true}} />
		);

	}


	/**
	 * Render option.
	 *
	 * @param {mixed} option
	 * @return {ReactNode}
	 */
	renderOption(option) {
		return <mui.Typography>{option}</mui.Typography>;
	}


	/**
	 * Find a value's option in an array of given options.
	 * 
	 * @param {mixed} value
	 * @param {Array} options
	 * @return {mixed}
	 */
	findOptionByValue(value, options) {
		if (this.props.findOptionByValue) {
			return this.props.findOptionByValue(value, options);
		}
		else return options.find(v => (v === value));
	}


	/**
	 * Get option label.
	 *
	 * @param {mixed} option
	 * @return {String}
	 */
	getOptionLabel(option) {
		return option;
	}


	/**
	 * Get whether an option is selected.
	 *
	 * @param {mixed} option
	 * @param {mixed} value
	 * @return {Boolean}
	 */
	getOptionSelected(option, value) {
		return (option === value);
	}


	/**
	 * Get whether a query is valid.
	 *
	 * @param {String} query
	 * @return {Boolean}
	 */
	isValidQuery(query) {
		return (query.length >= this.minQueryLength);
	}


	/**
	 * Search.
	 *
	 * @return {void}
	 */
	search() {

		const options = [];
		this.searchPromise(this.state.query).then(opts => {
			opts.forEach(opt => {
				opt.label = this.getOptionLabel(opt);
				options.push(opt);
			});
		}).catch(e => {
			if (e?.status !== 404) {
				this.setState({error: true});
				this.snackbar(this.strings.error, "error");
			}
		}).finally(() => {
			this.setState({options, loading: false});
		});

	}


	/**
	 * Search query promise.
	 *
	 * @return {Promise} Resolve with array of options
	 */
	searchPromise() {
		return new Promise(resolve => {
			resolve([]);
		});
	}


	/**
	 * Snackbar handler.
	 *
	 * @param {String} msg
	 * @param {String} variant optional (`default`)
	 * @return {void}
	 */
	snackbar(msg, variant="default") {
		if (this.props.snackbar) this.props.snackbar(msg, variant);
	}


	/**
	 * Update query.
	 *
	 * @param {Event} e
	 * @return {void}
	 */
	update(e) {

		const query = e.target.value;
		this.setState({query, error: false});

		if (query && this.isValidQuery(query)) {
			this.setState({loading: true});
			if (this.timer) clearTimeout(this.timer);
			this.timer = setTimeout(this.search, this.timeout);
		}
		else this.setState({options: []});

	}


	/**
	 * Get minimum query length before searching occurs.
	 *
	 * @return {Boolean}
	 */
	get minQueryLength() {
		return this.chars;
	}


	/**
	 * Get "no options" string to use.
	 *
	 * @return {String}
	 */
	get noOptionsText() {
		if (!this.state.query || !this.isValidQuery(this.state.query)) {
			return this.strings.noOptionsText;
		}
		else if (this.state.error) {
			return this.strings.error;
		}
		else return this.strings.noOptionsTextSearched;
	}


	/**
	 * Get options.
	 * 
	 * @return {Array}
	 */
	get options() {
		const opts = [...this.state.options];
		if (this.value && !opts.includes(this.value)) {
			if (!this.findOptionByValue(this.value, opts)) {
				opts.push(this.value);
			}
		}
		return opts;
	}


	/**
	 * Strings.
	 *
	 * @return {Object}
	 */
	get strings() {
		return Strings;
	}


	/**
	 * Get value from props or state.
	 *
	 * @return {mixed}
	 */
	get value() {
		if (this.props.hasOwnProperty("value")) {
			return this.props.value;
		}
		else return this.state.value;
	}

}

export default SearchablePicker;
