import React from "react";
import Flex from "Components/Flex.js";
import {Typography} from "@material-ui/core";

/**
 * String component
 *
 * Accepts a string or array of strings to render into typography.
 * 
 * Please refer to the source for details of the available props.
 * 
 * @package SECGroup
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class String extends React.PureComponent {

	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Flex gap={this.gap}>
				{
					this.str.map((s, k) => (
						<Typography
							color={this.props.color}
							key={k}
							paragraph={!this.props.noParagraph}
							style={this.style}
							variant={this.variant}>
							{s}
						</Typography>
					))
				}
			</Flex>
		);
	}


	/**
	 * Get the gap between paragraphs.
	 *
	 * @return {Float} Multipler of `rem`
	 */
	get gap() {
		return ((this.variant === "caption") ? 0.5 : (this.props.gap || 0));
	}


	/**
	 * Get string(s) as an array.
	 * 
	 * @return {Array}
	 */
	get str() {
		const str = this.props.str;
		return ((str instanceof Array) ? str : [str]);
	}


	/**
	 * Get styles.
	 * 
	 * @return {Object}
	 */
	get style() {
		return {
			fontWeight: (this.props.bold ? "bold" : this.props.fontWeight),
			...this.props.style
		};
	}


	/**
	 * Get the variant name for the Material typography element.
	 * 
	 * @return {String|null}
	 */
	get variant() {
		return (this.props.variant || "body1");
	}

}

export default String;
