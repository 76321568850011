import {api, uris} from "api.js";
import Message from "Messages/Message.js";
import Conversation from "Messages/MessageConversation.js";
import ConversationCollect from "Messages/MessageConversationCollection.js";

/**
 * Message service
 *
 * @package SEC
 * @subpackage Services
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class MessageService {

	/**
	 * Send a message to a named recipient.
	 *
	 * The sender will always be the active user.
	 *
	 * @param {String} msg
	 * @param {String} recipient Username
	 * @return {Promise}
	 */
	static send(msg, recipient) {
		return api.call({
			method: "POST",
			data: {msg, recipient},
			url: uris.messages.base
		});
	}


	/**
	 * Delete a conversation with a named contact.
	 *
	 * @param {String} contact
	 * @return {Promise}
	 */
	static deleteConversation(contact) {
		return api.call({method: "DELETE", url: `${uris.messages.conversations}/${contact}`});
	}


	/**
	 * Get conversation messages with a contact.
	 *
	 * Returns an array of `Message` instances.
	 *
	 * @param {String} contact Username
	 * @param {Integer} Limit Count of messages to get
	 * @param {Integer} Offset Page to get messages from
	 * @return {Promise}
	 */
	static getConversation(contact, Limit, Offset=0) {
		const url = `${uris.messages.conversations}/${contact}`;
		return api.call({params: {Limit, Offset}, url}).then(({data}) => {
			return data.map(msg => {
				return new Message(
					msg.Message,
					msg.Sender,
					msg.Recipient,
					msg.SentTime,
					((msg.ReadTime !== null)),
					msg.Id
				);
			});
		});
	}


	/**
	 * Get count of unread messages a conversation between the 
	 * active user and a named contact.
	 *
	 * @param {String} contact
	 * @return {Promise} Resolves with integer count
	 */
	static getConversationUnreadCount(contact) {
		const uri = `${uris.messages.conversations}/${contact}/unread`;
		return api.call(uri).then(({data}) => parseInt(data));
	}


	/**
	 * Get conversations for the current user.
	 *
	 * Resolves the returned promise with a `MessageConversationCollection`.
	 * 
	 * @return {Promise}
	 */
	static getConversations() {
		return api.call(uris.messages.conversations).then(({data}) => {
			return new ConversationCollect(data.map(convo => {
				return new Conversation(
					convo.Contact,
					convo.Message,
					convo.MessageTimestamp,
					convo.MessageSentByContact,
					convo.Read
				);
			}));
		});
	}


	/**
	 * Get whether an array of message IDs have been read.
	 * 
	 * The response will be an array of the IDs which have been read.
	 * 
	 * @param {Array} ids IDs
	 * @return {Promise}
	 */
	static getRead(ids) {
		return api.call({params: {ids}, url: uris.messages.read}).then(({data}) => data);
	}


	/**
	 * Get unread messages for the current user.
	 *
	 * @param {Integer} sentmin optional Minimum sent time
	 * @return {Promise}
	 */
	static getUnread(sentmin=null) {
		const params = {};
		if (sentmin) params.SentMin = sentmin;
		return api.call({params, url: uris.messages.base}).then(({data}) => data);
	}


	/**
	 * Mark a conversation with a contact as read.
	 *
	 * @param {String} contact Contact username
	 * @return {Promise}
	 */
	static markConversationRead(contact) {
		return api.call({method: "POST", url: `${uris.messages.conversations}/${contact}/read`});
	}


	/**
	 * Mark message IDs as read.
	 * 
	 * @param {Array} ids
	 * @return {Promise}
	 */
	static markReadIds(ids) {
		return api.call({
			data: {id: ids.join(",")},
			method: "POST",
			url: uris.messages.read
		});
	}

}

export default MessageService;
