import React from "react";
import TableCell from "Components/TableCell.js";
import TableData from "Components/TableData.js";
import Currency from "Helpers/Currency.js";
import bp from "Helpers/Bp.js";
import rem from "Helpers/Rem.js";
import Details from "./SpoiPoItemsTableDetails.js";
import Total from "../Atoms/SpoiTotalTypography.js";
import * as mui from "@material-ui/core";

/**
 * SPOI PO line items table
 *
 * This supports viewing SPOI PO line items.
 *
 * Please refer to the source for details of all the available props.
 * 
 * @package SEC
 * @subpackage Spoi
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class SpoiPoItemsTable extends React.PureComponent {

	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<TableData
				fields={this.fields}
				info={false}
				values={this.props.lis.get()}>

				{this.renderTotals()}
			</TableData>
		);
	}


	/**
	 * Render the totals.
	 *
	 * @return {ReactNode}
	 */
	renderTotals() {
		return (
			<mui.TableRow>
				<TableCell colSpan={(this.fieldsNow.length - 1)} />
				<TableCell>
					<Total total={this.props.lis.total} />
				</TableCell>
			</mui.TableRow>
		);
	}


	/**
	 * Field definitions.
	 *
	 * @return {Array}
	 */
	get fields() {
		return [
			{
				label: "Qty",
				mobile: true,
				renderer: li => li.Quantity,
				style: {width: rem(4)}
			},
			{
				label: "Description",
				renderer: li => li.Description,
				rendererMobile: li => <Details li={li} />
			},
			{
				label: "Unit Cost",
				mobile: false,
				renderer: li => Currency.format(li.price),
				style: {minWidth: rem(8)}
			},
			{
				label: "Total Cost",
				renderer: li => Currency.format(li.total),
				style: {minWidth: rem(7)}
			}
		];
	}


	/**
	 * Get the active field definitions.
	 *
	 * This excludes inactive fields due to the viewport size.
	 *
	 * @return {Array}
	 */
	get fieldsNow() {
		let fields = this.fields;
		if (this.mobile) fields = fields.filter(f => (f.mobile !== false));
		return fields;
	}


	/**
	 * Get whether we've a mobile viewport.
	 * 
	 * @return {Boolean}
	 */
	get mobile() {
		return !bp(this.props.width);
	}

}

export default mui.withWidth()(SpoiPoItemsTable);
