import React from "react";
import LabelCentred from "Components/LabelCentred.js";
import UserAvatar from "Components/UserAvatar.js";
import {ArrowBack} from "@material-ui/icons";
import {Box, Divider, IconButton, withWidth} from "@material-ui/core";

/**
 * Message thread header
 *
 * @package SEC
 * @subpackage Messages
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class MessageThreadHeader extends React.PureComponent {

	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Box>
				{((this.props.width === "xs") ? this.renderBack() : null)}

				<UserAvatar
					nativeTooltip={true}
					user={{Username: this.contact}} />

				<Box my={1}><LabelCentred>{this.contact}</LabelCentred></Box>

				<Divider />
			</Box>
		);
	}


	/**
	 * Render back button.
	 *
	 * @return {ReactNode}
	 */
	renderBack() {
		return (
			<IconButton
				color="primary"
				onClick={this.props.onBack}
				style={{position: "absolute"}}>
				<ArrowBack />
			</IconButton>
		);
	}


	/**
	 * Get contact username.
	 *
	 * @return {String}
	 */
	get contact() {
		return this.props.convo.Contact;
	}

}

export default withWidth()(MessageThreadHeader);
