import React from "react";
import Component from "App/Component.js";
import Select from "Components/Select.js";
import withSnackbar from "Hoc/withSnackbar.js";
import LoadableComponent from "./LoadableComponent.js";
import {MenuItem} from "@material-ui/core";

/**
 * Loadable select component
 *
 * You must pass an `optionsSource` prop which must return a Promise 
 * resolving with an array of objects with `children` and `value` 
 * properties; the value will be the `value` for that option within 
 * the `select`, while `children` will be the content of the option.
 *
 * Hidden options can be specified in a `hiddenOptions` array - this 
 * should be an array of options to hide, given as `value` property 
 * values from the set of option objects from the options Promise.
 * 
 * State is controlled by `disabled`, `error` and `loading` props.
 * 
 * Please refer to the source for details of all the available props.
 *
 * @package SEC
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class LoadableSelect extends Component {

	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * State
		 *
		 * @type {Object}
		 */
		this.state = {

			/**
			 * Options
			 *
			 * An array of objects with `children`/`value` properties
			 * 
			 * @type {Array}
			 */
			options: [],

			/**
			 * Error state
			 *
			 * @type {Boolean}
			 */
			error: false,

			/**
			 * Loading state
			 *
			 * @type {Boolean}
			 */
			loading: true

		};

		/**
		 * Update bind
		 */
		this.update = this.update.bind(this);

	}


	/**
	 * Component mounted.
	 *
	 * @return {void}
	 */
	componentDidMount() {
		this.update();
	}


	/**
	 * Update the options.
	 *
	 * @return {void}
	 */
	update() {

		this.setState({loading: true, error: false});

		this.props.optionsSource().then(options => {
			this.setState({options});
		}).catch(() => {
			this.setState({error: true});
			if (this.props.errorSnack) {
				this.props.snackbar(this.props.errorSnack, "error");
			}
		}).finally(() => {
			this.setState({loading: false});
		});

	}


	/**
	 * Render the component.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<LoadableComponent
				error={this.state.error}
				loading={this.state.loading}
				onWantsRefresh={this.update}>
				{this.renderSelect()}
			</LoadableComponent>
		);
	}


	/**
	 * Render the actual select component.
	 * 
	 * @return {ReactNode}
	 */
	renderSelect() {
		return (
			<Select
				any={this.props.any}
				anyLabel={this.props.anyLabel}
				disabled={this.props.disabled}
				label={this.props.label}
				onChange={this.props.onChange}
				value={this.props.value}>
				{
					this.options.map((o, k) => (
						<MenuItem
							disabled={this.optionDisabled(o.value)}
							key={k}
							value={o.value}>
							{o.children}
						</MenuItem>
					))
				}
			</Select>
		);
	}


	/**
	 * Get whether an option should be disabled.
	 *
	 * We forward the invocation up to the `getOptionDisabled` prop.
	 *
	 * @param {mixed} option o The `value` of an option
	 * @return {Boolean}
	 */
	optionDisabled(o) {
		if (this.props.getOptionDisabled) {
			return this.props.getOptionDisabled(o);
		}
		else return false;
	}


	/**
	 * Get options to render.
	 *
	 * @return {Array}
	 */
	get options() {
		return this.state.options.filter(o => {
			if (this.props.hiddenOptions) {
				return !this.props.hiddenOptions.includes(o.value);
			}
			else return true;
		});
	}

}

export default withSnackbar(LoadableSelect);
