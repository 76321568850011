import {useCallback, useMemo, useState} from "react";

export default (initialValue=false) => {

	const [value, setValue] = useState(initialValue);

	const setTrue = useCallback(() => setValue(true), []);
	const setFalse = useCallback(() => setValue(false), []);
	const toggle = useCallback(() => setValue(!value), [value]);

	return useMemo(
		() => ({value, setTrue, setFalse, setValue, toggle}),
		[value, setTrue, setFalse, setValue, toggle]
	);

};
