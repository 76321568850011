import React from "react";
import Checkbox from "Components/Checkbox.js";
import dDark from "Dispatchers/Dark.js";
import {connect} from "react-redux";

export default connect(({dark}) => ({dark}))(({dark}) => (
	<Checkbox
		checked={dark}
		label="Dark mode"
		onChange={() => dDark(!dark)} />
));
