import rem from "Helpers/Rem.js";
import {memo} from "react";

export default memo(props => {

	const styles = {
		display: "flex",
		flexDirection: (!props.columnar ? "column" : "row"),
		gap: rem(props.gap),
		width: (props.width || (props.fullWidth ? "100%" : undefined))
	};

	return (
		<div
			className={props.className}
			style={styles}>
			{props.children}
		</div>
	);

});
