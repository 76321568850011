import {memo} from "react";
import scss from "./Grid.module.scss";

export default memo(props => {

	return (
		<div className={scss.grid}>
			{props.children}
		</div>
	);

});
