import React from "react";
import Loader from "./Loader.js";
import {Link, Typography} from "@material-ui/core";

/**
 * Loadable component
 * 
 * Rendering occurs as follows:
 *  - 1. `loading` prop is truthy, then render a `Loader`
 *  - 2. `error` prop is truthy, then render an error message
 *  - 3. Render the component's children.
 * 
 * When an error is rendered, a "retry" link is displayed.
 * 
 * Clicking the error retry link invokes the `onWantsRefresh` prop.
 * 
 * @package SEC
 * @subpackage Spoi
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class LoadableComponent extends React.PureComponent {

	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		if (this.props.loading) {
			return <Loader size={(this.props.loaderSize || 20)} style={{margin: 0, ...this.props.loaderStyles}} />;
		}
		else if (this.props.error) {
			return <Typography>Error. {this.renderLink()}</Typography>;
		}
		else return this.props.children;
	}


	/**
	 * Render retry link.
	 *
	 * @return {ReactNode}
	 */
	renderLink() {
		return <Link onClick={this.props.onWantsRefresh}>Retry</Link>;
	}

}

export default LoadableComponent;
