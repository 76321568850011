import React from "react";
import mobile from "Helpers/Mobile.js";
import rem from "Helpers/Rem.js";
import SpoiStatusChip from "../Atoms/SpoiStatusChip.js";
import {Box, Typography, withWidth} from "@material-ui/core";

/**
 * SPOI domain caption
 *
 * A component to show a SPOI resource's identity and status.
 * 
 * Please refer to the source for details of all the available props.
 * 
 * @package SEC
 * @subpackage Spoi
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class SpoiDomainCaption extends React.PureComponent {

	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Box style={this.styles}>
				<Box>
					<SpoiStatusChip size="small" status={this.props.status} />
				</Box>
				<Box>
					<Typography style={{fontWeight: "inherit"}}>
						{this.props.reference}
					</Typography>
				</Box>
			</Box>
		);
	}


	/**
	 * Get whether we're using a columnar layout.
	 * 
	 * @return {Boolean}
	 */
	get columnar() {
		return (!this.mobile || (this.props.af === "column"));
	}


	/**
	 * Get whether we've a mobile viewport.
	 *
	 * @return {Boolean}
	 */
	get mobile() {
		return mobile(this.props.width);
	}


	/**
	 * Styles.
	 *
	 * @return {Object}
	 */
	get styles() {
		return {
			alignItems: "center",
			display: "grid",
			gridAutoFlow: (this.props.af || (this.mobile ? "row" : "column")),
			gridColumnGap: rem(this.stylesColumnGap),
			gridRowGap: rem(0.75),
			gridTemplateColumns: this.stylesTemplateColumns
		};
	}


	/**
	 * Styles (column gap multiplier).
	 * 
	 * @return {Float}
	 */
	get stylesColumnGap() {
		return ((this.columnar && this.mobile) ? 0.5 : 1);
	}


	/**
	 * Styles (template columns definition).
	 * 
	 * @return {String|null}
	 */
	get stylesTemplateColumns() {
		if (this.columnar && this.mobile) return "min-content auto";
		else return (this.mobile ? null : "max-content auto");
	}

}

export default withWidth()(SpoiDomainCaption);
