import React from "react";
import {Autocomplete} from "@material-ui/lab";
import {TextField} from "@material-ui/core";

/**
 * Picker component base
 * 
 * @package SEC
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class Picker extends React.PureComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Autocomplete
				autoComplete={true}
				disabled={(this.props.disabled || this.props.loading)}
				getOptionLabel={this.props.getOptionLabel}
				loading={this.props.loading}
				noOptionsText={this.noOptionsText}
				onChange={this.handleChange}
				openOnFocus={true}
				options={(this.props.options || [])}
				renderInput={this.renderInput}
				value={this.props.value} />
		);
	}


	/**
	 * Render the input.
	 *
	 * @param {Object} props
	 * @return {ReactNode}
	 */
	renderInput = props => {
		return (
			<TextField
				{...props}
				label={this.props.label}
				required={this.props.required} />
		);
	};


	/**
	 * Value changed.
	 *
	 * @param {Event} e
	 * @param {mixed} value
	 * @return {void}
	 */
	handleChange = (e, value) => this.props.onChange(value);


	/**
	 * `noOptionsText`
	 * 
	 * @return {String}
	 */
	get noOptionsText() {
		if (this.props.error) return "Error.";
		else return "Nothing to show.";
	}

}

export default Picker;
