import React from "react";
import LoadableSelect from "Components/LoadableSelect.js";
import SpoiService from "../SpoiService.js";

/**
 * Tax code picker
 *
 * Accepts `value` prop as either a tax code object with the same 
 * referential identity as one of the tax code objects held internally 
 * as possible values, or as a string ID matching the `Id` property 
 * of one of the tax code objects held internally.
 *
 * Upon mount/tax code options being obtained, invokes the `onChange()` 
 * prop with the currently selected option, which will be the selected 
 * option given by `value` if any, or the first available option if not.
 *
 * @package SEC
 * @subpackage Spoi
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class SpoiTaxCodePicker extends React.PureComponent {

	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * State
		 * 
		 * @type {Object}
		 */
		this.state = {

			/**
			 * Options
			 * 
			 * @type {Array}
			 */
			options: []

		};

	}


	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<LoadableSelect
				disabled={this.props.disabled}
				errorSnack="Error loading tax codes."
				getOptionDisabled={this.getOptionDisabled.bind(this)}
				label="Tax Code"
				onChange={this.props.onChange}
				optionsSource={this.getOptions.bind(this)}
				value={this.value} />
		);
	}


	/**
	 * Get whether an option should disabled.
	 * 
	 * @param {Object} option `value`
	 * @return {Boolean} [description]
	 */
	getOptionDisabled(option) {
		const disabled = this.props.disabledItems.includes(option.Id);
		const value = (this.value !== option.Id);
		const valueId = (this.value?.Id !== option.Id);
		return (disabled && value && valueId);
	}


	/**
	 * Get the options.
	 *
	 * This will automatically change the selected option to 
	 * the first available option if the `value` prop does not 
	 * reference any available option.
	 *
	 * We match available options based on exact object match or 
	 * on the `value` prop being a string value matching the `Id` 
	 * of one of our newly acquired tax code objects.
	 *
	 * @return {Promise} Resolves with options for `LoadableSelect`
	 */
	getOptions() {
		return SpoiService.getTaxCodes().then(tcs => {
			const opts = tcs.map(tc => {
				return {
					value: tc,
					children: tc.Name
				};
			});

			if (!opts.includes(this.props.value)) {
				const value = (this.props.value?.Id || this.props.value);
				const opt = opts.filter(o => (o.value.Id === value))[0];
				if (opt) this.props.onChange(opt.value);
				else this.props.onChange(opts[0].value);
			}

			this.setState({options: opts});
			return opts;
		});
	}


	/**
	 * Get the value to use.
	 *
	 * You may use either a tax code ID or object in `options` as `value`.
	 * 
	 * @return {mixed}
	 */
	get value() {
		const value = this.props.value;
		if (typeof value === "string") {
			const o = this.state.options.find(o => (o.value.Id === value));
			if (o) return o.value;
		}
		else if (value.Id) {
			const o = this.state.options.find(o => (o.value.Id === value.Id));
			if (o) return o.value;
		}
		return value;
	}

}

export default SpoiTaxCodePicker;
