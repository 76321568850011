import React from "react";
import String from "Components/String.js";

/**
 * `String` with more sensible defaults
 * 
 * This will replace `String` eventually.
 * 
 * @package SEC
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class Stringx extends React.PureComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return <String noParagraph={true} {...this.props} />;
	}

}

export default Stringx;
