import Push from "App/Push.js";
import Post from "App/Post.js";
import store from "App/Store.js";

export default async () => {

	try {
		await Post.clear();
	}
	catch (e) {
		// ...
	}

	try {
		await Push.unsubscribe();
	}
	catch (e) {
		// ...
	}

	store.dispatch({type: "reset"});

};
