import React from "react";
import Container from "Components/Container.js";
import mobile from "Helpers/Mobile.js";
import {Button, withWidth} from "@material-ui/core";

/**
 * SPOI PI items table controls
 *
 * @package SEC
 * @subpackage Spoi
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class SpoiPiItemsTableControls extends React.PureComponent {

	/**
	 * Render the controls.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Container
				fullWidth={this.mobile}
				gap={(this.mobile ? 0.5 : 1)}>
				<Button
					color="primary"
					disabled={this.props.addDisabled}
					onClick={this.props.onAddItem}
					variant="outlined">
					+ Add New Tax Code
				</Button>
			</Container>
		);
	}


	/**
	 * Get whether we've a mobile viewport.
	 */
	get mobile() {
		return mobile(this.props.width);
	}

}

export default withWidth()(SpoiPiItemsTableControls);
