import MessageConversation from "./MessageConversation.js";
import MessageHelper from "./MessageHelper.js";

/**
 * Message conversation collection object
 *
 * @package SEC
 * @subpackage Messages
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class MessageConversationCollection {

	/**
	 * Constructor.
	 *
	 * @param {Array} convos optional Conversations (`MessageConversation`)
	 * @return {self}
	 */
	constructor(convos=[]) {

		/**
		 * Conversations
		 *
		 * @type {Array} `MessageConversation`
		 */
		this.convos = convos;

	}


	/**
	 * Add a new conversation to the collection.
	 *
	 * Returns a new collection with the conversation included.
	 *
	 * @param {MessageConversation} convo
	 * @return {MessageConversationCollection}
	 */
	add(convo) {
		return new MessageConversationCollection([convo, ...this.convos]);
	}


	/**
	 * Get count of conversations in the collection.
	 *
	 * @return {Integer}
	 */
	count() {
		return this.convos.length;
	}


	/**
	 * Delete a conversation with a contact.
	 *
	 * Returns a new collection with new conversation instances.
	 *
	 * @return {MessageConversationCollection}
	 */
	delete(contact) {
		return new MessageConversationCollection(
			this.convos.filter(convo => (convo.Contact !== contact))
		);
	}


	/**
	 * Get whether the collection is empty.
	 *
	 * @return {Boolean}
	 */
	empty() {
		return (this.convos.length === 0);
	}


	/**
	 * Get all conversations ordered by most recent timestamp first.
	 *
	 * @return {Array} `MessageConversation`
	 */
	get() {
		return this.convos.sort((a, b) => {
			if (a.Timestamp > b.Timestamp) return -1;
			else if (a.Timestamp < b.Timestamp) return 1;
			else return 0;
		});
	}


	/**
	 * Get conversation with a given contact.
	 *
	 * The first matching conversation is returned as 
	 * multiple conversations with the same contact are unsupported.
	 *
	 * @param {String} contact
	 * @return {MessageConversation|null}
	 */
	getContactConversation(contact) {
		return this.convos.filter(c => (c.Contact === contact))[0];
	}


	/**
	 * Get whether there is a conversation with a given contact.
	 *
	 * @param {String} contact
	 * @return {Boolean}
	 */
	hasContactConversation(contact) {
		return (this.getContactConversation(contact) !== undefined);
	}


	/**
	 * Mark all conversations in the collection as read.
	 *
	 * Returns a new collection of new conversation instances.
	 *
	 * @return {MessageConversationCollection}
	 */
	markRead() {
		return new MessageConversationCollection(
			this.convos.map(convo => {
				return MessageConversation.newFromExisting({
					...convo,
					Read: true
				});
			})
		);
	}


	/**
	 * Mark a conversation with a named contact as read.
	 *
	 * Returns a new collection of new conversation instances.
	 *
	 * @return {MessageConversationCollection}
	 */
	markConversationRead(contact) {
		return new MessageConversationCollection(
			this.convos.map(convo => {
				return MessageConversation.newFromExisting({
					...convo,
					Read: ((convo.Contact === contact) ? true : convo.Read)
				});
			})
		);
	}


	/**
	 * Record that a message was sent.
	 *
	 * We always use `Recipient` on the `Message` instance as the 
	 * contact, as we are sending from the perspective of the user.
	 *
	 * We return a new collection with the updated message instance 
	 * within it, including the up-to-date properties from the message.
	 *
	 * @param {Message} msg
	 * @return {MessageConversationCollection}
	 */
	recordMessageSent(msg) {
		const convos = this.delete(msg.Recipient);
		return convos.add(MessageConversation.newFromMessageObject(msg));
	}


	/**
	 * Update the conversations in the collection from network messages.
	 *
	 * Returns a new collection of new conversation instances.
	 *
	 * @return {MessageConversationCollection}
	 */
	updateFromMessages(msgs) {
		let convos = new MessageConversationCollection([...this.convos]);
		const threads = MessageHelper.getUniqueThreadsBySender(msgs);

		/**
		 * Iterate our threads
		 */
		Object.keys(threads).forEach(contact => {
			const msg = threads[contact];
			const convo = convos.getContactConversation(contact);

			/**
			 * Are we creating new?
			 */
			if (convo) {
				if (msg.SentTime > convo.Timestamp) {
					const cnv = MessageConversation.newFromExisting({
						...convo,
						Message: msg.Message,
						Timestamp: msg.SentTime,
						Received: true,
						Read: false
					});
					convos = convos.delete(contact).add(cnv);
				}
			}
			else convos = convos.add(MessageConversation.newFromMessage(msg));
		});

		return convos;
	}

}

export default MessageConversationCollection;
