import React from "react";
import CaptionedDetails from "Components/CaptionedDetails.js";
import Time from "Helpers/Time.js";

/**
 * Purchase order view detail
 *
 * @package SEC
 * @subpackage Spoi
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class SpoiPurchaseOrderDetail extends React.PureComponent {

	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return <CaptionedDetails details={this.details} />;
	}


	/**
	 * Get details for rendering.
	 * 
	 * @return {Array}
	 */
	get details() {
		return [
			{
				label: "Order Date",
				tooltip: "The date we raised this order",
				value: Time.dmyFromYmd(this.props.po.RaisedDate, "/")
			},
			{
				label: "Project ID",
				tooltip: "Our project reference",
				value: this.props.po.Project
			},
			{
				label: "Customer Name",
				tooltip: "Our customer's name who's ordering this project",
				value: (this.props.po.Customer?.Name || "-")
			}
		];
	}

}

export default SpoiPurchaseOrderDetail;
