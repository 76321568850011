import React from "react";
import {Typography} from "@material-ui/core";

/**
 * Label for empty state
 *
 * @package SEC
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class LabelEmpty extends React.PureComponent {

	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Typography style={this.styles}>
				{this.props.children}
			</Typography>
		);
	}


	/**
	 * Styles.
	 *
	 * @return {Object}
	 */
	get styles() {
		return {
			margin: "auto",
			opacity: 0.6,
			textAlign: "center",
			...this.props.style
		};
	}

}

export default LabelEmpty;
