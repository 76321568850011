import React from "react";
import AccessDeniedIcon from "@material-ui/icons/NotInterested";
import Checkbox from "Components/Checkbox.js";
import Component from "App/Component.js";
import Flex from "Components/Flex.js";
import Loader from "Components/Loader.js";
import Push from "App/Push.js";
import String from "Components/Stringx.js";
import dPushBanner from "Dispatchers/PushBanner.js";
import withSnackbar from "Hoc/withSnackbar.js";
import {Card, CardContent} from "@material-ui/core";

/**
 * App settings on the profile screen
 *
 * @package SEC
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class ProfileSettingsApp extends Component {

	/**
	 * State
	 * 
	 * @type {Object}
	 */
	state = {

		/**
		 * Push subscribed?
		 * 
		 * @type {Boolean|null}
		 */
		push: null,

		/**
		 * Push available?
		 * 
		 * @type {Boolean|null}
		 */
		pushAvailable: null,

		/**
		 * Push toggling?
		 *
		 * @type {Boolean|null}
		 */
		pushToggling: false

	};


	/**
	 * Component mounted.
	 * 
	 * @return {void}
	 */
	componentDidMount() {
		Push.subscribed.then(subscribed => {
			this.setState({push: subscribed, pushAvailable: true});
		}).catch(() => {
			this.setState({pushAvailable: false});
		});
	}


	/**
	 * Subscribe push notifications.
	 *
	 * @return {void}
	 */
	handlePushSubscribe = () => {

		this.setState({pushToggling: true});

		Push.subscribe().then(() => {
			this.setState({push: true});
			this.props.snackbar("Push notifications enabled.", "success");
		}).catch(e => {
			this.props.snackbar(e);
		}).finally(() => {
			this.setState({pushToggling: false});
		});

	};


	/**
	 * Unsubscribe push notifications.
	 * 
	 * @return {void}
	 */
	handlePushUnsubscribe = () => {

		this.setState({pushToggling: true});

		Push.unsubscribe().then(() => {
			dPushBanner(true);
			this.setState({push: false});
			this.props.snackbar("Push notifications disabled.", "success");
		}).catch(e => {
			this.props.snackbar(e);
		}).finally(() => {
			this.setState({pushToggling: false});
		});

	};


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Card>
				<CardContent>
					<Flex>
						<String
							str="App settings"
							variant="h6" />
						<Flex gap={0} mb={0.5}>
							<Checkbox
								checked={(this.state.push || false)}
								disabled={(!this.state.pushAvailable || this.state.pushToggling)}
								label="Receive push notifications"
								indeterminate={(this.state.push === null)}
								indeterminateIcon={((this.state.pushAvailable === null) ? <Loader size={15} /> : <AccessDeniedIcon />)}
								onChange={(!this.state.push ? this.handlePushSubscribe : this.handlePushUnsubscribe)}
								size="small" />
							<Flex gap={0.25} ml={2.5} mt={-0.5} pr={3}>
								{((this.state.pushAvailable !== false) && <String color="textSecondary" str="When turned off, you will not receive any push notifications on this device, irrespective of your Profile settings below." variant="caption" />)}
								{((this.state.pushAvailable !== false) && <String color="textSecondary" str="Toggling this setting may help if you're not seeing notifications you expect to receive." variant="caption" />)}
								{((this.state.pushAvailable === false) && <String color="error" str="Push notifications aren't available on your device. Check your browser permissions if you think this isn't right." variant="caption" />)}
							</Flex>
						</Flex>
						<String
							color="textSecondary"
							str="These settings are saved on this device only." />
					</Flex>
				</CardContent>
			</Card>
		);
	}

}

export default withSnackbar(ProfileSettingsApp);
