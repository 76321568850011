import React from "react";
import Theme from "Resources/Theme.json";
import {createMuiTheme, ThemeProvider as MuiThemeProvider} from "@material-ui/core";
import {connect} from "react-redux";

/**
 * Theme provider
 *
 * @package SEC
 * @subpackage Providers
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class ThemeProvider extends React.PureComponent {

	/**
	 * State
	 *
	 * @type {Object}
	 */
	state = {

		/**
		 * Theme object
		 *
		 * @type {Object} Material UI theme
		 */
		theme: this.theme

	};


	/**
	 * Component mounted.
	 * 
	 * @return {void}
	 */
	componentDidMount() {
		this.updateTheme();
	}


	/**
	 * Component updated.
	 * 
	 * @param {Object} prevProps
	 * @return {void}
	 */
	componentDidUpdate(prevProps) {
		if (prevProps.dark !== this.props.dark) {
			this.updateTheme();
		}
	}


	/**
	 * Update the active theme.
	 *
	 * @return {void}
	 */
	updateTheme() {

		const theme = this.theme;
		this.setState({theme});

		document.documentElement.style.setProperty(
			"--base-colour",
			theme.palette.background.default
		);

	}


	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<MuiThemeProvider theme={this.state.theme}>
				{this.props.children}
			</MuiThemeProvider>
		);
	}


	/**
	 * Get the current theme object.
	 *
	 * @return {Object} Material UI theme object
	 */
	get theme() {
		const theme = {...Theme.sec};
		if (this.props.dark) {
			theme.palette = {...theme.palette, ...Theme.sec_dark.palette};
		}
		return createMuiTheme(theme);
	}

}

export default connect(({dark}) => ({dark}))(ThemeProvider);
