import React from "react";
import AddIcon from "@material-ui/icons/Add";
import Fab from "Components/Fab.js";
import HealthSafetyNmpisDrafts from "./HealthSafetyNmpisDrafts.js";
import HealthSafetyNmpisSearch from "./HealthSafetyNmpisSearch.js";
import Navigator from "App/Navigator.js";
import {Box} from "@material-ui/core";
import {connect} from "react-redux";

/**
 * Health and safety NMPI dashboard
 *
 * @package SEC
 * @subpackage Views
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class HealthSafetyNmpisDashboard extends React.PureComponent {

	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<React.Fragment>
				<Box mb={4}><HealthSafetyNmpisSearch /></Box>
				<Box><HealthSafetyNmpisDrafts /></Box>
				{(this.canCreate ? this.renderFab() : null)}
			</React.Fragment>
		);
	}


	/**
	 * Render fab.
	 *
	 * @return {ReactNode}
	 */
	renderFab() {
		return (
			<Fab onClick={this.handleCreate}>
				<AddIcon />
			</Fab>
		);
	}


	/**
	 * Handle creation.
	 *
	 * @return {void}
	 */
	handleCreate = () => Navigator.navigate("/health-safety/nmpis/post");


	/**
	 * Get whether can create.
	 *
	 * @return {Boolean}
	 */
	get canCreate() {
		return this.props.permissions.includes("sec.nmpi.create");
	}


	/**
	 * Map state to props.
	 *
	 * @param {Object} state
	 * @return {Object}
	 */
	static mapStateToProps(state) {
		return {permissions: state.permissions};
	}

}

export default connect(
	HealthSafetyNmpisDashboard.mapStateToProps
)(HealthSafetyNmpisDashboard);
