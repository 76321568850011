import StatePersistent from "./StatePersistent.js";

/**
 * Storage class for state persistence
 *
 * @package SEC
 * @subpackage App
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class Storage {

	/**
	 * App local storage key
	 * 
	 * @type {String}
	 */
	static APP_LS_KEY = "app";


	/**
	 * Get the persisted state.
	 *
	 * @return {Object} Returns the initial state when not persisted
	 */
	static get() {
		const state = JSON.parse(window.localStorage.getItem(this.APP_LS_KEY));
		return ((state !== null) ? state : StatePersistent);
	}


	/**
	 * Save a state object to storage, replacing existing state.
	 * 
	 * @param {Object} state
	 * @return {void}
	 */
	static save(state) {

		const persist = {};

		Object.keys(StatePersistent).forEach(k => {
			persist[k] = state[k];
		});

		window.localStorage.setItem(this.APP_LS_KEY, JSON.stringify(persist));

	}

}

export default Storage;
