import React from "react";
import LabelEmpty from "Components/LabelEmpty.js";
import Loader from "Components/Loader.js";
import rem from "Helpers/Rem.js";
import {connect} from "react-redux";
import {Box, Divider, withWidth} from "@material-ui/core";
import {MessageDrawer as Strings} from "Resources/Strings.js";
import MessageConversationCard from "./MessageConversationCard.js";
import MessageConversationComposer from "./MessageConversationComposer.js";

/**
 * Message drawer
 * 
 * @package SEC
 * @subpackage Messages
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class MessageDrawer extends React.PureComponent {

	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * Ref
		 *
		 * @type {ReactRef}
		 */
		this.ref = React.createRef();

		/**
		 * Method binds
		 */
		this.handleNew = this.handleNew.bind(this);
		this.handleNewExpanded = this.handleNewExpanded.bind(this);

	}


	/**
	 * Create a new conversation to a user.
	 * 
	 * @param {Object} user
	 * @return {void}
	 */
	handleNew(user) {
		this.props.onNew(user);
	}


	/**
	 * Conversation composer expanded.
	 *
	 * We want to scroll to the bottom to ensure the composer is visible.
	 * 
	 * @return {void}
	 */
	handleNewExpanded() {
		setTimeout(() => {
			if (this.ref) {
				if (this.ref.current) {
					const scroll = this.ref.current.scrollHeight;
					this.ref.current.scrollTop = scroll;
				}
			}
		}, 100);
	}


	/**
	 * Handle conversation deletion.
	 *
	 * @param {MessageConversation} convo
	 * @return {void}
	 */
	handleDelete(convo) {
		this.props.onDelete(convo);
	}


	/**
	 * Handle conversation selection.
	 *
	 * @param {MessageConversation} convo
	 * @return {void}
	 */
	handleSelect(convo) {
		this.props.onSelect(convo);
	}


	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Box ref={this.ref} style={this.styles}>
				{this.renderMain()}
				{this.shouldRenderNew() ? this.renderNew() : null}
			</Box>
		);
	}


	/**
	 * Render main.
	 *
	 * @return {ReactNode}
	 */
	renderMain() {
		if (this.props.loading) return this.renderLoader();
		else if (this.props.error) return this.renderError();
		else if (this.props.convos.count() === 0) return this.renderEmpty();
		else return <Box style={this.constructor.stylesBox}>{this.renderConversations()}</Box>;
	}


	/**
	 * Render the new conversation picker.
	 *
	 * @return {ReactNode}
	 */
	renderNew() {
		return (
			<Box style={{marginTop: "auto"}}>
				<Box mr={(this.mobile ? 0 : 1)} my={1}>
					<Divider />
				</Box>
				<MessageConversationComposer
					onExpanded={this.handleNewExpanded}
					onNew={this.handleNew} />
			</Box>
		);
	}


	/**
	 * Render conversations.
	 *
	 * @return {ReactNode}
	 */
	renderConversations() {
		return this.props.convos.get().map((convo, k) => {
			return (
				<MessageConversationCard
					active={(convo.Contact === this.props.convo)}
					convo={convo}
					divider={(k !== (this.props.convos.count() - 1))}
					key={k}
					onClick={convo => this.handleSelect(convo)}
					onDelete={convo => this.handleDelete(convo)}
					style={{marginTop: ((k === 0) ? 0 : rem(1))}} />
			);
		});
	}


	/**
	 * Render empty state.
	 *
	 * @return {ReactNode}
	 */
	renderEmpty() {
		return (
			<LabelEmpty style={this.constructor.stylesEmpty}>
				{Strings.empty}
			</LabelEmpty>
		);
	}


	/**
	 * Render error.
	 *
	 * @return {ReactNode}
	 */
	renderError() {
		return (
			<LabelEmpty style={this.constructor.stylesEmpty}>
				{Strings.loadError}
			</LabelEmpty>
		);
	}


	/**
	 * Render loader.
	 *
	 * @return {ReactNode}
	 */
	renderLoader() {
		return <Loader style={this.constructor.stylesLoader} />;
	}


	/**
	 * Get whether we should render the new user picker.
	 *
	 * @return {Boolean}
	 */
	shouldRenderNew() {
		return (this.props.user.CustomerId === null);
	}


	/**
	 * Mobile view.
	 *
	 * @return {Boolean}
	 */
	get mobile() {
		return (this.props.width === "xs");
	}


	/**
	 * Styles.
	 *
	 * @return {Object}
	 */
	get styles() {
		return {
			borderRight: (this.mobile ? 0 : "0.1rem solid rgba(0,0,0,0.12)"),
			boxSizing: "borderBox",
			display: "flex",
			flexDirection: "column",
			height: "100%",
			maxWidth: "auto",
			overflowY: "auto",
			width: (this.mobile ? "auto" : rem(20))
		};
	}


	/**
	 * Styles (box).
	 * 
	 * @type {Object}
	 */
	static stylesBox = {overflowY: "auto"};

	/**
	 * Styles (empty).
	 *
	 * @return {Object}
	 */
	static stylesEmpty = {margin: 0};

	/**
	 * Styles (loader).
	 * 
	 * @return {Object}
	 */
	static stylesLoader = {alignSelf: "center", margin: 0};

}

export default connect(({user}) => ({user}))(withWidth()(MessageDrawer));
