import React from "react";
import {Link} from "react-router-dom";
import {IconButton} from "@material-ui/core";
import UserAvatar from "./UserAvatar.js";

const PROFILE_ICON_REFSET = r => (r ? r.setAttribute("tabindex", -1) : null);

const PROFILE_ICON_STYLES = {color: "#fff", textDecoration: "none"};

export default ({user, iconStyles}) => {
	return (
		<Link
			innerRef={PROFILE_ICON_REFSET}
			style={PROFILE_ICON_STYLES}
			to="/profile">
			<IconButton>
				<UserAvatar
					nativeTooltip={true}
					style={iconStyles}
					user={user} />
			</IconButton>
		</Link>
	);
};
