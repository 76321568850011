import React from "react";
import String from "./String.js";
import {Box} from "@material-ui/core";

/**
 * Details caption component
 *
 * An expandable component with caption styles for revealing a tip or similar.
 *
 * You can optionally include your own child elements above the caption.
 *
 * Refer to the source for details of all the available props.
 *
 * @package SEC
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class DetailsCaption extends React.PureComponent {

	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<details open={this.props.open}>
				<summary>{this.props.label}</summary>
				<Box pl={0.75} mt={this.constructor.gap}>
					{(this.props.children ? this.renderChildren() : null)}
					<String noParagraph={true} str={this.props.caption} variant="caption" />
				</Box>
			</details>
		);
	}


	/**
	 * Render the custom children.
	 *
	 * @return {ReactNode}
	 */
	renderChildren() {
		return (
			<Box mb={this.constructor.gap}>
				{this.props.children}
			</Box>
		);
	}


	/**
	 * Get the gap amount to apply between elements.
	 *
	 * This is a multiplier for the active Material UI theme spacing value.
	 * 
	 * @type {Number}
	 */
	static gap = 0.5;

}

export default DetailsCaption;
