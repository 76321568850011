import React from "react";
import Component from "App/Component.js";
import AlertBanner from "Components/AlertBanner.js";
import Currency from "Helpers/Currency.js";
import SpoiService from "../SpoiService.js";
import Strings from "../Resources/SpoiNextPaymentDateTotalBannerStrings.json";

/**
 * SPOI component to display the amount to be paid on the next payment date
 *
 * @package SECGroup
 * @subpackage Spoi
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class SpoiNextPaymentDateTotalBanner extends Component {

	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * State
		 *
		 * @type {Object}
		 */
		this.state = {

			/**
			 * Amount to display
			 *
			 * @type {Float|null} `null` = not loaded
			 */
			amount: null

		};

	}


	/**
	 * Component mounted.
	 *
	 * We get the value now.
	 * 
	 * @return {void}
	 */
	componentDidMount() {
		SpoiService.getNextPaymentDateTotal().then(amount => {
			this.setState({amount});
		}).catch(() => {
			// ... we've nothing to do
		});
	}


	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		if (!this.state.amount || (this.state.amount <= 0)) return null;
		else return this.renderBanner();
	}


	/**
	 * Render banner.
	 * 
	 * @return {ReactNode}
	 */
	renderBanner() {
		return (
			<AlertBanner
				content={this.content}
				style={this.props.style}
				title={Strings.title} />
		);
	}


	/**
	 * Get our content.
	 * 
	 * @return {String}
	 */
	get content() {
		return (
			<React.Fragment>
				<span style={{fontWeight: "bold"}}>
					{Currency.format(this.state.amount)}
				</span>
				<span> - </span>
				<span>{Strings.content}</span>
			</React.Fragment>
		);
	}

}

export default SpoiNextPaymentDateTotalBanner;
