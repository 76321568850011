import {api, uris} from "api.js";
import Pagination from "Helpers/Pagination.js";
import StatusHelper from "./Helpers/SpoiStatusHelper.js";
import SpoiConfig from "./SpoiConfig.js";
import SpoiLineItemCollection from "./SpoiLineItemCollection.js";
import SpoiPurchaseInvoice from "./SpoiPurchaseInvoice.js";
import SpoiPurchaseInvoiceCollection from "./SpoiPurchaseInvoiceCollection.js";

/**
 * SPOI service
 * 
 * @package SEC
 * @subpackage Spoi
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class SpoiService {

	/**
	 * Raise a purchase invoice against a PO.
	 *
	 * @param {Integer} poid Purchase order
	 * @param {Object} pi Object with PI submission properties
	 * @param {Boolean} cis optional Raise CIS invoice (`false`)
	 * @param {Boolean} CompletePo optional Mark PO as `Complete` (`false`)
	 * @return {Promise} Resolves with the UUID of the created PI
	 */
	static raisePI(poid, pi, cis=false, CompletePo=false) {
		return api.call(
			{
				data: {...pi, poid, CompletePo},
				method: "POST",
				url: `${uris.spoi.pis}${(cis ? "/cis" : "")}`
			}
		).then(({data}) => data);
	}


	/**
	 * Upload a PI original document.
	 * 
	 * As detailed in the API spec, this is only possible when 
	 * there's no existing document uploaded for the invoices.
	 * 
	 * @param {Array} uuids Purchase invoice UUIDs to link to
	 * @param {File} doc File upload
	 * @return {Promise}
	 */
	static uploadPIDocument(uuids, doc) {

		const data = new FormData();
		data.append("pdf", doc);
		data.append("uuids", uuids.join(","));

		return api.call(
			{
				data,
				headers: {"Content-Type": "multipart/form-data"},
				method: "POST",
				url: `${uris.spoi.pis}/documents`
			}
		);

	}


	/**
	 * Get a purchase invoice by UUID.
	 *
	 * @param {String} uuid
	 * @return {Promise} Resolves with PI object (`SpoiPurchaseInvoice`)
	 */
	static getPI(uuid) {
		return api.call(`${uris.spoi.pis}/${uuid}`).then(({data}) => {
			return new SpoiPurchaseInvoice(data);
		});
	}


	/**
	 * Get purchase invoices.
	 *
	 * Filters in `filters` will be added as query parameters indexed 
	 * by the property name with the value as given in the filters object.
	 *
	 * The Promise will resolve with an object with `count` (the delimited 
	 * count of items matching the query on the server, for pagination 
	 * purposes) and `objects` - a `SpoiPurchaseInvoiceCollection` containing 
	 * the PIs fetched from the server as `SpoiPurchaseInvoice` instances.
	 *
	 * @param {Object} filters
	 * @param {Integer} page optional (1)
	 * @param {Integer} limit optional (10) (Must not exceed backend limits)
	 * @return {Promise} See above.
	 */
	static getPIs(filters, page=1, limit=10) {

		/**
		 * Resolve statuses
		 */
		if (filters.Statuses) {
			const s = filters.Statuses;
			filters.Statuses = JSON.stringify(StatusHelper.resolveLocalToGlobalStatuses(s));
		}

		/**
		 * Get our parameters
		 */
		const params = {...filters, limit, offset: Pagination(page, limit)};

		/**
		 * Get matching PIs
		 */
		return api.call({params, url: uris.spoi.pis}).then(({data}) => {
			const objects = data.objects;
			data.objects = SpoiPurchaseInvoiceCollection.construct(objects);
			return data;
		});

	}


	/**
	 * Get a purchase order by ID.
	 *
	 * @param {Integer} id
	 * @return {Promise} Resolves with an object representing the fetched PO
	 */
	static getPO(id) {
		return api.call(`${uris.spoi.pos}/${id}`).then(({data}) => data);
	}


	/**
	 * Get purchase orders.
	 *
	 * Filters in `filters` will be added as query parameters indexed 
	 * by the property name with the value as given in the filters object.
	 *
	 * The Promise will resolve with an object with `count` (the delimited 
	 * count of items matching the query on the server, for pagination 
	 * purposes) and `objects` - an array of objects representing the POs.
	 *
	 * @param {Object} filters
	 * @param {Integer} page optional (1)
	 * @param {Integer} limit optional (10) (Must not exceed backend limits)
	 * @param {Boolean} InvoicedTotals optional Add `InvoicedTotals` (`false`)
	 * @return {Promise} See above.
	 */
	static getPOs(filters, page=1, limit=10, InvoicedTotals=false) {

		/**
		 * Params
		 */
		const params = {
			...filters,
			limit,
			offset: Pagination(page, limit),
			InvoicedTotals,
			Statuses: JSON.stringify(filters?.Statuses)
		};

		/**
		 * Get the POs
		 */
		return api.call({params, url: uris.spoi.pos}).then(res => {

			/**
			 * Body
			 */
			const obj = res.data;

			/**
			 * Attach invoiced totals
			 */
			if (obj.totals) {
				Object.keys(obj.totals).forEach(poid => {
					const id = parseInt(poid);
					const inv = obj.totals[poid];
					obj.objects.find(po => (po.Id === id)).Invoiced = inv;
				});
			}

			/**
			 * Resolve
			 */
			return obj;

		});

	}


	/**
	 * Get purchase invoices raised against a PO by PO ID.
	 *
	 * The Promise will resolve with a `SpoiPurchaseInvoiceCollection` 
	 * instance containing the PIs as `SpoiPurchaseInvoice` instances.
	 * 
	 * @param {Integer} po Purchase order ID
	 * @return {Promise} See above.
	 */
	static getPOPIs(po) {
		return api.call(`${uris.spoi.pis}/po/${po}`).then(({data}) => {
			return SpoiPurchaseInvoiceCollection.construct(data.objects);
		});
	}


	/**
	 * Get a purchase order and the purchase invoices raised against it.
	 * 
	 * The Promise will resolve with an object with `po` (see `getPO()`) 
	 * and `pis` (see `getPOPIs()`) properties containing the PO and PIs.
	 * 
	 * @param {Integer} id Purchase order ID
	 * @return {Promise} See above.
	 */
	static getPOWithPIs(id) {
		const po = this.getPO(id);
		const pis = this.getPOPIs(id);
		return Promise.all([po, pis]).then(([po, pis]) => ({po, pis}));
	}


	/**
	 * Get PO externally accessible original document URI by PO ID.
	 *
	 * @param {Integer} id
	 * @return {Promise} Resolves with URI
	 */
	static getPoDocumentUri(id) {
		return api.call(`${uris.spoi.pos}/${id}/documentUri`).then(({data}) => data);
	}


	/**
	 * Get the amount to be paid to the active supplier on the next pay date.
	 * 
	 * @return {Promise} Resolves with the amount to be paid
	 */
	static getNextPaymentDateTotal() {
		return api.call(uris.spoi.totalNpd).then(({data}) => data);
	}


	/**
	 * Get the SPOI configuration object from the server.
	 *
	 * @return {Promise} Resolves with a `SpoiConfig` instance
	 */
	static getConfig() {
		return api.call(uris.spoi.config).then(({data}) => {
			return new SpoiConfig(data);
		});
	}


	/**
	 * Get the value which has not been invoiced against a given PO, 
	 * when given a collection of PIs presumed raised against it.
	 *
	 * @param {Object} po Object with `Items` as a `SpoiLineItemCollection`
	 * @param {PurchaseInvoiceCollection} pis
	 * @return {Float}
	 */
	static getInvoicableValue(po, pis) {
		const value = (po.Items.total - pis.total);
		return ((value >= 0) ? value : 0);
	}


	/**
	 * Get tax codes.
	 *
	 * Returns a Promise which resolves with an array of 
	 * objects representing tax codes as defined by the API.
	 * 
	 * @return {Promise} See above
	 */
	static getTaxCodes() {
		return api.call(uris.spoi.tcs).then(({data}) => data);
	}


	/**
	 * Get whether a PO-like object is in a raisable state.
	 * 
	 * This relies on the object having a valid `Status` property.
	 *
	 * Note: This is a local check considering the PO's status only.
	 *
	 * No network calls are made, either to verify the PO's identity, 
	 * current status, or available invoicable amount considering any 
	 * previous PIs which may have been made against the PO when valid.
	 * 
	 * @param {Object} po PO-like object with `Status` property
	 * @return {Boolean}
	 */
	static poInvoicable(po) {
		return (po.Status === "Live");
	}


	/**
	 * Prepare a purchase invoice instance from a purchase order.
	 * 
	 * You must pass a `SpoiConfig` instance and an object representation 
	 * of a purchase order (the configuration instance is used to assign 
	 * the initial tax code). The initial nominal code is assigned from the 
	 * purchase order. The initial line items are assigned from the PO too.
	 *
	 * You may optionally pass a PI-like object `pi` to assign properties 
	 * from that PI onto the new PI, e.g. to create a purchase invoice 
	 * instance from a PI representative object delivered from the network.
	 *
	 * The PO object must have its `Items` property set to an instance 
	 * of `SpoiLineItemCollection` representing the PO's line items.
	 * 
	 * @param {SpoiConfig} config
	 * @param {Object} po
	 * @param {Object} pi optional
	 * @return {SpoiPurchaseInvoice} [description]
	 */
	static preparePi(config, po, pi=null) {
		return new SpoiPurchaseInvoice({
			Items: po.Items.clone(),
			...(pi || {}),
			PurchaseOrder: po,
			NominalCode: (pi?.NominalCode.Id || po.NominalCode),
			TaxCode: (pi?.TaxCode || config.taxcode_default)
		});
	}


	/**
	 * Prepare a purchase order for use within the app.
	 * 
	 * This converts the purchase order's line items array into 
	 * a `SpoiLineItemCollection` instance for subsequent reference.
	 *
	 * @param {Object} po
	 * @return {Object} Purchase order
	 */
	static preparePo(po) {
		po.Items = SpoiLineItemCollection.construct(po.Items);
		return po;
	}


	/**
	 * Get the accounts messaging URI for a given contact.
	 *
	 * @param {String} contact
	 * @return {String}
	 */
	static getAdminMessagesUri(contact) {
		return `/messages/${contact}`;
	}


	/**
	 * Get the accounts messaging URI for the default contact over network.
	 *
	 * @return {Promise} Resolves to the URI
	 */
	static getAdminMessagesUriNetworked() {
		return this.getConfig().then(config => {
			return this.getAdminMessagesUri(config.spoi_contact_username);
		});
	}

}

export default SpoiService;
