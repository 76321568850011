import React from "react";
import Currency from "Helpers/Currency.js";
import {Box, Tooltip, Typography} from "@material-ui/core";

/**
 * Spoi total typography
 * 
 * Please refer to the source for details of all the available props.
 * 
 * @package SEC
 * @subpackage Spoi
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class SpoiTotalTypography extends React.PureComponent {

	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Box>
				{this.props.error ? this.renderTooltip() : this.renderTotal()}
				<Box mt={0.25}>
					<Typography variant="caption">(exc. VAT)</Typography>
				</Box>
			</Box>
		);
	}


	/**
	 * Render the tooltip.
	 *
	 * @return {ReactNode}
	 */
	renderTooltip() {
		return (
			<Tooltip
				arrow
				placement="left-start"
				title="Purchase order value exceeded!">
				{this.renderTotal()}
			</Tooltip>
		);
	}


	/**
	 * Render the total text.
	 *
	 * @return {ReactNode}
	 */
	renderTotal() {
		return (
			<Typography variant="h6" style={this.styles}>
				{Currency.format(this.props.total)}
			</Typography>
		);
	}


	/**
	 * Styles.
	 *
	 * @return {Object}
	 */
	get styles() {
		return {
			color: (this.props.error ? "red" : null),
			fontWeight: "bold",
			justifySelf: (this.props.alignStart ? null : "flex-end"),
			marginLeft: (this.props.alignStart ? null : "auto"),
			textDecoration: "underline",
			wordBreak: "break-word"
		};
	}

}

export default SpoiTotalTypography;
