import React from "react";
import Container from "Components/Container.js";
import Dialog from "Components/Dialog.js";
import F12Api from "./F12Api.js";
import F12Dark from "./F12Dark.js";
import String from "Components/String.js";
import dF12Close from "Dispatchers/F12Close.js";
import {connect} from "react-redux";
import {Divider} from "@material-ui/core";

/**
 * F12 UI
 *
 * @package SEC
 * @subpackage F12
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class F12 extends React.PureComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Dialog
				fullWidth={true}
				nocancel={true}
				onClose={dF12Close}
				onOk={dF12Close}
				open={this.props.f12}
				title="Developer tools">
				<Container af="row" gap={1}>
					<F12Api />
					<F12Dark />
					<Divider />
					<Container style={this.constructor.stringContainerStyles}>
						<String
							color="textSecondary"
							gap={0.5}
							noParagraph={true}
							str="These controls are for development use only." />
					</Container>
				</Container>
			</Dialog>
		);
	}


	/**
	 * String container styles.
	 * 
	 * @type {Object}
	 */
	static stringContainerStyles = {margin: "0.75rem 0"};

}

export default connect(({f12}) => ({f12}))(F12);
