import React from "react";
import rem from "Helpers/Rem.js";
import withMobile from "Hoc/withMobile.js";
import Styles from "Resources/Styles.json";
import Theme from "Resources/Theme.json";
import * as mui from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import ProfileIcon from "./ProfileIcon.js";
import SyncIcon from "./SyncIcon.js";
import {connect} from "react-redux";
import {Link} from "react-router-dom";

/**
 * Bar
 *
 * @package SEC
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class Bar extends React.PureComponent {

	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<mui.AppBar
				color="secondary"
				position="sticky"
				style={this.constructor.stylesBar}>

				<mui.Toolbar style={this.constructor.styles}>
					<mui.Hidden mdUp>
						<mui.Badge
							anchorOrigin={{horizontal: "left", vertical: "top"}}
							badgeContent={this.props.messages?.length}
							color="error"
							overlap="circle"
							variant="dot">
							<mui.IconButton
								edge="start"
								color="inherit"
								onClick={this.props.onDrawerToggle}
								style={this.constructor.stylesMenu}>
								<MenuIcon />
							</mui.IconButton>
						</mui.Badge>
					</mui.Hidden>

					<Link to="/">
						<img
							alt="SEC Group"
							src={this.imgSrc}
							style={this.constructor.stylesImg} />
					</Link>

					<div style={this.constructor.stylesSettings}>
						<SyncIcon
							pending={this.props.sync.pending}
							syncing={this.props.sync.syncing}
							iconStyles={this.constructor.stylesIcons} />
						<ProfileIcon
							user={this.props.user}
							iconStyles={this.constructor.stylesIcons} />
					</div>
				</mui.Toolbar>

			</mui.AppBar>
		);
	}


	/**
	 * Get logo source.
	 *
	 * @return {String}
	 */
	get imgSrc() {
		return (this.props.isMobile ? "/sec-white.png" : "/sec-group.png");
	}


	/**
	 * Styles for notification centre.
	 *
	 * @return {Object}
	 */
	get notifyStyles() {
		return {
			position: "absolute",
			left: (this.props.isMobile ? "1rem" : "initial"),
			right: (this.props.isMobile ? "1rem" : rem()),
			height: `auto`,
			maxHeight: `calc(100vh - ${rem(2)} - ${Styles.bar})`,
			top: `calc(${Styles.bar} + ${rem()})`,
			zIndex: 900,
			width: (this.props.isMobile ? "calc(100% - 2rem)" : rem(20))
		};
	}


	/**
	 * Styles for username.
	 *
	 * @return {Object}
	 */
	get userStyles() {
		return {
			display: ((this.props.width === "xs") ? "none" : "block"),
			marginRight: rem(0.3)
		};
	}


	/**
	 * Styles.
	 *
	 * @type {Object}
	 */
	static styles = {
		minHeight: Styles.bar,
		padding: `${(Theme.sec.spacing / 10)}rem`,
		paddingRight: rem(0.5)
	};

	/**
	 * Styles for bar.
	 *
	 * @type {Object}
	 */
	static stylesBar = {
		boxShadow: "none",
		height: Styles.bar,
		zIndex: 10000
	};

	/**
	 * Image styles.
	 * 
	 * @type {Object}
	 */
	static stylesImg = {
		height: "100%",
		maxHeight: rem(2)
	};

	/**
	 * Styles for icons.
	 *
	 * @type {Object}
	 */
	static stylesIcons = {
		width: rem(2),
		height: rem(2)
	};

	/**
	 * Styles for menu button.
	 *
	 * @type {Object}
	 */
	static stylesMenu = {
		marginRight: rem(0.5)
	};

	/**
	 * Styles for settings container.
	 *
	 * @type {Object}
	 */
	static stylesSettings = {
		alignItems: "center",
		display: "flex",
		marginLeft: "auto"
	};

}

export default connect(({messages, sync, user}) => ({messages, sync, user}))(withMobile(Bar));
