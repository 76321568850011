import React from "react";
import withMobile from "Hoc/withMobile.js";
import {Box, Card, Typography} from "@material-ui/core";

/**
 * Search card
 *
 * A basic search card to act as a container for filter controls.
 *
 * Controls are displayed horizontally on desktop with `space-between` filters.
 *
 * Please refer to the source for details of the available props.
 *
 * @package SEC
 * @subpackage Spoi
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class SearchCard extends React.PureComponent {

	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Card>
				<Box p={1}>
					<Typography variant="h6">Search</Typography>
					<Box mt={1}>
						<Box style={this.styles}>{this.props.children}</Box>
					</Box>
				</Box>
			</Card>
		);
	}


	/**
	 * Styles for container.
	 *
	 * @return {Object}
	 */
	get styles() {
		return {
			display: "flex",
			flexDirection: (this.props.isMobile ? "column" : "row"),
			gap: "1.5rem",
			justifyContent: "space-between"
		};
	}

}

export default withMobile(SearchCard);
