import React from "react";
import mobile from "Helpers/Mobile.js";
import {withWidth} from "@material-ui/core";

/**
 * `withMobile()` HOC wrapper
 * 
 * Makes an `isMobile` prop available on components.
 * 
 * @package SEC
 * @subpackage Hoc
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
const withMobile = Component => {

	/**
	 * `ComponentWithMobile` inner wrapper
	 * 
	 * @package SEC
	 * @subpackage Hoc
	 * @author Heron Web Ltd
	 * @copyright SEC Group
	 */
	const cwm = class ComponentWithMobile extends React.Component {

		/**
		 * Render.
		 * 
		 * @return {ReactNode}
		 */
		render() {
			return (
				<Component
					{...this.props}
					isMobile={mobile(this.props.width)}
					width={this.props.width} />
			);
		}

	};

	return withWidth()(cwm);

};

export default withMobile;
