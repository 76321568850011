import React from "react";
import Component from "App/Component.js";
import History from "App/History.js";
import View from "App/View.js";
import ConversationCollection from "./MessageConversationCollection.js";
import MessageConversation from "./MessageConversation.js";
import MessageDrawer from "./MessageDrawer.js";
import MessageThread from "./MessageThread.js";
import MessageService from "Services/MessageService.js";
import Navigator from "App/Navigator.js";
import rem from "Helpers/Rem.js";
import {connect} from "react-redux";
import {withWidth} from "@material-ui/core";

/**
 * Messaging view
 *
 * @package SEC
 * @subpackage Messages
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class MessageView extends Component {

	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * State
		 *
		 * @type {Object}
		 */
		this.state = {

			/**
			 * Active conversation
			 *
			 * @type {String} Contact username
			 */
			convo: null,

			/**
			 * Conversations
			 *
			 * @type {MessageConversationCollection}
			 */
			convos: new ConversationCollection(),

			/**
			 * Error
			 *
			 * @type {Boolean}
			 */
			error: false,

			/**
			 * Loading
			 *
			 * @type {Boolean}
			 */
			loading: true,

			/**
			 * View to display
			 *
			 * @type {Boolean} When `true`, thread; when `false`, drawer.
			 */
			view: false

		};

		/**
		 * Whether the user has selected a conversation this mount
		 *
		 * This will be updated exactly once after mount, the first 
		 * time that the user selects a conversation to view.
		 *
		 * @type {Boolean}
		 */
		this.userSelectedConvo = false;

	}


	/**
	 * Component mounted.
	 *
	 * @return {void}
	 */
	componentDidMount() {
		this.update();
	}


	/**
	 * Component updated.
	 *
	 * We may need to mark conversations read if the application 
	 * control for "mark all messages read" was used.
	 *
	 * We may need to add/update new conversations if we have 
	 * received a new message via the `Messenger` system.
	 *
	 * We also need to handle routing to make sure we follow the URI.
	 * 
	 * @param {Object} prevProps
	 * @return {self}
	 */
	componentDidUpdate(prevProps) {

		/**
		 * Conversations
		 */
		let convos = this.state.convos;

		/**
		 * Application "marked all read"
		 */
		if (this.props.readTime !== prevProps.readTime) {
			convos = convos.markRead();
		}

		/**
		 * We might have received new messages from `Messenger`
		 */
		if (this.props.messages !== prevProps.messages) {
			convos = convos.updateFromMessages(this.props.messages);
		}

		/**
		 * We need to route to the conversation given in the route
		 */
		if (!this.props.match.params.contact) {
			if (prevProps.match.params.contact) {
				this.setState({convo: null, view: false});
			}
		}
		else if (this.shouldSelectRouteConvo) {
			const contact = this.props.match.params.contact;
			if (!this.state.convos.hasContactConversation(contact)) {
				this.handleNew({Username: contact});
			}
			else this.selectRouteConvo();
			this.userSelectedConvo = true;
		}
		else if (this.routeChanged(this.props, prevProps)) {
			this.selectRouteConvo();
		}

		/**
		 * Update conversations in state
		 */
		if (this.state.convos !== convos) this.setState({convos});

	}


	/**
	 * Handle new conversation.
	 *
	 * We select the existing conversation if there is already 
	 * one available for the user we want to send to.
	 *
	 * @param {Object} user
	 * @return {void}
	 */
	handleNew(user) {
		const convo = this.state.convos.getContactConversation(user.Username);
		if (convo) this.select(user.Username);
		else this.newConversationWithContact(user.Username);
	}


	/**
	 * Handle conversation deletion.
	 *
	 * The selection will be removed if the conversation was selected.
	 *
	 * @param {MessageConversation} convo
	 * @return {void}
	 */
	handleDeleted(convo) {
		const active = (this.state.convo === convo.Contact);
		const selection = (active ? null : this.state.convo);
		this.select(selection, this.state.convos.delete(convo.Contact));
	}


	/**
	 * Handle a sent message.
	 *
	 * @param {Message} msg
	 * @return {void}
	 */
	handleSent(msg) {
		this.setState({convos: this.state.convos.recordMessageSent(msg)});
	}


	/**
	 * Create and select a new conversation with a contact.
	 *
	 * @param {String} contact
	 * @return {void}
	 */
	newConversationWithContact(contact) {
		const convo = MessageConversation.newToContact(contact);
		this.select(contact, this.state.convos.add(convo));
	}


	/**
	 * React to route changes.
	 *
	 * @param {Object} props
	 * @param {Object} prevProps
	 * @return {void} [description]
	 */
	routeChanged(props, prevProps) {
		return (props.location.pathname !== prevProps.location.pathname);
	}


	/**
	 * Select a conversation.
	 *
	 * The conversation must exist in our collection.
	 * 
	 * @param {String} contact Contact conversation to select
	 * @param {MessageConversationCollection} convos Use this collection
	 * @return {void}
	 */
	select(contact, convos=null) {
		convos = (convos || this.state.convos);
		if (contact) convos = convos.markConversationRead(contact);
		this.setState({convo: contact, view: (contact !== null), convos});
		History.push(this.getMessagesUri(contact));
	}


	/**
	 * Select the conversation given by the active route.
	 *
	 * The route will be changed to the root URI if the contact is invalid.
	 *
	 * @param {Boolean} reroute optional Route to root URI if invalid contact
	 * @return {void}
	 */
	selectRouteConvo(reroute=true) {
		const contact = this.routeContact;
		if (this.state.convos.hasContactConversation(contact)) {
			this.setState({convo: contact, view: true});
		}
		else if (reroute) {
			this.setState({view: false});
			Navigator.navigate(this.getMessagesUri());
		}
	}


	/**
	 * Update conversations from network.
	 *
	 * We should only need to call this once per-mount, on-mount.
	 *
	 * (New incoming messages are then received via the `Messenger` system.)
	 * 
	 * @return {void}
	 */
	update() {
		MessageService.getConversations().then(convos => {
			this.setState({convos});
		}).catch(() => {
			this.setState({error: true});
		}).finally(() => this.setState({loading: false}));
	}


	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<View pb={1} style={this.styles}>
				{this.shouldRenderDrawer ? this.renderDrawer() : null}
				{this.shouldRenderThread ? this.renderThread() : null}
			</View>
		);
	}


	/**
	 * Render drawer UI.
	 *
	 * @return {ReactNode}
	 */
	renderDrawer() {
		return (
			<MessageDrawer
				convo={this.state.convo}
				convos={this.state.convos}
				error={this.state.error}
				loading={this.state.loading}
				onDelete={convo => this.handleDeleted(convo)}
				onNew={user => this.handleNew(user)}
				onSelect={convo => this.select(convo.Contact)} />
		);
	}


	/**
	 * Render thread UI.
	 *
	 * @return {ReactNode}
	 */
	renderThread() {
		return (
			<MessageThread
				convo={this.activeConversation}
				contact={this.state.convo}
				onHide={() => this.setState({convo: null, view: false})}
				onSent={msg => this.handleSent(msg)} />
		);
	}


	/**
	 * Get messages route URI.
	 *
	 * @param {String} contact optional Conversation route for contact
	 * @return {String}
	 */
	getMessagesUri(contact=null) {
		return `/messages${(contact ? (`/${contact}`) : "")}`;
	}


	/**
	 * Get the active conversation object.
	 * 
	 * @return {MessageConversation|null}
	 */
	get activeConversation() {
		const contact = this.state.convo;
		if (!contact) return null;
		else return this.state.convos.getContactConversation(contact);
	}


	/**
	 * Get contact given by the active route.
	 *
	 * @return {String|null}
	 */
	get routeContact() {
		return this.props.match.params.contact;
	}


	/**
	 * Get whether we should render the drawer UI.
	 *
	 * @return {Boolean}
	 */
	get shouldRenderDrawer() {
		return (!this.state.view || !this.mobile);
	}


	/**
	 * Get whether we should render the thread UI.
	 *
	 * @return {Boolean}
	 */
	get shouldRenderThread() {
		return (this.state.view || !this.mobile);
	}


	/**
	 * Get whether we should select the conversation from the route.
	 *
	 * @return {Boolean}
	 */
	get shouldSelectRouteConvo() {
		return ((this.routeContact !== null) && !this.userSelectedConvo);
	}


	/**
	 * Mobile view.
	 *
	 * @return {Boolean}
	 */
	get mobile() {
		return (this.props.width === "xs");
	}


	/**
	 * Styles.
	 *
	 * @return {Object}
	 */
	get styles() {
		return {
			gridTemplateColumns: (this.mobile ? "auto" : "min-content auto"),
			gridTemplateRows: `calc(100vh - ${rem(6)} - ${rem()})`
		};
	}


	/**
	 * Map state to props.
	 *
	 * @param {Array} options.messages
	 * @param {Integer|null} options.MessagesMarkedReadTime
	 * @return {Object}
	 */
	static mapStateToProps({messages, messagesMarkedReadTime}) {
		return {messages, readTime: messagesMarkedReadTime};
	}

}

export default connect(MessageView.mapStateToProps)(
	withWidth()(MessageView)
);
