import React from "react";
import mobile from "Helpers/Mobile.js";
import rem from "Helpers/Rem.js";
import SpoiStatusChip from "../Atoms/SpoiStatusChip.js";
import {Box, Divider, Typography, withWidth} from "@material-ui/core";

/**
 * SPOI domain header
 *
 * A component intended for use as a screen page header which 
 * identifies a SPOI resource and its current status (via a chip).
 * 
 * Please refer to the source for details of all the available props.
 * 
 * @package SEC
 * @subpackage Spoi
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class SpoiDomainHeader extends React.PureComponent {

	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Box style={this.styles}>
				<Typography variant="h5">
					{this.props.label}
					{(this.props.id ? " " : null)}
					{(this.props.id ? this.renderId() : null)}
				</Typography>
				<Box ml={(this.mobile ? 0 : 2)} mt={(this.mobile ? 1 : 0)}>
					<SpoiStatusChip status={this.props.status} />
				</Box>
				{(this.mobile ? <Divider style={this.stylesDivider} /> : null)}
			</Box>
		);
	}


	/**
	 * Render ID field.
	 *
	 * We use a React fragment so we can render arbitrary children.
	 * 
	 * @return {ReactNode}
	 */
	renderId() {
		return <React.Fragment>#{this.props.id}</React.Fragment>;
	}


	/**
	 * Get whether we've a mobile viewport.
	 *
	 * @return {Boolean}
	 */
	get mobile() {
		return mobile(this.props.width);
	}


	/**
	 * Styles.
	 *
	 * @return {Object}
	 */
	get styles() {
		return {
			alignItems: "center",
			display: "flex",
			flexDirection: (this.mobile ? "column" : "row")
		};
	}


	/**
	 * Divider styles.
	 *
	 * @return {Object}
	 */
	get stylesDivider() {
		return {
			marginTop: rem(1.5),
			width: "100%"
		};
	}

}

export default withWidth()(SpoiDomainHeader);
