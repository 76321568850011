import React from "react";
import Theme from "Resources/Theme.json";
import withMobile from "Hoc/withMobile.js";
import {Box} from "@material-ui/core";
import CountStatistic from "./HealthSafetyNmpisStatisticsCount.js";
import IncidentsStatistic from "./HealthSafetyNmpisStatisticsIncidents.js";
import ProbablesStatistic from "./HealthSafetyNmpisStatisticsProbables.js";

/**
 * Health and safety NMPI statistics
 *
 * @package SEC
 * @subpackage Views
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class HealthSafetyNmpisStatistics extends React.PureComponent {

	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Box style={this.styles}>
				<CountStatistic stats={this.props.stats} />
				<IncidentsStatistic group={true} stats={this.props.stats} />
				<ProbablesStatistic stats={this.props.stats} />
			</Box>
		);
	}


	/**
	 * Styles for stats container.
	 *
	 * @return {Object}
	 */
	get styles() {
		return {
			display: "grid",
			gridAutoFlow: (this.props.isMobile ? "row" : "column"),
			gridColumnGap: `${(Theme.sec.spacing * 2) / 10}rem`,
			gridRowGap: `${(Number(Theme.sec.spacing)) / 10}rem`,
			gridTemplateColumns: (!this.props.isMobile ? "1fr 2fr 2fr" : "100%"),
			width: "100%"
		};
	}

}

export default withMobile(HealthSafetyNmpisStatistics);
