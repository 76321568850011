import React from "react";
import AddIcon from "@material-ui/icons/Add";
import Container from "Components/Containerx.js";
import Fab from "Components/Fab.js";
import Navigator from "App/Navigator.js";
import TimesheetsTable from "./TimesheetsTable.js";
import View from "App/View.js";
import dayjs from "dayjs";

/**
 * Timesheets view
 * 
 * @package SEC
 * @subpackage Timesheets
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class TimesheetsView extends React.PureComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return <>
			<View>
				<Container singleColumn={true}>
					<TimesheetsTable
						initialSheetDateMax={((new dayjs())).format("YYYY-MM-DD")}
						initialSheetDateMin={dayjs().isoWeekday(1).format("YYYY-MM-DD")} />
				</Container>
			</View>
			<Fab icon={AddIcon} onClick={this.constructor.handleFabClicked} />
		</>;
	}


	/**
	 * Action button clicked.
	 *
	 * We go to the submission screen.
	 * 
	 * @return {void}
	 */
	static handleFabClicked() {
		Navigator.navigate("/timesheets/submit");
	}

}

export default TimesheetsView;
