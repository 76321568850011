import Messenger from "./Messenger.js";
import Postmaster from "./Postmaster.js";
import Store from "./Store.js";

/**
 * Tasker
 *
 * @package SEC
 * @subpackage App
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class Tasker {

	/*
	 * Message timer
	 *
	 * @type {Integer}
	 */
	static msgTimer = null;

	/**
	 * Sync timer
	 *
	 * @type {Integer}
	 */
	static syncTimer = null;


	/**
	 * Initialise all tasks.
	 * 
	 * @return {void}
	 */
	static init() {
		Tasker.msgSchedule();
		Tasker.syncSchedule();
		window.addEventListener("online", Tasker.init);
	}


	/**
	 * Detach all task schedules.
	 * 
	 * @return {void}
	 */
	static detach() {
		if (Tasker.msgTimer) clearTimeout(Tasker.msgTimer);
		if (Tasker.syncTimer) clearTimeout(Tasker.syncTimer);
		window.removeEventListener("online", Tasker.init);
	}


	/**
	 * Message scheduler.
	 *
	 * @return {void}
	 */
	static msgSchedule() {
		if (Store.getState().auth) Messenger.poll();
		if (Tasker.msgTimer) clearTimeout(Tasker.msgTimer);
		Tasker.msgTimer = setTimeout(Tasker.msgSchedule, (60 * 1000));
	}


	/**
	 * Sync scheduler.
	 *
	 * @return {void}
	 */
	static syncSchedule() {
		if (Store.getState().auth) Postmaster.post();
		if (Tasker.syncTimer) clearTimeout(Tasker.syncTimer);
		Tasker.syncTimer = setTimeout(Tasker.syncSchedule, (60 * 1000));
	}

}

export default Tasker;
