import React from "react";
import ReactHelper from "Helpers/ReactHelper.js";
import {Fab as MuiFab} from "@material-ui/core";
import {Check as CheckIcon} from "@material-ui/icons";
import {withStyles} from "@material-ui/styles";

/**
 * Fab
 *
 * @package SEC
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class Fab extends React.PureComponent {

	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<MuiFab
				className={this.props.classes.fab}
				color="primary"
				disabled={this.props.disabled}
				onClick={this.props.onClick}
				type={this.props.type}>
				{this.renderIcon()}
			</MuiFab>
		);
	}


	/**
	 * Render the icon.
	 * 
	 * @return {ReactNode}
	 */
	renderIcon() {
		if (this.props.icon) return ReactHelper.render(this.props.icon);
		else if (this.props.children) return this.props.children;
		else return <CheckIcon />;
	}


	/**
	 * Make styles.
	 *
	 * @param {Object} theme
	 * @return {Object}
	 */
	static styles(theme) {
		return {
			fab: {
				position: "fixed",
				right: theme.spacing(2),
				bottom: theme.spacing(2),
				zIndex: 1000
			}
		};
	}

}

export default withStyles(Fab.styles)(Fab);
