import React from "react";
import rem from "Helpers/Rem.js";
import {Typography} from "@material-ui/core";

/**
 * Label (centred)
 *
 * @package SEC
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class LabelCentred extends React.PureComponent {

	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Typography style={this.styles}>
				{this.props.children}
			</Typography>
		);
	}


	/**
	 * Styles.
	 *
	 * @return {Object}
	 */
	get styles() {
		return {
			margin: (this.props.autoMargin ? "auto" : `${rem} auto`),
			textAlign: "center"
		};
	}

}

export default LabelCentred;
