import React from "react";
import Component from "App/Component.js";
import ContextMenu from "Components/ContextMenu.js";
import Label from "Components/Label.js";
import UserAvatar from "Components/UserAvatar.js";
import FadeOnHover from "Resources/FadeOnHover.json";
import DeleteDialog from "./MessageConversationDeleteDialog.js";
import {MessageConversationCard as Strings} from "Resources/Strings.js";
import * as mui from "@material-ui/core";

/**
 * Message conversation card
 * 
 * @package SEC
 * @subpackage Messages
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class MessageConversationCard extends Component {

	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * State
		 *
		 * @type {Object}
		 */
		this.state = {

			/**
			 * Context menu open
			 *
			 * @type {Boolean}
			 */
			contextMenu: false,

			/**
			 * Context menu event
			 *
			 * @type {MouseEvent}
			 */
			contextMenuEvent: null,

			/**
			 * Deleting
			 *
			 * @type {Boolean}
			 */
			delete: false,

			/**
			 * Seen (clicked the message)
			 *
			 * @type {Boolean}
			 */
			seen: this.props.active

		};

	}


	/**
	 * Component updated.
	 *
	 * We might use this instance to display another 
	 * conversation, for example if this gets reordered 
	 * in a list, so we have to listen for changes to 
	 * our conversation and reset our state when required.
	 *
	 * @param {Object} prevProps
	 * @return {void}
	 */
	componentDidUpdate(prevProps) {
		if (this.props.convo.Contact !== prevProps.convo.Contact) {
			this.hideContextMenu();
			this.setState({seen: this.props.active});
		}
		else if (this.props.convo.Message !== prevProps.convo.Message) {
			this.setState({seen: this.props.active});
		}
	}


	/**
	 * Handle click.
	 *
	 * @return {void}
	 */
	handleClick() {
		this.props.onClick(this.convo);
	}


	/**
	 * Context menu.
	 *
	 * @param {MouseEvent} e
	 * @return {void}
	 */
	handleContextMenu(e) {
		e.persist();
		e.preventDefault();
		this.setState({contextMenu: true, contextMenuEvent: e});
	}


	/**
	 * Close the context menu.
	 *
	 * @return {void}
	 */
	hideContextMenu() {
		this.setState({contextMenu: false, contextMenuEvent: null});
	}


	/**
	 * Handle deletion.
	 *
	 * @return {void}
	 */
	handleDelete() {
		this.hideContextMenu();
		this.setState({delete: true});
	}


	/**
	 * Handle deletion dialog close.
	 *
	 * @return {void}
	 */
	handleDeleteClose() {
		this.setState({delete: false});
	}


	/**
	 * Handle deletion completed.
	 *
	 * @return {void}
	 */
	handleDeleteDone() {
		this.handleDeleteClose();
		this.props.onDelete(this.convo);
	}


	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<React.Fragment>
				<mui.Paper
					className={this.paperClassName}
					elevation={0}
					onClick={() => this.handleClick()}
					onContextMenu={e => this.handleContextMenu(e)}
					style={{background: "none", position: "relative", ...this.props.style}}>

					<mui.Box style={this.style}>
						<mui.Box mr={1}>
							<UserAvatar user={{Username: this.contact}} />
						</mui.Box>
						<mui.Box
							mr={(this.mobile ? 0 : 1)}
							style={{flexGrow: 1, minWidth: 0}}>

							<mui.Box mb={0.5} style={this.stylesContactBox}>
								<Label style={this.stylesContact}>
									{this.contact}
								</Label>
								<mui.Typography style={this.stylesTime}>
									{this.convo.TimestampString || "(New)"}
								</mui.Typography>
							</mui.Box>
							<mui.Box style={{display: "flex"}}>
								<mui.Typography style={this.stylesMsg}>
									{this.convo.Message || "(New Message)"}
								</mui.Typography>
							</mui.Box>
						</mui.Box>
					</mui.Box>

					{this.props.divider ? this.renderDivider() : null}
				</mui.Paper>

				{this.renderContext()}
				{this.renderDelete()}
			</React.Fragment>
		);
	}


	/**
	 * Render context menu.
	 *
	 * @return {ReactNode}
	 */
	renderContext() {
		return (
			<ContextMenu
				e={this.state.contextMenuEvent}
				open={this.state.contextMenu}
				onClose={() => this.hideContextMenu()}>

				<mui.MenuItem onClick={() => this.handleDelete()}>
					{Strings.delete.label}
				</mui.MenuItem>
			</ContextMenu>
		);
	}


	/**
	 * Render deletion dialog.
	 *
	 * @return {ReactNode}
	 */
	renderDelete() {
		return (
			<DeleteDialog
				open={this.state.delete}
				contact={this.contact}
				onClose={() => this.handleDeleteClose()}
				onDone={() => this.handleDeleteDone()} />
		);
	}


	/**
	 * Render divider.
	 *
	 * @return {ReactNode}
	 */
	renderDivider() {
		return (
			<mui.Box mr={(this.mobile ? 0 : 1)} mt={1}>
				<mui.Divider />
			</mui.Box>
		);
	}


	/**
	 * Get conversation from props.
	 *
	 * @return {ReactNode}
	 */
	get convo() {
		return this.props.convo;
	}


	/**
	 * Get contact's username.
	 *
	 * @return {String}
	 */
	get contact() {
		return this.convo.Contact;
	}


	/**
	 * Get mobile view.
	 *
	 * @return {Boolean}
	 */
	get mobile() {
		return (this.props.width === "xs");
	}


	/**
	 * Get class name for `Paper`.
	 *
	 * @return {string}
	 */
	get paperClassName() {
		return (this.props.active ? "" : this.props.classes.FadeOnHover);
	}


	/**
	 * Get whether to render in an "unread" state.
	 *
	 * @return {Boolean}
	 */
	get renderUnread() {
		return (!this.convo.Read && !this.props.active && !this.state.seen);
	}


	/**
	 * Styles.
	 *
	 * @return {Object}
	 */
	get style() {
		return {
			display: "flex",
			opacity: (this.props.active ? 0.25 : 1),
			padding: 0
		};
	}


	/**
	 * Styles for contact name.
	 *
	 * @return {Object}
	 */
	get stylesContact() {
		return {
			flexGrow: 1,
			fontWeight: (this.renderUnread ? "bold" : "normal")
		};
	}


	/**
	 * Styles for contact name box.
	 *
	 * @return {Object}
	 */
	get stylesContactBox() {
		return {
			alignItems: "center",
			display: "flex"
		};
	}


	/**
	 * Styles for message preview.
	 *
	 * @return {Object}
	 */
	get stylesMsg() {
		return {
			fontWeight: (this.renderUnread ? "bold" : "normal"),
			overflow: "hidden",
			textOverflow: "ellipsis",
			whiteSpace: "nowrap"
		};
	}


	/**
	 * Styles for timestamp.
	 *
	 * @return {Object}
	 */
	get stylesTime() {
		return {
			fontWeight: (this.renderUnread ? "bold" : "normal")
		};
	}

}

export default mui.withStyles(FadeOnHover)(
	mui.withWidth()(MessageConversationCard)
);
