import React from "react";
import UserAvatar from "Components/UserAvatar.js";
import rem from "Helpers/Rem.js";
import MessageHelper from "./MessageHelper.js";
import {connect} from "react-redux";
import {Box, Divider, Typography} from "@material-ui/core";

/**
 * Message thread chat message
 *
 * @package SEC
 * @subpackage Messages
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class MessageThreadChatMessage extends React.PureComponent {

	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Box my={2} style={this.styles}>
				<UserAvatar user={{Username: this.props.msg.Sender}} />
				<Box>
					<Box mb={0.25}>
						<Typography variant="caption">
							<span>{this.props.msg.Sender}</span>
							<span> &#8226; </span>
							<span>{this.timestamp}</span>
						</Typography>
					</Box>
					<Box style={this.stylesMessage}>
						{
							this.message.map((msg, k) => (
								<Typography key={k}>{msg}</Typography>
							))
						}
					</Box>
					<Divider />
				</Box>
			</Box>
		);
	}


	/**
	 * Message lines.
	 *
	 * @return {Array}
	 */
	get message() {
		return this.props.msg.Message.split("\n");
	}


	/**
	 * Get timestamp string for the message.
	 *
	 * @return {String}
	 */
	get timestamp() {
		return MessageHelper.getTimestampUIString(this.props.msg.Timestamp);
	}


	/**
	 * Styles.
	 *
	 * @return {Object}
	 */
	get styles() {
		return {
			display: "grid",
			gridAutoFlow: "column",
			gridColumnGap: rem(),
			justifyContent: "start"
		};
	}


	/**
	 * Styles for message.
	 *
	 * @return {Object}
	 */
	get stylesMessage() {
		return {
			display: "grid",
			gridRowGap: rem(0.5)
		};
	}

}

export default connect(({user}) => ({user}))(MessageThreadChatMessage);
