import React from "react";
import DayJsUtils from "@date-io/dayjs";
import ErrorView from "Views/ErrorView.js";
import F12 from "F12/F12.js";
import IndexedDB from "Helpers/IndexedDB.js";
import Post from "./Post.js";
import Router from "./Router.js";
import SnackbarHost from "Snackbars/SnackbarHost.js";
import Snackbars from "Snackbars/Notistack.json";
import Store from "./Store.js";
import Tasker from "./Tasker.js";
import ThemeProvider from "Providers/ThemeProvider.js";
import {Provider as ReduxProvider} from "react-redux";
import {SnackbarProvider} from "notistack";
import {CssBaseline, withStyles} from "@material-ui/core";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import * as Sentry from "@sentry/react";

/**
 * App
 * 
 * @package SEC
 * @subpackage App
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class App extends React.Component {

	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * State
		 *
		 * @type {Object}
		 */
		this.state = {

			/**
			 * App error
			 * 
			 * @type {Boolean}
			 */
			error: false

		};

	}


	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (!this.state.error ? this.renderApp() : <ErrorView />);
	}


	/**
	 * Render app.
	 * 
	 * @return {ReactNode}
	 */
	renderApp() {
		return (
			<Sentry.ErrorBoundary fallback={<ErrorView />}>
				<ReduxProvider store={Store}>
					<MuiPickersUtilsProvider utils={DayJsUtils}>
						<ThemeProvider>
							<CssBaseline />
							<SnackbarProvider {...this.notistackProps} {...Snackbars}>
								<F12 />
								<SnackbarHost />
								<Sentry.ErrorBoundary fallback={<ErrorView />}>
									<Router />
								</Sentry.ErrorBoundary>
							</SnackbarProvider>
						</ThemeProvider>
					</MuiPickersUtilsProvider>
				</ReduxProvider>
			</Sentry.ErrorBoundary>
		);
	}


	/**
	 * Component mounted.
	 * 
	 * @return {void}
	 */
	componentDidMount() {
		IndexedDB.verifyAvailability().then(() => {
			Post.dispatch();
			Tasker.init();
		}).catch(() => {
			this.setState({error: true});
		});
	}


	/**
	 * Component unmounting.
	 *
	 * @return {void}
	 */
	componentWillUnmount() {
		Tasker.detach();
	}


	/**
	 * Notistack configuration.
	 * 
	 * @reurn {Object}
	 */
	get notistackProps() {
		return {
			classes: {
				containerAnchorOriginBottomCenter: this.props.classes.notistackAlert
			}
		};
	}


	/**
	 * App-level CSS classes.
	 * 
	 * @type {Object}
	 */
	static classes = {
		notistackAlert: {
			zIndex: 10000000
		}
	};
}

export default withStyles(App.classes)(App);
