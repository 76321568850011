import React from "react";
import rem from "Helpers/Rem.js";
import AuthService from "Services/AuthService.js";
import Component from "App/Component.js";
import Loader from "Components/Loader.js";
import UserAvatar from "Components/UserAvatar.js";
import withSnackbar from "Hoc/withSnackbar.js";
import scss from "./AdminPermissionsTable.module.scss";
import {connect} from "react-redux";
import {AdminPermissionsTable as Strings} from "Resources/Strings.js";
import {Box, Checkbox, Tooltip, Typography} from "@material-ui/core";

/**
 * Admin permissions table
 *
 * We render a single customer's users at a time.
 *
 * @package SEC
 * @subpackage Views
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class AdminPermissionsTable extends Component {

	/**
	 * State
	 *
	 * @type {Object}
	 */
	state = {

		/**
		 * Assigned permissions
		 *
		 * User => array of permission names
		 *
		 * @type {Object}
		 */
		assigned: {},

		/**
		 * Pending permission changes
		 *
		 * Array of strings as `{username}.{permission}`.
		 *
		 * @type {Array}
		 */
		pending: [],

		/**
		 * Users
		 *
		 * @type {Array}
		 */
		users: [],

		/**
		 * Permissions
		 * 
		 * @type {Array}
		 */
		permissions: [],

		/**
		 * Error
		 *
		 * @type {Boolean}
		 */
		error: false,

		/**
		 * Loading users
		 * 
		 * @type {Boolean}
		 */
		loadingUsers: true,

		/**
		 * Loading permissions
		 *
		 * @type {Boolean}
		 */
		loadingPermissions: true

	};


	/**
	 * Mounted; get permissions.
	 *
	 * @return {void}
	 */
	componentDidMount() {
		this.updateUsers();
		this.updatePermissions();
	}


	/**
	 * Component updated.
	 *
	 * We may need to update users to match active customer.
	 *
	 * We may also need to update the active assignation pre-selections 
	 * and fire events to update listeners of the current loading status.
	 *
	 * @param {Object} prevProps
	 * @param {Object} prevState
	 * @return {void}
	 */
	componentDidUpdate(prevProps, prevState) {
		if (this.state.users !== prevState.users) {
			this.prepareAssignations();
		}
		if (this.props.customer !== prevProps.customer) {
			this.updateUsers();
		}
		if (this.loadingUpdated(this.state, prevState)) {
			this.props.onLoad();
		}
	}


	/**
	 * Get whether loading status has changed between two states.
	 *
	 * @param {Object} current
	 * @param {Object} previous
	 * @return {Boolean}
	 */
	loadingUpdated(current, previous) {
		const cur = (current.loadingUsers || current.loadingPermissions);
		const prev = (previous.loadingUsers || previous.loadingPermissions);
		return (!cur && prev);
	}


	/**
	 * Prepare assignation states for users.
	 *
	 * @return {void}
	 */
	prepareAssignations() {
		const assigned = {};
		this.state.users.forEach(({Username, permissions}) => {
			assigned[Username] = permissions;
		});
		this.setState({assigned});
	}


	/**
	 * Toggle state of a user's permission.
	 *
	 * @param {String} user Username
	 * @param {String} permission Permission name
	 * @return {void}
	 */
	toggle(user, permission) {

		/**
		 * Are we granting?
		 */
		let req;
		const grant = !this.state.assigned[user].includes(permission);

		/**
		 * Toggle the local states
		 */
		this.toggleState(user, permission);
		this.togglePending(user, permission);

		/**
		 * Make the network request
		 */
		if (grant) req = AuthService.grant(user, permission);
		else req = AuthService.revoke(user, permission);

		/**
		 * Await the network response
		 */
		req.catch(() => {
			this.toggleState(user, permission);
			this.props.snackbar(Strings.error, "error");
		}).finally(() => {
			this.togglePending(user, permission);
		});

	}


	/**
	 * Toggle the local assigned state of a user's permission.
	 *
	 * @param {String} user Username
	 * @param {String} permission Permission name
	 * @return {void}
	 */
	toggleState(user, permission) {
		let perms = this.state.assigned[user];
		if (!perms.includes(permission)) perms.push(permission);
		else perms = perms.filter(perm => (perm !== permission));
		this.setState({assigned: {...this.state.assigned, [user]: perms}});
	}


	/**
	 * Toggle the pending state of a user's permission.
	 *
	 * @param {String} user Username
	 * @param {String} permission Permission name
	 * @return {void}
	 */
	togglePending(user, permission) {
		let pending = this.state.pending;
		const pendingId = this.getPendingId(user, permission);
		if (!this.getIsPending(user, permission)) pending.push(pendingId);
		else pending = pending.filter(pending => (pending !== pendingId));
		this.setState({pending});
	}


	/**
	 * Update users.
	 *
	 * Customer ID is acquired from `customer` prop.`
	 * 
	 * @return {void}
	 */
	updateUsers() {
		this.props.onLoading();
		this.setState({loadingUsers: true});
		AuthService.getUsers(this.props.customer).then(users => {
			this.setState({users});
		}).catch(() => {
			this.setState({error: true});
		}).finally(() => {
			this.setState({loadingUsers: false});
		});
	}


	/**
	 * Update permissions.
	 *
	 * @return {void}
	 */
	updatePermissions() {
		this.props.onLoading();
		this.setState({loadingPermissions: true});
		AuthService.getPermissions().then(permissions => {
			this.setState({permissions});
		}).catch(() => {
			this.setState({error: true});
		}).finally(() => {
			this.setState({loadingPermissions: false});
		});
	}


	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		if (this.state.error) return this.renderError();
		else if (this.loading) return <Loader />;
		else return this.renderMain();
	}


	/**
	 * Render main.
	 *
	 * @return {ReactNode}
	 */
	renderMain() {
		return (
			<Box style={this.styles}>
				<div className={scss.header} style={this.constructor.stylesLabelFirst} />
				{this.renderMainContent()}
			</Box>
		);
	}


	/**
	 * Render the main content.
	 * 
	 * @return {ReactNode}
	 */
	renderMainContent() {
		if (this.users.length > 0) {
			return this.renderContent();
		}
		else return this.renderEmpty();
	}


	/**
	 * Render main.
	 * 
	 * @return {ReactNode}
	 */
	renderContent() {
		return (
			<React.Fragment>
				{this.renderUsers()}
				{this.renderPermissions()}
			</React.Fragment>
		);
	}


	/**
	 * Render error.
	 *
	 * @return {ReactNode}
	 */
	renderError() {
		return (
			<Typography style={this.constructor.stylesEmpty}>
				{Strings.empty}
			</Typography>
		);
	}


	/**
	 * Render empty state.
	 * 
	 * @return {ReactNode}
	 */
	renderEmpty() {
		return (
			<Typography style={this.constructor.stylesEmpty}>
				{Strings.emptyReal}
			</Typography>
		);
	}


	/**
	 * Render users header.
	 *
	 * @return {ReactNode}
	 */
	renderUsers() {
		return this.users.map((u, k) => {
			return (
				<div className={scss.header} key={k}>
					<UserAvatar
						style={this.constructor.stylesUser}
						user={u} />
				</div>
			);
		});
	}


	/**
	 * Render a checkbox for a user/permission.
	 *
	 * @param {String} user Username
	 * @param {String} perm Permission name
	 * @param {mixed} key React list key
	 * @return {ReactNode}
	 */
	renderCheckbox(user, perm, key) {
		if (this.shouldDisable(user, perm)) {
			return this.renderCheckboxControl(user, perm, key);
		}
		else return this.renderCheckboxControlTooltipped(user, perm, key);
	}


	/**
	 * Render a standalone checkbox control for a user/permission.
	 *
	 * @param {String} user Username
	 * @param {String} permission Permission name
	 * @param {mixed} key React list key
	 * @return {ReactNode}
	 */
	renderCheckboxControl(user, permission, key) {
		return (
			<Checkbox
				color="primary"
				key={key}
				style={this.constructor.stylesCheckbox}
				disabled={this.shouldDisable(user, permission)}
				checked={this.getIsAssigned(user, permission)}
				onChange={() => this.toggle(user, permission)} />
		);
	}


	/**
	 * Render checkbox control with tooltip.
	 *
	 * @param {String} user Username
	 * @param {String} permission Permission name
	 * @param {mixed} key React list key
	 * @return {ReactNode}
	 */
	renderCheckboxControlTooltipped(user, permission, key) {
		return (
			<Tooltip
				key={key}
				arrow={true}
				title={`${user} / ${permission}`}>
				{this.renderCheckboxControl(user, permission, key)}
			</Tooltip>
		);
	}


	/**
	 * Render permission checkboxes for a permission.
	 * 
	 * @param {Object} p Permission
	 * @return {ReactNode}
	 */
	renderCheckboxes(p) {
		return this.users.map(({Username}, k) => {
			return this.renderCheckbox(Username, p.permission, k);
		});
	}


	/**
	 * Render a permission's row of controls.
	 *
	 * @param {Object} p Permission
	 * @param {mixed} k React list key
	 * @return {ReactNode}
	 */
	renderPermission(p, k) {
		return (
			<React.Fragment key={k}>
				<Box style={this.constructor.stylesLabel}>
					<Tooltip title={p.description} placement="bottom-start">
						<Typography style={this.constructor.stylesLabelText}>
							{p.permission}
						</Typography>
					</Tooltip>
				</Box>
				{this.renderCheckboxes(p)}
			</React.Fragment>
		);
	}


	/**
	 * Render the table rows for the active permissions.
	 *
	 * Permissions which are not available to external users are 
	 * not rendered if any user in the active set of users is an 
	 * external user - this is currently a naieve implementation 
	 * with the assumption that all users in the set will be 
	 * external and so we can hide the permission entirely, without 
	 * having to handle how to render an "unavailable for user" state.
	 *
	 * @return {ReactNode}
	 */
	renderPermissions() {

		/**
		 * Get whether to enforce external-only
		 */
		const extUser = this.state.users.some(u => u.CustomerId !== null);
		const extPerms = this.state.permissions.some(p => p.external === true);

		/**
		 * We render empty state if there's nothing visible
		 */
		if (extUser && !extPerms) {
			return this.renderPermissionsEmpty();
		}
		else return this.renderPermissionsMain(extUser);

	}


	/**
	 * Render the permission rows for the current permission set.
	 *
	 * @param {Boolean} ext optional Hide internal-only permissions (`false`)
	 * @return {void}
	 */
	renderPermissionsMain(ext=false) {
		return this.state.permissions.map((p, k) => {
			if (!p.external && ext) return null;
			else return this.renderPermission(p, k);
		});
	}


	/**
	 * Render permissions when none available.
	 *
	 * @return {ReactNode}
	 */
	renderPermissionsEmpty() {
		return (
			<Typography style={this.constructor.stylesEmpty}>
				{Strings.emptyPermissions}
			</Typography>
		);
	}


	/**
	 * Get whether a user is assigned a permission.
	 *
	 * @param {String} user Username
	 * @param {String} permission Permission name
	 * @return {Boolean}
	 */
	getIsAssigned(user, permission) {
		if (!this.state.assigned[user]) return false;
		else return this.state.assigned[user].includes(permission);
	}


	/**
	 * Get whether a user's permission is currently in a pending state.
	 *
	 * This occurs while a network request is made to grant or revoke.
	 *
	 * @param {String} user Username
	 * @param {String} perm Permission name
	 * @return {Boolean}
	 */
	getIsPending(user, perm) {
		return this.state.pending.includes(this.getPendingId(user, perm));
	}


	/**
	 * Get the pending ID for a user/permission combination.
	 *
	 * This uniquely identifies pending permission state changes 
	 * within the `pending` array in state for later reference.
	 *
	 * @param {String} user Username
	 * @param {String} permission Permission name
	 * @return {void}
	 */
	getPendingId(user, permission) {
		return `${user}.${permission}`;
	}


	/**
	 * Get whether a permission control should be disabled for a user.
	 *
	 * We disable it if the permission is currently being toggled (network 
	 * request in-progress) or the user is the app's authenticated user 
	 * and the permission is the "sec.admin" permission - prevent users 
	 * from inadvertently revoking their own admin status!
	 * 
	 * @param {String} user Username
	 * @param {String} perm Permission name
	 * @return {Boolean}
	 */
	shouldDisable(user, perm) {
		if (this.getIsPending(user, perm)) return true;
		else return ((this.props.AppUser === user) && (perm === "sec.admin"));
	}


	/**
	 * Get count of columns to display.
	 *
	 * One per user, plus one for permission names.
	 *
	 * @return {Integer}
	 */
	get columns() {
		return (this.state.users.length + 1);
	}


	/**
	 * Loading.
	 * 
	 * @return {Boolean}
	 */
	get loading() {
		return (this.state.loadingUsers || this.props.loadingPermissions);
	}


	/**
	 * Get users sorted alphabetically.
	 *
	 * @return {Array}
	 */
	get users() {
		return this.state.users.sort((a, b) => {
			if (a.Username > b.Username) return 1;
			else if (a.Username < b.Username) return -1;
			else return 0;
		});
	}


	/**
	 * Styles.
	 *
	 * @return {Object}
	 */
	get styles() {
		return {
			display: "grid",
			gridColumnGap: rem(2),
			gridTemplateColumns: `repeat(${this.columns}, auto)`,
			gridRowGap: rem(),
			overflowX: "scroll",
			paddingBottom: rem()
		};
	}


	/**
	 * Styles for checkboxes.
	 *
	 * @type {Object}
	 */
	static stylesCheckbox = {
		height: rem(3),
		margin: "0 auto",
		width: rem(3)
	};

	/**
	 * Styles for empty state.
	 *
	 * @type {Object}
	 */
	static stylesEmpty = {
		gridColumn: "1 / -1",
		textAlign: "center"
	};

	/**
	 * Styles for permission labels.
	 *
	 * @type {Object}
	 */
	static stylesLabel = {
		alignItems: "center",
		background: "var(--base-colour)",
		display: "flex",
		left: 0,
		marginRight: rem(2),
		maxWidth: "50vw",
		position: "sticky",
		zIndex: 100
	};

	/**
	 * Styles for first label (top-left, empty header).
	 *
	 * @type {Object}
	 */
	static stylesLabelFirst = {
		marginRight: rem(2),
		maxWidth: "50vw",
		left: 0,
		zIndex: 110
	};

	/**
	 * Styles for permission labels (inner text).
	 * 
	 * @type {Object}
	 */
	static stylesLabelText = {
		overflow: "hidden",
		textOverflow: "ellipsis",
		whiteSpace: "nowrap"
	};

	/**
	 * User avatar styles
	 * 
	 * @type {Object}
	 */
	static stylesUser = {
		zIndex: 100
	};

	/**
	 * Map state to props.
	 *
	 * @param {Object} options.user App authenticated user
	 * @return {Object}
	 */
	static mstp({user}) {
		return {AppUser: user.Username};
	}

}

const apt = withSnackbar(AdminPermissionsTable);
export default connect(AdminPermissionsTable.mstp)(apt);
