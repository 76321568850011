import React from "react";
import Chip from "Components/Chip.js";
import TimesheetStatuses from "./TimesheetStatuses.json";

/**
 * Timesheet status chip
 * 
 * @package SEC
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class TimesheetStatusChip extends React.PureComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Chip
				colour={this.status?.colour}
				label={this.status?.label} />
		);
	}


	/**
	 * Get our status object.
	 *
	 * @return {Object}
	 */
	get status() {
		return TimesheetStatuses.find(s => (s.value === this.props.status));
	}

}

export default TimesheetStatusChip;
