import Dexie from "dexie";
import dispatchPost from "Dispatchers/Postable.js";

/**
 * Post
 *
 * @package SEC
 * @subpackage App
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class Post {

	/**
	 * Constructor .
	 *
	 * @return {self}
	 */
	constructor() {

		/**
		 * Database
		 *
		 * @type {Dexie}
		 */
		this.db = new Dexie("sec_db_post");

		/**
		 * Configure the database
		 */
		this.db.version(1).stores(this.constructor.stores);

	}


	/**
	 * Dispatch a postable update (asynchronous).
	 *
	 * @return {void}
	 */
	dispatch() {
		this.getPostable().then(postable => dispatchPost(postable.length));
	}


	/**
	 * Add an item.
	 *
	 * @param {String} type
	 * @param {mixed} data
	 * @param {Boolean} locked optional `false`
	 * @return {void}
	 */
	async add(type, data, locked=false) {
		locked = (locked ? 1 : 0);
		data._apid = Math.random().toString(36).substr(2, 16);
		await this.db.post.add({type, locked, data, apid: data._apid, error: false});
		this.dispatch();
	}


	/**
	 * Update an item's data.
	 *
	 * @param {String} id
	 * @param {mixed} data
	 * @return {void}
	 */
	async update(id, data) {
		data._apid = id;
		await this.put(id, {data, error: 0});
	}


	/**
	 * Get an item by APID.
	 *
	 * @param {String} id
	 * @return {Object|null}
	 */
	async get(id) {
		const items = await this.db.post.where("apid").equals(id).toArray();
		return (items[0] || null);
	}


	/**
	 * Get a set of items by type.
	 *
	 * @param {String} type
	 * @return {Array}
	 */
	async getType(type) {
		return await this.db.post.where("type").equals(type).toArray();
	}


	/**
	 * Get all postable items (they are not locked).
	 *
	 * @return {Array}
	 */
	async getPostable() {
		return await this.db.post.where("locked").equals(0).and(i => !i.error).toArray();
	}


	/**
	 * Modify an item.
	 *
	 * @param {String} id APID
	 * @param {Object} assign Properties to change
	 * @return {void}
	 */
	async put(id, assign) {
		const item = await this.get(id);
		await this.db.post.put(Object.assign(item, assign));
	}


	/**
	 * Delete.
	 *
	 * @param {String} id APID
	 * @return {void}
	 */
	async delete(id) {
		await this.db.post.where("apid").equals(id).delete();
		this.dispatch();
	}


	/**
	 * Clear.
	 *
	 * @return {void}
	 */
	async clear() {
		await this.db.post.clear();
		this.dispatch();
	}


	/**
	 * Lock.
	 *
	 * @param {String} id APID
	 * @return {void}
	 */
	async lock(id) {
		await this.put(id, {locked: 1});
		this.dispatch();
	}


	/**
	 * Unlock.
	 *
	 * @param {String} id APID
	 * @return {void}
	 */
	async unlock(id) {
		await this.put(id, {locked: 0});
		this.dispatch();
	}


	/**
	 * Stores.
	 *
	 * @type {Object}
	 */
	static stores = {post: "++id, &apid, type, locked, error"};

}

export default new Post();
