import React from "react";
import Component from "App/Component.js";
import Container from "Components/Containerx.js";
import IconButton from "Components/IconButton.js";
import Table from "./MaterialTableMaterialIcons.js";
import scss from "./MaterialTable.module.scss";
import throttle from "lodash.throttle";
import withSnackbar from "Hoc/withSnackbar.js";
import {MTableToolbar} from "material-table";

/**
 * Material table
 *
 * An abstraction of `material-table`.
 * 
 * @package SEC
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class MaterialTable extends Component {

	/**
	 * Ref to our container
	 *
	 * @type {ReactRef}
	 */
	containerRef = React.createRef();

	/**
	 * Ref to our table
	 *
	 * @type {ReactRef}
	 */
	tableRef = React.createRef();

	/**
	 * Resizing.
	 * 
	 * @type {Function|null}
	 */
	handleResize = null;


	/**
	 * Constructor.
	 * 
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * State
		 * 
		 * @type {Object}
		 */
		this.state = {

			/**
			 * Data
			 *
			 * @type {Array|null}
			 */
			data: null,

			/**
			 * Loading?
			 *
			 * @type {Boolean}
			 */
			loading: true,

			/**
			 * Page size
			 * 
			 * @type {Integer}
			 */
			pageSize: (props.pageSize || 10),

			/**
			 * Stacked?
			 * 
			 * @type {Boolean}
			 */
			stacked: this.stacked

		};

	}


	/**
	 * Mounted.
	 * 
	 * @return {void}
	 */
	componentDidMount() {
		this.updateCss();
		this.handleResize = throttle(this.updateCss, 50);
		window.addEventListener("resize", this.handleResize);
	}


	/**
	 * Updating.
	 *
	 * @param {Object} prevProps
	 * @return {void}
	 */
	componentDidUpdate(prevProps) {
		if (prevProps.filters !== this.props.filters) {
			if (this.tableRef.current) {
				this.tableRef.current.onQueryChange({page: 0});
			}
		}
		else if (prevProps.dataKey !== this.props.dataKey) {
			if (this.tableRef.current) {
				this.tableRef.current.onQueryChange();
			}
		}
	}


	/**
	 * Unmounting.
	 * 
	 * @return {void}
	 */
	componentWillUnmount() {
		super.componentWillUnmount();
		window.removeEventListener("resize", this.handleResize);
	}


	/**
	 * Page size changed.
	 *
	 * @param {Integer} pageSize
	 * @return {void}
	 */
	handlePageSizeChange = pageSize => this.setState({pageSize});


	/**
	 * Update table CSS.
	 * 
	 * @return {void}
	 */
	updateCss = () => {
		if (this.containerRef.current) {

			/**
			 * Stacked?
			 */
			const stacked = this.stacked;

			/**
			 * Update the state
			 *
			 * This operation causes Material Table to properly 
			 * update the `columns` (as our definition may 
			 * change due to changed stacking) - even though we 
			 * don't supply this value to the table, it works...
			 */
			if (stacked !== this.state.stacked) {
				this.setState({stacked});
			}

			/**
			 * Update the CSS
			 */
			this.containerRef.current.style.setProperty("--table-cell-border", (stacked ? 0 : "0.1rem"));
			this.containerRef.current.style.setProperty("--table-cell-justify-content", (stacked ? "center" : (this.state.data?.length ? "flex-start" : "center")));
			this.containerRef.current.style.setProperty("--table-cell-padding", (stacked ? "1.5rem" : "0.5rem"));
			this.containerRef.current.style.setProperty("--table-columns", (!stacked ? (this.state.data?.length ? this.columnWidths : "1 / -1") : "1fr"));
			this.containerRef.current.style.setProperty("--table-columns-head", (!stacked ? this.columnWidths : "1fr"));
			this.containerRef.current.style.setProperty("--table-flow", (!stacked ? "column" : "row"));
			this.containerRef.current.style.setProperty("--table-head-display", (stacked ? "none" : "initial"));

		}
	};


	/**
	 * Update data.
	 *
	 * Abstracts Material Table data fetching.
	 * 
	 * @param {Object} query
	 * @return {Promise}
	 */
	updateData = query => {

		/**
		 * Loading
		 */
		this.setState({loading: true});

		/**
		 * Make the query now
		 */
		return this.props.data(query).then(data => {

			const maxPage = Math.ceil(data.totalCount / query.pageSize);

			if (((data.page + 1) > maxPage) && maxPage) {
				query.page = (maxPage - 1);
				return this.updateData(query);
			}

			else {
				this.setState({data: data?.data});
				this.updateCss();
				window.scrollTo({top: 0});
				return data;
			}

		}).catch(e => {
			this.props.snackbar(e);
			this.setState({data: null});
			return {data: [], page: 0, totalCount: 0};
		}).finally(() => {
			this.setState({loading: false});
		});

	};


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<div
				className={scss.MaterialTable}
				ref={this.containerRef}>
				<Table
					{...this.props}
					components={this.components}
					columns={this.columns}
					data={this.updateData}
					isLoading={this.loading}
					localization={this.localization}
					onChangeRowsPerPage={this.handlePageSizeChange}
					options={this.options}
					tableRef={this.tableRef} />
			</div>
		);
	}


	/**
	 * Render actions.
	 *
	 * @param {Object} row
	 * @return {ReactNode}
	 */
	renderActions = row => {
		return (
			<Container
				columnar={true}
				disableAutoMobileRows={true}
				gap={0.5}
				gridAutoColumns="max-content">
				{
					this.props.action.map((action, key) => (
						<IconButton
							color={action.color}
							dims="2.5rem"
							disabled={this.loading}
							icon={action.icon}
							key={key}
							onClick={() => action.onClick(row)}
							size="small"
							tooltip={action.tooltip} />
					))
				}
			</Container>
		);
	};


	/**
	 * Render toolbar.
	 * 
	 * @param {Object} props
	 * @return {ReactNode}
	 */
	renderToolbar = props => {
		return (
			<Container gap="0rem" mb={1.5}>
				<MTableToolbar {...props} />
				<Container px={1}>
					{this.props.toolbar({disabled: this.loading})}
				</Container>
			</Container>
		);
	};


	/**
	 * Get the pixel width of the actions cell.
	 * 
	 * @return {Integer}
	 */
	get actionsCellWidth() {
		let width = 45;
		width += ((this.props.action?.length || 0) * 22.5);
		width += (((this.props.action?.length || 0) - 1) * 7.5);
		return width;
	}


	/**
	 * Components.
	 * 
	 * @return {Object}
	 */
	get components() {
		return {
			...this.props.components,
			Toolbar: (this.props.toolbar ? this.renderToolbar : undefined)
		};
	}


	/**
	 * Get our column definitions.
	 * 
	 * @return {Array}
	 */
	get columns() {
		const cols = this.props.columns({stacked: this.stacked});
		if (this.props.action) {
			cols.push({
				field: "_actions",
				title: "Actions",
				render: this.renderActions,
				gridWidth: `${(this.actionsCellWidth / 10)}rem`
			});
		}
		return cols.filter(c => !c.hidden);
	}


	/**
	 * Get column widths as a CSS string.
	 * 
	 * @return {String}
	 */
	get columnWidths() {
		return this.columns.map(c => c.gridWidth).join(" ");
	}


	/**
	 * Loading?
	 * 
	 * @type {Boolean}
	 */
	get loading() {
		return (this.state.loading || this.props.loading);
	}


	/**
	 * `localization` prop.
	 * 
	 * @return {Object}
	 */
	get localization() {
		return {
			...this.props.localization,
			body: {
				emptyDataSourceMessage: "-",
				...this.props.localization?.body
			},
			pagination: {
				labelDisplayedRows: (!this.state.data?.length ? "-" : "{from} - {to} of {count}"),
				labelRowsSelect: "items",
				...this.props.localization?.pagination
			}
		};
	}


	/**
	 * `options` prop.
	 *
	 * @return {Object}
	 */
	get options() {
		return {
			draggable: false,
			emptyRowsWhenPaging: false,
			padding: "dense",
			pageSize: this.state.pageSize,
			pageSizeOptions: this.pageSizeOptions,
			sorting: this.props.sorting,
			search: this.props.search,
			...this.props.options
		};
	}


	/**
	 * Get available page sizes.
	 * 
	 * @return {Array}
	 */
	get pageSizeOptions() {
		const pageSizeOptions = (this.props.pageSizeOptions || [10, 25, 50, 100]);
		if (!pageSizeOptions.includes(this.state.pageSize)) {
			pageSizeOptions.push(this.state.pageSize);
		}
		return pageSizeOptions.sort((a, b) => (a - b));
	}


	/**
	 * Get whether to use stacked layout.
	 * 
	 * We have to account for the drawer + view X-padding.
	 * 
	 * @return {Boolean}
	 */
	get stacked() {
		if (this.props.stackBreakpoint) {
			const stack = (this.props.stackBreakpoint + 240 + 60 + (this.props.action ? this.actionsCellWidth : 0));
			return window.matchMedia(`(max-width: ${stack}px)`).matches;
		}
		else return false;
	}

}

export default withSnackbar(MaterialTable);
