import React from "react";
import Component from "App/Component.js";
import Navigator from "App/Navigator.js";
import View from "App/View.js";
import Container from "Components/Container.js";
import DatePicker from "Components/DatePicker.js";
import DetailsCaption from "Components/DetailsCaption.js";
import Fab from "Components/Fab.js";
import RowLabelled from "Components/RowLabelled.js";
import TextField from "Components/TextField.js";
import mobile from "Helpers/Mobile.js";
import withSnackbar from "Hoc/withSnackbar.js";
import SpoiService from "../SpoiService.js";
import SpoiView from "./SpoiView.js";
import CisDetails from "../Atoms/SpoiCisDetails.js";
import Confirm from "../Components/SpoiPurchaseInvoiceRaiseConfirmDialog.js";
import Detail from "../Components/SpoiPurchaseInvoiceDetail.js";
import DomainHeader from "../Components/SpoiDomainHeader.js";
import ItemsTable from "../Components/SpoiPiItemsTable.js";
import PartialAlert from "../Components/SpoiPartiallyInvoicedAlert.js";
import PoInvalidDialog from "../Components/SpoiPoPiRaiseInvalidStatus.js";
import PoLink from "../Atoms/SpoiPoLink.js";
import RaiseFactory from "../Raises/SpoiPurchaseInvoiceRaiseFactory.js";
import StatusAlert from "../Components/SpoiPurchaseInvoiceStatusAlert.js";
import Strings from "../Resources/SpoiPurchaseInvoiceViewStrings.json";
import Totaller from "../Mixins/SpoiPurchaseInvoiceLineItemsTableTotals.js";
import * as mui from "@material-ui/core";

/**
 * SPOI purchase invoice view
 *
 * @package SEC
 * @subpackage Spoi
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class SpoiPurchaseInvoiceView extends Component {

	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * State
		 *
		 * @type {Object}
		 */
		this.state = {

			/**
			 * Accounts link
			 *
			 * @type {String}
			 */
			al: null,

			/**
			 * Purchase invoice
			 *
			 * @type {SpoiPurchaseInvoice|null}
			 */
			pi: null,

			/**
			 * Already raised PIs for our PO (when raising)
			 * 
			 * @type {Array|null}
			 */
			pis: null,

			/**
			 * Tax codes
			 * 
			 * @type {Array|null}
			 */
			tcs: null,

			/**
			 * Purchase invoice document
			 *
			 * @type {File|null}
			 */
			pdf: null,

			/**
			 * CIS
			 * 
			 * @type {Boolean}
			 */
			cis: false,

			/**
			 * Raise
			 *
			 * @type {SpoiPurchaseInvoiceRaise|null}
			 */
			raise: null,

			/**
			 * Confirm dialog
			 *
			 * @type {Boolean}
			 */
			confirming: false,

			/**
			 * Error state
			 *
			 * @type {Boolean}
			 */
			error: false,

			/**
			 * Raising PI against invalid PO dialog
			 * 
			 * @type {Boolean}
			 */
			invalid: false,

			/**
			 * Loading state
			 *
			 * @type {Boolean}
			 */
			loading: true,

			/**
			 * Submitting state
			 *
			 * @type {Boolean}
			 */
			submitting: false,

			/**
			 * SPOI configuration object
			 *
			 * @type {SpoiConfig}
			 */
			SpoiConfig: {}

		};

		/**
		 * Form
		 *
		 * @type {ReactRef}
		 */
		this.form = React.createRef();

		/**
		 * CIS raises when switched to standard
		 *
		 * (We can restore these if CIS toggle is then reverted.)
		 */
		this.raisesCis = null;

		/**
		 * Standard raises when switched to CIS
		 *
		 * (We can restore these if CIS toggle is then reverted.)
		 */
		this.raisesTcs = null;

		/**
		 * Assign binds
		 */
		this.raise = this.raise.bind(this);
		this.handlePdf = this.handlePdf.bind(this);
		this.handlePiInvoiceDate = this.handlePiInvoiceDate.bind(this);
		this.handlePiSupplierRef = this.handlePiSupplierRef.bind(this);
		this.toggleCis = this.toggleCis.bind(this);
		this.raiseHandle = this.raiseHandle.bind(this);
	}


	/**
	 * Component mounted.
	 *
	 * We have to get our resources ready for rendering.
	 *
	 * We invoke `init()` to make the network calls; this must return 
	 * a Promise which resolves with an array where the first element 
	 * is a `SpoiConfig` instance and the second is an object containing 
	 * the domains, as `pi` (purchase invoice if viewing), `po` (the purchase 
	 * order the PI is raised against) and `pis` (when raising a new invoice, 
	 * a collection of the existing PIs against the PO we're raising against).
	 * 
	 * @return {void}
	 */
	componentDidMount() {

		/**
		 * Get data
		 */
		Promise.all(this.init()).then(([config, objs]) => {

			/**
			 * Get ready!
			 */
			let raise;
			const ct = config.spoi_contact_username;
			const rok = SpoiService.poInvoicable(objs.po);
			const invoice = SpoiService.preparePi(config, objs.po, objs.pi);
			const al = SpoiService.getAdminMessagesUri(ct);

			/**
			 * Create a raise
			 */
			if (this.raising) {
				const tc = config.taxcode_default;
				const tcx = objs.tcs.find(t => (t.Id === tc));
				raise = RaiseFactory.construct(tcx);
			}

			/**
			 * Set the state
			 */
			this.setState({
				al,
				pi: invoice,
				pis: objs.pis,
				tcs: objs.tcs,
				raise,
				SpoiConfig: config,
				invalid: (this.raising ? !rok : false)
			});

		}).catch(() => {
			this.setState({error: true});
		}).finally(() => {
			this.setState({loading: false});
		});

	}


	/**
	 * Initialise.
	 * 
	 * Returns an array of promises which resolve with our domain objects.
	 * 
	 * @return {Array} Refer also to `componentDidMount()`.
	 */
	init() {
		return [SpoiService.getConfig(), this.initObjects()];
	}


	/**
	 * Initialise domain objects.
	 *
	 * Returns a Promise which resolves with our domain objects.
	 *
	 * @return {Promise} Refer also to `componentDidMount()`/`init()`
	 */
	initObjects() {
		if (!this.raising) {
			return this.initObjectsStatic();
		}
		else return this.initObjectsRaising();
	}


	/**
	 * Get our domain objects when we're raising a new PI.
	 *
	 * Returns a Promise which resolves with our domain objects.
	 *
	 * The purchase order is prepared via `SpoiService` for reference.
	 * 
	 * @return {Promise} Refer also to `componentDidMount()`/`init()`
	 */
	initObjectsRaising() {
		return SpoiService.getPOWithPIs(this.uriPoid).then(popis => {
			return SpoiService.getTaxCodes().then(tcs => {
				const po = SpoiService.preparePo(popis.po);
				return {...popis, po, tcs};
			});
		});
	}


	/**
	 * Get our domain objects when we're rendering an existing PI.
	 *
	 * Returns a Promise which resolves with our domain objects.
	 *
	 * The purchase order is prepared via `SpoiService` for reference.
	 * 
	 * @return {Promise} Refer also to `componentDidMount()`/`init()`
	 */
	initObjectsStatic() {
		return SpoiService.getPI(this.uriUuid).then(pi => {
			return SpoiService.getPO(pi.poid).then(po => {
				return {pi, po: SpoiService.preparePo(po)};
			});
		});
	}


	/**
	 * Raise the current purchase invoice.
	 *
	 * This is a two-step process: first we raise the invoice(s), then 
	 * we attach the document in a second call, once the UUIDs are known, 
	 * and thus ensuring the invoices are saved even if the document 
	 * upload fails for any reason.
	 *
	 * @param {Boolean} cpo optional Mark PO `Complete` (`false`)
	 * @return {void}
	 */
	raise(cpo=false) {

		/**
		 * Get ready!
		 */
		const pi = RaiseFactory.getApiRaise(this.state.raise);
		const cis = this.state.cis;
		const doc = this.state.pdf;
		this.setState({submitting: true});

		/**
		 * Raise the PI
		 */
		SpoiService.raisePI(this.state.pi.po.Id, pi, cis, cpo).then(uuids => {
			return SpoiService.uploadPIDocument(uuids, doc).then(() => {
				this.props.snackbar(Strings.raise.success, "success");
			}).catch(() => {
				this.props.snackbar(Strings.raise.successWithoutDoc, "warning");
			}).finally(() => {
				Navigator.navigate(`/spoi/invoices`);
			});
		}).catch(() => {
			this.props.snackbar(Strings.raise.error, "error");
		}).finally(() => {
			this.setState({submitting: false, confirming: false});
		});

	}


	/**
	 * Handle a user request to raise the invoice.
	 *
	 * We commence the process here by verifying form validity 
	 * and then displaying the confirmingation prompt; refer to the 
	 * confirmingation prompt's props to understand what happens next.
	 *
	 * @return {void}
	 */
	raiseHandle() {

		if (!this.form.current.reportValidity()) {
			return;
		}

		if (this.isCis && (this.state.raise?.Items?.total === 0)) {
			this.props.snackbar("Please enter a Labour and/or Materials cost.", "error");
			return;
		}

		this.setState({confirming: true});

	}


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<SpoiView err={this.state.error} loading={this.state.loading}>
				{this.renderHeader()}

				<form ref={this.form}>
					<View child={true} px={(this.mobile ? 0 : 1)} py={0}>
						<StatusAlert uri={this.state.al} pi={this.state.pi} />
						<Detail pi={this.state.pi} />

						<mui.Divider />

						<RowLabelled
							label={Strings.inputs.reference.label}
							noPy={true}>
							<TextField
								disabled={!this.raising}
								helperText={Strings.inputs.reference.caption}
								label={Strings.inputs.reference.label}
								onChange={this.handlePiSupplierRef}
								required={true}
								value={this.state.pi?.SupplierInvoiceRef} />
						</RowLabelled>
						<RowLabelled
							label={Strings.inputs.date.label}
							noPy={true}>
							<DatePicker
								disabled={!this.raising}
								enableFuture={true}
								helperText={Strings.inputs.date.caption}
								max={this.InvoiceDateMax}
								min={this.InvoiceDateMin}
								onChange={this.handlePiInvoiceDate}
								value={this.state.pi?.InvoiceDate} />
						</RowLabelled>
						<RowLabelled
							label={Strings.inputs.datedue.label}
							noPy={true}>
							<Container af="row" fullWidth={true}>
								<DatePicker
									disabled={true}
									enableFuture={true}
									fullWidth={true}
									helperText={Strings.inputs.datedue.caption}
									value={this.state.pi?.DueDateCurrent} />
								{this.shouldRenderDdpar ? this.renderDdpar() : null}
							</Container>
						</RowLabelled>
						<RowLabelled
							label={Strings.inputs.cis.label}
							noPy={true}>
							<mui.Box>
								<mui.Box mb={(this.raising ? 0.5 : 0)}>
									<mui.Checkbox
										color="primary"
										checked={this.isCis}
										disabled={!this.raising}
										onChange={this.toggleCis} />
								</mui.Box>
								{this.raising ? <CisDetails /> : null}
							</mui.Box>
						</RowLabelled>
						{this.raising ? this.renderPdfPicker() : null}

						<mui.Divider />

						{this.renderLineItemsTable()}
						{this.raisingPartial ? <PartialAlert /> : null}
					</View>
				</form>

				<PoInvalidDialog open={this.state.invalid} />
				{this.raising ? <Fab onClick={this.raiseHandle} /> : null}
				{this.state.confirming ? this.renderRaiseConfirm() : null}
			</SpoiView>
		);
	}


	/**
	 * Render agreed due dates.
	 * 
	 * @return {ReactNode}
	 */
	renderDdpar() {
		return (
			<DetailsCaption
				caption={this.strings.ddpar.warning}
				label={this.strings.ddpar.label}
				open={!this.raising}>
				<DatePicker
					clearable={true}
					disabled={!this.raising}
					disablePast={true}
					emptyLabel="None"
					enableFuture={true}
					onChange={this.handlePiDdpar.bind(this)}
					value={this.state.pi?.DueDatePreagreedRequested} />
			</DetailsCaption>
		);
	}


	/**
	 * Render the view header.
	 *
	 * @return {ReactNode}
	 */
	renderHeader() {
		if (!this.raising) {
			return this.renderHeaderStatic();
		}
		else return this.renderHeaderRaising();
	}


	/**
	 * Render the view header when raising an invoice.
	 *
	 * @return {ReactNode}
	 */
	renderHeaderRaising() {
		return (
			<mui.Typography variant="h5">
				{Strings.label_raising}
			</mui.Typography>
		);
	}


	/**
	 * Render the view header when viewing an existing invoice.
	 * 
	 * @return {ReactNode}
	 */
	renderHeaderStatic() {
		return (
			<DomainHeader
				id={<PoLink noTypography={true} poid={this.state.pi?.poid} />}
				label={Strings.label}
				status={this.state.pi?.Status} />
		);
	}


	/**
	 * Render the line items table.
	 *
	 * @return {ReactNode}
	 */
	renderLineItemsTable() {
		return (
			<ItemsTable
				canAdd={(this.tcsAvailable.length > 0)}
				cis={this.isCis}
				disabledTaxCodes={this.state.raise?.Items.usedTaxCodes}
				editable={this.raising}
				lis={(this.state.raise?.Items || this.state.pi?.Items)}
				onAdd={this.addItem.bind(this)}
				onUpdate={Items => this.updatePi({Items})}
				totals={(this.raising ? this.renderTotals() : null)} />
		);
	}


	/**
	 * Render the document uploader.
	 * 
	 * @return {ReactNode}
	 */
	renderPdfPicker() {
		return (
			<RowLabelled label={Strings.inputs.document.label} noPy={true}>
				<input
					accept=".pdf,application/pdf"
					onChange={this.handlePdf.bind(this)}
					required={true}
					title={Strings.inputs.document.tooltip}
					type="file" />
			</RowLabelled>
		);
	}


	/**
	 * Render the raise confirmation dialog.
	 *
	 * @return {ReactNode}
	 */
	renderRaiseConfirm() {
		return (
			<Confirm
				loading={this.state.submitting}
				onClose={() => this.setState({confirming: false})}
				onOk={this.raise}
				open={this.state.confirming}
				toleranceExceeded={!this.raisable} />
		);
	}


	/**
	 * Render the total rows for the line items table.
	 * 
	 * @return {ReactNode}
	 */
	renderTotals() {
		return Totaller(
			this.state.pi?.PurchaseOrder,
			this.state.pis,
			this.state.raise?.Items
		);
	}


	/**
	 * Handle changing PI document selection.
	 *
	 * @param {Event} e
	 * @return {void}
	 */
	handlePdf(e) {
		this.setState({pdf: (e.target.files[0] || null)});
	}


	/**
	 * Handle changing PI agreed due date.
	 *
	 * @param {String|null} DueDatePreagreedRequested YYYY-MM-DD
	 * @return {void}
	 */
	handlePiDdpar(DueDatePreagreedRequested) {
		this.updatePi({DueDatePreagreedRequested});
	}


	/**
	 * Handle changing PI invoice date.
	 *
	 * @param {String|null} InvoiceDate YYYY-MM-DD
	 * @return {void}
	 */
	handlePiInvoiceDate(InvoiceDate) {
		this.updatePi({InvoiceDate});
	}


	/**
	 * Handle changing the supplier's reference.
	 *
	 * @param {String} SupplierInvoiceRef
	 * @return {void}
	 */
	handlePiSupplierRef(SupplierInvoiceRef) {
		this.updatePi({SupplierInvoiceRef});
	}


	/**
	 * Toggle the PI CIS status.
	 * 
	 * @param {Event} e
	 * @return {void}
	 */
	toggleCis(e) {

		/**
		 * Items
		 */
		let Items;

		/**
		 * CIS?
		 */
		const Cis = e.target.checked;

		/**
		 * Cache things
		 */
		const Current = this.state.raise.Items;
		const po = this.state.pi.PurchaseOrder;
		const pis = this.state.pis;

		/**
		 * Work out update
		 */
		if (Cis) {
			const tc = this.tcCis;
			this.raisesTcs = Current;
			Items = (this.raisesCis || RaiseFactory.constructItemsCis(tc));
		}
		else {
			this.raisesCis = Current;
			Items = (this.raisesTcs || RaiseFactory.constructItems(po, pis));
		}

		/**
		 * Commit the update
		 */
		this.updatePi({Items});
		this.setState({cis: Cis});

	}


	/**
	 * Update the properties of the PI raise.
	 * 
	 * @param {Object} props Properties to assign
	 * @return {void}
	 */
	updatePi(props) {
		Object.assign(this.state.pi, props);
		Object.assign(this.state.raise, props);
		this.setState({pi: this.state.pi, raise: this.state.raise});
	}


	/**
	 * Add a new item using the first available tax code.
	 *
	 * There is no verification that there is an available tax code!
	 * 
	 * @return {void}
	 */
	addItem() {
		const tc = this.tcsAvailable[0];
		this.state.raise.Items.add(RaiseFactory.constructItem(0, tc));
		this.setState({raise: this.state.raise});
	}


	/**
	 * Get the maximum possible invoice date from config.
	 *
	 * This is `null` when not raising - otherwise, the date picker 
	 * may render an error if we're viewing an invoice with a date 
	 * which has passed...
	 * 
	 * @return {String} YYYY-MM-DD `null` if not raising
	 */
	get InvoiceDateMax() {
		const InvoiceDateMax = this.state.SpoiConfig.InvoiceDateMax;
		return (this.raising ? InvoiceDateMax : null);
	}


	/**
	 * Get the minimum possible invoice date from config.
	 *
	 * This is `null` when not raising - otherwise, the date picker 
	 * may render an error if we're viewing an invoice with a date 
	 * which has passed...
	 *
	 * @return {String} YYYY-MM-DD `null` if not raising
	 */
	get InvoiceDateMin() {
		const InvoiceDateMin = this.state.SpoiConfig.InvoiceDateMin;
		return (this.raising ? InvoiceDateMin : null);
	}


	/**
	 * Get whether, in our current state, our purchase invoice 
	 * is currently raisable against its purchase order, when 
	 * summed with the cumulative total of all the other purchase 
	 * invoices we're aware of (which are assumed to have been 
	 * raised against the same purchase order).
	 *
	 * @return {Boolean}
	 */
	get raisable() {
		if (this.state.pi) {
			const po = this.state.pi.po;
			const pis = this.state.pis;
			const invoicable = SpoiService.getInvoicableValue(po, pis);
			return (this.state.raise.Items.total <= invoicable);
		}
		else return false;
	}


	/**
	 * Get whether we are raising a new invoice.
	 *
	 * We always try to raise an invoice when we routed to the view 
	 * having been a purchase order ID (as opposed to a PI UUID).
	 *
	 * @return {Boolean}
	 */
	get raising() {
		return (!!this.uriPoid);
	}


	/**
	 * Get whether we are raising an invoice and we already 
	 * have partial invoices raised against our purchase order.
	 *
	 * @return {Boolean}
	 */
	get raisingPartial() {
		if (!this.state.pis) return false;
		else return (this.raising && this.state.pis.length);
	}


	/**
	 * Get whether to render the agreed due date components.
	 * 
	 * @return {Boolean}
	 */
	get shouldRenderDdpar() {
		return (this.raising || this.state.pi?.DueDatePreagreedRequested);
	}


	/**
	 * Get available tax codes which aren't already used.
	 * 
	 * @return {Array}
	 */
	get tcsAvailable() {
		const tcs = this.state.raise?.Items.usedTaxCodes;
		if (!this.state.tcs) return [];
		else return this.state.tcs.filter(tc => !tcs.includes(tc.Id));
	}


	/**
	 * Get the tax code for CIS invoices.
	 *
	 * @return {Object}
	 */
	get tcCis() {
		const tc = this.state.SpoiConfig.taxcode_cis;
		return this.state.tcs.find(t => (t.Id === tc));
	}


	/**
	 * Get the purchase order ID to raise against from the URI.
	 *
	 * This OR `uriUuid` should be defined depending on if we're raising.
	 * 
	 * @return {String|null}
	 */
	get uriPoid() {
		return this.props.match.params.poid;
	}


	/**
	 * Get the purchase invoice UUID to render from the URI.
	 *
	 * This OR `uriUuid` should be defined depending on if we're raising.
	 * 
	 * @return {String|null}
	 */
	get uriUuid() {
		return this.props.match.params.uuid;
	}


	/**
	 * Get whether to render in CIS state.
	 *
	 * @return {Boolean}
	 */
	get isCis() {
		return (this.state.cis || this.state.pi?.CisOrCisItems);
	}


	/**
	 * Get whether we've a mobile viewport.
	 *
	 * @return {Boolean}
	 */
	get mobile() {
		return mobile(this.props.width);
	}


	/**
	 * Active string overrides.
	 * 
	 * @return {Object}
	 */
	get strings() {
		return {
			ddpar: {
				label: (this.raising ? Strings.inputs.ddpar.label : Strings.inputs.ddpar.labelv),
				warning: (this.raising ? Strings.inputs.ddpar.warning : Strings.inputs.ddpar.warningv)
			}
		};
	}

}

export default withSnackbar(mui.withWidth()(SpoiPurchaseInvoiceView));
