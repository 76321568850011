import React from "react";
import Link from "Components/Link.js";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {Typography} from "@material-ui/core";

/**
 * SPOI purchase order link
 * 
 * Please refer to the source for details of all the available props.
 * 
 * @package SEC
 * @subpackage Spoi
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class SpoiPoLink extends React.PureComponent {

	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (this.linkable ? this.renderLink() : this.renderCaption());
	}


	/**
	 * Render the caption.
	 *
	 * @return {ReactNode}
	 */
	renderCaption() {
		if (!this.props.noTypography) {
			return (
				<Typography variant={this.props.variant}>
					{this.props.poid}
				</Typography>
			);
		}
		else return this.props.poid;
	}


	/**
	 * Render the link.
	 *
	 * @return {ReactNode}
	 */
	renderLink() {
		return <Link uri={this.uri}>{this.renderCaption()}</Link>;
	}


	/**
	 * Get if we have permission to render as a link.
	 * 
	 * @return {Boolean}
	 */
	get linkable() {
		return this.props.permissions.includes("sec.spoi.GetPurchaseOrders");
	}


	/**
	 * Get the URI to link to.
	 *
	 * @return {String}
	 */
	get uri() {
		return `/spoi/orders/${this.props.poid}`;
	}

}

export default connect(({permissions}) => ({permissions}))(withRouter(SpoiPoLink));
