import React from "react";
import rem from "Helpers/Rem.js";
import withMobile from "Hoc/withMobile.js";
import Styles from "Resources/Styles.json";
import {Box} from "@material-ui/core";

/**
 * View
 *
 * The base view class to contain primary app screens.
 *
 * This provides a default row-based layout for screen content.
 * 
 * Please refer to the source to obtain details of the available props 
 * and the options for customising the component to suit different scenarios.
 *
 * @package SEC
 * @subpackage App
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class View extends React.PureComponent {

	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Box
				ml={(this.props.isMobile || this.props.child) ? 0 : Styles.drawer}
				p={this.padding}
				px={this.props.px}
				py={this.props.py}
				pb={this.props.pb}
				pt={this.props.pt}
				style={this.styles}>
				{this.props.children}
			</Box>
		);
	}


	/**
	 * Padding multiplier.
	 *
	 * @return {Number}
	 */
	get padding() {
		if (this.props.p !== undefined) return this.props.p;
		else return ((this.props.isMobile || this.props.child) ? 1 : 2);
	}


	/**
	 * Styles.
	 *
	 * @return {Object}
	 */
	get styles() {
		return {
			alignContent: (this.props.centred ? "center" : "flex-start"),
			display: "grid",
			direction: "row",
			gridRowGap: rem(2),
			gridTemplateColumns: "100%",
			gridTemplateRows: this.stylesTemplateRows,
			minHeight: this.stylesMinHeight,
			...this.props.style
		};
	}


	/**
	 * Styles (minimum height).
	 * 
	 * @return {String}
	 */
	get stylesMinHeight() {
		if (this.props.hasOwnProperty("minHeight")) {
			return this.props.minHeight;
		}
		else if (this.props.fullHeight) {
			return `calc(100vh - ${Styles.bar})`;
		}
		else return null;
	}


	/**
	 * Styles (template rows).
	 * 
	 * @return {String}
	 */
	get stylesTemplateRows() {
		const fh = (this.props.fullHeight || this.props.fullHeightRows);
		return (this.props.rows ? this.props.rows : (fh ? "100%" : null));
	}

}

export default withMobile(View);
