import api from "api.js";
import Checkbox from "Components/Checkbox.js";
import Divider from "@material-ui/core/Divider";
import EmailPickerContacts from "Components/EmailPickerContacts.js";
import Fab from "Components/Fab.js";
import Flex from "Components/Flexx.js";
import Grid from "Components/Grid/Grid.js";
import HtmlTemplateEditor from "Components/HtmlTemplateEditor/HtmlTemplateEditor.js";
import Loadable from "Components/LoadableComponent.js";
import ProjectPicker from "Components/ProjectPicker.js";
import Row from "Components/Grid/Row.js";
import RowFw from "Components/Grid/RowFw.js";
import SubmissionDialog from "./ItpSubmissionDialog.js";
import Text from "Components/Text.js";
import View from "Views/UiView.js";
import initialState from "./ItpView.state.js";
import strings from "./ItpView.strings.json";
import useData from "Hooks/useData.js";
import useToggle from "Hooks/useToggle.js";
import withSnackbar from "Hoc/withSnackbar.js";
import {useCallback, useEffect, useRef, useState} from "react";
import {Select} from "@heron-web/material";

export default withSnackbar(props => {

	const {snackbar: snack} = props;
	const emailTemplateHtml = useRef();

	const [data, setData] = useState({...initialState});

	const [emailPlaceholders, setEmailPlaceholders] = useState({});
	const [isSubmitting, setIsSubmitting] = useState(false);
	const submissionDialogOpen = useToggle();


	/**
	 * Get the quotations list for the selected Project
	 */
	const quotationsData = useData(useCallback(
		() => {
			if (data.Project) {
				return api.call({
					url: `/api/quotations`,
					params: {

						/**
						 * Only get this Project's quotations
						 */
						Project: data.Project.Id,

						/**
						 * Quotation IDs prefixed with `CN` are for 
						 * internal use only and not wanted in this 
						 * context - see note on sec0419#1
						 */
						ExcludeCnQuoteIds: true

					}
				}).then(({data}) => data.reverse());
			}
			else return null;
		},
		[data.Project]
	));


	/**
	 * Value changed.
	 *
	 * @param {mixed} value
	 * @param {String} name
	 * @return {void}
	 */
	const handleChange = useCallback((value, name) => {
		setData({
			...data,
			[name]: value
		});
	}, [data]);


	/**
	 * PDF file selection changed.
	 *
	 * @param {Event} e
	 * @return {void}
	 */
	const handleChangePdfFile = useCallback(e => {
		setData({
			...data,
			PdfFile: e.target.files?.[0]
		});
	}, [data]);


	/**
	 * Handle action button clicks to stop them propagating 
	 * to the email preview pane below them.
	 *
	 * @param {Event} e
	 * @return {void}
	 */
	const handleFab = useCallback(e => {
		e.stopPropagation();
	}, []);


	/**
	 * Submitting.
	 * 
	 * @async
	 * @return {void}
	 */
	const handleSubmit = useCallback(async () => {

		setIsSubmitting(true);

		/**
		 * Submit now!
		 */
		try {

			const fd = new FormData();
			fd.append("Project", data.Project.Id);
			fd.append("Quotation", data.Quotation.Id);
			fd.append("PdfFile", data.PdfFile);
			fd.append("MainRecipient", data.MainRecipient);
			fd.append("AdditionalRecipients", data.AdditionalRecipients.join(","));
			fd.append("EmailHtml", data.EmailHtml);
			fd.append("SendDocusignRequest", (data.SendDocusignRequest ? 1 : 0));

			await api.call({
				url: `/api/itp`,
				method: "POST",
				data: fd,
				headers: {"Content-Type": "multipart/form-data"}
			});

			setData({
				...initialState,
				PdfFile: data.PdfFile,
				EmailHtml: emailTemplateHtml.current
			});
			setEmailPlaceholders({});
			submissionDialogOpen.setFalse();

			window.scrollTo({top: 0});
			snack("ITP sent successfully.", "success");

		}
		catch (e) {

			const response = e?.response;
			const data = response?.data;

			if (response?.status === 400) {
				snack(`Submission error. Please check your inputs and try again.\nServer message: ${(data || "(None)")}`, "error");
			}
			else if (response?.status === 500) {
				if (data?.reason === "pdf") {
					snack(`PDF quotation document upload error.\nPlease try again.`, "error");
				}
				else if (data?.reason === "email") {
					snack(`Error sending the ITP email.\nServer message: ${(data?.data || "(None)")}`, "error");
				}
				else if (data?.reason === "docusign") {
					snack(`ITP email sent successfully, but there was an error sending the DocuSign request.\nServer message: ${(data?.data || "(None)")}`, "warning");
				}
				else snack(e);
			}
			else snack(e);

		}

		setIsSubmitting(false);

	}, [data, submissionDialogOpen, snack]);


	/**
	 * Submitting.
	 *
	 * @param {Event} e
	 * @return {void}
	 */
	const handleSubmitting = useCallback(e => {

		e.preventDefault();

		submissionDialogOpen.setTrue();

	}, [submissionDialogOpen]);


	/**
	 * Reset related fields when the selected Project changes
	 */
	useEffect(() => {

		const businessArea = data.Project?.BusinessArea?.toLowerCase?.();
		let businessAreaWebsiteLink = "https://www.sec-online.co.uk";

		if (businessArea === "storage") {
			businessAreaWebsiteLink = "https://www.sec-storage.co.uk";
		}
		else if (businessArea === "interiors") {
			businessAreaWebsiteLink = "https://www.sec-interiors.co.uk";
		}

		setData(data => ({
			...data,
			MainRecipient: null,
			Quotation: null,
			AdditionalRecipients: []
		}));

		setEmailPlaceholders(
			emailPlaceholders => (
				emailPlaceholders.tcs_link ?
					{
						...emailPlaceholders,
						tcs_link: {
							...emailPlaceholders.tcs_link,
							value: businessAreaWebsiteLink
						}
					} :
					emailPlaceholders
			)
		);

	}, [data.Project]);


	/**
	 * Automatically select the latest Quotation when new records are loaded
	 */
	useEffect(() => {
		setData(data => ({
			...data,
			Quotation: quotationsData.data?.[0]
		}));
	}, [quotationsData.data]);


	/**
	 * Reset the recipient's name in the email template when changed
	 */
	useEffect(() => {
		setEmailPlaceholders(
			emailPlaceholders => (
				emailPlaceholders.recipient ?
					{
						...emailPlaceholders,
						recipient: {
							...emailPlaceholders.recipient,
							value: (data.MainRecipient?.ContactName?.split?.(" ")?.[0] || "Recipient")
						}
					} :
					emailPlaceholders
			)
		);
	}, [data.MainRecipient]);


	/**
	 * Reset the DocuSign string in the email template when checkbox changed
	 */
	useEffect(() => {
		setEmailPlaceholders(
			emailPlaceholders => (
				emailPlaceholders.docusign_instruction ?
					{
						...emailPlaceholders,
						docusign_instruction: {
							...emailPlaceholders.docusign_instruction,
							value: (data.SendDocusignRequest ? strings.SendDocusignRequest.yes : strings.SendDocusignRequest.no)
						}
					} :
					emailPlaceholders
			)
		);
	}, [data.SendDocusignRequest]);


	/**
	 * Load the email content
	 */
	useEffect(() => {

		(async () => {

			let email;

			try {
				const data = await api.call({url: "/emails/templates/ItpEmail.html"});
				email = data.data;
			}
			catch (e) {
				email = "Error loading email template.";
			}

			emailTemplateHtml.current = email;
			setData(data => ({...data, EmailHtml: email}));

		})();


	}, []);


	/**
	 * Render!
	 */
	return (
		<View
			title="Instruction to Proceed">
			<form onSubmit={handleSubmitting}>
				<Flex gap={2}>
					<Flex>
						<Text>Issue an Instruction to Proceed (ITP) document to a customer.</Text>
						<Text>The instruction will be sent to the customer by email, optionally with a DocuSign signing request.</Text>
					</Flex>
					<Loadable
						loading={(data.EmailHtml === undefined)}
						loaderSize={30}
						loaderStyles={{alignSelf: "center"}}>
						<Grid>
							<Row
								label="Project"
								labelStyle={{alignSelf: "center"}}>
								<ProjectPicker
									autoFocus={true}
									disabled={isSubmitting}
									name="Project"
									onChange={handleChange}
									required={true}
									useNewChangeHandler={true}
									value={data.Project} />
							</Row>
							<Row
								label="Quotation">
								<Select
									disabled={isSubmitting}
									label="Quotation"
									loading={quotationsData.loading}
									loadingError={!!quotationsData.error}
									name="Quotation"
									noOptionsMessage={(!data.Project ? "Please select a Project to view the available Quotations." : "No quotations available.")}
									onChange={handleChange}
									options={
										(quotationsData.data || []).map(quotation => {
											return {
												id: quotation.Id,
												label: `${quotation.Id} (${quotation.Revision})`,
												value: quotation
											};
										})
									}
									required={true}
									value={data.Quotation?.Id}
									variant="standard" />
							</Row>
							<Row
								label="Quotation Document (PDF)">
								<input
									accept=".pdf,application/pdf"
									disabled={isSubmitting}
									onChange={handleChangePdfFile}
									required={true}
									type="file" />
							</Row>
							<Row
								label="Main Recipient">
								<Flex gap={0.5}>
									<EmailPickerContacts
										customer={data.Project?.Customer?.Id}
										disabled={isSubmitting}
										name="MainRecipient"
										onChange={handleChange}
										required={true}
										reportObjectValues={true}
										singular={true}
										value={data.MainRecipient} />
									<Text variant="caption">
										This contact will be the To address on the ITP email; they will also receive the DocuSign request, when enabled below.
									</Text>
								</Flex>
							</Row>
							<Row
								label="Additional Recipients">
								<Flex gap={0.5}>
									<EmailPickerContacts
										customer={data.Project?.Customer?.Id}
										disabled={isSubmitting}
										name="AdditionalRecipients"
										onChange={handleChange}
										value={data.AdditionalRecipients} />
									<Text variant="caption">
										These contacts will be included as CC addresses on the ITP email, but will not receive or sign the DocuSign request.
									</Text>
								</Flex>
							</Row>
							<Row
								label="Send DocuSign signing request?">
								<Checkbox
									checked={data.SendDocusignRequest}
									disabled={isSubmitting}
									name="SendDocusignRequest"
									onChange={handleChange}
									useNewChangeHandler={true} />
							</Row>
							<RowFw>
								<Divider />
							</RowFw>
							<RowFw>
								<HtmlTemplateEditor
									disabled={isSubmitting}
									label="Email preview"
									name="EmailHtml"
									onChange={handleChange}
									onChangePlaceholders={setEmailPlaceholders}
									placeholders={emailPlaceholders}
									showPlaceholdersWhenDisabled={true}
									value={data.EmailHtml} />
							</RowFw>
						</Grid>
						<Fab
							disabled={isSubmitting}
							onClick={handleFab}
							type="submit" />
					</Loadable>
				</Flex>
			</form>
			<SubmissionDialog
				onClose={submissionDialogOpen.setFalse}
				onSubmit={handleSubmit}
				open={submissionDialogOpen.value}
				submitting={isSubmitting} />
		</View>
	);

});
