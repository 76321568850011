import React from "react";
import Helper from "./DocuSignFormHelper.js";
import Component from "App/Component.js";
import Navigator from "App/Navigator.js";
import View from "App/View.js";
import Fab from "Components/Fab.js";
import Dialog from "Components/Dialog.js";
import FormSection from "Components/FormSection.js";
import Row from "Components/RowLabelled.js";
import mobile from "Helpers/Mobile.js";
import withSnackbar from "Hoc/withSnackbar";
import DocuSignService from "Services/DocuSignService.js";
import {DocuSignForm as Strings} from "Resources/Strings.js";
import * as mui from "@material-ui/core";
import withWidth from "@material-ui/core/withWidth";
import CheckIcon from "@material-ui/icons/Check";

/**
 * DocuSign form
 *
 * @package SEC
 * @subpackage Views
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class DocuSignForm extends Component {

	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * State
		 *
		 * @type {Object}
		 */
		this.state = {

			/**
			 * Recipients
			 *
			 * Map of role name to object with `name`/`email`.
			 *
			 * @type {Object}
			 */
			recipients: Helper.getTemplateRecipientsValues(this.template),

			/**
			 * Tabs
			 *
			 * Map of tab name to value.
			 *
			 * @type {Object}
			 */
			tabs: Helper.getTemplateTabsValues(this.template),

			/**
			 * Display confirmation
			 *
			 * @type {Boolean}
			 */
			confirmation: false,

			/**
			 * Submitting
			 *
			 * @type {Boolean}
			 */
			submitting: false

		};

		/**
		 * Form reference
		 *
		 * @type {ReactRef}
		 */
		this.form = React.createRef();

	}


	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<React.Fragment>
				<form ref={this.form}>
					<mui.Box>
						{
							this.sections.map((s, i) => (
								<FormSection key={i} label={s.label}>
									<View child={true}>
										{s.method()}
									</View>
								</FormSection>
							))
						}
					</mui.Box>

					<Fab onClick={() => this.handleFab()}>
						<CheckIcon />
					</Fab>
				</form>

				<Dialog
					open={this.state.confirmation}
					loading={this.state.submitting}
					title={Strings.confirmTitle}
					content={Strings.confirmContent}
					onOk={() => this.handleSubmit()}
					onClose={() => this.setState({confirmation: false})} />
			</React.Fragment>
		);
	}


	/**
	 * Render recipient inputs.
	 *
	 * @return {void}
	 */
	renderRecipients() {
		return this.template.recipients.map((r, i) => (
			<Row
				key={i}
				label={r.label}
				columns={`${this.constructor.labelWidth} auto auto`}>
				<mui.TextField
					required={!r.optional}
					fullWidth
					label="Name"
					value={this.state.recipients[r.role].name}
					onChange={e => this.updateRecipientName(e, r.role)} />
				<mui.TextField
					required={!r.optional}
					fullWidth
					label="Email"
					type="email"
					value={this.state.recipients[r.role].email}
					onChange={e => this.updateRecipientEmail(e, r.role)} />
			</Row>
		));
	}


	/**
	 * Render tab inputs.
	 *
	 * @return {void}
	 */
	renderTabs() {
		return this.template.tabs.map((tab, i) => (
			<Row key={i} label={tab.label}>
				<mui.TextField
					required={tab.required}
					fullWidth
					label={tab.label}
					type={tab.type}
					InputLabelProps={{shrink: tab.shrink}}
					multiline={tab.multiline}
					rows={1}
					onChange={e => this.updateTab(e, tab.name)}
					value={this.state.tabs[tab.name]} />
			</Row>
		));
	}


	/**
	 * Handle FAB click.
	 *
	 * @return {void}
	 */
	handleFab() {
		if (this.form.current.reportValidity()) {
			this.setState({confirmation: true});
		}
	}


	/**
	 * Handle submission.
	 *
	 * @return {void}
	 */
	handleSubmit() {
		const tpl = this.props.form.name;
		const tabs = Helper.resolveTabs(this.state.tabs, this.template.tabs);
		const recipients = Helper.resolveRecipients(this.state.recipients);
		this.setState({submitting: true});

		/**
		 * Submit
		 */
		DocuSignService.send(tpl, recipients, tabs).then(() => {
			Navigator.home();
			this.props.snackbar(Strings.toastSuccess, "success");
		}).catch(() => {
			this.setState({submitting: false, confirmation: false});
			this.props.snackbar(Strings.toastFailure, "error");
		});
	}


	/**
	 * Update a tab value.
	 *
	 * @param {Event} e
	 * @param {String} name
	 * @return {void}
	 */
	updateTab(e, name) {
		const tabs = this.state.tabs;
		tabs[name] = e.target.value;
		this.setState({tabs});
	}


	/**
	 * Update a recipient name.
	 *
	 * @param {Event} e
	 * @param {String} role
	 * @return {void}
	 */
	updateRecipientName(e, role) {
		const recipients = this.state.recipients;
		recipients[role].name = e.target.value;
		this.setState({recipients});
	}


	/**
	 * Update a recipient email.
	 *
	 * @param {Event} e
	 * @param {String} role
	 * @return {void}
	 */
	updateRecipientEmail(e, role) {
		const recipients = this.state.recipients;
		recipients[role].email = e.target.value;
		this.setState({recipients});
	}


	/**
	 * Section definitions.
	 *
	 * @return {Array}
	 */
	get sections() {
		return [
			{
				label: "Recipients",
				method: this.renderRecipients.bind(this)
			},
			{
				label: "Tabs",
				method: this.renderTabs.bind(this)
			}
		];
	}


	/**
	 * Get if "small".
	 *
	 * @return {Boolean}
	 */
	get small() {
		return mobile(this.props.width);
	}


	/**
	 * Get form template from props.
	 *
	 * @return {Object}
	 */
	get template() {
		return this.props.form;
	}


	/**
	 * Labels width.
	 *
	 * @return {void}
	 */
	static get labelWidth() {
		return "30rem";
	}

}

export default withWidth()(withSnackbar(DocuSignForm));
