import React from "react";
import Bar from "Components/Bar.js";
import Drawer from "Components/Drawer.js";
import Logout from "Components/Logout.js";
import withMobile from "Hoc/withMobile.js";
import {withRouter} from "react-router-dom";

/**
 * Shell
 *
 * @package SEC
 * @subpackage Views
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class Shell extends React.PureComponent {

	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * State
		 *
		 * @type {Object}
		 */
		this.state = {

			/**
			 * Drawer visibility
			 *
			 * @type {Boolean}
			 */
			drawer: false,

			/**
			 * Logout visiblity
			 *
			 * @type {Boolean}
			 */
			logout: false

		};

		/**
		 * Method binds
		 */
		this.handleDrawer = this.handleDrawer.bind(this);
		this.handleDrawerToggle = this.handleDrawerToggle.bind(this);
		this.handleLogout = this.handleLogout.bind(this);
		this.handleLogoutClose = this.handleLogoutClose.bind(this);

	}


	/**
	 * Component updating.
	 *
	 * @param {Object} prevProps
	 * @return {void}
	 */
	componentDidUpdate(prevProps) {
		if (prevProps.isMobile && !this.props.isMobile) {
			this.setState({drawer: false});
		}
		if (this.props.location.pathname !== prevProps.location.pathname) {
			window.scrollTo(0, 0);
		}
	}


	/**
	 * Handle drawer change.
	 *
	 * @param {Boolean} drawer
	 * @return {void}
	 */
	handleDrawer(drawer) {
		this.setState({drawer});
	}


	/**
	 * Handle drawer toggle.
	 *
	 * @return {void}
	 */
	handleDrawerToggle() {
		this.setState({drawer: !this.state.drawer});
	}


	/**
	 * Show logout dialog.
	 *
	 * @return {void}
	 */
	handleLogout() {
		this.setState({logout: true});
	}


	/**
	 * Close logout dialog.
	 * 
	 * @return {void}
	 */
	handleLogoutClose() {
		this.setState({logout: false});
	}


	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<React.Fragment>
				<Bar onDrawerToggle={this.handleDrawerToggle} />
				<Drawer
					history={this.props.history}
					onChange={this.handleDrawer}
					onLogout={this.handleLogout}
					open={this.state.drawer} />
				<Logout
					onClose={this.handleLogoutClose}
					open={this.state.logout} />
			</React.Fragment>
		);
	}

}

export default withMobile(withRouter(Shell));
