import PushService from "Services/PushService.js";

/**
 * Push subscription host
 *
 * @package SEC
 * @subpackage App
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class Push {

	/**
	 * Subscribe the app to push notifications.
	 *
	 * @return {Promise}
	 */
	static subscribe() {
		return new Promise((resolve, reject) => {
			if (navigator.serviceWorker) {
				navigator.serviceWorker.getRegistration().then(reg => {
					if (reg && reg.pushManager) {
						reg.pushManager.getSubscription().then(sub => {

							let subpromise;

							if (!sub) {
								subpromise = new Promise((resolve, reject) => {
									PushService.getServerVapidKey().then(key => {
										reg.pushManager.subscribe({
											applicationServerKey: key,
											userVisibleOnly: true
										}).then(resolve).catch(reject);
									}).catch(reject);
								});
							}
							else subpromise = new Promise(resolve);

							subpromise.then(sub => PushService.subscribe(sub).then(resolve).catch(reject)).catch(reject);

						}).catch(reject);
					}
					else reject();
				}).catch(reject);
			}
			else reject();
		});
	}


	/**
	 * Unsubscribe push notifications.
	 * 
	 * @return {Promise}
	 */
	static unsubscribe() {
		return new Promise((resolve, reject) => {
			if (navigator.serviceWorker) {
				navigator.serviceWorker.getRegistration().then(reg => {
					if (reg && reg.pushManager) {
						reg.pushManager.getSubscription().then(sub => {
							if (sub) {
								sub.unsubscribe().then(done => {
									if (!done) reject();
									else PushService.unsubscribe(sub).then(resolve).catch(reject);
								}).catch(reject);
							}
							else resolve();
						}).catch(reject);
					}
					else reject();
				}).catch(reject);
			}
			else reject();
		});
	}


	/**
	 * Get whether we are subscribed.
	 *
	 * @return {Promise} Resolves with `true`/`false`.
	 */
	static get subscribed() {
		return new Promise((resolve, reject) => {
			if (navigator.serviceWorker) {
				navigator.serviceWorker.getRegistration().then(reg => {
					if (reg && reg.pushManager) {
						reg.pushManager.getSubscription().then(sub => {
							resolve(!!sub);
						}).catch(reject);
					}
					else reject();
				}).catch(reject);
			}
			else reject();
		});
	}

}

export default Push;
