import Flex from "Components/Flexx.js";
import Text from "Components/Text.js";
import View from "App/View.js";

export default props => {
	return (
		<View>
			<Text
				content={props.title}
				variant="h6" />
			<Flex>
				{props.children}
			</Flex>
		</View>
	);
};
