/**
 * Currency helper
 *
 * @package SEC
 * @subpackage Helpers
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class Currency {

	/**
	 * Format a value for presentation as currency.
	 *
	 * @param {Float} v
	 * @param {String} def optional Default string when invalid value (`£0.00`)
	 * @param {Boolean} pound optional Include currency symbol (`true`)
	 * @return {String}
	 */
	static format(v, def="£0.00", pound=true) {
		if (!v) return def;
		const flt = Number(parseFloat(v).toFixed(2));
		const price = flt.toLocaleString("en", {minimumFractionDigits: 2});
		return `${(pound ? "£" : "")}${price}`;
	}


	/**
	 * Format a value for presentation as a discount percentage.
	 *
	 * @param {Float} v 0-1 discount ratio
	 * @param {String} def optional Default string when invalid value (`-`)
	 * @return {String}
	 */
	static formatDiscount(v, def="-") {
		return (v ? `${(v * 100)}%` : def);
	}

}

export default Currency;
