import EditorPlaceholder from "./HtmlTemplateEditorPlaceholder.js";
import {memo} from "react";

export default memo(props => {

	return Object.keys(props.placeholders).map(id => (
		<EditorPlaceholder
			{...props.placeholders[id]}
			disabled={props.disabled}
			id={id}
			key={id}
			onChange={props.onChange} />
	));

});
