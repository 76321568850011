import React from "react";
import ChartColour from "Helpers/ChartColour.js";
import {Avatar, Tooltip} from "@material-ui/core";

/**
 * User avatar
 *
 * @package SEC
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class UserAvatar extends React.PureComponent {

	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		const avatar = this.renderAvatar();
		if (this.props.nativeTooltip) return avatar;
		else return this.renderTooltip(avatar);
	}


	/**
	 * Render avatar.
	 *
	 * @return {ReactNode}
	 */
	renderAvatar() {
		return (
			<Avatar style={this.styles} title={this.nativeTooltip}>
				{this.initial}
			</Avatar>
		);
	}


	/**
	 * Render tooltip.
	 *
	 * @param {Object} children
	 * @return {ReactNode}
	 */
	renderTooltip(children) {
		return (
			<Tooltip arrow={true} title={this.props.user.Username}>
				{children}
			</Tooltip>
		);
	}


	/**
	 * Get colour for the avatar.
	 *
	 * @return {String}
	 */
	get colour() {
		return ChartColour(this.initial.charCodeAt(0));
	}


	/**
	 * Get initial for the avatar.
	 *
	 * @return {String}
	 */
	get initial() {
		return this.props.user.Username[0].toUpperCase();
	}


	/**
	 * Get native tooltip text.
	 *
	 * @return {String}
	 */
	get nativeTooltip() {
		if (!this.props.nativeTooltip) return null;
		else return this.props.user.Username;
	}


	/**
	 * Styles.
	 *
	 * @return {Object}
	 */
	get styles() {
		return {
			background: this.colour,
			margin: "auto",
			...this.props.style
		};
	}

}

export default UserAvatar;
