import React from "react";
import Navigator from "App/Navigator.js";
import * as mui from "@material-ui/core";

/**
 * Dashboard card
 *
 * A generic dashboard card component.
 *
 * Refer to the source for usage details and available props.
 *
 * @package SEC
 * @subpackage Spoi
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class DashboardCard extends React.PureComponent {

	/**
	 * Action clicked.
	 * 
	 * @return {void}
	 */
	handleActionClick = e => {
		e.preventDefault();
		e.stopPropagation();
		Navigator.navigate(this.props.uri);
	};

	/**
	 * Area clicked.
	 * 
	 * @return {void}
	 */
	handleAreaClick = () => Navigator.navigate(this.props.uriArea);


	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<mui.Card style={this.constructor.styles}>
				{(this.props.uriArea ? this.renderActioned() : this.renderContent())}
			</mui.Card>
		);
	}


	/**
	 * Render actioned card.
	 * 
	 * @return {ReactNode}
	 */
	renderActioned() {
		return (
			<mui.CardActionArea component="div" onClick={this.handleAreaClick}>
				{this.renderContent()}
			</mui.CardActionArea>
		);
	}


	/**
	 * Render card content.
	 * 
	 * @return {ReactNode}
	 */
	renderContent() {
		return <>
			<mui.CardContent>
				<mui.Typography color="textSecondary" gutterBottom>
					{this.props.caption}
				</mui.Typography>
				<mui.Box mb={1}>
					<mui.Typography component="h2" gutterBottom variant="h5">
						{this.props.title}
					</mui.Typography>
				</mui.Box>
				{
					this.props.text.map((text, key) => (
						this.renderText(text, key)
					))
				}
			</mui.CardContent>
			<mui.CardActions style={this.constructor.stylesActions}>
				<mui.Button onClick={this.handleActionClick} size="small">
					{this.props.buttonLbl}
				</mui.Button>
			</mui.CardActions>
		</>;
	}


	/**
	 * Render a text item.
	 *
	 * @param {String} text
	 * @param {mixed} key Node key
	 * @return {ReactNode}
	 */
	renderText(text, key) {
		return (
			<mui.Typography
				component="p"
				gutterBottom
				key={key}
				variant="body2">
				{text}
			</mui.Typography>
		);
	}


	/**
	 * Styles for the card base.
	 * 
	 * @type {Object}
	 */
	static styles = {display: "flex", flexDirection: "column"};

	/**
	 * Styles for the actions strip.
	 * 
	 * @type {Object}
	 */
	static stylesActions = {marginTop: "auto"};

}

export default DashboardCard;
