import React from "react";
import LoadableView from "Views/LoadableView.js";
import SupportBanner from "../Components/SpoiSupportBanner.js";

export default ({children, err, loading}) => (
	<LoadableView err={err} loading={loading}>
		{children}
		<SupportBanner />
	</LoadableView>
);
