import React from "react";
import ChartColours from "Resources/ChartColours.json";
import {Typography} from "@material-ui/core";
import scss from "./Chart.module.scss";

/**
 * Chart
 *
 * @package SEC
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class Chart extends React.PureComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<div className={scss.Chart}>
				{this.renderChart(this.props.chartComponent)}
				{((this.props.data.length === 0) ? this.constructor.renderEmpty() : null)}
			</div>
		);
	}


	/**
	 * Render chart component.
	 *
	 * @param {Object} ChartComponent Component to render
	 * @return {ReactNode}
	 */
	renderChart(ChartComponent) {
		return <ChartComponent data={this.chartData} {...this.constructor.chartProps} />;
	}


	/**
	 * Chart data.
	 *
	 * @return {Object}
	 */
	get chartData() {
		return {
			labels: this.props.labels,
			datasets: [{
				data: this.props.data,
				backgroundColor: ChartColours,
				hoverBackgroundColor: ChartColours
			}]
		};
	}


	/**
	 * Chart props.
	 * 
	 * @type {Object}
	 */
	static chartProps = {
		width: 600,
		height: 210,
		options: {
			responsive: false,
			legend: {
				align: "start",
				position: "right",
				labels: {
					fontSize: 10
				}
			}
		}
	};


	/**
	 * Render empty state.
	 *
	 * @return {ReactNode}
	 */
	static renderEmpty() {
		return (
			<Typography className={scss.ChartTypography}>
				Nothing to display.
			</Typography>
		);
	}

}

export default Chart;
