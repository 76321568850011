import Time from "Helpers/Time.js";

export default {

	/**
	 * Enquiry
	 *
	 * @type {Object}
	 */
	enquiry: null,

	/**
	 * Enquiry is SEC (office premises)
	 *
	 * @type {Boolean}
	 */
	enquirySec: false,

	/**
	 * Contractor
	 *
	 * @type {String}
	 */
	contractor: "",

	/**
	 * Contractor is SEC
	 *
	 * @type {Boolean}
	 */
	contractorSec: false,

	/**
	 * Incident date time
	 *
	 * @type {String}
	 */
	incidentDateTime: Time.ymdhmFromDt(Date.now()),

	/**
	 * NMPIs (names for submission)
	 *
	 * @type {Array}
	 */
	nmpis: [],

	/**
	 * Probables (names for submission)
	 *
	 * @type {Array}
	 */
	probables: [],

	/**
	 * Reported by
	 *
	 * @type {String}
	 */
	reportedBy: "",

	/**
	 * Reported by phone number
	 *
	 * @type {String}
	 */
	reportedByPhone: "",

	/**
	 * Reported to
	 *
	 * @type {Object}
	 */
	reportedTo: null,

	/**
	 * Details text
	 *
	 * @type {String}
	 */
	details: ""

};
