import {api, uris} from "api.js";

/**
 * Profile service
 *
 * @package SEC
 * @subpackage Services
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class ProfileService {

	/**
	 * Change password.
	 *
	 * @param {String} passwordCurrent
	 * @param {String} password
	 * @return {Promise}
	 */
	static pw(passwordCurrent, password) {
		return api.call({
			method: "POST",
			data: {password, passwordCurrent},
			url: uris.profile.pw
		});
	}


	/**
	 * Update a user profile setting.
	 *
	 * @param {String} setting
	 * @param {mixed} value
	 * @return {void}
	 */
	static setting(setting, value) {
		const url = `${uris.profile.settings}/${setting}`;
		return api.call({method: "POST", data: {value: (value ? 1 : 0)}, url});
	}


	/**
	 * Get the user's profile settings.
	 *
	 * @return {Promise}
	 */
	static settings() {
		return api.call(uris.profile.settings).then(({data}) => data);
	}

}

export default ProfileService;
