import React from "react";
import DomainDetailsCell from "Components/DomainDetailsCell.js";
import Currency from "Helpers/Currency.js";

/**
 * SPOI PO items table details
 *
 * @package SEC
 * @subpackage Spoi
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class SpoiPoItemsTableDetails extends React.PureComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<DomainDetailsCell
				details={this.values}
				label={this.props.li.Description}
				variant="caption" />
		);
	}


	/**
	 * Get the values to display.
	 *
	 * @return {Array}
	 */
	get values() {
		return [
			{
				label: "Discount",
				value: Currency.formatDiscount(this.props.li.Discount)
			},
			{
				label: "Unit Price",
				value: Currency.format(this.props.li.price)
			}
		];
	}

}

export default SpoiPoItemsTableDetails;
