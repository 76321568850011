import {api, uris} from "api.js";

/**
 * Project service
 *
 * @package SEC
 * @subpackage Services
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class ProjectService {

	/**
	 * Search projects.
	 *
	 * @param {String} query
	 * @return {Promise}
	 */
	static search(query) {
		return api.call(`${uris.projects}?Search=${query}`).then(({data}) => data);
	}

}

export default ProjectService;
