import React from "react";
import Container from "Components/Container.js";
import {Card as MuiCard} from "@material-ui/core";

/**
 * Card component
 * 
 * @package SEC
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class Card extends React.PureComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<MuiCard>
				<Container af="row" fullWidth={true} px={1} py={1}>
					{this.props.children}
				</Container>
			</MuiCard>
		);
	}

}

export default Card;
