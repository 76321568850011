import React from "react";
import {Link} from "react-router-dom";
import {IconButton} from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import SyncProblemIcon from "@material-ui/icons/SyncProblem";
import SyncingIcon from "./SyncingIcon.js";

/**
 * Sync icon
 *
 * @package SEC
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class SyncIcon extends React.PureComponent {

	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Link
				innerRef={this.constructor.setInnerRef}
				style={{color: "#fff", ...this.props.style}}
				to="/app">
				<IconButton color="inherit" style={this.props.style}>
					{this.icon}
				</IconButton>
			</Link>
		);
	}


	/**
	 * Icon.
	 *
	 * @return {ReactNode}
	 */
	get icon() {
		if (this.props.syncing) {
			return <SyncingIcon style={this.props.iconStyles} />;
		}
		else if (this.props.pending > 0) {
			return <SyncProblemIcon style={this.props.iconStyles} />;
		}
		else return <CheckCircleIcon style={this.props.iconStyles} />;
	}


	/**
	 * Set `innerRef` correctly.
	 *
	 * @param {DOMNode|null} r
	 * @return {void}
	 */
	static setInnerRef(r) {
		if (r) r.setAttribute("tabindex", -1);
	}

}

export default SyncIcon;
