import React from "react";
import Component from "App/Component.js";
import CustomerPicker from "Components/CustomerPicker.js";
import Dialog from "Components/Dialog.js";
import Table from "Components/Table.js";
import withSnackbar from "Hoc/withSnackbar.js";
import UserService from "Services/UserService.js";
import * as mui from "@material-ui/core";
import {AdminUsersCreateDialog as Strings} from "Resources/Strings.js";

/**
 * User creation dialog
 *
 * @package SEC
 * @subpackage Views
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class AdminUsersCreateDialog extends Component {

	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * State
		 *
		 * @type {Object}
		 */
		this.state = {

			/**
			 * Loading
			 *
			 * @type {Boolean}
			 */
			loading: false,

			/**
			 * Username
			 *
			 * @type {String}
			 */
			username: "",

			/**
			 * Email address
			 *
			 * @type {String}
			 */
			email: "",

			/**
			 * Customer
			 *
			 * @type {Object|null}
			 */
			customer: null

		};

		/**
		 * Form
		 *
		 * @type {ReactRef}
		 */
		this.form = React.createRef();

		/**
		 * Method binds
		 */
		this.handleCreate = this.handleCreate.bind(this);
		this.updateCustomer = this.updateCustomer.bind(this);
		this.updateEmail = this.updateEmail.bind(this);
		this.updateUsername = this.updateUsername.bind(this);

	}


	/**
	 * Component mounted.
	 *
	 * @return {void}
	 */
	componentDidMount() {
		this.update();
	}


	/**
	 * Component updated.
	 *
	 * @param {Object} prevProps
	 * @return {void}
	 */
	componentDidUpdate(prevProps) {
		if (this.props.customer !== prevProps.customer) {
			this.update();
		}
	}


	/**
	 * Update.
	 *
	 * @return {void}
	 */
	update() {
		this.setState({
			email: "",
			username: "",
			customer: this.props.customer
		});
	}


	/**
	 * Handle creation.
	 *
	 * @return {void}
	 */
	handleCreate() {
		if (this.form.current.reportValidity()) {

			this.setState({loading: true});

			UserService.create(this.user).then(() => {
				this.props.snackbar(Strings.success, "success");
				this.props.onDone();
			}).catch(() => {
				this.props.snackbar(Strings.error, "error");
			}).finally(() => {
				this.setState({loading: false, username: "", email: ""});
				this.props.onClose();
			});

		}
	}


	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Dialog
				loading={this.state.loading}
				onClose={this.props.onClose}
				onOk={this.handleCreate}
				open={this.props.open}
				title={Strings.title}>

				<form ref={this.form}>
					<Table>
						<mui.TableRow>
							<mui.TableCell style={this.constructor.cpStyles}>
								<CustomerPicker
									disabled={true}
									onChange={this.updateCustomer}
									value={this.state.customer} />
							</mui.TableCell>
						</mui.TableRow>
						<mui.TableRow>
							<mui.TableCell>
								<mui.TextField
									color="primary"
									disabled={this.state.loading}
									fullWidth
									label="Username"
									onChange={this.updateUsername}
									required
									value={this.state.username} />
							</mui.TableCell>
						</mui.TableRow>
						<mui.TableRow>
							<mui.TableCell>
								<mui.TextField
									color="primary"
									disabled={this.state.loading}
									fullWidth
									label="Email address"
									onChange={this.updateEmail}
									required
									type="email"
									value={this.state.email} />
							</mui.TableCell>
						</mui.TableRow>
						<mui.TableRow>
							<mui.TableCell>
								{this.constructor.help}
							</mui.TableCell>
						</mui.TableRow>
					</Table>
				</form>
			</Dialog>
		);
	}


	/**
	 * Update customer value.
	 *
	 * @param {Object|null} customer
	 * @return {void}
	 */
	updateCustomer(customer) {
		this.setState({customer});
	}


	/**
	 * Update email address.
	 *
	 * @param {Event} e
	 * @return {void}
	 */
	updateEmail(e) {
		this.setState({email: e.target.value});
	}


	/**
	 * Update username.
	 *
	 * @param {Event} e
	 * @return {void}
	 */
	updateUsername(e) {
		this.setState({username: e.target.value});
	}


	/**
	 * Get user object for submission.
	 *
	 * @return {Object}
	 */
	get user() {
		return {
			Username: this.state.username,
			EmailAddress: this.state.email,
			CustomerId: this.customer
		};
	}


	/**
	 * Get customer ID.
	 *
	 * @return {String|null}
	 */
	get customer() {
		return (this.state.customer?.CustomerID || null);
	}


	/**
	 * Help paragraphs.
	 * 
	 * @type {Array}
	 */
	static help = Strings.help.map((s, i) => {
		return (
			<mui.Typography
				key={i}
				paragraph={(i !== (Strings.help.length - 1))}>
				{s}
			</mui.Typography>
		);
	});


	/**
	 * Customer picker cell styles.
	 * 
	 * @type {Object}
	 */
	static cpStyles = {paddingTop: 0};

}

export default withSnackbar(AdminUsersCreateDialog);
