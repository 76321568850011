import React from "react";
import TextField from "Components/TextField.js";
import dApi from "Dispatchers/Api.js";
import {connect} from "react-redux";

/**
 * F12 API URI control
 * 
 * @package SEC
 * @subpackage F12
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class F12Api extends React.PureComponent {

	/**
	 * Constructor.
	 * 
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * State
		 * 
		 * @type {object}
		 */
		this.state = {

			/**
			 * Loading?
			 * 
			 * @type {Boolean}
			 */
			loading: false

		};

		/**
		 * Method binds
		 */
		this.handleChange = this.handleChange.bind(this);

	}


	/**
	 * Value changed.
	 * 
	 * @param {String} api
	 * @return {void}
	 */
	handleChange(api) {
		if (api !== this.props.api) {

			this.setState({loading: true});

			dApi((api || null)).then(() => {
				window.location.reload();
			}).finally(() => {
				this.setState({loading: false});
			});

		}
	}


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<TextField
				disabled={this.state.loading}
				label="API URI"
				helperText="The app will be reset when this is changed."
				onChange={this.handleChange}
				placeholder="(Automatic)"
				shrunk={true}
				value={(this.props.api || "")} />
		);
	}

}

export default connect(({api}) => ({api}))(F12Api);
