import React from "react";
import Loader from "Components/Loader.js";
import String from "Components/String.js";
import View from "App/View.js";

/**
 * Loadable view component
 *
 * Intended for networked views with loading and error states.
 *
 * Automatically renders the proper state based on `err` and `loading` 
 * props and renders the component's children when both are `null`.
 * 
 * Please refer to the source for details of all the available props.
 * 
 * @package SEC
 * @subpackage Views
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class LoadableView extends React.PureComponent {

	/**
	 * Render the view.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<View
				centred={!this.ready}
				fullHeight={true}
				rows={(this.ready ? "auto" : null)}>
				{(this.ready ? this.props.children : null)}
				{(this.props.err ? <String str="Error." /> : null)}
				{(this.props.loading ? <Loader /> : null)}
			</View>
		);
	}


	/**
	 * Get whether ready to render the view.
	 *
	 * @return {Boolean}
	 */
	get ready() {
		return (!this.props.loading && !this.props.err);
	}

}

export default LoadableView;
