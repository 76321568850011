import {Accordion as AccordionMui, AccordionDetails, AccordionSummary} from "@material-ui/core";
import ExpandIcon from "@material-ui/icons/ExpandMore";
import Flex from "./Flexx.js";
import Text from "./Text.js";
import {memo} from "react";

export default memo(props => {

	return (
		<AccordionMui
			expanded={props.open}
			onChange={props.onOpenToggle}>
			<AccordionSummary
				expandIcon={<ExpandIcon />}>
				<Text
					bold={true}
					content={props.label} />
			</AccordionSummary>
			<AccordionDetails>
				<Flex fullWidth={true}>
					{props.children}
				</Flex>
			</AccordionDetails>
		</AccordionMui>
	);

});
