import React from "react";
import {Tooltip, Typography} from "@material-ui/core";

/**
 * Labelled caption
 *
 * A component with a small caption with a label, value and tooltip.
 * 
 * Please refer to the source for details of the available props.
 * 
 * @package SEC
 * @subpackage Spoi
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class LabelledCaption extends React.PureComponent {

	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Tooltip
				arrow
				className="width-fit-content"
				placement="bottom"
				style={this.constructor.styles}
				title={(this.props.tooltip || "")}>

				<Typography variant={(this.props.variant || "body2")}>
					<span style={this.constructor.stylesLabel}>
						{`${this.props.label}: `}
					</span>
					<span>{this.props.value}</span>
				</Typography>
			</Tooltip>
		);
	}


	/**
	 * Basic styles.
	 * 
	 * @type {Object}
	 */
	static styles = {display: "block"};

	/**
	 * Label styles.
	 * 
	 * @type {Object}
	 */
	static stylesLabel = {fontWeight: "bold"};

}

export default LabelledCaption;
