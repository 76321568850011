import React from "react";
import Button from "Components/Button.js";
import Card from "Components/Card.js";
import Checkbox from "Components/Checkbox.js";
import Container from "Components/Containerx.js";
import HealthSafetyNmpisFormDialog from "Views/HealthSafetyNmpisFormDialog.js";
import ProjectPicker from "Components/ProjectPicker.js";
import String from "Components/Stringx.js";
import Strings from "./TimesheetsSubmissionView.strings.json";
import TextField from "Components/TextField.js";
import TimePicker from "Components/TimePicker.js";
import TimesheetSubmissionCardStatus from "./TimesheetSubmissionCardStatus.js";
import pluralize from "pluralize";
import scss from "./TimesheetSubmissionCard.module.scss";
import withAuth from "Hoc/withAuth.js";

/**
 * Timesheet submission card
 * 
 * @package SEC
 * @subpackage Timesheets
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class TimesheetSubmissionCard extends React.PureComponent {

	/**
	 * State
	 *
	 * @type {Object}
	 */
	state = {

		/**
		 * NMPI dialog open?
		 *
		 * @type {Boolean}
		 */
		nmpiDialog: false

	};


	/**
	 * NMPI dialog opened.
	 *
	 * @return {void}
	 */
	handleNmpiDialog = () => this.setState({nmpiDialog: true});

	/**
	 * NMPI dialog closed.
	 * 
	 * @return {void}
	 */
	handleNmpiDialogClose = () => this.setState({nmpiDialog: false});

	/**
	 * NMPI submitted.
	 *
	 * @param {Object} nmpi
	 * @return {void}
	 */
	handleNmpiSubmit = nmpi => {
		this.reportChange({Nmpis: [...this.props.values.Nmpis, nmpi]});
	};

	/**
	 * NMPIs declaration toggled.
	 * 
	 * @return {void}
	 */
	handleNmpisDeclarationChange = () => {
		this.reportChange({
			NmpisDeclaration: !this.props.values.NmpisDeclaration
		});
	};

	/**
	 * Project changed.
	 *
	 * @param {Integer} Id Project ID
	 * @param {Object} Project Project object
	 * @return {void}
	 */
	handleProjectChange = (Id, Project) => this.reportChange({Project});

	/**
	 * Value changed.
	 *
	 * @param {mixed} value
	 * @param {String} name
	 * @return {void}
	 */
	handleValueChange = (value, name) => this.reportChange({[name]: value});

	/**
	 * Report a change.
	 * 
	 * @param {Object} values
	 * @return {void}
	 */
	reportChange = vals => this.props.onChange(vals, this.props.segmentId);


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Card>
				<Container
					alignItems="center"
					columns="repeat(2, max-content)"
					justifyContent="space-between">
					<String str={this.props.segmentId} variant="h6" />
					<TimesheetSubmissionCardStatus
						duplicate={this.props.duplicate}
						error={this.props.error}
						loading={this.props.loading}
						ready={this.complete}
						submitted={this.props.submitted} />
				</Container>
				<ProjectPicker
					disabled={this.disabled}
					initial={this.props.values.Project}
					onChange={this.handleProjectChange}
					required={this.started}
					value={this.props.values.Project} />
				<Container
					className={scss.nmpisContainer}
					mt={(this.nmpiPermission ? 1 : 0)}
					mb={(this.nmpiPermission ? 1 : 0)}>
					{(!!this.props.values.Nmpis?.length && this.renderNmpis())}
					<Container className={scss.nmpisButtonsContainer}>
						{(this.nmpiPermission && this.renderNmpiButton())}
						<Checkbox
							checked={this.props.values.NmpisDeclaration}
							disabled={(this.disabled || !this.props.values.Project)}
							label={Strings.nmpis.declaration}
							onChange={this.handleNmpisDeclarationChange}
							required={this.started} />
					</Container>
				</Container>
				<Container>
					<TimePicker
						disabled={this.fieldsDisabled}
						label="Start Time"
						minutesStep={5}
						name="StartTime"
						onChange={this.handleValueChange}
						required={this.started}
						value={this.props.values.StartTime} />
					<TimePicker
						disabled={this.fieldsDisabled}
						label="Finish Time"
						minutesStep={5}
						name="FinishTime"
						onChange={this.handleValueChange}
						required={this.started}
						value={this.props.values.FinishTime} />
					<TextField
						dataType="integer"
						disabled={this.fieldsDisabled}
						label="Hours Worked"
						name="HoursWorked"
						InputProps={this.constructor.HoursInputProps}
						onChange={this.handleValueChange}
						required={this.started}
						type="number"
						value={this.props.values.HoursWorked} />
					<TextField
						dataType="integer"
						disabled={this.fieldsDisabled}
						label="Additional Hours"
						InputProps={this.constructor.HoursInputProps}
						name="HoursWorkedAdditional"
						onChange={this.handleValueChange}
						required={this.started}
						type="number"
						value={this.props.values.HoursWorkedAdditional} />
				</Container>
				<HealthSafetyNmpisFormDialog
					date={this.props.date}
					enquiry={this.props.values.Project}
					noComplianceDialog={true}
					onClose={this.handleNmpiDialogClose}
					noDrafts={true}
					noSyncQueue={true}
					onSubmit={this.handleNmpiSubmit}
					open={this.state.nmpiDialog}
					useProject={true} />
			</Card>
		);
	}


	/**
	 * Render submitted NMPIs.
	 * 
	 * @return {void}
	 */
	renderNmpis() {

		const len = this.props.values.Nmpis?.length;
		const plural = pluralize("incident report", len);

		return (
			<String
				color="textSecondary"
				str={`${len} ${plural} submitted - thank you!`} />
		);

	}


	/**
	 * Render NMPI submission button.
	 * 
	 * @return {ReactNode}
	 */
	renderNmpiButton() {
		return (
			<Button
				disabled={(this.disabled || !this.props.values.Project)}
				label={Strings.nmpis.button}
				onClick={this.handleNmpiDialog}
				variant="contained" />
		);
	}


	/**
	 * Get whether we're complete.
	 *
	 * @return {Boolean}
	 */
	get complete() {
		return Object.values(this.props.values).every(v => ![null, undefined].includes(v));
	}


	/**
	 * Get whether to disable inputs.
	 * 
	 * @return {ReactNode}
	 */
	get disabled() {
		const {loading, disabled, submitted, duplicate} = this.props;
		return (loading || disabled || submitted || duplicate);
	}


	/**
	 * Get whether to disable the input fields.
	 * 
	 * @return {Boolean}
	 */
	get fieldsDisabled() {
		return (this.disabled || (this.enableNmpis && !this.props.values.NmpisDeclaration));
	}


	/**
	 * Get whether we have NMPI submission permission.
	 * 
	 * @return {Boolean}
	 */
	get nmpiPermission() {
		return this.props.hasPermission("sec.nmpi.create");
	}


	/**
	 * Get whether we've started to fill this timesheet out.
	 * 
	 * @return {Boolean}
	 */
	get started() {
		return (this.props.values.Project && Object.values(this.props.values).some(v => v));
	}


	/**
	 * Working hours input props (`TextField` `InputProps`)
	 * 
	 * @type {Object}
	 */
	static HoursInputProps = {inputProps: {max: 24, min: 0}};

}

export default withAuth(TimesheetSubmissionCard);
