import {api, uris} from "api.js";

/**
 * Audit service
 *
 * @package SEC
 * @subpackage Services
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class AuditService {

	/**
	 * Get audit events.
	 *
	 * @param {Object} params Endpoint filters
	 * @return {Promise}
	 */
	static get(params) {
		if (params.user === null) delete params.user;
		if (params.event === null) delete params.event;
		return api.call({params, url: uris.audit.get}).then(({data}) => data);
	}


	/**
	 * Get presentable name for event class.
	 *
	 * @param {String} event Event class
	 * @return {String} Event name
	 */
	static getEventName(event) {

		let e = event.split("\\").reverse()[0].replace(/([A-Z])/g, " $1");

		this.REPLACED_EVENT_NAMES.forEach(r => {
			e = e.replace(r[0], r[1]);
		});

		return e.trim();

	}


	/**
	 * Array of UI event name alterations
	 * 
	 * @type {Array}
	 */
	static REPLACED_EVENT_NAMES = [
		["Audit Event", ""],
		["Docu Sign", "DocuSign"],
		["Nmpi", "NMPI"],
		["Setting403", "Setting (Denied)"]
	];

}

export default AuditService;
