import React from "react";
import Dialog from "Components/DialogInfo.js";
import Time from "Helpers/Time.js";
import AuditService from "Services/AuditService.js";

/**
 * Admin audits dialog
 *
 * @package SEC
 * @subpackage Views
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class AdminAuditsDialog extends React.PureComponent {

	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Dialog
				title="Audit log"
				open={this.props.open}
				onClose={this.props.onClose}
				fields={this.fields} />
		);
	}


	/**
	 * Map a data value to a string.
	 *
	 * @param {mixed} value
	 * @return {String}
	 */
	mapValue(value) {
		if (value === false) return "No";
		else if (value === true) return "Yes";
		else if (value === null) return "–";
		else return value;
	}


	/**
	 * Get data value fields.
	 *
	 * @return {Array}
	 */
	get data() {
		return Object.keys(this.props.infoItem.Data).map(key => {
			return {
				label: (key.charAt(0).toUpperCase() + key.slice(1)),
				value: this.mapValue(this.props.infoItem.Data[key])
			};
		});
	}


	/**
	 * Field definitions.
	 *
	 * @return {Array}
	 */
	get fields() {
		return [
			{
				label: "ID",
				value: this.props.infoItem.EventId
			},
			{
				label: "Event",
				value: AuditService.getEventName(this.props.infoItem.Event)
			},
			{
				label: "User",
				value: (this.props.infoItem.EventUser || "(SEC Hub)")
			},
			{
				label: "Time",
				value: Time.dmyhmsFromDatetime(this.props.infoItem.EventTime)
			},
			{
				label: "Domain",
				value: (this.props.infoItem.DomainKey || "–")
			},
			...this.data
		];
	}

}

export default AdminAuditsDialog;
