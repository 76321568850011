import {api, uris} from "api.js";

/**
 * DocuSign service
 *
 * @package SEC
 * @subpackage Services
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class DocuSignService {

	/**
	 * Get authentication URI.
	 *
	 * @return {Promise}
	 */
	static getAuthURI() {
		return api.call(uris.docusign.auth).then(({data}) => data);
	}


	/**
	 * Send a DocuSign request.
	 *
	 * @param {String} template Template name
	 * @param {Object} recipients Array of objects with `role`/`name`/`email`
	 * @param {Object} tabs Array of objects with `role`/`name`/`value`
	 * @return {void}
	 */
	static send(template, recipients, tabs) {
		return api.call({
			method: "POST",
			data: {
				template,
				recipients: JSON.stringify(recipients),
				tabs: JSON.stringify(tabs)
			},
			url: uris.docusign.send
		});
	}

}

export default DocuSignService;
