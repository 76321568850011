import Admin from "Views/Admin.js";
import About from "Views/About.js";
import DataRequests from "Views/DataRequests.js";
import DataRequestsEditor from "Views/DataRequestsEditor.js";
import Dashboard from "Views/Dashboard.js";
import DocuSign from "Views/DocuSign.js";
import HealthSafety from "Views/HealthSafety.js";
import ItpView from "Itp/ItpView.js";
import Login from "Views/Login.js";
import Messages from "Messages/MessageView.js";
import Profile from "Views/Profile.js";
import SpoiAdminWarning from "Spoi/Components/SpoiAdminWarning.js";
import SpoiPurchaseInvoiceView from "Spoi/Views/SpoiPurchaseInvoiceView.js";
import SpoiPurchaseInvoicesView from "Spoi/Views/SpoiPurchaseInvoicesView.js";
import SpoiPurchaseOrderView from "Spoi/Views/SpoiPurchaseOrderView.js";
import SpoiPurchaseOrdersView from "Spoi/Views/SpoiPurchaseOrdersView.js";
import TimesheetSubmissionView from "Timesheets/TimesheetSubmissionView.js";
import TimesheetsAdminView from "Timesheets/TimesheetsAdminView.js";
import TimesheetsView from "Timesheets/TimesheetsView.js";

export default [
	{
		uri: "/admin",
		permission: "sec.admin",
		component: Admin
	},
	{
		uri: "/app",
		component: About
	},
	{
		uri: "/data-requests/create",
		permission: "sec.drequests.post",
		component: DataRequestsEditor
	},
	{
		uri: "/data-requests/:id",
		permission: "sec.drequests.post",
		component: DataRequestsEditor
	},
	{
		uri: "/data-requests",
		permission: "sec.drequests.get",
		component: DataRequests
	},
	{
		uri: "/docusign",
		permission: "sec.docusign",
		component: DocuSign
	},
	{
		uri: "/health-safety",
		permission: "sec.healthsafety",
		component: HealthSafety
	},
	{
		uri: "/itp",
		permission: "sec.itp.send",
		component: ItpView
	},
	{
		uri: "/messages/:contact?",
		component: Messages
	},
	{
		uri: "/profile",
		component: Profile
	},
	{
		uri: "/spoi",
		component: SpoiAdminWarning,
		universal: true
	},
	{
		uri: "/spoi/invoices/raise/:poid",
		permission: "sec.spoi.RaisePurchaseInvoice",
		component: SpoiPurchaseInvoiceView
	},
	{
		uri: "/spoi/invoices/:uuid",
		permission: "sec.spoi.GetPurchaseInvoices",
		component: SpoiPurchaseInvoiceView
	},
	{
		uri: "/spoi/invoices",
		permission: "sec.spoi.GetPurchaseInvoices",
		component: SpoiPurchaseInvoicesView
	},
	{
		uri: "/spoi/orders/:id",
		permission: "sec.spoi.GetPurchaseOrders",
		component: SpoiPurchaseOrderView
	},
	{
		uri: "/spoi/orders",
		permission: "sec.spoi.GetPurchaseOrders",
		component: SpoiPurchaseOrdersView
	},
	{
		uri: "/timesheets/submit",
		permission: "sec.Timesheets",
		component: TimesheetSubmissionView
	},
	{
		uri: "/timesheets/admin",
		permission: "sec.Timesheets.Admin",
		component: TimesheetsAdminView
	},
	{
		uri: "/timesheets",
		permission: "sec.Timesheets",
		component: TimesheetsView
	},
	{
		uri: "/",
		component: Dashboard
	},
	{
		uri: "/",
		permission: false,
		component: Login
	}
];
