import React from "react";
import {Menu} from "@material-ui/core";

/**
 * Context menu
 *
 * You must provide a mouse event as the `e` prop so the 
 * menu can calculate the position to render at (you will 
 * encounter unexpected behaviour if it is invalid).
 *
 * @package SEC
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class ContextMenu extends React.PureComponent {

	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (this.props.children ? this.renderMenu() : null);
	}


	/**
	 * Render the menu.
	 *
	 * @return {ReactNode}
	 */
	renderMenu() {
		return (
			<Menu
				anchorPosition={this.anchor}
				anchorReference="anchorPosition"
				disableScrollLock={true}
				onClose={this.props.onClose}
				open={this.props.open}
				transitionDuration={0}>
				{this.props.children}
			</Menu>
		);
	}


	/**
	 * Anchor position.
	 *
	 * We handle the case where the value of the `e` prop is `null` 
	 * but note this will result in unexpected behaviour; you must 
	 * provide a mouse event as the `e` prop (see component docblock).
	 * 
	 * @return {Object}
	 */
	get anchor() {
		const x = ((this.props?.e?.clientX || 0) - 2);
		const y = ((this.props?.e?.clientY || 0) - 4);
		return {left: x, top: y};
	}

}

export default ContextMenu;
