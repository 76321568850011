import React from "react";
import Alert from "./Alert.js";
import rem from "Helpers/Rem.js";
import {Box} from "@material-ui/core";

/**
 * Alert banner
 * 
 * This defines a banner which can optionally be absolutely positioned 
 * at the centre bottom of the display.
 *
 * Please refer to the source for details of the props which are available.
 * 
 * @package SEC
 * @subpackage Spoi
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class AlertBanner extends React.PureComponent {

	/**
	 * Constructor.
	 *
	 * @type {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * State
		 *
		 * @type {Object}
		 */
		this.state = {

			/**
			 * Open
			 *
			 * @type {Boolean}
			 */
			open: true

		};

		/**
		 * Method binds
		 */
		this.handleClose = this.handleClose.bind(this);

	}


	/**
	 * Closed.
	 *
	 * @return {void}
	 */
	handleClose() {
		this.setState({open: false});
	}


	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (this.shouldRender ? this.renderAlert() : null);
	}


	/**
	 * Render the alert.
	 * 
	 * @return {ReactNode}
	 */
	renderAlert() {
		return (
			<Box style={this.styles}>
				<Alert
					content={this.props.content}
					onClose={this.handleClose}
					title={this.props.title}
					severity={this.props.severity}>
					{this.props.children}
				</Alert>
			</Box>
		);
	}


	/**
	 * Get whether to render the alert.
	 *
	 * @return {Boolean}
	 */
	get shouldRender() {
		return (this.state.open && (this.props.open !== false));
	}


	/**
	 * Get styles.
	 * 
	 * @return {Object}
	 */
	get styles() {
		const styles = (this.props.abs ? this.constructor.styles : null);
		return {...styles, ...this.props.style};
	}


	/**
	 * Styles.
	 *
	 * @type {Object}
	 */
	static styles = {
		bottom: 0,
		left: "50%",
		maxWidth: rem(50),
		opacity: 1,
		position: "fixed",
		transform: "translateX(-50%)",
		width: "100%",
		zIndex: 100000
	};

}

export default AlertBanner;
