import React from "react";
import Select from "Components/Select.js";
import Statuses from "./TimesheetStatuses.json";
import {MenuItem} from "@material-ui/core";

/**
 * Timesheets status picker
 * 
 * @package SEC
 * @subpackage Timesheets
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class TimesheetsStatusPicker extends React.PureComponent {

	/**
	 * Value changed.
	 *
	 * @param {Array} values
	 * @return {void}
	 */
	handleChange = values => {
		const vals = values.map(l => Statuses.find(s => (s.label === l)).value);
		this.props.onChange(vals);
	};


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Select
				disabled={this.props.disabled}
				label="Status"
				multiple={true}
				onChange={this.handleChange}
				value={this.value}>
				{
					Statuses.map((s, k) => (
						<MenuItem key={k} value={s.label}>{s.label}</MenuItem>
					))
				}
			</Select>
		);
	}


	/**
	 * Get `value` for the `Select`.
	 * 
	 * @return {Array}
	 */
	get value() {
		return this.props.value.map(v => Statuses.find(s => (s.value === v)).label);
	}

}

export default TimesheetsStatusPicker;
