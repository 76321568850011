import strftime from "strftime";

/**
 * Message helper
 * 
 * @package SEC
 * @subpackage Messages
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class MessageHelper {

	/**
	 * Get a message timestamp as an interface string.
	 *
	 * We return DD-MM-YYYY, or HH:MM if the timestamp is for today.
	 *
	 * We return `null` if the message instance does not have a timestamp.
	 *
	 * @return {String}
	 */
	static getTimestampUIString(timestamp) {
		const dt = new Date((timestamp * 1000));
		const today = strftime("%d-%m-%Y", new Date());
		const msgday = strftime("%d-%m-%Y", dt);
		const msgtime = strftime("%H:%M", dt);
		if (!timestamp) return null;
		else return ((msgday === today) ? msgtime : msgday);
	}


	/**
	 * Get unique senders from a set of messages.
	 *
	 * These should be message objects retrieved from the network.
	 *
	 * @param {Array} msgs
	 * @return {Array} Contact usernames
	 */
	static getUniqueSenders(msgs) {
		const senders = msgs.map(m => m.Sender).filter(m => (m !== null));
		return senders.filter((c, i, s) => (s.indexOf(c) === i));
	}


	/**
	 * Get unique threads by sender from a set of messages.
	 *
	 * Returns an object indexed by sender name with the value 
	 * being the most recent message sent by that sender.
	 *
	 * @param {Array} msgs
	 * @return {Object}
	 */
	static getUniqueThreadsBySender(msgs) {
		const threads = {};
		this.getUniqueSenders(msgs).forEach(sender => {
			threads[sender] = msgs.find(msg => (msg.Sender === sender));
		});
		return threads;
	}


	/**
	 * Sort messages to newest first.
	 *
	 * @param {Array} msgs `Message`
	 * @param {Boolean} asc Sort order optional
	 * @return {Array}
	 */
	static sort(msgs, asc=true) {
		return msgs.sort((a, b) => {
			if (a.Timestamp > b.Timestamp) return (asc ? -1 : 1);
			else if (a.Timestamp < b.Timestamp) return (asc ? 1 : -1);
			else return 0;
		});
	}


	/**
	 * Sort messages to newest first (message objects received from network).
	 *
	 * @param {Array} msgs Message objects received from network
	 * @param {Boolean} asc Sort order optional
	 * @return {Array}
	 */
	static sortNetworked(msgs, asc=true) {
		return msgs.sort((a, b) => {
			if (a.SentTime > b.SentTime) return (asc ? -1 : 1);
			else if (a.SentTime < b.SentTime) return (asc ? 1 : -1);
			else return 0;
		});
	}

}

export default MessageHelper;
