import {api, uris} from "api.js";

/**
 * Auth service
 *
 * @package SEC
 * @subpackage Services
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class AuthService {

	/**
	 * Authenticate a user.
	 *
	 * Upon successful authentication, sets the API 
	 * 	authentication token to the new token for the user 
	 * 	and resolves the promise with two arguments: an 
	 * 	object representing the parsed JWT, containing the 
	 * 	authenticated user's object properties, and the 
	 * 	raw string JSON web token.
	 *
	 * On failure, an `AuthError` is passed to the rejected 
	 * 	promise callback.
	 *
	 * @param {String} options.username
	 * @param {String} options.password
	 * @return {Promise}
	 */
	static auth({username, password}={}) {
		return new Promise((resolve, reject) => {
			api.call({
				method: "POST",
				data: {username, password},
				url: uris.auth.auth
			}).then(({data}) => {
				const auth = data;
				this.getUserPermissions(auth).then(permissions => {
					resolve({auth, permissions, user: this.parse(auth)});
				}).catch(e => reject(e));
			}).catch(e => reject(e));
		});
	}


	/**
	 * Get a temporary authentication token for a URI.
	 *
	 * @param {String} uri
	 * @return {Promise}
	 */
	static authToken(uri) {
		return api.call({params: {uri}, url: uris.auth.auth}).then(({data}) => data);
	}


	/**
	 * Grant a permission to a user.
	 *
	 * @param {String} user Username
	 * @param {String} permission Permission name
	 * @return {Promise}
	 */
	static grant(user, permission) {
		return api.call({
			method: "POST",
			data: {user, permission},
			url: uris.auth.permissions
		});
	}


	/**
	 * Revoke a permission from a user.
	 * 
	 * @param {String} user Username
	 * @param {String} perm Permission name
	 * @return {Promise}
	 */
	static revoke(user, perm) {
		return api.call({
			method: "DELETE",
			url: `${uris.auth.permissions}?user=${user}&permission=${perm}`
		});
	}


	/**
	 * Get auth user instances.
	 *
	 * @param {String} customer optional Customer ID (`null` = SEC = default)
	 * @return {Promise}
	 */
	static getUsers(customer=null) {
		let uri = uris.auth.users;
		if (customer) uri += `?customer=${customer}`;
		return api.call(uri).then(({data}) => data);
	}


	/**
	 * Get all permissions;
	 *
	 * @return {Promise}
	 */
	static getPermissions() {
		return api.call(uris.auth.permissions).then(({data}) => data);
	}


	/**
	 * Get user permissions.
	 *
	 * Optionally accepts a specific auth token for use during e.g. login 
	 * when the default authorisation token in the app's state is unwanted.
	 * 
	 * @param {String} auth optional Authentication token
	 * @return {Promise}
	 */
	static getUserPermissions(auth=null) {
		const req = {url: uris.profile.permissions};
		if (auth) req.headers = {Authorization: auth};
		return api.call(req).then(({data}) => data);
	}


	/**
	 * Get user object payload as object from JSON web token.
	 *
	 * @param {String} jwt
	 * @return {mixed}
	 */
	static parse(jwt) {
		const pl = jwt.split(".")[1];
		return JSON.parse(atob(pl.replace(/-/g, "+").replace(/_/g, "/")));
	}

}

export default AuthService;
