import {api, uris} from "api.js";
import Cache from "App/Cache.js";
import DownloadService from "Services/DownloadService.js";
import SnackbarService from "Snackbars/SnackbarService.js";

/**
 * Data request service
 *
 * @package SEC
 * @subpackage Services
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class DataRequestService {

	/**
	 * Create a new data request from a data request object.
	 *
	 * @param {Object} request
	 * @return {Promise}
	 */
	static create(request) {
		const data = new FormData();
		data.append("enquiry", request.enquiry.EnquiryID);
		data.append("email", request.email);
		data.append("recipients", JSON.stringify(request.recipients));
		data.append("attach_DOC2", request.attach_DOC2);
		data.append("attach_DOC3", request.attach_DOC3);
		data.append("attach_DOC4", request.attach_DOC4);
		data.append("attach_DataSheets", request.attach_DataSheets);
		data.append("clogo", request._clogo);
		if (request._files) {
			request._files.forEach(file => {
				data.append("attachments", file);
			});
		}
		return api.call({
			data,
			method: "POST",
			url: uris.drequests.post,
			headers: {"Content-Type": "multipart/form-data"}
		}).then(({data}) => {
			if (data?.attachments === false) {
				SnackbarService.snack("Error handling data request attachment(s) - check before sending.", "warning");
			}
		});
	}


	/**
	 * Update a data request from a data request object.
	 *
	 * @param {Integer} id
	 * @param {Object} request
	 * @return {Promise}
	 */
	static update(id, request) {
		const data = new FormData();
		data.append("email", request.email);
		data.append("recipients", JSON.stringify(request.recipients));
		if (request._files) {
			request._files.forEach(file => {
				data.append("attachments", file);
			});
		}
		return api.call({
			data,
			method: "POST",
			url: `${uris.drequests.post}/${id}`,
			headers: {"Content-Type": "multipart/form-data"}
		});
	}


	/**
	 * Delete by ID.
	 * 
	 * @param {Integer} id
	 * @return {Promise}
	 */
	static delete(id) {
		return api.call({method: "DELETE", url: `${uris.drequests.get}/${id}`});
	}


	/**
	 * Get by ID.
	 *
	 * @param {Integer} id
	 * @return {Promise}
	 */
	static get(id) {
		return api.call(`${uris.drequests.get}/${id}`).then(({data}) => data);
	}


	/**
	 * Get flat array of email addresses for a given mode (type) 
	 * within the `recipients` array of a data request object.
	 *
	 * @param {Object} request
	 * @param {String} mode
	 * @return {Array}
	 */
	static getAddresses(r, mode) {
		return r.recipients.filter(r => r.mode === mode).map(m => m.address);
	}


	/**
	 * Get an attachment blob for a request.
	 *
	 * @param {String} request Request `name`
	 * @param {String} attach Attachment filename
	 * @return {Promise}
	 */
	static getAttachment(request, attach) {
		const uri = this.getAttachmentUrl(request, attach);
		return DownloadService.downloadBlob(uri);
	}


	/**
	 * Get the URL of a given data request attachment.
	 *
	 * @param {String} request Data request's name
	 * @param {String} attach Attachment filename
	 * @return {String}
	 */
	static getAttachmentUrl(request, attach) {
		return `${this.getRequestAttachmentsPath(request)}/${attach}`;
	}


	/**
	 * Get email template.
	 *
	 * Loads from the network if available and saves to the cache.
	 *
	 * Returns from the cache if cached and network is available.
	 *
	 * Rejects if we cannot get the template from network or cache.
	 *
	 * @return {Promise}
	 */
	static getEmail() {
		return new Promise((resolve, reject) => {
			api.call(uris.drequests.email).then(({data}) => {
				Cache.save("drequests_email", data);
				resolve(data);
			}).catch(e => {
				const cached = Cache.get("drequests_email");
				if (cached) resolve(cached);
				else reject(e);
			});
		});
	}


	/**
	 * Get sent data requests.
	 *
	 * @param {String} sentmin YYYY-MM-DD Earliest sent date
	 * @param {String} sentmax YYYY-MM-DD Latest sent date
	 * @return {Promise}
	 */
	static getSent(sentmin, sentmax) {
		return api.call({
			params: {sentmin, sentmax},
			url: uris.drequests.sent
		}).then(({data}) => data);
	}


	/**
	 * Get data requests by enquiry ID.
	 *
	 * @param {Integer} id
	 * @return {Promise}
	 */
	static getByEnquiry(id) {
		return api.call(`${uris.drequests.enquiry}/${id}`).then(({data}) => data);
	}


	/**
	 * Get the root attachments API path for a given request.
	 *
	 * @param {String} req Request `name`
	 * @return {String}
	 */
	static getRequestAttachmentsPath(req) {
		return `/api/drequests/${req.substring(0, 12)}/attachments?file=`;
	}


	/**
	 * Get unsent data requests.
	 *
	 * @return {Promise}
	 */
	static getUnsent() {
		return api.call(uris.drequests.unsent).then(({data}) => data);
	}


	/**
	 * Send a data request.
	 *
	 * @param {Integer} id
	 * @return {Promise}
	 */
	static send(id) {
		return api.call({method: "POST", url: `${uris.drequests.send}/${id}/send`});
	}


	/**
	 * Delete an attachment of a request.
	 *
	 * @param {Integer} id
	 * @param {String} attachment
	 * @return {Promise}
	 */
	static deleteAttachment(id, attachment) {
		return api.call({method: "DELETE", url: `${uris.drequests.get}/${id}/attachment/${attachment}`});
	}

}

export default DataRequestService;
