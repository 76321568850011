import React from "react";
import rem from "Helpers/Rem.js";
import withMobile from "Hoc/withMobile.js";
import {Typography} from "@material-ui/core";

const ENQ_RENDERER_BOLD = {fontWeight: "bold"};

const ENQ_RENDERER_MARGIN = {marginTop: rem(0.3)};

const EnquiryRenderer = ({enquiry, width, ...props}) => {
	return (
		<React.Fragment>
			<Typography paragraph={!props.isMobile}>
				<span>{enquiry.EnquiryID}</span>
				<span> </span>
				<span style={ENQ_RENDERER_BOLD}>
					{enquiry.CustomerName}
				</span>
			</Typography>
			<Typography>
				{(props.isMobile ? null : enquiry.ProjectDescription)}
			</Typography>
			<div style={ENQ_RENDERER_MARGIN}>
				{(!props.isMobile ? props.children : null)}
				{(props.isMobile ? props.childrenMobile : null)}
			</div>
		</React.Fragment>
	);
};

export default withMobile(EnquiryRenderer);
