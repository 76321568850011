import React from "react";
import {withSnackbar as withSnack} from "notistack";

/**
 * `withSnack()` HOC wrapper
 * 
 * Wraps a component with `notistack` helper methods.
 * 
 * @package SEC
 * @subpackage Hoc
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
const withSnackbar = Component => {

	/**
	 * `ComponentWithSnack` inner wrapper
	 *
	 * @package SEC
	 * @subpackage Hoc
	 * @author Heron Web Ltd
	 * @copyright SEC Group
	 */
	const cws = class ComponentWithSnack extends React.Component {

		/**
		 * Constructor.
		 *
		 * @param {Object} props
		 * @return {self}
		 */
		constructor(props) {
			super(props);

			/**
			 * Method binds
			 */
			this.snack = this.snack.bind(this);

		}


		/**
		 * Render.
		 *
		 * @return {ReactNode}
		 */
		render() {
			return <Component {...this.props} snackbar={this.snack} />;
		}


		/**
		 * Display a snackbar.
		 *
		 * @param {String} msg All values are automatically cast to string
		 * @param {String} variant optional See `notistack` (`default`)
		 * @param {Boolean} preventDuplicate optional See `notistack` (`false`)
		 * @return {void}
		 */
		snack(msg, variant="default", preventDuplicate=false) {
			if (msg instanceof Error) variant="error";
			this.props.enqueueSnackbar(`${msg}`, {variant, preventDuplicate, style: {whiteSpace: "pre-line"}});
		}

	};

	return withSnack(cws);

};

export default withSnackbar;
