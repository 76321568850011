import {UserPicker} from "Components/UserPicker.js";
import NmpiService from "Services/NmpiService.js";
import withSnackbar from "Hoc/withSnackbar.js";

/**
 * NMPI reportee picker
 *
 * We currently extend `UserPicker` in a hacky manner in the 
 * interests of expediency in implementing #120. Really, this 
 * might be better factored out to its own component!
 *
 * @package SEC
 * @subpackage Views
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class HealthSafetyNmpisReporteePicker extends UserPicker {

	/**
	 * Get users.
	 *
	 * @return {Promise}
	 */
	getUsers() {
		return NmpiService.getReportees();
	}

}

export default withSnackbar(HealthSafetyNmpisReporteePicker);
