import React from "react";
import Theme from "Resources/Theme.json";
import withMobile from "Hoc/withMobile.js";
import {Box} from "@material-ui/core";

/**
 * Row
 *
 * A basic content row implementation which defaults to a two-column layout.
 *
 * Please refer to the source for details of the available props.
 *
 * @package SEC
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class Row extends React.PureComponent {

	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Box py={this.py} style={this.styles}>
				{this.props.children}
			</Box>
		);
	}


	/**
	 * Get columns definition.
	 *
	 * @return {String}
	 */
	get columns() {
		return (this.props.columns || "30rem auto");
	}


	/**
	 * Get `py` value for our Material `Box`.
	 * 
	 * @return {Integer}
	 */
	get py() {
		return ((this.props.isMobile && !this.props.noPy) ? 1 : 0);
	}


	/**
	 * Styles.
	 *
	 * @return {Object}
	 */
	get styles() {
		return {
			alignItems: "center",
			display: "grid",
			gridAutoFlow: (this.props.isMobile ? "row" : "column"),
			gridColumnGap: `${(Theme.sec.spacing / 10)}rem`,
			gridRowGap: `${(Theme.sec.spacing / 10)}rem`,
			gridTemplateColumns: (!this.props.isMobile ? this.columns : "100%")
		};
	}

}

export default withMobile(Row);
