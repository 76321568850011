import React from "react";
import Component from "App/Component.js";
import Dialog from "Components/Dialog.js";
import withSnackbar from "Hoc/withSnackbar.js";
import ProfileService from "Services/ProfileService.js";
import * as mui from "@material-ui/core";
import {ProfilePasswordDialog as Strings} from "Resources/Strings.js";

/**
 * Profile password dialog
 *
 * @package SEC
 * @subpackage Views
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class ProfilePasswordDialog extends Component {

	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * State
		 *
		 * @type {Object}
		 */
		this.state = {

			/**
			 * Loading
			 *
			 * @type {Boolean}
			 */
			loading: false,

			/**
			 * Confirmation doesn't match
			 *
			 * @type {Boolean}
			 */
			invalid: false,

			/**
			 * Wrong current password
			 *
			 * @type {Boolean}
			 */
			e403: false,

			/**
			 * Current password
			 *
			 * @type {String}
			 */
			current: "",

			/**
			 * Updated password
			 *
			 * @type {String}
			 */
			updated: "",

			/**
			 * Updated password (confirmation)
			 *
			 * @type {String}
			 */
			updatedConfirm: ""

		};

		/**
		 * Form reference
		 *
		 * @type {ReactRef}
		 */
		this.form = React.createRef();

	}


	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Dialog
				open={this.props.open}
				loading={this.state.loading}
				title={Strings.title}
				onOk={() => this.submit()}
				onClose={() => this.close()}>

				<form ref={this.form}>
					<mui.Box mb={1}>
						<mui.TextField
							autoFocus
							fullWidth
							required
							type="password"
							color="primary"
							disabled={this.state.loading}
							label="Old password"
							value={this.state.current}
							error={this.state.e403}
							helperText={this.state.e403 ? Strings.e403 : ""}
							onChange={e => this.set(e, "current")} />
					</mui.Box>
					<mui.Box mb={1}>
						<mui.TextField
							fullWidth
							required
							type="password"
							color="primary"
							disabled={this.state.loading}
							inputProps={{minLength: 8, maxLength: 32}}
							label="New password"
							value={this.state.updated}
							onChange={e => this.set(e, "updated")} />
					</mui.Box>
					<mui.Box mb={1}>
						<mui.TextField
							fullWidth
							required
							type="password"
							color="primary"
							disabled={this.state.loading}
							inputProps={{minLength: 8, maxLength: 32}}
							label="New password (confirm)"
							value={this.state.updatedConfirm}
							error={this.state.invalid}
							helperText={this.state.invalid ? Strings.e : ""}
							onChange={e => this.set(e, "updatedConfirm")} />
					</mui.Box>
				</form>
				<mui.Box mt={2}>
					<mui.Typography>
						{Strings.msg}
					</mui.Typography>
				</mui.Box>
			</Dialog>
		);
	}


	/**
	 * Closing; clear state.
	 *
	 * @return {void}
	 */
	close() {
		this.setState({
			loading: false,
			invalid: false,
			e403: false,
			current: "",
			updated: "",
			updatedConfirm: ""
		});
		this.props.onClose();
	}


	/**
	 * Set.
	 *
	 * @param {Event} e
	 * @param {String} key
	 * @return {void}
	 */
	set(e, key) {
		this.setState({[key]: e.target.value, invalid: false, e403: false});
	}


	/**
	 * Submit.
	 *
	 * @return {void}
	 */
	submit() {
		if (this.form.current.reportValidity()) {
			if (this.state.updated === this.state.updatedConfirm) {
				const old = this.state.current;
				const now = this.state.updated;
				this.setState({loading: true});
				ProfileService.pw(old, now).then(() => {
					this.props.snackbar(Strings.saved, "success");
					this.close();
				}).catch(e => {
					if (e.status !== 403) {
						this.close();
						this.props.snackbar(Strings.savedError, "error");
					}
					else this.setState({e403: true});
				}).finally(() => this.setState({loading: false}));
			}
			else this.setState({invalid: true});
		}
	}

}

export default withSnackbar(ProfilePasswordDialog);
