import React from "react";
import Alert from "Components/Alert.js";
import str from "../Resources/SpoiPartiallyInvoicedAlertStrings.json";

/**
 * SPOI partially invoiced alert
 *
 * @package SEC
 * @subpackage Spoi
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class SpoiPartiallyInvoicedAlert extends React.PureComponent {

	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Alert
				content={str.content}
				title={str.title}
				severity="warning" />
		);
	}

}

export default SpoiPartiallyInvoicedAlert;
