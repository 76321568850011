import React from "react";
import AuditEvents from "Resources/AuditEvents.json";
import AuditService from "Services/AuditService.js";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {TextField, Typography} from "@material-ui/core";

/**
 * Audit event picker
 *
 * @package SEC
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class AuditEventPicker extends React.PureComponent {

	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * Method binds
		 */
		this.handleChange = this.handleChange.bind(this);

	}


	/**
	 * Value changed.
	 *
	 * @param {Event} e
	 * @param {String} v Audit event name
	 * @return {void}
	 */
	handleChange(e, v) {
		this.props.onChange(v);
	}


	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Autocomplete
				autoComplete={true}
				autoHighlight={true}
				autoSelect={true}
				disabled={this.props.disabled}
				freeSolo={true}
				getOptionLabel={this.constructor.getOptionLabel}
				onChange={this.handleChange}
				options={AuditEvents}
				renderInput={this.constructor.renderInput}
				renderOption={this.constructor.renderOption}
				value={this.props.value} />
		);
	}


	/**
	 * Get option label.
	 *
	 * @param {String} o Event name
	 * @return {String}
	 */
	static getOptionLabel(o) {
		return AuditService.getEventName(o);
	}


	/**
	 * Render the input.
	 *
	 * @param {Object} p Props passed by `Autocomplete`
	 * @return {ReactNode}
	 */
	static renderInput(p) {
		return <TextField {...p} fullWidth label="Audit event" />;
	}


	/**
	 * Render an option.
	 *
	 * @param {String} o Event name
	 * @return {ReactNode}
	 */
	static renderOption(o) {
		return <Typography>{AuditEventPicker.getOptionLabel(o)}</Typography>;
	}

}

export default AuditEventPicker;
