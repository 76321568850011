import React from "react";
import * as mui from "@material-ui/core";

/**
 * Dialog
 *
 * @package SEC
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class Dialog extends React.PureComponent {

	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<mui.Dialog
				disableScrollLock={true}
				disableBackdropClick={(this.props.loading || this.props.disabled)}
				disableEscapeKeyDown={(this.props.loading || this.props.disabled)}
				fullWidth={this.props.fullWidth}
				maxWidth={this.props.maxWidth}
				onClose={this.props.onClose}
				open={this.props.open}
				PaperProps={{elevation: 0, style: this.props.style}}
				transitionDuration={0}>

				{(!this.props.notitle ? this.renderTitle() : null)}
				<mui.DialogContent style={this.constructor.contentStyles}>
					{this.content}
					<mui.Box py={2} style={this.loadingStyles}>
						<mui.CircularProgress />
					</mui.Box>
				</mui.DialogContent>
				<mui.DialogActions>
					{this.props.actions}
					{(!this.props.nocancel ? this.renderCancel() : null)}
					{(!this.props.nook ? this.renderOk() : null)}
				</mui.DialogActions>
			</mui.Dialog>
		);

	}


	/**
	 * Render cancel button.
	 *
	 * @return {void}
	 */
	renderCancel() {
		return (
			<mui.Button
				color="primary"
				disabled={(this.props.loading || this.props.disabled)}
				onClick={this.props.onClose}>
				Cancel
			</mui.Button>
		);
	}


	/**
	 * Render OK button.
	 * 
	 * @return {void}
	 */
	renderOk() {
		return (
			<mui.Button
				color="primary"
				disabled={(this.props.loading || this.props.disabled)}
				onClick={this.props.onOk}
				variant="contained">
				OK
			</mui.Button>
		);
	}


	/**
	 * Render the title.
	 * 
	 * @return {ReactNode}
	 */
	renderTitle() {
		return (
			<mui.DialogTitle disableTypography={true}>
				<mui.Typography variant="h6" style={this.titleStyles}>
					{this.props.title}
				</mui.Typography>
			</mui.DialogTitle>
		);
	}


	/**
	 * Get content.
	 *
	 * @return {ReactNode}
	 */
	get content() {
		if (!this.props.children && !this.props.nocontent) {
			return this.contentNodes;
		}
		else return this.props.children;
	}


	/**
	 * Get props content as an array for splicing into paragraphs.
	 *
	 * @return {Array}
	 */
	get contentArray() {
		if (this.props.content?.constructor !== Array) {
			return [this.props.content];
		}
		else return this.props.content;
	}


	/**
	 * Get content nodes from props.
	 *
	 * @return {ReactNode}
	 */
	get contentNodes() {
		return (
			<React.Fragment>
				{
					this.contentArray.map((content, key) => {
						return (
							<mui.Typography color="textSecondary" key={key} paragraph>
								{content}
							</mui.Typography>
						);
					})
				}
			</React.Fragment>
		);
	}


	/**
	 * Loading styles.
	 *
	 * @return {Object}
	 */
	get loadingStyles() {
		return {
			display: (this.props.loading ? "block" : "none"),
			textAlign: "center"
		};
	}


	/**
	 * Title styles.
	 * 
	 * @return {Object}
	 */
	get titleStyles() {
		return {
			overflow: (this.props.titleTruncate ? "hidden" : null),
			textOverflow: (this.props.titleTruncate ? "ellipsis" : null),
			whiteSpace: (this.props.titleTruncate ? "nowrap" : null)
		};
	}

}

export default Dialog;
