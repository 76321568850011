import React from "react";
import Component from "App/Component.js";
import DialogLoader from "Components/DialogLoader.js";
import DataRequestService from "Services/DataRequestService.js";
import withSnackbar from "Hoc/withSnackbar.js";

/**
 * Data requests attachment viewer
 *
 * @package SEC
 * @subpackage Views
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class DataRequestsAttachment extends Component {

	/**
	 * Component updated.
	 *
	 * @return {void}
	 */
	componentDidUpdate(propsPrev) {
		if (this.props.open && !propsPrev.open) {
			this.update();
		}
	}


	/**
	 * Update.
	 *
	 * @return {void}
	 */
	update() {

		if (this.props.attachment instanceof File) {
			this.updateFile();
		}
		else this.updateNetworked();

	}


	/**
	 * Update using `File`.
	 * 
	 * @return {void}
	 */
	updateFile() {
		const url = URL.createObjectURL(this.props.attachment);
		window.open(url);
		this.props.onClose();
	}


	/**
	 * Update using network attachment.
	 * 
	 * @return {void}
	 */
	updateNetworked() {

		const params = [this.props.request, this.props.attachment];

		DataRequestService.getAttachment(...params).then(({blobUrl}) => {
			window.open(blobUrl);
		}).catch(e => {
			this.props.snackbar(e);
		}).finally(() => {
			this.props.onClose();
		});

	}


	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return <DialogLoader open={this.props.open} />;
	}

}

export default withSnackbar(DataRequestsAttachment);
