import Domain from "Domain/Domain.js";

/**
 * SPOI line item domain
 *
 * A generalised domain to represent line items, irrespective 
 * of their identity, state or associated resource type.
 * 
 * @package SEC
 * @subpackage Spoi
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class SpoiLineItem extends Domain {

	/**
	 * Constructor.
	 * 
	 * @param {Object} obj optional
	 * @return {self}
	 */
	constructor(obj={}) {
		super(obj);

		/**
		 * ID
		 * 
		 * @type {Integer}
		 */
		this.Id = null;

		/**
		 * Description
		 *
		 * @type {String}
		 */
		this.Description = null;

		/**
		 * Quantity
		 *
		 * @type {Integer}
		 */
		this.Quantity = 0;

		/**
		 * Discount
		 * 
		 * @type {Float}
		 */
		this.Discount = 0;

		/**
		 * List price
		 * 
		 * @type {Float}
		 */
		this.ListPrice = 0;

		/**
		 * Unit price
		 * 
		 * @type {Float}
		 */
		this.UnitPrice = 0;

		/**
		 * Item's tax code
		 * 
		 * @type {Object}
		 */
		this.TaxCode = null;

		/**
		 * Whether this is a CIS item
		 *
		 * This will always be `null` when the item only exists locally.
		 * 
		 * (Within the app, all line items of a resource should always 
		 * be all-CIS or all-non-CIS, and so we track CIS status on the 
		 * parent resource - this property will be set only when this 
		 * instance is created as the hydration of a line item which 
		 * was fetched from the API over the network, and in that case, 
		 * we expect all line items which were part of that fetch to 
		 * have the same CIS status and always assume this is the case.)
		 * 
		 * @type {Boolean}
		 */
		this.CISItem = null;

		/**
		 * Assign values
		 */
		Object.assign(this, obj);
	}


	/**
	 * Get the discount ratio to apply.
	 *
	 * This is the ratio to multiple a monetary value by to 
	 * get the final amount payable by the payer of that value.
	 *
	 * @return {Float}
	 */
	get discountRatio() {
		return (1 - this.Discount);
	}


	/**
	 * Get whether we have a list price.
	 * 
	 * @return {Boolean}
	 */
	get hasListPrice() {
		return ((this.ListPrice > 0));
	}


	/**
	 * Get whether we have a unit price.
	 * 
	 * @return {Boolean}
	 */
	get hasUnitPrice() {
		return ((this.UnitPrice > 0));
	}


	/**
	 * Get the price.
	 *
	 * Returns either `ListPrice` or `UnitPrice` depending on the value.
	 *
	 * @return {Float}
	 */
	get price() {
		return (this.ListPrice || this.UnitPrice);
	}


	/**
	 * Get the total line item value.
	 *
	 * @return {Float}
	 */
	get total() {
		const dc = this.discountRatio;
		const lp = this.ListPrice;
		const up = this.UnitPrice;
		const price = (this.hasUnitPrice ? up : (dc * lp));
		return (this.Quantity * price);
	}

}

export default SpoiLineItem;
