import React from "react";
import {TextField} from "@material-ui/core";
import {TimePicker as MuiTimePicker} from "@material-ui/pickers";

/**
 * Time picker
 * 
 * @package SEC
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class TimePicker extends React.PureComponent {

	/**
	 * Value changed.
	 * 
	 * @param {String} value
	 * @return {void}
	 */
	handleChange = value => this.props.onChange(value, this.props.name);


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<MuiTimePicker
				{...this.props}
				ampm={false}
				autoOk={true}
				onChange={this.handleChange}
				DialogProps={this.constructor.DialogProps}
				TextFieldComponent={this.constructor.renderTextField} />
		);
	}


	/**
	 * Render the `TextField`.
	 *
	 * We override `readOnly` so form validation works correctly.
	 *
	 * @param {Object} props
	 * @return {ReactNode}
	 */
	static renderTextField(props) {
		return (
			<TextField
				{...props}
				InputProps={TimePicker.InputProps} />
		);
	}


	/**
	 * `InputProps`
	 * 
	 * @type {Object}
	 */
	static InputProps = {readOnly: false};

	/**
	 * `DialogProps`
	 * 
	 * @type {Object}
	 */
	static DialogProps = {disableScrollLock: true, transitionDuration: 0, PaperProps: {elevation: 0}};

}

export default TimePicker;
