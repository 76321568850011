import React from "react";
import Container from "Components/Container.js";
import Dialog from "Components/Dialog.js";
import PriceInput from "../Components/SpoiPiRaiseItemPriceInput.js";
import RowLabelled from "Components/RowLabelled.js";
import TaxCodePicker from "../Components/SpoiTaxCodePicker.js";
import rem from "Helpers/Rem.js";
import * as mui from "@material-ui/core";

/**
 * SPOI PI raise item editor
 *
 * @package SEC
 * @subpackage Spoi
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class SpoiPiRaiseItemEditor extends React.PureComponent {

	/**
	 * Constructor.
	 * 
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * Method binds
		 */
		this.handleDelete = this.handleDelete.bind(this);

	}


	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Dialog
				actions={this.renderActions()}
				noCancel={true}
				onClose={this.props.onClose}
				onOk={this.props.onClose}
				open={this.props.open}
				title="Invoice Editor">
				<Container
					af="row"
					fullWidth={true}
					gap={2}
					marginBottom={1}>
					<RowLabelled
						columns={this.columns}
						label="Price"
						noPy={true}>
						<PriceInput
							li={this.props.li}
							onChange={Amount => this.props.onChange({Amount})}
							required={!this.props.cis} />
					</RowLabelled>
					{(!this.props.cis ? this.renderTaxCodeInput() : null)}
				</Container>
			</Dialog>
		);
	}


	/**
	 * Render the actions.
	 * 
	 * @return {ReactNode}
	 */
	renderActions() {
		if (!this.props.showDeletion) return null;
		else return this.renderDeleteButton();
	}


	/**
	 * Render the delete button.
	 *
	 * @return {ReactNode}
	 */
	renderDeleteButton() {
		return (
			<mui.Button
				color="primary"
				disabled={this.props.disableDeletion}
				onClick={this.handleDelete}
				style={this.constructor.DeleteStyles}
				variant="text">
				Delete
			</mui.Button>
		);
	}


	/**
	 * Render the tax code input.
	 *
	 * @return {ReactNode}
	 */
	renderTaxCodeInput() {
		return (
			<RowLabelled columns={this.columns} label="Tax Code" noPy={true}>
				<TaxCodePicker
					disabledItems={this.props.disabledTaxCodes}
					onChange={TaxCode => this.props.onChange({TaxCode})}
					value={this.props.li.TaxCode} />
			</RowLabelled>
		);
	}


	/**
	 * Handle deletion.
	 *
	 * @return {void}
	 */
	handleDelete() {
		this.props.onClose();
		this.props.onDelete(this.props.li);
	}


	/**
	 * Columns definition.
	 * 
	 * @return {String}
	 */
	get columns() {
		return "12rem auto";
	}


	/**
	 * Paper props.
	 * 
	 * @type {Object}
	 */
	static PaperProps = {elevation: 0, style: {width: "100%"}};

	/**
	 * Content styles.
	 * 
	 * @type {Object}
	 */
	static ContentStyles = {marginBottom: rem(1)};

	/**
	 * Delete styles.
	 * 
	 * @type {Object}
	 */
	static DeleteStyles = {marginLeft: rem(), marginRight: "auto"};

}

export default SpoiPiRaiseItemEditor;
