import React from "react";
import Component from "App/Component.js";
import Container from "Components/Container.js";
import EmailPicker from "./EmailPicker.js";
import withSnackbar from "Hoc/withSnackbar";
import CustomerService from "Services/CustomerService.js";
import {EmailPickerContacts as Strings} from "Resources/Strings.js";

/**
 * Email picker for SEC contacts
 *
 * @package SEC
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class EmailPickerContacts extends Component {

	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * State
		 *
		 * @type {Object}
		 */
		this.state = {

			/**
			 * Contacts
			 *
			 * @type {Array}
			 */
			contacts: [],

			/**
			 * Loading
			 *
			 * @type {Boolean}
			 */
			loading: false

		};

		/**
		 * Method binds
		 */
		this.handleChange = this.handleChange.bind(this);

	}


	/**
	 * Component mounted.
	 *
	 * @return {void}
	 */
	componentDidMount() {
		this.update();
	}


	/**
	 * Component updated.
	 *
	 * @param {Object} prevProps
	 * @return {void}
	 */
	componentDidUpdate(prevProps) {
		if (this.props.customer !== prevProps.customer) {
			this.update();
		}
	}


	/**
	 * Handle change.
	 *
	 * @param {Event} e
	 * @param {mixed} value
	 * @return {void}
	 */
	handleChange(e, value) {
		if (!this.props.disabled) {
			value = (!this.props.reportObjectValues ? this.parseValues(value) : value);
			this.props.onChange(value, this.props.name);
		}
	}


	/**
	 * Parse values.
	 *
	 * Only unique values are included.
	 * 
	 * @param {mixed} value
	 * @return {void}
	 */
	parseValues(value) {
		if (!this.props.singular) {
			return [...(new Set(value.map(this.constructor.getOptionLabel)))];
		}
		else return this.constructor.getOptionLabel(value);
	}


	/**
	 * Update – get contacts.
	 *
	 * @return {void}
	 */
	update() {

		let contacts = [];

		if (!this.props.customer) {
			this.setState({contacts});
			return;
		}

		this.setState({loading: true});

		CustomerService.getContacts(this.props.customer).then(con => {
			contacts = con;
		}).catch(() => {
			this.props.snackbar(Strings.error, "error");
		}).finally(() => {
			this.setState({contacts, loading: false});
		});

	}


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<EmailPicker
				{...this.props}
				getOptionLabel={this.constructor.getOptionLabel}
				loading={this.state.loading}
				onChange={this.handleChange}
				options={this.state.contacts.filter(c => !!c.EmailAddress)}
				renderOption={this.constructor.renderOption} />
		);
	}


	/**
	 * Get the label for an option.
	 *
	 * @param {Object|String} o
	 * @return {String}
	 */
	static getOptionLabel(o) {
		return (o?.EmailAddress || o);
	}


	/**
	 * Render an option.
	 *
	 * @param {Object} c
	 * @return {void}
	 */
	static renderOption(c) {
		return (
			<Container af="row" gap={0.25}>
				<span style={EmailPickerContacts.stylesContactName}>
					{c.ContactName}
				</span>
				<span>{c.EmailAddress}</span>
			</Container>
		);
	}


	/**
	 * Styles for the contact name.
	 * 
	 * @type {Object}
	 */
	static stylesContactName = {fontWeight: "bold"};

}

export default withSnackbar(EmailPickerContacts);
