import HtmlTemplateEditor from "Components/HtmlTemplateEditor/HtmlTemplateEditor.js";
import {memo} from "react";

export default memo(props => {

	return (
		<HtmlTemplateEditor
			disabled={props.disabled}
			label="Email preview"
			onChange={props.onChange}
			value={props.email} />
	);

});
