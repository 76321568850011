import React from "react";
import View from "App/View.js";
import AdminAuditsSearch from "./AdminAuditsSearch.js";

/**
 * Admin audit log
 *
 * @package SEC
 * @subpackage Views
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class AdminAudits extends React.PureComponent {

	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<View child={true}>
				<AdminAuditsSearch />
			</View>
		);
	}

}

export default AdminAudits;
