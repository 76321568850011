import React from "react";
import withMobile from "Hoc/withMobile.js";
import * as mui from "@material-ui/core";

/**
 * Table
 *
 * This supports pagination and renders the body rows as `children`.
 * 
 * Please refer to the source for details of all the available props.
 * 
 * @package SEC
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class Table extends React.PureComponent {

	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * Method binds
		 */
		this.getPaginationRowsLabel = this.getPaginationRowsLabel.bind(this);
		this.onChangePage = this.onChangePage.bind(this);
		this.onChangeRows = this.onChangeRows.bind(this);

	}


	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<mui.Table
				className="height-fit-content"
				size={this.props.size}
				style={this.props.style}>
				{(this.props.header ? this.renderHeader() : null)}
				<mui.TableBody>
					{this.props.children}
					{(this.props.pagination ? this.renderPagination() : null)}
				</mui.TableBody>
			</mui.Table>
		);
	}


	/**
	 * Render header.
	 *
	 * @return {ReactNode}
	 */
	renderHeader() {
		return (
			<mui.TableHead>
				<mui.TableRow>
					{
						this.props.header.map((lbl, i) => {
							return this.renderHeaderCell(lbl, i);
						})
					}
				</mui.TableRow>
			</mui.TableHead>
		);
	}


	/**
	 * Render header cell.
	 *
	 * @param {String} label
	 * @param {Integer} i
	 * @return {ReactNode}
	 */
	renderHeaderCell(label, i) {
		return (
			<mui.TableCell key={i} style={this.constructor.headerCellStyles}>
				<mui.Typography style={this.constructor.headerTypoStyles}>
					{label}
				</mui.Typography>
			</mui.TableCell>
		);
	}


	/**
	 * Render pagination controls.
	 *
	 * @return {ReactNode}
	 */
	renderPagination() {
		return (
			<mui.TableRow>
				<mui.TablePagination
					count={this.paginationCount}
					labelDisplayedRows={this.getPaginationRowsLabel}
					labelRowsPerPage="Items:"
					page={this.props.paginationPage}
					rowsPerPage={this.props.paginationRows}
					rowsPerPageOptions={this.paginationRowsPerPageOptions}
					onChangePage={this.onChangePage}
					onChangeRowsPerPage={this.onChangeRows} />
			</mui.TableRow>
		);
	}


	/**
	 * Functional alias of `paginationRowsLabel`.
	 *
	 * @return {String}
	 */
	getPaginationRowsLabel() {
		return this.paginationRowsLabel;
	}


	/**
	 * Page changed.
	 *
	 * @param {Event} e
	 * @param {Number} p
	 * @return {void}
	 */
	onChangePage(e, p) {
		this.props.onChangePage(p);
	}


	/**
	 * Visible rows per page changed.
	 *
	 * @param {Event} e
	 * @param {MenuItem} MenuItem Selected option
	 * @return {void}
	 */
	onChangeRows(e, menuItem) {
		this.props.onChangeRows(menuItem.props.value);
	}


	/**
	 * Get the pagination count value to use.
	 *
	 * When not specified, `-1` (indeterminate) is used.
	 * 
	 * @return {Integer}
	 */
	get paginationCount() {
		if (![null, undefined].includes(this.props.paginationCount)) {
			return this.props.paginationCount;
		}
		else return -1;
	}


	/**
	 * Get the label for the pagination component "Rows to display" text.
	 *
	 * @return {String}
	 */
	get paginationRowsLabel() {
		const xy = `${this.startRecord} - ${this.endRecord}`;
		if (this.paginationCount === 0) return "-";
		else if (this.paginationCount === -1) return xy;
		else return `${xy} of ${this.paginationCount}`;
	}


	/**
	 * Get the rows per page options.
	 *
	 * This will be disabled when we're on mobile to save space.
	 *
	 * @return {Array}
	 */
	get paginationRowsPerPageOptions() {
		return (this.props.isMobile ? [] : [10, 25, 50]);
	}


	/**
	 * Get the ID of the last record we're displaying.
	 * 
	 * This will be inaccurate when we don't have pagination 
	 * properties set, or when `paginationCount` is indeterminate.
	 * 
	 * @return {Integer}
	 */
	get endRecord() {
		const end = this.endRecordMax;
		return ((end > this.paginationCount) ? this.paginationCount : end);
	}


	/**
	 * Get the ID of the last possible record based on our pagination.
	 * 
	 * This will be inaccurate when we don't have pagination.
	 * 
	 * @return {Integer}
	 */
	get endRecordMax() {
		return ((this.props.paginationPage + 1) * this.props.paginationRows);
	}


	/**
	 * Get the ID of the first record we're displaying.
	 * 
	 * This will be inaccurate when we don't have pagination.
	 * 
	 * @return {Integer}
	 */
	get startRecord() {
		return ((this.endRecordMax - this.props.paginationRows) + 1);
	}


	/**
	 * Header cell styles.
	 * 
	 * @type {Object}
	 */
	static headerCellStyles = {padding: "1rem 0.5rem"};

	/**
	 * Header typography styles.
	 * 
	 * @type {Object}
	 */
	static headerTypoStyles = {fontWeight: "bold"};

}

export default withMobile(Table);
