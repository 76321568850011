import React from "react";
import DomainDetails from "./DomainDetails.js";
import {Box, Typography} from "@material-ui/core";

/**
 * Domain details cell
 *
 * A component wrapping `DomainDetails` to add a label above the details panel.
 *
 * Please refer to the source for details of the available props.
 *
 * @package SEC
 * @subpackage Spoi
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class DomainDetailsCell extends React.PureComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Box>
				<Typography>{this.props.label}</Typography>
				<Box mt={0.5}>
					<DomainDetails
						details={this.props.details}
						variant={(this.props.variant || "body2")} />
				</Box>
			</Box>
		);
	}

}

export default DomainDetailsCell;
