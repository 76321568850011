import React from "react";
import Component from "App/Component.js";
import AuditEventPicker from "Components/AuditEventPicker.js";
import UserPicker from "Components/UserPicker.js";
import mobile from "Helpers/Mobile.js";
import rem from "Helpers/Rem.js";
import withSnackbar from "Hoc/withSnackbar.js";
import AuditService from "Services/AuditService.js";
import AdminAuditsTable from "./AdminAuditsTable.js";
import strftime from "strftime";
import * as mui from "@material-ui/core";
import {DatePicker} from "@material-ui/pickers";
import {AdminAuditsSearch as Strings} from "Resources/Strings.js";

/**
 * Admin audit search
 *
 * @package SEC
 * @subpackage Views
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class AdminAuditsSearch extends Component {

	/**
	 * Constructor.
	 *
	 * @param {optionsect} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * State
		 *
		 * @type {optionsect}
		 */
		this.state = {

			/**
			 * Audits
			 *
			 * @type {Array}
			 */
			audits: [],

			/**
			 * Loading
			 *
			 * @type {Boolean}
			 */
			loading: true,

			/**
			 * User filter
			 *
			 * @type {optionsect}
			 */
			user: null,

			/**
			 * Event filter
			 *
			 * @type {optionsect}
			 */
			event: null,

			/**
			 * System user only
			 *
			 * @type {Boolean}
			 */
			system: false,

			/**
			 * System events hidden
			 * 
			 * @type {Boolean}
			 */
			systemh: true,

			/**
			 * `datemin` filter
			 *
			 * @type {String}
			 */
			datemin: strftime("%Y-%m-%d", new Date()),

			/**
			 * `datemax` filter
			 *
			 * @type {String}
			 */
			datemax: strftime("%Y-%m-%d", new Date())

		};

		/**
		 * All audits
		 *
		 * @type {Array}
		 */
		this.audits = [];

	}


	/**
	 * Component mounted.
	 *
	 * @return {void}
	 */
	componentDidMount() {
		this.update(this.getFilters());
	}


	/**
	 * Component updated.
	 *
	 * @param {Object} prevProps
	 * @param {Object} prevState
	 * @return {void}
	 */
	componentDidUpdate(prevProps, prevState) {
		const filters = this.getFilters();
		if (!this.compareFilters(filters, this.getFilters(prevState))) {
			this.update(filters);
		}
	}


	/**
	 * Update the view.
	 *
	 * @param {Object} filters
	 * @return {void}
	 */
	update(filters) {
		this.setState({loading: true});
		AuditService.get(filters).then(audits => {
			this.audits = audits;
			this.updateActive();
		}).catch(() => {
			this.setState({error: true, audits: []});
			this.props.snackbar(Strings.error, "error");
		}).finally(() => this.setState({loading: false}));
	}


	/**
	 * Update active audits.
	 *
	 * @return {void}
	 */
	updateActive() {
		if (!this.state.systemh || this.state.system || this.state.event) {
			this.setState({audits: this.audits});
		}
		else this.setState({audits: this.audits.filter(a => a.EventUser)});
	}


	/**
	 * Update system hidden status.
	 *
	 * @param {Boolean} systemh
	 * @return {void}
	 */
	systemhChanged(systemh) {
		this.setState({systemh});
		setTimeout(() => this.updateActive());
	}


	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<mui.Box>
				<mui.Box mb={2}>
					<mui.Typography variant="h6">
						{Strings.title}
					</mui.Typography>
				</mui.Box>

				<mui.Box mb={4}>{this.renderOptions()}</mui.Box>

				<mui.Box my={4}><mui.Divider /></mui.Box>

				<AdminAuditsTable
					audits={this.state.audits}
					loading={this.state.loading} />
			</mui.Box>
		);
	}


	/**
	 * Render options.
	 *
	 * @return {ReactNode}
	 */
	renderOptions() {
		return (
			<mui.Box style={this.stylesOptionsContainer}>
				{this.renderOptionsUserSystem()}
				{this.renderOptionsDates()}
				{this.renderOptionsEvent()}
				{this.renderOptionsUser()}
			</mui.Box>
		);
	}


	/**
	 * Render options (event input).
	 *
	 * @return {ReactNode}
	 */
	renderOptionsEvent() {
		return (
			<AuditEventPicker
				disabled={this.state.loading || this.state.system}
				value={this.state.event}
				onChange={event => this.setState({event})} />
		);
	}


	/**
	 * Render options (user input).
	 *
	 * @return {ReactNode}
	 */
	renderOptionsUser() {
		return (
			<mui.Box>
				<UserPicker
					customer={true}
					disabled={this.state.loading || this.state.system}
					value={this.state.user}
					onChange={user => this.setState({user})} />
			</mui.Box>
		);
	}


	/**
	 * Render "system only" / "hide system" options.
	 *
	 * @return {ReactNode}
	 */
	renderOptionsUserSystem() {
		return (
			<React.Fragment>
				<mui.FormGroup>
					<mui.FormControlLabel
						label="System events only"
						control={this.renderOptionsUserSystemCheckbox()} />
				</mui.FormGroup>
				<mui.FormGroup style={{order: (this.mobile ? -1 : 0)}}>
					<mui.FormControlLabel
						label="Hide system events"
						control={this.renderOptionsSystemHiddenCheckbox()} />
				</mui.FormGroup>
			</React.Fragment>
		);
	}


	/**
	 * Render "system only" checkbox.
	 *
	 * @return {ReactNode}
	 */
	renderOptionsUserSystemCheckbox() {
		return (
			<mui.Checkbox
				color="primary"
				checked={this.systemOnlyChecked}
				disabled={this.systemOnlyDisabled}
				onChange={e => this.setState({system: e.target.checked})} />
		);
	}


	/**
	 * Render "hide system" checkbox.
	 *
	 * @return {ReactNode}
	 */
	renderOptionsSystemHiddenCheckbox() {
		return (
			<mui.Checkbox
				color="primary"
				checked={this.systemHiddenChecked}
				disabled={this.systemHiddenDisabled}
				onChange={e => this.systemhChanged(e.target.checked)} />
		);
	}


	/**
	 * Render options (datepickers).
	 *
	 * @return {ReactNode}
	 */
	renderOptionsDates() {
		return (
			<React.Fragment>
				<DatePicker
					autoOk
					DialogProps={this.constructor.DialogProps}
					disableFuture
					disabled={this.state.loading}
					label="Date (start)"
					format="DD-MM-YYYY"
					value={this.state.datemin}
					onChange={d => this.setDate(d, "datemin")} />
				<DatePicker
					autoOk
					DialogProps={this.constructor.DialogProps}
					disableFuture
					disabled={this.state.loading}
					label="Date (end)"
					format="DD-MM-YYYY"
					value={this.state.datemax}
					onChange={d => this.setDate(d, "datemax")} />
			</React.Fragment>
		);
	}


	/**
	 * Get options for submission.
	 *
	 * @param {Object} state Compare to this state
	 * @return {Object}
	 */
	getFilters(state=this.state) {
		const options = {};

		/**
		 * Dates
		 */
		options.datemin = state.datemin;
		options.datemax = state.datemax;

		/**
		 * Event
		 */
		if (state.event) {
			options.event = state.event;
		}
		else options.event = null;

		/**
		 * User
		 */
		if (!state.system || state.event) {
			options.user = (state.user ? state.user.Username : "*");
		}
		else options.user = null;

		return options;
	}


	/**
	 * Compare two sets of filters.
	 *
	 * @param {Object} old
	 * @param {Object} now
	 * @return {Boolean}
	 */
	compareFilters(old, now) {
		return Object.keys(old).every(key => old[key] === now[key]);
	}


	/**
	 * Update a date in state.
	 *
	 * @param {DayJS} d Date
	 * @param {String} state State property to update
	 * @return {void}
	 */
	setDate(d, state) {
		this.setState({[state]: (d ? d.format("YYYY-MM-DD") : null)});
	}


	/**
	 * Get whether to check the "system only" checkbox.
	 *
	 * @return {Boolean}
	 */
	get systemOnlyChecked() {
		if (this.state.systemh) return false;
		else if (this.state.event !== null) return false;
		else if (this.state.user !== null) return false;
		else return this.state.system;
	}


	/**
	 * Get whether to disable the "system only" checkbox.
	 *
	 * @return {Boolean}
	 */
	get systemOnlyDisabled() {
		const loading = this.state.loading;
		const systemh = this.state.systemh;
		const event = (this.state.event !== null);
		const user = (this.state.user !== null);
		return (loading || systemh || event || user);
	}


	/**
	 * Get whether to check the "system hidden" checkbox.
	 *
	 * @return {Boolean}
	 */
	get systemHiddenChecked() {
		if (this.state.system) return false;
		else if (this.state.event !== null) return false;
		else return this.state.systemh;
	}


	/**
	 * Get whether to disable the "system hidden" checkbox.
	 *
	 * @return {Boolean}
	 */
	get systemHiddenDisabled() {
		const loading = this.state.loading;
		const system = this.state.system;
		const event = (this.state.event !== null);
		const user = (this.state.user !== null);
		return (loading || system || event || user);
	}


	/**
	 * Get whether mobile.
	 *
	 * @return {Boolean}
	 */
	get mobile() {
		return mobile(this.props.width);
	}


	/**
	 * Styles for options container.
	 *
	 * @return {Options}
	 */
	get stylesOptionsContainer() {
		return {
			display: "grid",
			gridColumnGap: rem(1),
			gridRowGap: rem(2),
			gridTemplateColumns: this.mobile ? "100%" : "repeat(2, 1fr)",
			maxWidth: rem(60)
		};
	}


	/**
	 * `DialogProps`
	 * 
	 * @type {Objectg}
	 */
	static DialogProps = {
		disableScrollLock: true,
		PaperProps: {elevation: 0},
		transitionDuration: 0
	};

}

export default mui.withWidth()(withSnackbar(AdminAuditsSearch));
