/**
 * IndexedDB utilities
 *
 * @package SEC
 * @subpackage Helpers
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class IndexedDB {

	/**
	 * Verify IndexedDB availability.
	 *
	 * @return {Promise} Resolves if available; rejects if not
	 */
	static verifyAvailability() {
		return new Promise((resolve, reject) => {
			if (!window.indexedDB) reject();
			const open = window.indexedDB.open("indexeddb");
			open.onerror = reject;
			open.onsuccess = resolve;
		});
	}

}

export default IndexedDB;
