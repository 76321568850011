export default {

	/**
	 * API URI
	 * 
	 * @type {String|null}
	 */
	api: null,

	/**
	 * Authentication token
	 *
	 * @type {String|null}
	 */
	auth: null,

	/**
	 * Cached resources
	 *
	 * @type {Object}
	 */
	cache: {},

	/**
	 * Dark mode
	 * 
	 * @type {Boolean}
	 */
	dark: false,

	/**
	 * Messages left unread
	 *
	 * @type {Array}
	 */
	messages: [],

	/**
	 * Permission names we have
	 *
	 * @type {Array}
	 */
	permissions: [],

	/**
	 * Push notifications banner dismissed on homepage
	 * 
	 * @type {Boolean}
	 */
	pushBanner: true,

	/**
	 * User object
	 * 
	 * @type {Object|null}
	 */
	user: null

};
