import Dialog from "Components/Dialog.js";
import {memo} from "react";

export default memo(props => {

	return (
		<Dialog
			content="Are you sure you want to send this ITP?"
			fullWidth={true}
			loading={props.submitting}
			onClose={props.onClose}
			onOk={props.onSubmit}
			open={props.open}
			title="Send Instruction to Proceed (ITP)" />
	);

});
