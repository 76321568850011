import React from "react";
import DashboardCard from "Components/DashboardCard.js";

/**
 * Timesheets dashboard card
 * 
 * @package SEC
 * @subpackage Timesheets
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class TimesheetsDashboardCard extends React.PureComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<DashboardCard
				buttonLbl="Submit Timesheet"
				caption="Timesheets"
				text={this.constructor.text}
				title="My Timesheets"
				uri="/timesheets/submit"
				uriArea="/timesheets" />
		);
	}


	/**
	 * Text.
	 *
	 * @type {Array}
	 */
	static text = [
		"Submit a new timesheet.",
		"View timesheets you've submitted."
	];

}

export default TimesheetsDashboardCard;
