import React from "react";
import EditorBase from "./EditorBase.js";
import View from "App/View.js";
import AttachmentsList from "Components/AttachmentsList.js";
import Checkbox from "Components/Checkbox.js";
import EmailPickerContacts from "Components/EmailPickerContacts.js";
import EnquiryPicker from "Components/EnquiryPicker.js";
import Row from "Components/RowLabelled.js";
import Html from "Helpers/Html.js";
import withSnackbar from "Hoc/withSnackbar";
import * as mui from "@material-ui/core";
import InitialState from "./DataRequestsEditorState.js";
import DataRequestsAttachment from "./DataRequestsAttachment.js";
import DataRequestsEmail from "./DataRequestsEmail.js";
import DataRequestService from "Services/DataRequestService";
import {connect} from "react-redux";
import {DataRequestsEditor as Strings} from "Resources/Strings.js";

/**
 * Data requests editor
 *
 * @package SEC
 * @subpackage Views
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class DataRequestsEditor extends EditorBase {

	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * Attachment files
		 *
		 * @type {Array}
		 */
		this.files = [];

		/**
		 * Configure the initial state
		 */
		this.state = {...this.state, ...this.initialState};

	}


	/**
	 * Create a new data request object from state. 
	 *
	 * @return {Object}
	 */
	createDomainObjectFromState() {
		return {
			enquiry: this.state.enquiry,
			email: this.state.email,
			recipients: this.resolveRecipientsFromState(),
			attach_DOC2: this.state.clogo ? this.state.attach_DOC2 : false,
			attach_DOC3: this.state.clogo ? this.state.attach_DOC3 : false,
			attach_DOC4: this.state.clogo ? this.state.attach_DOC4 : false,
			attach_DataSheets: this.state.attach_DataSheets,
			attachments: this.state.attachments,
			_clogo: this.state.clogo,
			_files: this.files
		};
	}


	/**
	 * Populate state from data request object.
	 *
	 * @param {Object} domain
	 * @return {void}
	 */
	populateStateFromDomainObject(domain) {
		this.files = domain._files || [];
		super.populateStateFromDomainObject(domain, {
			...domain,
			to: DataRequestService.getAddresses(domain, "to"),
			cc: DataRequestService.getAddresses(domain, "cc"),
			bcc: DataRequestService.getAddresses(domain, "bcc"),
			email: Html.sanitizeTemplate(domain.email || ""),
			clogo: domain._clogo || null
		});
	}


	/**
	 * Update active data request over network with given body data.
	 *
	 * @param {Object} request
	 * @return {Promise}
	 */
	performActiveDomainObjectNetworkUpdate(request) {
		return DataRequestService.update(this.state.domain.id, request);
	}


	/**
	 * Get whether to render the action button.
	 *
	 * @return {Boolean}
	 */
	shouldRenderFab() {
		return (super.shouldRenderFab() && !this.sent);
	}


	/**
	 * Validate ahead of submission.
	 *
	 * @return {void}
	 */
	validate() {
		if (this.draft && !this.local && !this.state.enquiry) {
			this.snackbar(Strings.enquiryValidate, "error");
			return false;
		}
		else if (this.state.to.length === 0) {
			this.snackbar(Strings.recipientValidate, "error");
			return false;
		}
		else return true;
	}


	/**
	 * Load new.
	 *
	 * @return {void}
	 */
	loadNew() {
		this.setState({attachments: []});
		DataRequestService.getEmail().then(email => {
			this.setState({email: Html.sanitizeTemplate(email)});
		}).catch(() => {
			this.snackbar(Strings.emailLoadFailure, "error");
		}).finally(() => this.setState({loading: false}));
	}


	/**
	 * Load from network.
	 *
	 * @param {Integer} id
	 * @return {void}
	 */
	loadFromNetwork(id) {
		DataRequestService.get(id).then(request => {
			this.populateStateFromDomainObject(request);
			if (request.sent) this.snackbar(Strings.sentAlert);
		}).catch(e => this.handleLoadError(e));
	}


	/**
	 * Add attachments from filepicker.
	 *
	 * @param {FileList} attachments
	 * @return {void}
	 */
	addAttachments(attachments) {
		const names = this.state.attachments;
		for (let i = 0; i < attachments.length; i++) {
			names.push(attachments[i].name);
			this.files.push(attachments[i]);
		}
		this.setState({attachments: names});
	}


	/**
	 * Display an attachment.
	 * 
	 * @param {String} a Filename of clicked attachment
	 * @return {void}
	 */
	handleAttachmentsClick(a) {
		const upload = this.files.find(f => (f.name === a));
		this.setState({activeAttachment: (upload || a), activeAttachmentView: true});
	}


	/**
	 * Update attachments to match attachments in the filepicker.
	 *
	 * @param {Array} attachments
	 * @return {void}
	 */
	updateAttachments(attachments) {
		const id = this.state.domain ? this.state.domain.id : null;
		this.state.attachments.forEach(at => {
			if (!attachments.includes(at)) {
				if (!this.files.filter(f => f.name === at)[0]) {
					DataRequestService.deleteAttachment(id, at).then(() => {
						this.snackbar(Strings.deleteAttachOk, "success");
					}).catch(() => {
						attachments = [...attachments, at];
						this.snackbar(Strings.deleteAttachError, "error");
					});
				}
				else this.files = this.files.filter(f => f.name !== at);
			}
		});
		this.setState({attachments});
	}


	/**
	 * Render form.
	 *
	 * @return {ReactNode}
	 */
	renderForm() {
		return (
			<View>
				{this.renderEnquiry()}

				{this.renderRecipients()}

				{!this.archived ? <mui.Divider /> : null}

				{!this.sent ? this.renderAttachments() : null}

				{!this.editing ? this.renderCustomerLogo() : null}

				{this.draft ? this.renderDocumentGeneration() : null}

				{!this.archived ? this.renderAttachmentsCurrent() : null}

				{!this.archived ? <mui.Divider /> : null}

				{!this.archived ? this.renderEmail() : null}

				{!this.local ? this.renderAttachment() : null}
			</View>
		);
	}


	/**
	 * Attachment dialog.
	 *
	 * @return {void}
	 */
	renderAttachment() {
		return (
			<DataRequestsAttachment
				attachment={this.state.activeAttachment}
				onClose={() => this.setState({activeAttachmentView: false})}
				open={this.state.activeAttachmentView}
				request={this.state.domain?.name} />
		);
	}


	/**
	 * Render attachments input.
	 *
	 * @return {ReactNode}
	 */
	renderAttachments() {
		return (
			<Row label="Attachments">
				<input
					multiple
					type="file"
					onChange={e => this.addAttachments(e.target.files)} />
			</Row>
		);
	}


	/**
	 * Render current attachments viewer.
	 *
	 * @return {ReactNode}
	 */
	renderAttachmentsCurrent() {
		return (
			<Row label="Current attachments">
				<AttachmentsList
					value={this.state.attachments}
					disabled={this.sent}
					onClick={e => this.handleAttachmentsClick(e)}
					onChange={v => this.updateAttachments(v)} />
			</Row>
		);
	}


	/**
	 * Render customer logo picker.
	 *
	 * @return {ReactNode}
	 */
	renderCustomerLogo() {
		return (
			<Row label="Customer logo">
				<input
					accept="image/jpeg,image/png"
					onChange={e => this.setState({clogo: e.target.files[0]})}
					type="file" />
			</Row>
		);
	}


	/**
	 * Render document generation checkmarks.
	 *
	 * @return {ReactNode}
	 */
	renderDocumentGeneration() {
		return (
			<Row label="Document generation">
				<mui.FormGroup row>
					{this.docgen("DOC2", "attach_DOC2")}
					{this.docgen("DOC3", "attach_DOC3")}
					{this.docgen("DOC4", "attach_DOC4")}
					{this.docgen("Data sheets", "attach_DataSheets", false)}
				</mui.FormGroup>
			</Row>
		);
	}


	/**
	 * Render email components.
	 *
	 * @return {ReactNode}
	 */
	renderEmail() {
		return (
			<DataRequestsEmail
				disabled={this.sent}
				email={this.state.email}
				open={this.sent}
				onChange={email => this.setState({email})} />
		);
	}


	/**
	 * Render enquiry picker.
	 *
	 * @return {ReactNode}
	 */
	renderEnquiry() {
		return (
			<EnquiryPicker
				disabled={!this.draft}
				initial={this.state.enquiry}
				onChange={(id, enquiry) => this.setState({enquiry})} />
		);
	}


	/**
	 * Render recipient inputs.
	 *
	 * @return {ReactNode}
	 */
	renderRecipients() {
		return (
			<React.Fragment>
				{this.renderRecipientsRow("To", "to")}
				{this.renderRecipientsRow("Cc", "cc")}
				{this.renderRecipientsRow("Bcc", "bcc")}
			</React.Fragment>
		);
	}


	/**
	 * Render an individual recipient input.
	 *
	 * @param {String} label Label
	 * @param {String} state State property name
	 * @return {ReactNode}
	 */
	renderRecipientsRow(label, state) {
		return (
			<Row label={label}>
				<EmailPickerContacts
					customer={this.activeCustomerId}
					disabled={this.sent}
					value={this.state[state]}
					onChange={v => this.setState({[state]: v})} />
			</Row>
		);
	}


	/**
	 * Create a new checkbox control for document generation.
	 *
	 * @param {String} label Label
	 * @param {String} s State property name
	 * @param {Boolean} c optional Require `clogo` set in state (`true`)
	 * @return {ReactNode}
	 */
	docgen(label, s, c=true) {
		return (
			<Checkbox
				label={label}
				checked={!(this.state.clogo || !c) ? false : this.state[s]}
				disabled={!(this.state.clogo || !c)}
				onChange={v => this.setState({[s]: v})} />
		);
	}


	/**
	 * Convert `to`/`cc`/`bcc` inputs into a `recipients` object 
	 * for attachment to a data request domain object.
	 *
	 * @return {Object}
	 */
	resolveRecipientsFromState() {
		const recipients = [];
		["to", "cc", "bcc"].forEach(mode => {
			this.state[mode].forEach(address => {
				recipients.push({address, mode});
			});
		});
		return recipients;
	}


	/**
	 * Get active customer ID.
	 *
	 * @return {String|null}
	 */
	get activeCustomerId() {
		return (this.state.enquiry ? this.state.enquiry.CustomerID : null);
	}


	/**
	 * Get whether request has been archived.
	 *
	 * @return {Boolean}
	 */
	get archived() {
		if (!this.state.domain) return false;
		else return (!!this.state.domain.archived);
	}


	/**
	 * Get whether request has been sent.
	 *
	 * @return {Boolean}
	 */
	get sent() {
		if (!this.state.domain) return false;
		else return (!!this.state.domain.sent);
	}


	/**
	 * Get the name of the type to give to `Post` for new draft saves.
	 *
	 * @return {String}
	 */
	get localPostDomainType() {
		return "drequest";
	}


	/**
	 * Use "info" snackbar on save success.
	 *
	 * This was requested by SEC on 23-01-2020 to better distinguish 
	 * from the green success banner used when *sending* a request.
	 *
	 * @return {String}
	 */
	get snackbarSubmitVariant() {
		return "info";
	}


	/**
	 * Strings.
	 *
	 * @return {Object}
	 */
	get strings() {
		return Strings;
	}


	/**
	 * Redirect URIs.
	 *
	 * @return {Object}
	 */
	get uris() {
		return {loadErr: "/data-requests", submitSuccess: "/data-requests"};
	}


	/**
	 * Initial state.
	 *
	 * @return {Object}
	 */
	get initialState() {
		return {
			...InitialState,
			cc: [...InitialState.cc, this.props.user.EmailAddress]
		};
	}

}

export default connect(s => (
	{user: s.user}
))(withSnackbar(DataRequestsEditor));
