import React from "react";
import rem from "Helpers/Rem.js";
import {Box} from "@material-ui/core";

const CDC_STYLES = {display: "grid", gridRowGap: rem(0.5)};

export default ({children}) => (
	<Box style={CDC_STYLES}>
		{children}
	</Box>
);
