import React from "react";
import ReactHelper from "Helpers/ReactHelper.js";
import {IconButton as IconButtonMui, Tooltip} from "@material-ui/core";

/**
 * Icon button
 *
 * An abstraction of the Material component.
 * 
 * @package SEC
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class IconButton extends React.PureComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Tooltip title={(this.props.tooltip || "")}>
				<IconButtonMui
					disabled={this.props.disabled}
					color={(this.props.color || "primary")}
					onClick={this.props.onClick}
					size={this.props.size}
					style={this.style}>
					{ReactHelper.render(this.props.icon)}
				</IconButtonMui>
			</Tooltip>
		);
	}


	/**
	 * Styles.
	 * 
	 * @return {Object}
	 */
	get style() {
		return {
			height: this.props.dims,
			width: this.props.dims
		};
	}

}

export default IconButton;
