import React from "react";
import Component from "App/Component.js";
import Flex from "Components/Flex.js";
import IconButton from "Components/IconButton.js";
import MaterialTable from "Components/MaterialTable.js";
import String from "Components/Stringx.js";
import TimesheetService from "./TimesheetService.js";
import TimesheetStatusChip from "./TimesheetStatusChip.js";
import TimesheetsTableToolbar from "./TimesheetsTableToolbar.js";
import dayjs from "dayjs";
import withSnackbar from "Hoc/withSnackbar.js";
import {Check as ApproveIcon, Close as RejectIcon} from "@material-ui/icons";

/**
 * Timesheets table
 * 
 * @package SEC
 * @subpackage Timesheets
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class TimesheetsTable extends Component {

	/**
	 * Constructor.
	 * 
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * State
		 *
		 * @type {Object}
		 */
		this.state = {

			/**
			 * Loading?
			 *
			 * @type {Boolean}
			 */
			loading: false,

			/**
			 * Filters
			 * 
			 * @type {Object}
			 */
			filters: {

				/**
				 * Installer
				 * 
				 * @type {Object|null}
				 */
				Installer: null,

				/**
				 * Maximum `SheetDate`
				 *
				 * @type {String|null} YYYY-MM-DD
				 */
				SheetDateMax: (props.initialSheetDateMax || null),

				/**
				 * Minimum `SheetDate`
				 *
				 * @type {String|null} YYYY-MM-DD
				 */
				SheetDateMin: (props.initialSheetDateMin || null),

				/**
				 * Statuses
				 *
				 * @type {Array} `null`/`true`/`false` entries
				 */
				Statuses: (props.initialStatuses || [])

			},

			/**
			 * Table key
			 *
			 * Used to force table to get new data.
			 *
			 * @type {Integer}
			 */
			tableKey: 0

		};

	}


	/**
	 * Get timesheets for the table.
	 *
	 * @param {Object} query
	 * @return {Promise} Resolves with `material-table` data object
	 */
	getData = query => {

		const params = {
			Limit: query.pageSize,
			Offset: (query.pageSize * query.page),
			Installer: this.state.filters.Installer?.InstallerId,
			SheetDateMax: this.state.filters.SheetDateMax,
			SheetDateMin: this.state.filters.SheetDateMin,
			Statuses: JSON.stringify(this.state.filters.Statuses)
		};

		return TimesheetService.get(params).then(timesheets => {
			return {
				data: timesheets.objects,
				page: query.page,
				totalCount: timesheets.count
			};
		});

	};


	/**
	 * Timesheet approved.
	 * 
	 * @param {Object} ts
	 * @return {void}
	 */
	handleApprove = ts => this.handleReview(ts, true);


	/**
	 * Timesheet rejected.
	 * 
	 * @param {Object} ts
	 * @return {void}
	 */
	handleReject = ts => this.handleReview(ts, false);


	/**
	 * Timesheet reviewed.
	 *
	 * @param {Object} ts
	 * @param {Boolean} accepted
	 * @return {void}
	 */
	handleReview = (ts, accepted) => {

		this.setState({loading: true});

		TimesheetService.review(ts.SheetId, accepted).then(() => {
			this.setState({tableKey: (this.state.tableKey + 1)});
			this.props.snackbar(`Timesheet ${(accepted ? "accepted" : "rejected")}.`, "success");
		}).catch(e => {
			this.props.snackbar(e);
		}).finally(() => {
			this.setState({loading: false});
		});

	};


	/**
	 * Filter value changed.
	 *
	 * @param {mixed} value
	 * @param {String} filter
	 * @return {void}
	 */
	handleChangeFilter = (value, filter) => {
		this.setState({filters: {...this.state.filters, [filter]: value}});
	};


	/**
	 * Date column renderer.
	 *
	 * @param {Object} ts
	 * @param {Boolean} stacked Using stacked layout
	 * @return {String}
	 */
	renderDate = (ts, stacked) => {
		const date = (new dayjs(ts.SheetDate)).format(`DD/MM/YYYY`);
		return <String bold={stacked} str={`${date} (${ts.SheetSegment})`} variant="inherit" />;
	};


	/**
	 * Status column renderer.
	 *
	 * @param {Object} ts
	 * @return {ReactNode}
	 */
	renderStatus = ts => <TimesheetStatusChip status={ts.Status} />;


	/**
	 * Time column renderer.
	 *
	 * @param {Object} ts
	 * @return {String}
	 */
	renderTime = ts => `${ts.StartTime} - ${ts.FinishTime}`;


	/**
	 * Total hours column renderer.
	 *
	 * @param {Object} ts
	 * @param {Boolean} stacked
	 * @return {Integer}
	 */
	renderTotalHours = (ts, stacked) => {

		const total = (ts.WorkedHours + ts.WorkedHoursAdditional);

		if (!stacked) return total;
		else return `${total} Hours (${ts.WorkedHours} + ${ts.WorkedHoursAdditional})`;

	};


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<MaterialTable
				action={(this.props.withApprovalActions && this.actions)}
				columns={this.columns}
				data={this.getData}
				dataKey={this.state.tableKey}
				filters={this.state.filters}
				loading={this.state.loading}
				pageSize={this.props.pageSize}
				search={false}
				sorting={false}
				stackBreakpoint={1125}
				title="Timesheets"
				toolbar={this.renderToolbar} />
		);
	}


	/**
	 * Render toolbar.
	 *
	 * @param {Object} props See `MaterialTable`
	 * @return {ReactNode}
	 */
	renderToolbar = props => {
		return (
			<TimesheetsTableToolbar
				{...props}
				{...this.state.filters}
				onChangeFilter={this.handleChangeFilter} />
		);
	};


	/**
	 * `columns` for `material-table`.
	 *
	 * @param {Boolean} options.stacked Using stacked layout
	 * @return {Array}
	 */
	columns = ({stacked}) => {
		return [
			{
				field: "Status",
				title: "Status",
				render: this.renderStatus,
				gridWidth: "12rem"
			},
			{
				field: "SheetDate",
				title: "Date",
				render: row => this.renderDate(row, stacked),
				gridWidth: "15rem"
			},
			{
				field: "_time",
				title: "Time",
				render: this.renderTime,
				gridWidth: "15rem"
			},
			{
				field: "Project.Customer.Name",
				title: "Customer",
				gridWidth: "minmax(15rem, 1fr)"
			},
			{
				field: "Installer.InstallerName",
				title: "Installer",
				gridWidth: "minmax(15rem, 1fr)"
			},
			{
				field: "WorkedHours",
				title: "Hours",
				gridWidth: "11rem",
				hidden: stacked
			},
			{
				field: "WorkedHoursAdditional",
				title: "Add. Hours",
				gridWidth: "11rem",
				hidden: stacked
			},
			{
				field: "_totalhours",
				title: "Tot. Hours",
				render: row => this.renderTotalHours(row, stacked),
				gridWidth: "11rem"
			},
			{
				field: "_actions",
				title: "",
				render: row => this.renderActions(row),
				gridWidth: "9rem"
			}
		];
	};


	/**
	 * Render actions.
	 *
	 * @param {Object} ts Timesheet object
	 * @return {ReactNode}
	 */
	renderActions(ts) {
		if (ts.Status === null) {
			return (
				<Flex
					columnar={true}
					gap={0}>
					<IconButton
						icon={ApproveIcon}
						onClick={() => this.handleApprove(ts)}
						size="small"
						tooltip="Approve" />
					<IconButton
						icon={RejectIcon}
						onClick={() => this.handleReject(ts)}
						size="small"
						tooltip="Reject" />
				</Flex>
			);
		}
		else return null;
	}

}

export default withSnackbar(TimesheetsTable);
