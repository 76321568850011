import React from "react";
import Chart from "Components/Chart.js";
import HighlightTile from "Components/HighlightTile.js";
import AbstractError from "Errors/AbstractError.js";
import {Doughnut} from "react-chartjs-2";

/**
 * Health and safety NMPIs statistics insight base
 *
 * @package SEC
 * @subpackage Views
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class HealthSafetyNmpisStatisticsEnumInsight extends React.PureComponent {

	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<HighlightTile label={this.label}>
				<Chart
					data={this.data}
					labels={this.labels}
					chartComponent={Doughnut} />
			</HighlightTile>
		);
	}


	/**
	 * Get label for a statistic.
	 *
	 * @param {Object} stat
	 * @return {String}
	 */
	statLabel(stat) {
		return `${stat.label} (${Math.round(stat.percent)}%)`;
	}


	/**
	 * Data for chart.
	 *
	 * We aggregate by colon groups if there is the `group` prop.
	 *
	 * @return {Array}
	 */
	get data() {
		if (this.props.group) {
			return Object.values(this.dataColonGroups).map(s => s.count);
		}
		else return Object.keys(this.stats).map(s => this.stats[s].count);
	}


	/**
	 * Get data as aggregated colon groups.
	 *
	 * This just counts the data by "category", where the category 
	 * is the first component of the label split at colon delimiters.
	 *
	 * Returns an object indexed by category name, with the value as an 
	 * object that contains `count` (count of times category is present 
	 * in `data`) and `percent` (`count` as a percentage of the total 
	 * number of all items in `data`).
	 * 
	 * @return {Object}
	 */
	get dataColonGroups() {

		const data = {};

		/**
		 * Get counts
		 */
		Object.values(this.stats).forEach(stat => {
			const category = stat.label.split(": ")[0];
			if (!data.hasOwnProperty(category)) {
				data[category] = {count: 0, percent: null};
			}
			data[category].count += stat.count;
		});

		/**
		 * Get the total count
		 */
		const counts = Object.values(data).map(s => s.count);
		const count = counts.reduce((a, b) => a + b, 0);

		/**
		 * Calculate percentages
		 */
		Object.keys(data).forEach(cat => {
			data[cat].percent = ((data[cat].count / count) * 100);
		});

		return data;

	}


	/**
	 * Label for tile.
	 *
	 * @return {String}
	 */
	get label() {
		return this.constructor.name;
	}


	/**
	 * Labels for chart.
	 *
	 * @return {Array}
	 */
	get labels() {
		if (this.props.group) return this.labelsColonGroups;
		else return Object.values(this.stats).map(s => this.statLabel(s));
	}


	/**
	 * Get labels from aggregated colon group data.
	 *
	 * @return {Array}
	 */
	get labelsColonGroups() {
		const d = this.dataColonGroups;
		return Object.keys(d).map(stat => {
			return this.statLabel({label: stat, percent: d[stat].percent});
		});
	}


	/**
	 * Get statistics to use for data (from `props.stats`).
	 *
	 * @return {Object}
	 */
	get stats() {
		throw new AbstractError();
	}

}

export default HealthSafetyNmpisStatisticsEnumInsight;
