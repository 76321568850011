import Domain from "Domain/Domain.js";

/**
 * SPOI Purchase invoice item (raise)
 *
 * An item associated with an invoice raise request.
 *
 * @package SECGroup
 * @subpackage Spoi
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class SpoiPurchaseInvoiceRaiseItem extends Domain {

	/**
	 * Constructor.
	 * 
	 * @param {Object} obj optional Construction
	 * @return {self}
	 */
	constructor(obj={}) {
		super(obj);

		/**
		 * Amount
		 *
		 * @type {Float}
		 */
		this.Amount = 0;

		/**
		 * Description
		 *
		 * Note: Internal use only when displaying as a string!
		 * 
		 * @type {String}
		 */
		this.Description = null;

		/**
		 * Tax code
		 *
		 * This may be `null` when using the raise default.
		 * 
		 * @type {Object|null}
		 */
		this.TaxCode = null;

		Object.assign(this, obj);
	}

}

export default SpoiPurchaseInvoiceRaiseItem;
