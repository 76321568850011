import React from "react";
import EditorBase from "./EditorBase.js";
import View from "App/View";
import ActionBar from "Components/ActionBar.js";
import DialogAlert from "Components/DialogAlert.js";
import EnquiryPicker from "Components/EnquiryPicker.js";
import MultiSelectEnum from "Components/MultiSelectEnum.js";
import ProjectPicker from "Components/ProjectPicker.js";
import Row from "Components/RowLabelled.js";
import Time from "Helpers/Time.js";
import withSnackbar from "Hoc/withSnackbar";
import NmpiService from "Services/NmpiService.js";
import InitialState from "./HealthSafetyNmpisFormState.js";
import HealthSafetyNmpisDialog from "./HealthSafetyNmpisDialog.js";
import HealthSafetyNmpisCloseDialog from "./HealthSafetyNmpisCloseDialog.js";
import HealthSafetyNmpisDrafts from "./HealthSafetyNmpisDrafts.js";
import ReporteePicker from "./HealthSafetyNmpisReporteePicker.js";
import * as mui from "@material-ui/core";
import {DateTimePicker} from "@material-ui/pickers";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {HealthSafetyNmpisForm as Strings} from "Resources/Strings.js";
import {HealthSafetyNmpisComplianceDialog} from "Resources/Strings.js";

/**
 * Health & safety (NMPIs) form
 *
 * @package SEC
 * @subpackage Views
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class HealthSafetyNmpisForm extends EditorBase {

	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props, InitialState);

		/**
		 * State
		 *
		 * @type {Object}
		 */
		this.state = {

			...this.state,

			/**
			 * Enquiry object
			 * 
			 * @type {Object|null}
			 */
			enquiry: (props.enquiry || InitialState.Enquiry),

			/**
			 * Incident date time
			 *
			 * @type {String|null}
			 */
			incidentDateTime: (props.date ? `${props.date} 09:00` : null),

			/**
			 * Display info dialog
			 *
			 * @type {Boolean}
			 */
			infoDialog: false,

			/**
			 * Display close/open dialog
			 *
			 * @type {Boolean}
			 */
			closeDialog: false,

			/**
			 * Render the compliance dialog
			 *
			 * @type {Boolean}
			 */
			complianceDialog: !props.noComplianceDialog

		};

		/**
		 * Form
		 *
		 * @type {ReactRef}
		 */
		this.form = React.createRef();

	}


	/**
	 * Create NMPI object from state.
	 * 
	 * @return {Object}
	 */
	createDomainObjectFromState() {
		return {
			Enquiry: (this.state.enquiry?.Id || this.state.enquiry),
			ReportedTo: this.state.reportedTo,
			ReportedBy: this.state.reportedBy,
			ReportedByPhone: this.state.reportedByPhone,
			IncidentDateTime: this.state.incidentDateTime,
			Contractor: this.state.contractor,
			Details: this.state.details,
			incidents: this.state.nmpis.map(v => v.key),
			probables: this.state.probables.map(v => v.key)
		};
	}


	/**
	 * Populate the state from an NMPI object.
	 *
	 * @param {Object} nmpi
	 * @return {void}
	 */
	populateStateFromDomainObject(nmpi) {
		super.populateStateFromDomainObject(nmpi, {
			enquiry: nmpi.Enquiry,
			contractor: nmpi.Contractor || "",
			details: nmpi.Details || "",
			contractorSec: ["", null].includes(nmpi.Contractor),
			enquirySec: (nmpi.Enquiry === null),
			incidentDateTime: Time.ymdhmFromDt(nmpi.IncidentDateTime),
			nmpis: nmpi.incidents,
			probables: nmpi.probables,
			reportedBy: nmpi.ReportedBy,
			reportedByPhone: nmpi.ReportedByPhone,
			reportedTo: nmpi.ReportedTo
		});
	}


	/**
	 * Create the given domain object over the network.
	 *
	 * @param {Object} nmpi
	 * @return {Promise}
	 */
	performActiveDomainObjectNetworkCreate(nmpi) {
		return NmpiService.create(nmpi);
	}


	/**
	 * Update the active domain object over network to have the given body.
	 *
	 * @param {Object} nmpi
	 * @return {Promise}
	 */
	performActiveDomainObjectNetworkUpdate(nmpi) {
		return NmpiService.update(this.state.domain.Name, nmpi);
	}


	/**
	 * Loading from network.
	 *
	 * @param {String} name
	 * @return {void}
	 */
	loadFromNetwork(name) {
		NmpiService.getNmpi(name).then(nmpi => {
			this.populateStateFromDomainObject(nmpi);
		}).catch(e => this.handleLoadError(e));
	}


	/**
	 * Load new
	 */
	loadNew() {
		this.setState({
			...InitialState,
			enquiry: (this.props.enquiry || InitialState.enquiry),
			incidentDateTime: (this.props.date ? `${this.props.date} 09:00` : null)
		});
		super.loadNew();
	}


	/**
	 * Refresh active NMPI over network.
	 *
	 * @return {void}
	 */
	refresh() {
		this.setState({loading: true});
		this.loadFromNetwork(this.state.domain.Name);
	}


	/**
	 * Validate for submission.
	 *
	 * @return {void}
	 */
	validate() {
		const form = this.form.current.reportValidity();
		const enquiry = (this.state.enquiry || this.state.enquirySec);
		const contractor = (this.state.contractor || this.state.contractorSec);
		const reportedTo = (!!this.state.reportedTo);
		if (!enquiry) this.snackbar(Strings.errors.enquiry, "error");
		if (!contractor) this.snackbar(Strings.errors.contractor, "error");
		if (!reportedTo) this.snackbar(Strings.errors.reportedTo, "error");
		return (form && enquiry && contractor && reportedTo);
	}


	/**
	 * Render form.
	 *
	 * @return {ReactNode}
	 */
	renderForm() {
		return (
			<form ref={this.form}>
				<View child={true} p={0} pb={0}>
					{!this.exists ? this.renderComplianceDialog() : null}
					{this.exists ? this.renderActions() : null}

					{this.renderEnquiry()}
					{this.renderContractor()}
					{this.renderIncidentDateTime()}

					<mui.Box my={1}><mui.Divider /></mui.Box>

					{this.renderIncidents()}
					{this.renderProbables()}

					<Row>
						<mui.Typography variant="caption">
							<span style={{textDecoration: "underline"}}>
								{Strings.incidents.others.caption}
							</span>
							<span> </span>
							<span>{Strings.incidents.others.warning}</span>
						</mui.Typography>
					</Row>

					<mui.Box mb={1}><mui.Divider /></mui.Box>

					{this.renderReportedTo()}
					{this.renderReportedBy()}

					<mui.Box my={1}><mui.Divider /></mui.Box>

					{this.renderDetails()}

					{(!this.props.noDrafts && <mui.Box mt={1}><HealthSafetyNmpisDrafts /></mui.Box>)}

					{this.exists ? this.renderInfoDialog() : null}
					{this.exists ? this.renderCloseDialog() : null}
				</View>
			</form>
		);
	}


	/**
	 * Render action bar.
	 *
	 * @return {ReactNode}
	 */
	renderActions() {
		return (
			<React.Fragment>
				<ActionBar label={this.actionsLabel}>
					<mui.Button
						color="primary"
						variant="outlined"
						onClick={() => this.setState({infoDialog: true})}>
						About
					</mui.Button>
					<mui.Button
						color="primary"
						variant="contained"
						onClick={() => this.setState({closeDialog: true})}>
						{(this.closed ? "Reopen" : "Close")}
					</mui.Button>
				</ActionBar>
				<mui.Box><mui.Divider /></mui.Box>
			</React.Fragment>
		);
	}


	/**
	 * Render close dialog.
	 *
	 * @return {ReactNode}
	 */
	renderCloseDialog() {
		return (
			<HealthSafetyNmpisCloseDialog
				nmpi={this.state.domain}
				open={this.state.closeDialog}
				onClose={() => this.setState({closeDialog: false})}
				onChange={() => this.refresh()}>
			</HealthSafetyNmpisCloseDialog>
		);
	}


	/**
	 * Render compliance dialog.
	 *
	 * @return {ReactNode}
	 */
	renderComplianceDialog() {
		return (
			<DialogAlert
				content={HealthSafetyNmpisComplianceDialog.warnings}
				onClose={() => this.setState({complianceDialog: false})}
				open={this.state.complianceDialog}
				title={HealthSafetyNmpisComplianceDialog.title}>
			</DialogAlert>
		);
	}


	/**
	 * Render contractor input.
	 *
	 * @return {ReactNode}
	 */
	renderContractor() {
		return (
			<Row label="Contractor" columns="30rem auto 3rem 18rem">
				<mui.TextField
					required
					label="Contractor"
					disabled={this.contractorInputDisabled}
					value={this.state.contractor}
					onChange={e => this.updateContractor(e.target.value)} />
				<mui.Typography>OR</mui.Typography>
				<mui.FormGroup row>
					<mui.Tooltip title={this.contractorCheckboxTooltip}>
						<mui.FormControlLabel
							label="SEC contractor"
							control={(
								<mui.Checkbox
									color="primary"
									onChange={e => this.onSecContractor(e)}
									disabled={!this.draft && !this.local}
									checked={this.state.contractorSec} />
							)} />
					</mui.Tooltip>
				</mui.FormGroup>
			</Row>
		);
	}


	/**
	 * Render enquiry picker.
	 *
	 * @return {ReactNode}
	 */
	renderEnquiry() {

		const Picker = (!this.props.useProject ? EnquiryPicker : ProjectPicker);

		return (
			<Row
				columns={`30rem auto ${(!this.props.enquiry ? "3rem 18rem" : "")}`}
				label={(!this.props.useProject ? "Enquiry" : "Project")}>
				<Picker
					specific={true}
					disabled={(!this.draft || this.state.enquirySec || !!this.props.enquiry)}
					initial={this.state.enquiry}
					error={true}
					value={this.state.enquiry}
					onChange={(id, enquiry) => this.setState({enquiry})} />
				{(!this.props.enquiry && this.renderEnquirySecOffice())}
			</Row>
		);

	}


	/**
	 * Render the "SEC Office" enquiry option.
	 * 
	 * @return {ReactNode}
	 */
	renderEnquirySecOffice() {
		return <>
			<mui.Typography>OR</mui.Typography>
			<mui.FormGroup row>
				<mui.Tooltip title={this.enquiryCheckboxTooltip}>
					<mui.FormControlLabel
						label="SEC office"
						control={(
							<mui.Checkbox
								color="primary"
								onChange={e => this.onSecEnquiry(e)}
								disabled={!this.draft && !this.local}
								checked={this.state.enquirySec} />
						)} />
				</mui.Tooltip>
			</mui.FormGroup>
		</>;
	}


	/**
	 * Render incident date time input.
	 *
	 * @return {ReactNode}
	 */
	renderIncidentDateTime() {
		return (
			<Row label="Incident Date/Time">
				<DateTimePicker
					autoOk
					DialogProps={this.constructor.DialogProps}
					disableFuture
					ampm={false}
					disabled={!this.draft && !this.local}
					format="DD-MM-YYYY HH:mm"
					label="Incident date/time"
					maxDate={this.props.date}
					minDate={this.props.date}
					openTo={(this.props.date && "hours")}
					value={this.state.incidentDateTime}
					onChange={d => this.updateIncidentDateTime(d)} />
			</Row>
		);
	}


	/**
	 * Render NMPI picker.
	 *
	 * @return {ReactNode}
	 */
	renderIncidents() {
		return (
			<Row label="NMPIs">
				<MultiSelectEnum
					label="NMPIs"
					disabled={!this.editable}
					source={NmpiService.getIncidentsEnum}
					group={true}
					sortAlphabetically={true}
					value={this.state.nmpis}
					onChange={nmpis => this.setState({nmpis})} />
			</Row>
		);
	}


	/**
	 * Render information dialog.
	 *
	 * @return {ReactNode}
	 */
	renderInfoDialog() {
		return (
			<HealthSafetyNmpisDialog
				open={this.state.infoDialog}
				onClose={() => this.setState({infoDialog: false})}
				infoItem={this.state.domain}>
			</HealthSafetyNmpisDialog>
		);
	}


	/**
	 * Render probables picker.
	 *
	 * @return {ReactNode}
	 */
	renderProbables() {
		return (
			<Row label="Probable causes">
				<MultiSelectEnum
					label="Probable cause(s)"
					disabled={!this.editable}
					source={NmpiService.getProbablesEnum}
					sortAlphabetically={true}
					value={this.state.probables}
					onChange={probables => this.setState({probables})} />
			</Row>
		);
	}


	/**
	 * Render reported to picker.
	 *
	 * @return {ReactNode}
	 */
	renderReportedTo() {
		return (
			<Row label="Report to">
				<ReporteePicker
					disabled={!this.draft && !this.local}
					initial={this.state.reportedTo}
					value={this.state.reportedTo}
					onChange={reportedTo => this.setState({reportedTo})} />
			</Row>
		);
	}


	/**
	 * Render reported by field.
	 *
	 * @return {ReactNode}
	 */
	renderReportedBy() {
		return (
			<Row label="Your details" columns="30rem auto auto">
				<mui.TextField
					required
					label="Name"
					disabled={!this.draft && !this.local}
					value={this.state.reportedBy}
					onChange={e => this.updateReportedBy(e)} />
				<mui.TextField
					required
					type="tel"
					label="Phone number"
					disabled={!this.draft && !this.local}
					value={this.state.reportedByPhone}
					onChange={e => this.updateReportedByPhone(e)} />
			</Row>
		);
	}


	/**
	 * Render optional details field.
	 *
	 * @return {ReactNode}
	 */
	renderDetails() {
		return (
			<Row label="Details" labelAlignment="start">
				<mui.TextField
					multiline
					rows={5}
					label="Details"
					variant="outlined"
					disabled={!this.editable}
					helperText={Strings.fields.details.help}
					onChange={e => this.setState({details: e.target.value})}
					value={this.state.details} />
			</Row>
		);
	}


	/**
	 * Get whether to render the action button.
	 *
	 * @return {Boolean}
	 */
	shouldRenderFab() {
		return (super.shouldRenderFab() && this.editable);
	}


	/**
	 * Update contractor value from input.
	 *
	 * @param {String} contractor
	 * @return {void}
	 */
	updateContractor(contractor) {
		this.setState({contractor});
	}


	/**
	 * Change whether the contractor is SEC.
	 *
	 * @param {Event} e
	 * @return {void}
	 */
	onSecContractor(e) {
		this.setState({contractor: "", contractorSec: e.target.checked});
	}


	/**
	 * Change whether the enquiry is SEC.
	 *
	 * @param {Event} e
	 * @return {void}
	 */
	onSecEnquiry(e) {
		this.setState({enquiry: null, enquirySec: e.target.checked});
	}


	/**
	 * Update `IncidentDateTime` from MUI date picker.
	 *
	 * @param {DayJS} d
	 * @return {void}
	 */
	updateIncidentDateTime(d) {
		this.setState({incidentDateTime: d.format("YYYY-MM-DD HH:mm")});
	}


	/**
	 * Update reported by value from input change event.
	 *
	 * @param {Event} e
	 * @return {void}
	 */
	updateReportedBy(e) {
		this.setState({reportedBy: e.target.value});
	}


	/**
	 * Update reported by (phone) value from input change event.
	 *
	 * @param {Event} e
	 * @return {void}
	 */
	updateReportedByPhone(e) {
		this.setState({reportedByPhone: e.target.value});
	}


	/**
	 * Get label for action bar.
	 *
	 * @return {String}
	 */
	get actionsLabel() {
		const time = this.state.domain.IncidentDateTime;
		return `Report created ${Time.dmyhmFromDatetime(time)}`;
	}


	/**
	 * Get whether the request is closed.
	 *
	 * @return {Boolean}
	 */
	get closed() {
		if (!this.state.domain) return false;
		else return !!this.state.domain.ClosedTime;
	}


	/**
	 * Get whether contractor text input should be disabled.
	 *
	 * @return {Boolean}
	 */
	get contractorInputDisabled() {
		return ((!this.draft && !this.local) || this.state.contractorSec);
	}


	/**
	 * Get contractor checkbox tooltip.
	 *
	 * @return {String}
	 */
	get contractorCheckboxTooltip() {
		if (this.draft) return Strings.tooltips.contractor_sec;
		else if (!this.state.contractorSec) return "";
		else return Strings.tooltips.contractor_sec_assigned;
	}


	/**
	 * Get enquiry checkbox tooltip.
	 *
	 * @return {String}
	 */
	get enquiryCheckboxTooltip() {
		if (this.draft) return Strings.tooltips.enquiry_sec;
		else if (!this.state.enquirySec) return "";
		else return Strings.tooltips.enquiry_sec_assigned;
	}


	/**
	 * Get whether the form should be editable.
	 *
	 * @return {Boolean}
	 */
	get editable() {
		if (this.closed) return false;
		else if (this.local) return true;
		else if (!this.exists) return true;
		else return this.hasEditPermission;
	}


	/**
	 * Get whether the user has permission to edit reports.
	 *
	 * @return {Boolean}
	 */
	get hasEditPermission() {
		return this.props.permissions.includes("sec.nmpi.update");
	}


	/**
	 * Get whether the user has permission to view/search reports.
	 *
	 * @type {Boolean}
	 */
	get hasViewPermission() {
		return this.props.permissions.includes("sec.nmpi.get");
	}


	/**
	 * Get the name of the type to give to `Post` for new draft saves.
	 *
	 * @return {String}
	 */
	get localPostDomainType() {
		return "nmpi";
	}


	/**
	 * Strings.
	 *
	 * @return {Object}
	 */
	get strings() {
		const str = {...Strings};
		if (!this.exists) {
			str.submitConfirmMsg = [
				Strings.submitConfirmMsg,
				...HealthSafetyNmpisComplianceDialog.warnings
			];
		}
		return str;
	}


	/**
	 * Redirect URIs.
	 *
	 * @return {Object}
	 */
	get uris() {
		return {
			loadErr: (this.hasViewPermission ? this.urisRedirect : "/"),
			submitSuccess: (this.hasViewPermission ? this.urisRedirect : null)
		};
	}


	/**
	 * Redirect URI after submission.
	 *
	 * @return {Object}
	 */
	get urisRedirect() {
		return "/health-safety/nmpis";
	}


	/**
	 * `DialogProps`
	 * 
	 * @type {Objectg}
	 */
	static DialogProps = {
		disableScrollLock: true,
		PaperProps: {elevation: 0},
		transitionDuration: 0
	};

}

export default connect(state => {
	return {permissions: state.permissions};
})(withRouter(withSnackbar(HealthSafetyNmpisForm)));
