import {api, uris} from "api.js";
import Cache from "App/Cache.js";
import AuthService from "Services/AuthService.js";
import qs from "query-string";

/**
 * NMPI service
 *
 * @package SEC
 * @subpackage Services
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class NmpiService {

	/**
	 * Get NMPIs filtering by supported fields.
	 *
	 * @param {Object} filters
	 * @return {Promise}
	 */
	static get(filters) {
		return api.call({
			params: filters,
			url: uris.nmpis.get
		}).then(({data}) => data);
	}


	/**
	 * Get NMPIs CSV filtering by supported fields.
	 * 
	 * @param {Object} filters
	 * @return {Promise}
	 */
	static getCsvUri(filters) {
		return AuthService.authToken(uris.nmpis.getCsv).then(auth => {
			let uri = (api.base + uris.nmpis.getCsv);
			uri += `?${qs.stringify({...filters, auth_token: auth})}`;
			return uri;
		});
	}


	/**
	 * Get an NMPI object by its name.
	 *
	 * @param {String} name
	 * @return {Promise}
	 */
	static getNmpi(name) {
		return api.call(`${uris.nmpis.nmpi}/${name}`).then(({data}) => data);
	}


	/**
	 * Get NMPI statistics filtering on supported fields.
	 *
	 * @param {Object} filters
	 * @return {Promise}
	 */
	static getStatistics(filters) {
		return api.call({
			params: filters,
			url: uris.nmpis.statistics
		}).then(({data}) => data);
	}


	/**
	 * Get users who are valid NMPI reportees.
	 *
	 * Returns from network and then the cache (if `useCache` is truthy).
	 *
	 * @param {Boolean} useCache optional Use cache if can't fetch (`true`)
	 * @return {Promise}
	 */
	static getReportees(useCache=true) {
		return new Promise((resolve, reject) => {
			api.call(uris.nmpis.reportees).then(({data}) => {
				Cache.save("nmpi_reportees", data);
				resolve(data);
			}).catch(e => {
				const cached = Cache.get("nmpi_reportees");
				if (cached && useCache) resolve(cached);
				else reject(e);
			});
		});
	}


	/**
	 * Set the users who are valid NMPI reportees.
	 *
	 * @param {String} reportees CSV of usernames
	 * @return {Promise}
	 */
	static setReportees(reportees) {
		Cache.save("nmpi_reportees", null);
		return api.call({
			data: {reportees},
			method: "POST",
			url: uris.nmpis.reportees
		});
	}


	/**
	 * Create a new NMPI.
	 *
	 * @param {Object} nmpi NMPI object
	 * @return {Promise}
	 */
	static create(nmpi) {
		nmpi.Enquiry = nmpi.Enquiry?.EnquiryID || nmpi.Enquiry;
		nmpi.ReportedTo = nmpi.ReportedTo.Username || nmpi.ReportedTo;
		if (!nmpi.Enquiry) delete nmpi.Enquiry;
		if (!nmpi.Contractor) delete nmpi.Contractor;
		nmpi.incidents = JSON.stringify(nmpi.incidents);
		nmpi.probables = JSON.stringify(nmpi.probables);
		return api.call({data: nmpi, method: "POST", url: uris.nmpis.nmpi});
	}


	/**
	 * Update an NMPI.
	 *
	 * @param {String} name
	 * @param {Object} nmpi Body for NMPI update
	 * @return {Promise}
	 */
	static update(name, nmpi) {
		const data = {
			details: nmpi.Details,
			incidents: JSON.stringify(nmpi.incidents),
			probables: JSON.stringify(nmpi.probables)
		};
		return api.call({
			data,
			method: "POST",
			url: `${uris.nmpis.nmpi}/${name}`
		});
	}


	/**
	 * Delete an NMPI.
	 *
	 * @param {String} name
	 * @return {Promise}
	 */
	static delete(name) {
		return api.call({method: "DELETE", url: `${uris.nmpis.nmpi}/${name}`});
	}


	/**
	 * Close an NMPI by name.
	 *
	 * @param {String} name
	 * @return {Promise}
	 */
	static close(name) {
		return api.call({method: "POST", url: `${uris.nmpis.nmpi}/${name}/close`});
	}


	/**
	 * Open an NMPI by name.
	 *
	 * @param {String} name
	 * @return {Promise}
	 */
	static open(name) {
		return api.call({method: "POST", url: `${uris.nmpis.nmpi}/${name}/open`});
	}


	/**
	 * Get incidents enum.
	 *
	 * Returns from the network, then the cache.
	 *
	 * @return {Promise}
	 */
	static getIncidentsEnum() {
		return new Promise((resolve, reject) => {
			api.call(uris.nmpis.incidents).then(({data}) => {
				Cache.save("nmpi_incidents", data);
				resolve(data);
			}).catch(e => {
				const cached = Cache.get("nmpi_incidents");
				if (cached) resolve(cached);
				else reject(e);
			});
		});
	}


	/**
	 * Get probables enum.
	 *
	 * Returns from the network, then the cache.
	 *
	 * @return {Promise}
	 */
	static getProbablesEnum() {
		return new Promise((resolve, reject) => {
			api.call(uris.nmpis.probables).then(({data}) => {
				Cache.save("nmpi_probables", data);
				resolve(data);
			}).catch(e => {
				const cached = Cache.get("nmpi_probables");
				if (cached) resolve(cached);
				else reject(e);
			});
		});
	}

}

export default NmpiService;
