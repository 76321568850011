import React from "react";
import Currency from "Helpers/Currency.js";
import DomainDetails from "Components/DomainDetails.js";

/**
 * SPOI purchase orders table detail component
 * 
 * @package SEC
 * @subpackage Spoi
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class SpoiPurchaseOrdersTableDetails extends React.PureComponent {

	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return <DomainDetails details={this.details} variant="caption" />;
	}


	/**
	 * Get details to render.
	 * 
	 * @return {Array}
	 */
	get details() {
		return [
			{
				label: "Project",
				value: this.props.po.Project
			},
			{
				label: "Customer",
				value: (this.props.po.Customer?.Name || "-")
			},
			{
				label: "Invoiced",
				value: Currency.format(this.props.po.Invoiced)
			}
		];
	}

}

export default SpoiPurchaseOrdersTableDetails;
