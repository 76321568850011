import React from "react";
import {useSnackbar} from "notistack";
import SnackbarService from "./SnackbarService.js";

const SnackbarRefProvider = props => {
	props.onSnackbarRef(useSnackbar());
	return null;
};

const SnackbarHost = () => (
	<SnackbarRefProvider
		onSnackbarRef={snackbarRef => (SnackbarService.notistack = snackbarRef)} />
);

export default SnackbarHost;
