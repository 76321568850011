import React from "react";
import AdminPermissionsTable from "./AdminPermissionsTable.js";
import Container from "Components/Container.js";
import CustomerPicker from "Components/CustomerPicker.js";
import String from "Components/String.js";
import View from "App/View.js";
import {AdminPermissionsTable as Strings} from "Resources/Strings.js";

/**
 * Admin permissions view
 *
 * @package SEC
 * @subpackage Views
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class AdminPermissions extends React.PureComponent {

	/**
	 * State
	 *
	 * @type {Object}
	 */
	state = {

		/**
		 * Loading
		 *
		 * @type {Boolean}
		 */
		loading: false,

		/**
		 * Customer object to get users for
		 *
		 * @type {Object}
		 */
		customer: null

	};


	/**
	 * Customer changed.
	 *
	 * @param {Object} customer
	 * @return {void}
	 */
	handleChangeCustomer = customer => this.setState({customer});

	/**
	 * Loaded.
	 * 
	 * @return {void}
	 */
	handleLoad = () => this.setState({loading: false});

	/**
	 * Loading.
	 * 
	 * @return {void}
	 */
	handleLoading = () => this.setState({loading: true});


	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<View child={true} pb={0}>
				<CustomerPicker
					disabled={this.state.loading}
					value={this.state.customer}
					onChange={this.handleChangeCustomer} />

				<Container af="row" style={this.constructor.containerStyles}>
					<AdminPermissionsTable
						customer={this.customer}
						onLoad={this.handleLoad}
						onLoading={this.handleLoading} />
				</Container>

				<String
					color="textSecondary"
					noParagraph={true}
					str={Strings.help}
					variant="caption" />
			</View>
		);
	}


	/**
	 * Customer ID.
	 *
	 * @return {String}
	 */
	get customer() {
		return this.state.customer?.CustomerID;
	}


	/**
	 * Container styles.
	 * 
	 * @type {Object}
	 */
	static containerStyles = {
		height: `calc(100vh - 36rem)`,
		overflowY: "auto",
		width: "100%"
	};

}

export default AdminPermissions;
