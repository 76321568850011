import React from "react";
import DomainDetails from "Components/DomainDetails.js";
import Time from "Helpers/Time.js";
import SpoiPoLink from "../Atoms/SpoiPoLink.js";

/**
 * SPOI purchase invoices table detail component
 *
 * @package SEC
 * @subpackage Spoi
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class SpoiPurchaseInvoicesTableDetails extends React.PureComponent {

	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return <DomainDetails details={this.details} variant="caption" />;
	}


	/**
	 * Get details to render.
	 *
	 * @return {Array}
	 */
	get details() {
		return [
			{
				label: "Order",
				value: <SpoiPoLink noTypography={true} poid={this.props.pi?.poid} />
			},
			{
				label: "Due",
				value: Time.dmyFromYmd(this.props.pi.DueDate, "/")
			},
			{
				label: "Raised",
				value: Time.dmyFromYmd(this.props.pi.ReceivedDate, "/")
			},
			{
				label: "Project",
				value: this.props.pi.PurchaseOrderDomain.Project.ProjectID
			},
			{
				label: "Customer",
				value: (this.props.pi.PurchaseOrderDomain.Customer?.Name || "-")
			}
		];
	}

}

export default SpoiPurchaseInvoicesTableDetails;
