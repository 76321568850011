import React from "react";
import Cell from "Components/TableCell.js";
import TableData from "Components/TableData.js";
import Currency from "Helpers/Currency.js";
import rem from "Helpers/Rem.js";
import mobile from "Helpers/Mobile.js";
import Controls from "../Components/SpoiPiItemsTableControls.js";
import Editor from "../Components/SpoiPiRaiseItemEditor.js";
import PriceInput from "../Components/SpoiPiRaiseItemPriceInput.js";
import Total from "../Atoms/SpoiTotalTypography.js";
import TaxCodePicker from "../Components/SpoiTaxCodePicker.js";
import {Box, IconButton, TableCell, TableRow, withWidth} from "@material-ui/core";
import {Delete as DeleteIcon, Edit as EditIcon} from "@material-ui/icons";

/**
 * SPOI PI line items table
 *
 * This supports viewing SPOI PI line items.
 *
 * Please refer to the source for details of all the available props.
 *
 * @package SEC
 * @subpackage Spoi
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class SpoiPiItemsTable extends React.PureComponent {

	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * State
		 *
		 * @type {Object}
		 */
		this.state = {

			/**
			 * Active item
			 *
			 * @type {SpoiPurchaseInvoiceRaiseItem}
			 */
			active: null,

			/**
			 * Editing an item?
			 *
			 * @type {Boolean}
			 */
			editing: false

		};

	}


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<React.Fragment>
				<TableData
					editable={this.props.editable}
					fields={this.fields}
					info={false}
					values={this.props.lis.get()}>

					{this.itemsAddable ? this.renderControls() : null}
					{!this.props.totals ? this.renderTotals() : null}
					{this.props.totals ? this.renderTotalsProps() : null}
				</TableData>
				<Editor
					cis={this.props.cis}
					disabledTaxCodes={this.props.disabledTaxCodes}
					disableDeletion={this.itemsNotDeletable}
					li={(this.state.active || {})}
					onChange={li => this.update(this.state.active, li)}
					onClose={() => this.setState({editing: false})}
					onDelete={li => this.updateDelete(li)}
					open={this.state.editing}
					showDeletion={!this.props.cis} />
			</React.Fragment>
		);
	}


	/**
	 * Render the controls.
	 * 
	 * @return {ReactNode}
	 */
	renderControls() {
		return (
			<TableRow>
				<Cell colSpan={this.fieldsNow.length}>
					<Controls
						addDisabled={!this.props.canAdd}
						onAddItem={this.updateAdd.bind(this)} />
				</Cell>
			</TableRow>
		);
	}


	/**
	 * Render the delete button for an item.
	 *
	 * We only support deleting if the item was manually added.
	 * 
	 * @param {SpoiPurchaseInvoiceRaiseItem} li
	 * @return {void}
	 */
	renderDeleteButton(li) {
		return (
			<IconButton
				disabled={this.itemsNotDeletable}
				color="primary"
				onClick={() => this.updateDelete(li)}
				size="small"
				style={{width: "4.5rem", height: "4.5rem"}}>
				<DeleteIcon />
			</IconButton>
		);
	}


	/**
	 * Render the edit button for an item.
	 *
	 * @param {SpoiPurchaseInvoiceRaiseItem} li
	 * @return {void}
	 */
	renderEditButton(li) {
		return (
			<IconButton
				color="primary"
				onClick={() => this.setState({active: li, editing: true})}
				size="small"
				style={{width: "4.5rem", height: "4.5rem"}}>
				<EditIcon />
			</IconButton>
		);
	}


	/**
	 * Render the description of an item.
	 *
	 * @param {SpoiPurchaseInvoiceRaiseItem} li
	 * @return {void}
	 */
	renderItemDescription(li) {
		if (this.props.cis) return li.Description;
		else return `${li.Description} - ${li.TaxCode.Id}`;
	}


	/**
	 * Render the price input for an item.
	 *
	 * @param {SpoiPurchaseInvoiceRaiseItem} li
	 * @return {ReactNode}
	 */
	renderPriceInput(li) {
		return (
			<PriceInput
				li={li}
				onChange={Amount => this.update(li, {Amount})}
				required={!this.props.cis} />
		);
	}


	/**
	 * Render the tax code input for an item.
	 *
	 * @param {SpoiPurchaseInvoiceRaiseItem} li
	 * @return {ReactNode}
	 */
	renderTaxCodeInput(li) {
		return (
			<TaxCodePicker
				disabledItems={this.props.disabledTaxCodes}
				onChange={TaxCode => this.update(li, {TaxCode})}
				value={li.TaxCode?.Id} />
		);
	}


	/**
	 * Render the totals.
	 *
	 * @return {ReactNode}
	 */
	renderTotals() {
		return (
			<TableRow>
				<Cell colSpan={(this.fieldsNow.length - 1)} />
				<Cell><Total total={this.props.lis.total} /></Cell>
			</TableRow>
		);
	}


	/**
	 * Render totals given as props.
	 *
	 * You must pass a `totals` prop as an array of objects 
	 * with `label` and `value` properties to be appended to 
	 * the table as new rows after the line items; the columns 
	 * will be appended to the right (aligned with "Total") so 
	 * that you can e.g. render additional totals relevant to 
	 * the parent resource of the items we're rendering.
	 * 
	 * This will disable the default line items total row 
	 * displaying the current total of summed line item totals.
	 *
	 * @return {ReactNode}
	 */
	renderTotalsProps() {
		return this.props.totals.map((t, k) => {
			return (
				<TableRow key={k}>
					<TableCell colSpan={(this.fieldsNow.length - 2)} />
					<TableCell align="right" size="medium">
						<Box pr={1}>{t.label}</Box>
					</TableCell>
					<TableCell>{t.value}</TableCell>
				</TableRow>
			);
		});
	}


	/**
	 * Update an item's properties.
	 *
	 * @param {SpoiPurchaseInvoiceRaiseItem} li
	 * @param {Object} obj optional Update properties
	 * @return {void}
	 */
	update(li, obj={}) {
		this.props.lis.update(li, obj);
		this.props.onUpdate(this.props.lis);
	}


	/**
	 * Update when an item is to be added.
	 * 
	 * @return {void}
	 */
	updateAdd() {
		this.props.onAdd();
	}


	/**
	 * Update when an item is to be deleted.
	 *
	 * @param {SpoiPurchaseInvoiceRaiseItem} li
	 * @return {void}
	 */
	updateDelete(li) {
		this.props.lis.delete(li);
		this.props.onUpdate(this.props.lis);
	}


	/**
	 * Field definitions.
	 * 
	 * @return {Array}
	 */
	get fields() {
		return [
			{
				label: undefined,
				editable: true,
				editableOnly: true,
				hidden: this.props.cis,
				mobile: false,
				rendererEditable: this.renderDeleteButton.bind(this),
				style: {width: rem(4)}
			},
			{
				label: null,
				editable: true,
				editableOnly: true,
				rendererEditable: this.renderEditButton.bind(this),
				style: {width: rem(4)}
			},
			{
				label: "Description",
				renderer: this.renderItemDescription.bind(this),
				style: {minWidth: rem(8)}
			},
			{
				label: "Tax Code",
				editable: true,
				mobile: false,
				hidden: this.props.cis,
				renderer: li => li.TaxCode?.Name,
				rendererEditable: this.renderTaxCodeInput.bind(this),
				style: {minWidth: rem(8)}
			},
			{
				className: "sec-force-right-rem-padding",
				label: "Total Cost",
				editable: true,
				renderer: li => Currency.format((li.Amount || li.Total)),
				rendererEditable: this.renderPriceInput.bind(this),
				style: {maxWidth: rem(4)}
			}
		];
	}


	/**
	 * Get the fields visible now.
	 * 
	 * @return {Array}
	 */
	get fieldsNow() {
		let fields = this.fields;
		if (this.mobile) fields = fields.filter(f => (f.mobile !== false));
		if (this.props.editable) fields = fields.filter(f => (f.editable !== false));
		if (!this.props.editable) fields = fields.filter(f => !f.editableOnly);
		return fields.filter(f => !f.hidden);
	}


	/**
	 * Get whether items can be added.
	 *
	 * @return {Boolean}
	 */
	get itemsAddable() {
		return (this.props.editable && !this.props.cis);
	}


	/**
	 * Get whether to disable item deletion.
	 *
	 * @return {Boolean}
	 */
	get itemsNotDeletable() {
		return ((this.props.lis.length === 1) || this.props.cis);
	}


	/**
	 * Get whether we're mobile.
	 *
	 * @return {Boolean}
	 */
	get mobile() {
		return mobile(this.props.width);
	}

}

export default withWidth()(SpoiPiItemsTable);
