import React from "react";
import Container from "Components/Container.js";
import View from "App/View.js";
import String from "Components/String.js";
import rem from "Helpers/Rem.js";
import {ErrorView as Strings} from "Resources/Strings.js";
import {Box, Typography} from "@material-ui/core";

/**
 * Error view
 *
 * This is a `child` view by default, as given by `App/View`.
 *
 * You must set the `isRootView` prop to `true` if this component 
 * is being used in a context where it is not a child view.
 *
 * Please refer to `App/View` for details of how root/child views differ.
 *
 * @package SEC
 * @subpackage Views
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class ErrorView extends React.PureComponent {

	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<View child={!this.props.isRootView} fullHeightRows={true} pt={2}>
				<Box style={this.constructor.styles}>
					<Typography
						style={this.constructor.stylesHeader}
						variant="h5">
						{Strings.title}
					</Typography>
					<Container af="row" gap={1}>
						<String
							color="textSecondary"
							str={Strings.text}
							noParagraph={true}
							style={this.constructor.stylesText} />
					</Container>
				</Box>
			</View>
		);
	}


	/**
	 * Styles.
	 * 
	 * @type {Object}
	 */
	static styles = {
		display: "grid",
		gridAutoRows: "max-content",
		gridRowGap: rem(1)
	};

	/**
	 * Styles (header).
	 * 
	 * @type {Object}
	 */
	static stylesHeader = {fontSize: "2.1rem"};

	/**
	 * Styles (text).
	 * 
	 * @type {Object}
	 */
	static stylesText = {fontSize: "1.4rem"};

}

export default ErrorView;
