import React from "react";
import Component from "App/Component.js";
import {TextField as MaterialTextField} from "@material-ui/core";

/**
 * Text field
 *
 * A text field component with support for integer/float data types.
 *
 * Numeric precision can be set with the `precision` prop (default: 2).
 *
 * Value changes are reported via `onChange` when a `blur` event occurs.
 *
 * We support automatically casting to and from `null` which is not 
 * supported by the underlying Material UI `TextField` (which this is 
 * based on); when the input value is empty, we report `null`.
 *
 * Most props are forwarded to the underlying Material `TextField`.
 *
 * Please refer to the source for details of all the available props as 
 * well as the behaviour of this component when reacting to input value 
 * changes in varying scenarios and with various different data types.
 *
 * @package SEC
 * @subpackage Spoi
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class TextField extends Component {

	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * State
		 *
		 * @type {Object}
		 */
		this.state = {

			/**
			 * Value
			 *
			 * @type {String}
			 */
			value: ""

		};

		/**
		 * Method binds
		 */
		this.handleBlur = this.handleBlur.bind(this);
		this.handleChange = this.handleChange.bind(this);

	}


	/**
	 * Component mounted.
	 *
	 * @return {void}
	 */
	componentDidMount() {
		this.setValueFromProps();
	}


	/**
	 * Component updating.
	 *
	 * @param {Object} prevProps
	 * @return {void}
	 */
	componentDidUpdate(prevProps) {
		if (this.props.value !== prevProps.value) {
			this.setValueFromProps();
		}
	}


	/**
	 * Set value from props.
	 *
	 * We ensure we have a string value for our internal use, 
	 * since that's what's required by our underlying Material 
	 * input instance - refer to the class-level docblock 
	 * for further explanation of our type handling.
	 *
	 * @return {void}
	 */
	setValueFromProps() {
		let value = this.props.value;
		if (!value && (this.props.type !== "number")) value = "";
		this.setState({value});
	}


	/**
	 * Handle a blur event.
	 *
	 * We report the value via `onChange`.
	 *
	 * We cancel when using a numeric type and the input is invalid.
	 *
	 * @return {void}
	 */
	handleBlur() {

		let value = this.state.value;
		const props = (this.props.InputProps?.inputProps || {});

		if (this.props.dataType === "integer") {
			value = parseInt(value);
		}
		else if (this.props.dataType === "float") {
			value = parseFloat(parseFloat(value).toFixed(this.precision));
		}

		if (this.numeric && (!this.state.value && this.props.clearable)) {
			value = 0;
		}
		else if (this.numeric) {
			const max = (props.hasOwnProperty("max") && (value > props.max));
			const min = (props.hasOwnProperty("min") && (value < props.min));
			if (max) value = (props.max || 0);
			else if (min) value = (props.min || 0);
		}

		if (isNaN(value) && this.numeric) {
			value = this.props.value;
			this.props.onChange(value, this.props.name);
		}
		else if (!this.numeric && !value && !this.props.noNull) {
			this.props.onChange(null, this.props.name);
		}
		else this.props.onChange(value, this.props.name);

		this.setState({value});

	}


	/**
	 * Handle a change event.
	 *
	 * @param {Event} e
	 * @return {void}
	 */
	handleChange(e) {
		this.setState({value: e.target.value});
	}


	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<MaterialTextField
				disabled={this.props.disabled}
				error={this.props.error}
				fullWidth
				helperText={this.props.helperText}
				InputLabelProps={this.inputLabelProps}
				InputProps={(this.props.InputProps || {})}
				label={this.props.label}
				onBlur={this.handleBlur}
				onChange={this.handleChange}
				placeholder={this.props.placeholder}
				required={this.props.required}
				title={this.props.title}
				type={this.props.type}
				value={this.value} />
		);
	}


	/**
	 * Input label props.
	 * 
	 * @return {Object|undefined}
	 */
	get inputLabelProps() {
		return (this.props.shrunk ? this.constructor.SHRUNK_LABEL_PROPS : undefined);
	}


	/**
	 * Get whether we're using a numeric type.
	 *
	 * @return {Boolean}
	 */
	get numeric() {
		return (this.props.type === "number");
	}


	/**
	 * Get the precision with which to treat numeric values.
	 * 
	 * @return {Integer}
	 */
	get precision() {
		return (this.props.precision || 2);
	}


	/**
	 * Get the value for rendering.
	 *
	 * @return {mixed}
	 */
	get value() {
		if (this.props.type === "number") {
			if (this.props.hasOwnProperty("precision")) {
				if (this.state.value.toFixed) {
					return this.state.value.toFixed(this.precision);
				}
			}
		}
		return this.state.value;
	}


	/**
	 * Props to apply to "shrunk" labels.
	 * 
	 * @type {Object}
	 */
	static SHRUNK_LABEL_PROPS = {shrink: true};

}

export default TextField;
