import React from "react";

/**
 * Component base
 *
 * Makes sure we don't `setState(...)` when unmounted.
 *
 * @package SEC
 * @subpackage App
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class Component extends React.Component {

	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * Mounted
		 *
		 * @type {Boolean}
		 */
		this._mounted = true;

	}


	/**
	 * `setState(...)`
	 *
	 * @param {Object} state
	 * @param {Function} callback optional
	 * @return {void}
	 */
	setState(state, callback=null) {
		if (this._mounted) super.setState(state, callback);
	}


	/**
	 * Unmounting.
	 *
	 * @return {void}
	 */
	componentWillUnmount() {
		this._mounted = false;
	}

}

export default Component;
