import React from "react";
import rem from "Helpers/Rem.js";
import withMobile from "Hoc/withMobile.js";
import {Box} from "@material-ui/core";

/**
 * Container component
 *
 * A basic container which switches between horizontal and vertical 
 * axis depending on the viewport width (using the `Mobile` helper).
 *
 * Spacing is automatically added between elements using the `Rem` helper.
 *
 * Please refer to the source for details of the available props.
 *
 * @package SEC
 * @subpackage Spoi
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class Container extends React.PureComponent {

	/**
	 * Render the component.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Box
				className={this.className}
				style={this.styles}
				{...this.forwards}>
				{this.props.children}
			</Box>
		);
	}


	/**
	 * Get class name.
	 *
	 * @return {String}
	 */
	get className() {
		const cls = [this.props.className];
		if (this.props.fullWidth) cls.push("width-fit-content");
		return cls.join(" ");
	}


	/**
	 * Props to forward to `Box`.
	 * 
	 * @return {Object}
	 */
	get forwards() {
		const forwards = {};
		this.constructor.propForwards.forEach(forward => {
			if (this.props.hasOwnProperty(forward)) {
				forwards[forward] = this.props[forward];
			}
		});
		return forwards;
	}


	/**
	 * Get the gap style definition.
	 *
	 * @return {String}
	 */
	get gap() {
		return (this.props.hasOwnProperty("gap") ? rem(this.props.gap) : rem());
	}


	/**
	 * Styles.
	 * 
	 * @return {Object}
	 */
	get styles() {
		return {
			alignItems: this.props.alignItems,
			display: (this.props.display || "grid"),
			flexDirection: this.props.flexDirection,
			gridAutoColumns: this.props.gridAutoColumns,
			gridAutoFlow: (this.props.af || (this.props.isMobile ? "row" : "column")),
			gridColumnGap: this.gap,
			gridRowGap: this.gap,
			gridTemplateColumns: (this.props.singleColumn ? "100%" : this.props.columns),
			justifyContent: this.props.justifyContent,
			justifyItems: this.props.justifyItems,
			marginBottom: this.stylesMarginBottom,
			minHeight: this.props.minHeight,
			width: ((this.props.fullWidth && !this.props.noFullWidth100) ? "100%" : null),
			...this.props.style
		};
	}


	/**
	 * Styles (margin bottom definition).
	 *
	 * Enable the bottom margin by passing a `marginBottom` prop.
	 *
	 * When `null`, the margin will be 1x the rem value. When 
	 * the `marginBottomMobileOnly` prop is given, the margin 
	 * will only be applied when on a mobile device.
	 * 
	 * @return {String|null}
	 */
	get stylesMarginBottom() {
		if (this.props.hasOwnProperty("marginBottom")) {
			if (!this.props.marginBottomMobileOnly || this.props.isMobile) {
				return (rem(this.props.marginBottom) || rem());
			}
		}
		return null;
	}


	/**
	 * Props to forward to `Box`.
	 * 
	 * @return {Array}
	 */
	static get propForwards() {
		return [
			"mb",
			"ml",
			"mr",
			"mt",
			"mx",
			"my",
			"pb",
			"pl",
			"pr",
			"pt",
			"px",
			"py"
		];
	}

}

export default withMobile(Container);
