import React from "react";
import UserPicker from "Components/UserPicker.js";
import rem from "Helpers/Rem.js";
import {connect} from "react-redux";
import {Box, Button, Typography, withWidth} from "@material-ui/core";
import {MessageConversationComposer as Strings} from "Resources/Strings.js";

/**
 * Message conversation composer widget
 *
 * @package SEC
 * @subpackage Messages
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class MessageConversationComposer extends React.Component {

	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * State
		 *
		 * @type {Object}
		 */
		this.state = {

			/**
			 * Expanded
			 *
			 * @type {Boolean}
			 */
			open: false,

			/**
			 * Selected user
			 *
			 * @type {Object}
			 */
			user: null

		};

	}


	/**
	 * Handle cancellation.
	 *
	 * @return {void}
	 */
	handleCancel() {
		this.setState({user: null, open: false});
	}


	/**
	 * Handle creation.
	 *
	 * @return {void}
	 */
	handleCreate() {
		this.props.onNew(this.state.user);
		this.setState({user: null, open: false});
	}


	/**
	 * Handle opening.
	 *
	 * @return {void}
	 */
	handleOpen() {
		this.props.onExpanded();
		this.setState({open: true});
	}


	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Box mt={(this.mobile ? 2 : 0)} style={this.props.style}>
				{this.state.open ? this.renderMain() : this.renderClosed()}
			</Box>
		);
	}


	/**
	 * Render main.
	 *
	 * @return {ReactNode}
	 */
	renderMain() {
		return (
			<Box ml={(this.mobile ? 1 : 0)}>
				<Box mb={1}>
					<Typography>{Strings.title}</Typography>
				</Box>
				<Box mb={2}>
					<UserPicker
						customer={true}
						disabledUsers={this.props.user.Username}
						mwIndent={true}
						onChange={user => this.setState({user})}
						value={this.state.user}
						vertical={true} />
				</Box>
				<Box style={this.stylesButtons}>
					<Button
						color="primary"
						onClick={() => this.handleCancel()}>
						{Strings.cancel}
					</Button>
					<Button
						color="primary"
						variant="contained"
						disabled={this.state.user === null}
						onClick={() => this.handleCreate()}>
						{Strings.create}
					</Button>
				</Box>
			</Box>
		);
	}


	/**
	 * Render closed state.
	 *
	 * @return {ReactNode}
	 */
	renderClosed() {
		return (
			<Button
				color="primary"
				variant="contained"
				onClick={() => this.handleOpen()}
				style={this.stylesButton}>
				{Strings.closed}
			</Button>
		);
	}


	/**
	 * Mobile view.
	 *
	 * @return {Boolean}
	 */
	get mobile() {
		return (this.props.width === "xs");
	}


	/**
	 * Styles for new button.
	 *
	 * @return {Object}
	 */
	get stylesButton() {
		return {
			width: (this.mobile ? "100%" : `calc(100% - ${rem()})`)
		};
	}


	/**
	 * Styles for buttons.
	 *
	 * @return {Object}
	 */
	get stylesButtons() {
		return {
			display: "grid",
			gridAutoFlow: "column",
			gridColumnGap: rem(2),
			paddingRight: rem()
		};
	}

}

export default connect(({user}) => ({user}))(
	withWidth()(MessageConversationComposer)
);
