import React from "react";
import TabView from "Components/TabView";
import Nmpis from "./HealthSafetyNmpis.js";

/**
 * Health & safety
 *
 * @package SEC
 * @subpackage Views
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class HealthSafety extends React.PureComponent {

	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<TabView
				url={this.props.location.pathname}
				urlBase="/health-safety"
				history={this.props.history}
				tabs={this.tabs} />
		);
	}


	/**
	 * Tabs.
	 *
	 * @return {Array}
	 */
	get tabs() {
		return [
			{
				label: "NMPIs",
				url: "/nmpis",
				component: (
					<Nmpis
						history={this.props.history}
						location={this.props.location} />
				)
			}
		];
	}

}

export default HealthSafety;
