import React from "react";
import Component from "App/Component.js";
import InstallerService from "Services/InstallerService.js";
import Picker from "Components/Picker.js";

/**
 * Installer picker
 * 
 * @package SEC
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class InstallerPicker extends Component {

	/**
	 * State
	 *
	 * @type {Object}
	 */
	state = {

		/**
		 * Installers
		 *
		 * @type {Array|null}
		 */
		installers: null,

		/**
		 * Loading?
		 *
		 * @type {Boolean}
		 */
		loading: true,

		/**
		 * Error?
		 *
		 * @type {Boolean}
		 */
		error: false,

		/**
		 * Disabled?
		 * 
		 * @type {Boolean}
		 */
		disabled: false

	};


	/**
	 * Component mounted.
	 *
	 * @return {void}
	 */
	componentDidMount() {
		InstallerService.getUserInstallers().then(installers => {
			this.setState({installers}, () => {
				if (this.props.onLoad) {
					this.props.onLoad(this.state.installers);
				}
				if ((installers.length === 1) && this.props.disableWhenOnlyOne) {
					this.setState({disabled: true});
				}
			});
		}).catch(() => {
			this.setState({error: true});
		}).finally(() => {
			this.setState({loading: false});
		});
	}


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Picker
				disabled={(this.props.disabled || this.state.disabled)}
				error={this.state.error}
				getOptionLabel={o => o.InstallerName}
				label="Installer"
				loading={this.state.loading}
				onChange={this.props.onChange}
				options={this.state.installers}
				required={this.props.required}
				value={this.props.value} />
		);
	}

}

export default InstallerPicker;
