/**
 * Message object
 *
 * @package SEC
 * @subpackage Messages
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class Message {

	/**
	 * Constructor.
	 *
	 * @param {String} message Message content
	 * @param {String} sender Sender username
	 * @param {String} recipient Recipient username
	 * @param {Integer} ts optional Sent time (Unix epoch seconds) (now)
	 * @param {Boolean} read optional Message read by recipient (`false`)
	 * @param {String|null} id optional ID from the network
	 * @return {self}
	 */
	constructor(message, sender, recipient, ts=null, read=false, id=null) {

		/**
		 * Message ID
		 *
		 * @type {String}
		 */
		this.Id = id;

		/**
		 * Message content
		 *
		 * @type {String}
		 */
		this.Message = message;

		/**
		 * Sender username
		 *
		 * @type {String}
		 */
		this.Sender = sender;

		/**
		 * Recipient username
		 *
		 * @type {String}
		 */
		this.Recipient = recipient;

		/**
		 * Sent time (Unix epoch seconds)
		 *
		 * @type {Integer}
		 */
		this.Timestamp = ((ts !== null) ? ts : (Date.now() / 1000));

		/**
		 * Read time
		 *
		 * @type {Boolean}
		 */
		this.Read = (read || (ts === null));

	}

}

export default Message;
