import React from "react";
import {Checkbox as CheckboxMui, FormControlLabel} from "@material-ui/core";

/**
 * Checkbox
 *
 * @package SEC
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class Checkbox extends React.PureComponent {

	/**
	 * Value changed.
	 *
	 * @param {Event} e
	 * @param {mixed} v
	 * @return {void}
	 */
	handleChange = (e, v) => {
		if (this.props.useNewChangeHandler) {
			this.props.onChange(!this.props.checked, this.props.name);
		}
		else this.props.onChange(v, this.props.value);
	};


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<FormControlLabel
				className="width-fit-content"
				control={this.renderControl()}
				label={this.props.label} />
		);
	}


	/**
	 * Render the control.
	 * 
	 * @return {ReactNode}
	 */
	renderControl() {
		return (
			<CheckboxMui
				checked={this.props.checked}
				color="primary"
				disabled={this.props.disabled}
				indeterminate={this.props.indeterminate}
				indeterminateIcon={this.props.indeterminateIcon}
				onChange={this.handleChange}
				required={this.props.required}
				size={this.props.size}
				style={this.constructor.styles} />
		);
	}


	static styles = {height: "3rem", width: "3rem"};

}

export default Checkbox;
