import {useCallback, useEffect, useMemo, useState} from "react";

export default fetcher => {

	const [data, setData] = useState(null);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);


	/**
	 * Actual data fetcher implementation.
	 *
	 * @async
	 * @return {void}
	 */
	const fetch = useCallback(async () => {

		setData(null);
		setError(null);
		setLoading(true);

		try {
			const data = await fetcher();
			setData(data);
		}
		catch (e) {
			setError(e);
		}

		setLoading(false);

	}, [fetcher]);


	/**
	 * Run the fetcher once immediately automatically
	 */
	useEffect(fetch, [fetch]);


	return useMemo(() => {
		return {data, error, fetch, loading};
	}, [data, error, fetch, loading]);

};
