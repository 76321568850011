import React from "react";
import Component from "App/Component.js";
import View from "App/View.js";
import Label from "Components/Label.js";
import rem from "Helpers/Rem.js";
import DocuSignService from "Services/DocuSignService.js";
import NmpiService from "Services/NmpiService.js";
import withSnackbar from "Hoc/withSnackbar.js";
import {AdminInternals as Strings} from "Resources/Strings.js";
import {Check as CheckIcon} from "@material-ui/icons";
import * as mui from "@material-ui/core";

/**
 * Admin internals screen
 *
 * @package SEC
 * @subpackage Views
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class AdminInternals extends Component {

	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * State
		 *
		 * @type {Object}
		 */
		this.state = {

			/**
			 * Disable DocuSign login button
			 *
			 * @type {Boolean}
			 */
			docusignAuthDisabled: false,

			/**
			 * NMPI reportees string of usernames
			 *
			 * @type {String}
			 */
			nmpiReportees: "",

			/**
			 * Disable NMPI reportee controls
			 *
			 * @type {Boolean}
			 */
			nmpiReporteesDisabled: true

		};

	}


	/**
	 * Component mounted.
	 *
	 * @return {void}
	 */
	componentDidMount() {
		NmpiService.getReportees(false).then(users => {
			this.setState({
				nmpiReportees: users.map(u => u.Username).join(", "),
				nmpiReporteesDisabled: false
			});
		}).catch(() => {
			this.props.snackbar(Strings.nmpiReportees.loadError, "error");
		});
	}


	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<View child={true}>
				{this.renderTitle()}
				<mui.Divider />
				{this.renderDocuSign()}
				<mui.Divider />
				{this.renderNmpiReportees()}
			</View>
		);
	}


	/**
	 * Render DocuSign section.
	 *
	 * @return {ReactNode}
	 */
	renderDocuSign() {
		return (
			<View child={true} p={0} style={{justifyItems: "start"}}>
				<Label>{Strings.docusign.title}</Label>
				<mui.Button
					color="primary"
					variant="outlined"
					disabled={this.state.docusignAuthDisabled}
					onClick={() => this.docusignLogin()}>

					{Strings.docusign.auth.button}
				</mui.Button>
				<mui.Box>
					<mui.Typography paragraph>
						{Strings.docusign.auth.help[0]}
					</mui.Typography>
					<mui.Typography>
						{Strings.docusign.auth.help[1]}
					</mui.Typography>
				</mui.Box>
			</View>
		);
	}


	/**
	 * Render the NMPI reportees section.
	 *
	 * @return {ReactNode} [description]
	 */
	renderNmpiReportees() {
		return (
			<View child={true} p={0} style={{justifyItems: "start"}}>
				<Label>{Strings.nmpiReportees.title}</Label>
				<mui.Box style={this.constructor.nmpiReporteesStyles}>
					<mui.TextField
						color="primary"
						disabled={this.state.nmpiReporteesDisabled}
						label={Strings.nmpiReportees.title}
						onChange={e => this.handleNmpiReportees(e)}
						InputLabelProps={{shrink: true}}
						value={this.state.nmpiReportees} />
					<mui.IconButton
						color="primary"
						disabled={this.state.nmpiReporteesDisabled}
						onClick={() => this.updateNmpiReportees()}>
						<CheckIcon />
					</mui.IconButton>
				</mui.Box>
				<mui.Box>
					<mui.Typography paragraph>
						{Strings.nmpiReportees.help[0]}
					</mui.Typography>
					<mui.Typography paragraph>
						{Strings.nmpiReportees.help[1]}
					</mui.Typography>
					<mui.Typography>
						{Strings.nmpiReportees.help[2]}
					</mui.Typography>
				</mui.Box>
			</View>
		);
	}


	/**
	 * Render title section.
	 *
	 * @return {ReactNode}
	 */
	renderTitle() {
		return (
			<mui.Box>
				<mui.Box mb={2}>
					<mui.Typography paragraph variant="h6">
						{Strings.sec}
					</mui.Typography>
				</mui.Box>
				<mui.Typography paragraph>
					{Strings.help[0]}
				</mui.Typography>
				<mui.Typography>
					{Strings.help[1]}
				</mui.Typography>
			</mui.Box>
		);
	}


	/**
	 * DocuSign login.
	 *
	 * @return {void}
	 */
	docusignLogin() {
		this.setState({docusignAuthDisabled: true});
		DocuSignService.getAuthURI().then(uri => {
			window.open(uri, Strings.docusign.win, "width=1280,height=720");
		}).catch(() => {
			this.props.snackbar(Strings.docusign.auth.error, "error");
		}).finally(() => this.setState({docusignAuthDisabled: false}));
	}


	/**
	 * Update NMPI reportees value.
	 *
	 * @param {Event} e
	 * @return {void}
	 */
	handleNmpiReportees(e) {
		this.setState({nmpiReportees: e.target.value.trim()});
	}


	/**
	 * Update NMPI reportees.
	 *
	 * @return {void}
	 */
	updateNmpiReportees() {
		this.setState({nmpiReporteesDisabled: true});
		NmpiService.setReportees(this.state.nmpiReportees).then(() => {
			this.props.snackbar(Strings.nmpiReportees.saved, "success");
		}).catch(e => {
			const msg = (e.status === 400 ? "error400" : "error");
			this.props.snackbar(Strings.nmpiReportees[msg], "error");
		}).finally(() => this.setState({nmpiReporteesDisabled: false}));
	}


	/**
	 * Styles for NMPI reportees section.
	 *
	 * @return {Object}
	 */
	static get nmpiReporteesStyles() {
		return {
			alignItems: "center",
			display: "grid",
			gridAutoFlow: "column",
			gridColumnGap: rem(),
			gridTemplateColumns: `auto ${rem(3)}`,
			maxWidth: `60rem`,
			width: "100%"
		};
	}

}

export default withSnackbar(AdminInternals);
