import React from "react";

/**
 * React helper with utility methods
 *
 * @package SEC
 * @subpackage Helpers
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class ReactHelper {

	/**
	 * Render a component.
	 *
	 * @param {ReactComponent} Component
	 * @param {Object} props optional
	 * @return {ReactNode}
	 */
	static render(Component, props={}) {
		return <Component {...props} />;
	}

}

export default ReactHelper;
