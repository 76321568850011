import React from "react";
import Component from "App/Component.js";
import withSnackbar from "Hoc/withSnackbar";
import MultiSelect from "./MultiSelect.js";
import {MultiSelectEnum as Strings} from "Resources/Strings.js";

/**
 * Multiselect for enum values
 *
 * @package SEC
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class MultiSelectEnum extends Component {

	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * State
		 *
		 * @type {Object}
		 */
		this.state = {

			/**
			 * Loading
			 *
			 * @type {Boolean}
			 */
			loading: true,

			/**
			 * Options
			 *
			 * @type {Array}
			 */
			options: []

		};

		/**
		 * Method binds
		 */
		this.onChange = this.onChange.bind(this);
		this.renderGroupBy = this.renderGroupBy.bind(this);

	}


	/**
	 * Component mounted.
	 *
	 * @return {void}
	 */
	componentDidMount() {
		this.props.source().then(options => {
			this.setState({options: this.prepareOptions(options)});
		}).catch(() => {
			this.props.snackbar(Strings.fetchFailure, "error");
		}).finally(() => {
			this.setState({loading: false});
		});
	}


	/**
	 * Component updated.
	 *
	 * @param {Object} prevProps
	 * @param {Object} prevState
	 * @return {void}
	 */
	componentDidUpdate(prevProps, prevState) {
		if (this.state.options !== prevState.options) {
			this.props.onChange(this.resolveValues(this.props.value || []));
		}
	}


	/**
	 * Prepare options to pass to `MultiSelect`.
	 *
	 * We optionally support sorting via `sortAlphabetically` prop.
	 * 
	 * Grouping by first colon-separated segment is supported via `group` prop.
	 * 
	 * @param {Object} options Enum
	 * @return {Array}
	 */
	prepareOptions(options) {

		/**
		 * Options
		 */
		let opts = Object.keys(options).map(key => {
			const option = {key, label: options[key]};
			if (this.props.group) {
				option.labelVisible = options[key].split(": ")[1];
			}
			return option;
		});

		/**
		 * Sort alphabetically
		 */
		if (this.props.sortAlphabetically) {
			opts = opts.sort((a, b) => {
				if (a.label < b.label) return -1;
				else if (a.label > b.label) return 1;
				else return 0;
			});
		}

		return opts;

	}


	/**
	 * Resolve values.
	 *
	 * @param {Array} values
	 * @return {void}
	 */
	resolveValues(values) {
		if (!values.every(v => (typeof v === "string"))) return values;
		else return this.constructor.prepareValues(this.state.options, values || []);
	}


	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<MultiSelect
				disabled={this.props.disabled}
				loading={this.state.loading}
				groupBy={(this.props.group && this.renderGroupBy)}
				label={this.props.label}
				onChange={this.onChange}
				options={this.state.options}
				value={this.resolveValues(this.props.value)} />
		);
	}


	/**
	 * Render grouping.
	 *
	 * @param {Object} option
	 * @return {String|null}
	 */
	renderGroupBy(option) {
		return (this.props.group ? option.label.split(":")[0] : null);
	}


	/**
	 * Handle value change.
	 *
	 * @param {Array} v
	 * @return {void}
	 */
	onChange(v) {
		if (!this.props.disabled) this.props.onChange(v);
	}


	/**
	 * Prepare values to pass to `MultiSelect`.
	 *
	 * We have to filter options to get the value objects 
	 * so the same objects are passed as values to the nested 
	 * `Autocomplete` as it gets as options, so it knows which 
	 * options are selected at any given time.
	 *
	 * @param {Object} options Enum
	 * @param {Array} values Enum keys as values
	 * @return {Array}
	 */
	static prepareValues(options, values) {
		return options.filter(o => values.includes(o.key));
	}

}

export default withSnackbar(MultiSelectEnum);
