import Store from "./Store.js";
import dispatchCache from "Dispatchers/Cache.js";

/**
 * Cache
 *
 * @package SEC
 * @subpackage App
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class Cache {

	/**
	 * Get a cached item by ID.
	 *
	 * @param {String} id
	 * @return {mixed}
	 */
	static get(id) {
		return Store.getState().cache[id];
	}


	/**
	 * Save an item to cache by ID.
	 *
	 * @param {String} id
	 * @param {mixed} data
	 * @return {void}
	 */
	static save(id, data) {
		dispatchCache(id, data);
	}

}

export default Cache;
