import React from "react";
import Component from "App/Component.js";
import Store from "App/Store.js";
import Details from "Components/Details.js";
import EnquiryPicker from "Components/EnquiryPicker.js";
import FormControl from "Components/FormControl.js";
import Loader from "Components/Loader.js";
import UserPicker from "Components/UserPicker.js";
import mobile from "Helpers/Mobile.js";
import rem from "Helpers/Rem.js";
import withSnackbar from "Hoc/withSnackbar.js";
import Theme from "Resources/Theme.json";
import NmpiService from "Services/NmpiService.js";
import Helper from "./HealthSafetyNmpisSearchHelper.js";
import HealthSafetyNmpisStatistics from "./HealthSafetyNmpisStatistics.js";
import HealthSafetyNmpisTable from "./HealthSafetyNmpisTable.js";
import strftime from "strftime";
import * as mui from "@material-ui/core";
import {DatePicker} from "@material-ui/pickers";
import {withWidth} from "@material-ui/core";
import {AttachFile as DownloadIcon} from "@material-ui/icons";
import {HealthSafetyNmpisSearch as Strings} from "Resources/Strings.js";

/**
 * Health and safety NMPI search
 *
 * @package SEC
 * @subpackage Views
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class HealthSafetyNmpisSearch extends Component {

	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * State
		 *
		 * @type {Object}
		 */
		this.state = {

			/**
			 * NMPIs
			 *
			 * @type {Array}
			 */
			nmpis: [],

			/**
			 * Error
			 *
			 * @type {Boolean}
			 */
			error: false,

			/**
			 * Loading
			 *
			 * @type {Boolean}
			 */
			loading: true,

			/**
			 * Downloading CSV file
			 *
			 * @type {Boolean}
			 */
			downloadingCsv: false,

			/**
			 * `Closed` filter
			 *
			 * 0 = no filter
			 * 1 = open only
			 * 2 = closed only
			 *
			 * @type {Number}
			 */
			closed: 0,

			/**
			 * `ClosedBy` filter
			 *
			 * @type {Object} User object
			 */
			closedBy: null,

			/**
			 * `ClosedTime` filter
			 *
			 * @type {String}
			 */
			closedTime: null,

			/**
			 * `CreatedBy` filter
			 *
			 * @type {Object} User object
			 */
			createdBy: null,

			/**
			 * `Enquiry` filter
			 *
			 * @type {Number} Enquiry ID
			 */
			enquiry: null,

			/**
			 * `Enquiry` filter on SEC only
			 *
			 * @type {Boolean}
			 */
			enquirySec: false,

			/**
			 * `IncidentDateTime` filter
			 *
			 * @type {String}
			 */
			incidentDate: strftime("%Y-%m-01", new Date()),

			/**
			 * `IncidentDateTimeMax` filter
			 *
			 * @type {String}
			 */
			incidentDateMax: strftime("%Y-%m-%d", new Date())

		};

		/**
		 * Pending sync queue length
		 *
		 * We want to be alerted to changes so that 
		 * we can reload if a draft gets uploaded.
		 *
		 * @type {Integer}
		 */
		this.pending = Store.getState().sync.pending;

		/**
		 * Redux unsubscription method
		 *
		 * @type {Function}
		 */
		this.reduxUnsubscribe = null;

	}


	/**
	 * Component mounted.
	 *
	 * @return {void}
	 */
	componentDidMount() {
		this.update();
		this.reduxUnsubscribe = Store.subscribe(() => {
			const {pending} = Store.getState().sync;
			if (pending !== this.pending) {
				this.pending = pending;
				this.update();
			}
		});
	}


	/**
	 * Component updating.
	 *
	 * @param {Object} prevProps
	 * @param {Object} prevState
	 * @return {void}
	 */
	componentDidUpdate(prevProps, prevState) {
		if (Helper.filtersChanged(this.state, prevState)) {
			this.update();
		}
	}


	/**
	 * Component unmounting.
	 *
	 * @return {void}
	 */
	componentWillUnmount() {
		super.componentWillUnmount();
		this.reduxUnsubscribe();
	}


	/**
	 * Download as CSV.
	 *
	 * @return {Object}
	 */
	csv() {
		this.setState({downloadingCsv: true});
		this.props.snackbar(Strings.csv.download.start);
		NmpiService.getCsvUri(this.filters).then(uri => {
			window.open(uri, "_blank");
		}).catch(() => {
			this.props.snackbar(Strings.csv.download.error, "error");
		}).finally(() => this.setState({downloadingCsv: false}));
	}


	/**
	 * Update NMPIs.
	 *
	 * @return {void}
	 */
	update() {
		this.setState({loading: true, nmpis: [], error: false});
		NmpiService.getStatistics(this.filters).then(nmpis => {
			this.setState({nmpis});
		}).catch(() => {
			this.setState({error: true});
		}).finally(() => this.setState({loading: false}));
	}


	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Details open={true} label={Strings.label}>
				<mui.Box mb={4}>{this.renderOptions()}</mui.Box>
				{this.state.error ? this.renderError() : null}
				{this.state.loading ? <Loader /> : null}
				{this.ready ? this.renderStats() : null}
				{this.ready ? this.renderTable() : null}
			</Details>
		);
	}


	/**
	 * Render an error.
	 *
	 * @return {ReactNode}
	 */
	renderError() {
		return (
			<mui.Typography variant="body1" style={{textAlign: "center"}}>
				{Strings.error}
			</mui.Typography>
		);
	}


	/**
	 * Render options form.
	 *
	 * @return {ReactNode}
	 */
	renderOptions() {
		return (
			<mui.Box style={this.stylesOptionsContainer}>
				{this.renderOptionsEnquiry()}
				{this.renderOptionsDates()}
				{this.renderOptionsUsers()}
			</mui.Box>
		);
	}


	/**
	 * Render options form date pickers.
	 *
	 * @return {ReactNode}
	 */
	renderOptionsDates() {
		return (
			<div style={this.stylesOptionsGroup}>
				<DatePicker
					autoOk
					DialogProps={this.constructor.DialogProps}
					disableFuture
					disabled={this.disabled}
					label="Date (start)"
					format="DD-MM-YYYY"
					value={this.state.incidentDate}
					onChange={d => this.updateDate(d, "incidentDate")} />
				<DatePicker
					autoOk
					DialogProps={this.constructor.DialogProps}
					disableFuture
					disabled={this.disabled}
					label="Date (end)"
					format="DD-MM-YYYY"
					value={this.state.incidentDateMax}
					onChange={d => this.updateDate(d, "incidentDateMax")} />
				<DatePicker
					autoOk
					DialogProps={this.constructor.DialogProps}
					disableFuture
					disabled={this.disabled}
					label="Closed after"
					format="DD-MM-YYYY"
					value={this.state.closedTime}
					onChange={d => this.updateDate(d, "closedTime")} />
			</div>
		);
	}


	/**
	 * Render options form enquiry picker.
	 *
	 * @return {ReactNode}
	 */
	renderOptionsEnquiry() {
		return (
			<div style={this.stylesOptionsGroup}>
				<EnquiryPicker
					disabled={this.state.loading || this.state.enquirySec}
					onChange={enquiry => this.setState({enquiry})} />
				<mui.FormGroup
					row
					style={this.stylesSecOnly}>
					<mui.FormControlLabel
						label="SEC Office Premises only"
						control={(
							<mui.Checkbox
								color="primary"
								disabled={this.disableEnquirySecOnly}
								onChange={e => this.onSecEnquiry(e)}
								value={this.state.enquirySec} />
						)} />
				</mui.FormGroup>
				<mui.Button
					color="primary"
					variant={(this.mobile ? "outlined": "text")}
					endIcon={(!this.mobile ? <DownloadIcon /> : null)}
					disabled={this.disabled}
					onClick={() => this.csv()}
					style={this.stylesCsv}>
					Download as CSV
				</mui.Button>
			</div>
		);
	}


	/**
	 * Render options form user pickers.
	 *
	 * @return {ReactNode}
	 */
	renderOptionsUsers() {
		return (
			<div style={this.stylesOptionsGroup}>
				<FormControl label="Closed">
					<mui.Select
						MenuProps={{disableScrollLock: true}}
						value={this.state.closed}
						disabled={this.disabled}
						onChange={e => this.updateClosed(e.target.value)}>
						<mui.MenuItem value={0}>Any</mui.MenuItem>
						<mui.MenuItem value={1}>Open</mui.MenuItem>
						<mui.MenuItem value={2}>Closed</mui.MenuItem>
					</mui.Select>
				</FormControl>

				<UserPicker
					label="Closed by"
					disabled={this.disabled}
					onChange={closedBy => this.setState({closedBy})} />
				<UserPicker
					label="Reported by"
					disabled={this.disabled}
					onChange={createdBy => this.setState({createdBy})} />
			</div>
		);
	}


	/**
	 * Render statistics.
	 *
	 * @return {void}
	 */
	renderStats() {
		return (
			<mui.Box mb={4}>
				<HealthSafetyNmpisStatistics stats={this.state.nmpis} />
			</mui.Box>
		);
	}


	/**
	 * Render table.
	 *
	 * @return {ReactNode}
	 */
	renderTable() {
		return (
			<HealthSafetyNmpisTable
				nmpis={this.state.nmpis.nmpis}
				onWantsRefresh={() => this.update()} />
		);
	}


	/**
	 * Update SEC enquiry only value from checkbox event.
	 *
	 * @param {Event} e
	 * @return {void}
	 */
	onSecEnquiry(e) {
		this.setState({enquiry: null, enquirySec: e.target.checked});
	}


	/**
	 * Update `Closed` filter.
	 *
	 * @param {Integer} closed Value
	 * @return {void}
	 */
	updateClosed(closed) {
		this.setState({closed});
	}


	/**
	 * Update date in state.
	 *
	 * @param {DayJS} d Date
	 * @param {String} state State property to update
	 * @return {void}
	 */
	updateDate(d, state) {
		this.setState({[state]: d ? d.format("YYYY-MM-DD") : null});
	}


	/**
	 * Get whether to disable inputs.
	 *
	 * @return {Boolean}
	 */
	get disabled() {
		return (this.state.loading || this.state.downloadingCsv);
	}


	/**
	 * Get whether to disable the SEC enquiry only checkbox.
	 *
	 * @return {Boolean}
	 */
	get disableEnquirySecOnly() {
		return (this.state.loading || (this.state.enquiry !== null));
	}


	/**
	 * Get current filters.
	 *
	 * Resolves filters for use with `NmpiService` network calls.
	 *
	 * @param {Object} state
	 * @return {Object}
	 */
	get filters() {
		return Helper.getFiltersFromState(this.state);
	}


	/**
	 * Get whether mobile.
	 *
	 * @return {Boolean}
	 */
	get mobile() {
		return mobile(this.props.width);
	}


	/**
	 * Get whether ready to render results.
	 *
	 * @return {Boolean}
	 */
	get ready() {
		return (!this.state.loading && !this.state.error);
	}


	/**
	 * Styles for CSV download link.
	 *
	 * @return {Object}
	 */
	get stylesCsv() {
		return {
			alignSelf: "center",
			justifySelf: (this.mobile ? "start" : "end"),
			marginBottom: (this.mobile ? rem() : 0),
			order: (this.mobile ? -10 : 10)
		};
	}


	/**
	 * Styles for options control container.
	 *
	 * @return {Object}
	 */
	get stylesOptionsContainer() {
		return {
			display: "grid",
			gridAutoFlow: "row",
			gridRowGap: rem(2)
		};
	}


	/**
	 * Styles for options control groups.
	 *
	 * @return {Object}
	 */
	get stylesOptionsGroup() {
		return {
			display: "grid",
			gridAutoFlow: this.mobile ? "row" : "column",
			gridColumnGap: `${(Theme.sec.spacing * 2) / 10}rem`,
			gridRowGap: `${(Number(Theme.sec.spacing)) / 10}rem`,
			gridTemplateColumns: this.mobile ? "1fr" : "1fr 1fr 1fr"
		};
	}


	/**
	 * Styles for "SEC only" checkbox.
	 * 
	 * @return {Object}
	 */
	get stylesSecOnly() {
		return {
			order: (this.mobile ? -1 : 2)
		};
	}


	/**
	 * `DialogProps`
	 * 
	 * @type {Objectg}
	 */
	static DialogProps = {
		disableScrollLock: true,
		PaperProps: {elevation: 0},
		transitionDuration: 0
	};

}

export default withWidth()(withSnackbar(HealthSafetyNmpisSearch));
