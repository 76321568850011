import React from "react";
import AuthService from "Services/AuthService.js";
import Component from "App/Component.js";
import Dialog from "Components/Dialog.js";
import Navigator from "App/Navigator.js";
import Push from "App/Push.js";
import Version from "Components/Version.js";
import dispatchAuth from "Dispatchers/Authenticate.js";
import {connect} from "react-redux";
import {Redirect, withRouter} from "react-router-dom";
import {Login as Strings} from "Resources/Strings.js";
import queryString from "query-string";
import * as mui from "@material-ui/core";

/**
 * Login
 *
 * @package SEC
 * @subpackage Views
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class Login extends Component {

	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * State
		 *
		 * @type {Object}
		 */
		this.state = {};

		/**
		 * Initial state
		 *
		 * @type {Object}
		 */
		this.initialState = {

			/**
			 * Disable inputs
			 *
			 * @type {Boolean}
			 */
			disabled: false,

			/**
			 * Error state
			 *
			 * @type {Integer}
			 */
			error: null,

			/**
			 * Username input
			 *
			 * @type {String}
			 */
			username: "",

			/**
			 * Password input
			 *
			 * @type {String}
			 */
			password: ""

		};

		/**
		 * State
		 */
		this.state = {...this.initialState};

		/**
		 * Refs
		 */
		this.usernameRef = React.createRef();
		this.passwordRef = React.createRef();

		/**
		 * Method binds
		 */
		this.clearError = this.clearError.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleUsername = this.handleUsername.bind(this);
		this.handlePassword = this.handlePassword.bind(this);

	}


	/**
	 * Clear error state.
	 * 
	 * @return {void}
	 */
	clearError() {
		this.setState({error: null});
	}


	/**
	 * Reset state.
	 *
	 * @return {void}
	 */
	reset() {
		this.setState(this.initialState);
	}


	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		const uri = this.props.history.location.pathname;
		if (this.props.user) return <Redirect to="/" />;
		else if (this.redirect) return <Redirect to={`/login?uri=${uri}`} />;
		else return this.renderForm();
	}


	/**
	 * Render form.
	 *
	 * @return {ReactNode}
	 */
	renderForm() {
		return (
			<mui.Box display="flex" minHeight="100vh">
				<mui.Grid
					container
					alignItems="center"
					alignContent="center"
					direction="column"
					justify="center"
					style={this.constructor.styles}>
					<img
						alt="SEC Group"
						src={(!this.props.dark ? "/sec.png" : "/sec-white.png")}
						style={this.constructor.imgStyles} />
					<mui.Box mt={2}>
						<form onSubmit={this.handleSubmit}>
							<mui.Box mt={1} mb={1}>
								<mui.TextField
									autoFocus
									color="primary"
									disabled={this.state.disabled}
									error={(this.state.error === 404)}
									helperText={this.usernameHelper}
									inputRef={this.usernameRef}
									label="Username"
									onChange={this.handleUsername}
									required
									value={this.state.username} />
							</mui.Box>
							<mui.Box mt={1} mb={1}>
								<mui.TextField
									color="primary"
									disabled={this.state.disabled}
									error={(this.state.error === 403)}
									helperText={this.passwordHelper}
									inputRef={this.passwordRef}
									label="Password"
									onChange={this.handlePassword}
									required
									type="password"
									value={this.state.password} />
							</mui.Box>
							<mui.Box mt={2}>
								<mui.Button
									color="primary"
									disabled={this.state.disabled}
									type="submit"
									variant="outlined">
									Login
								</mui.Button>
							</mui.Box>
							<mui.Box mt={2}>
								<Version variant="caption" />
							</mui.Box>
						</form>
					</mui.Box>
				</mui.Grid>

				<Dialog
					noCancel={true}
					onClose={this.clearError}
					onOk={this.clearError}
					open={(this.state.error === true)}
					title={Strings.failedTitle}>
					<mui.DialogContentText>
						{Strings.failed}
					</mui.DialogContentText>
				</Dialog>
			</mui.Box>
		);
	}


	/**
	 * Get URI to navigate to after login.
	 *
	 * @return {String}
	 */
	getExitUri() {
		const qs = this.props.history.location.search;
		return (queryString.parse(qs).uri || "/");
	}


	/**
	 * Handle submission.
	 *
	 * @param {Event} e
	 * @return {void}
	 */
	handleSubmit(e) {

		e.preventDefault();
		this.setState({disabled: true});

		AuthService.auth(this.state).then(auth => {
			Push.unsubscribe().catch(() => null).finally(() => {
				dispatchAuth(auth);
				this.reset();
				Navigator.navigate(this.getExitUri());
			});
		}).catch(e => {
			const user = ((e?.response?.status === 404) || (e?.response?.status === 403));
			this.setState({error: (user ? e?.response?.status : true)});
			this.setState({disabled: false}, () => {
				if ((e?.response?.status === 404) && this.usernameRef?.current) {
					this.usernameRef.current.select();
				}
				else if ((e?.response?.status === 403) && this.passwordRef?.current) {
					this.passwordRef.current.select();
				}
			});
		});

	}


	/**
	 * Handle username change.
	 *
	 * @param {Event} e
	 * @return {void}
	 */
	handleUsername(e) {
		this.setState({username: e.target.value});
		if (this.state.error === 404) this.setState({error: null});
	}


	/**
	 * Handle password change.
	 *
	 * @param {Event} e
	 * @return {void}
	 */
	handlePassword(e) {
		this.setState({password: e.target.value});
		if (this.state.error === 403) this.setState({error: null});
	}


	/**
	 * Get whether needs to redirect to `/login`.
	 *
	 * @return {Boolean}
	 */
	get redirect() {
		return (this.props.location.pathname !== "/login");
	}


	/**
	 * Username help text.
	 *
	 * @return {void}
	 */
	get usernameHelper() {
		return (this.state.error === 404) ? Strings.e404 : null;
	}


	/**
	 * Password help text.
	 *
	 * @return {void}
	 */
	get passwordHelper() {
		return (this.state.error === 403) ? Strings.e403 : null;
	}


	/**
	 * Styles.
	 * 
	 * @type {Object}
	 */
	static styles = {margin: "auto", textAlign: "center"};

	/**
	 * Styles (image).
	 * 
	 * @type {Object}
	 */
	static imgStyles = {height: "4.5rem"};

}

export default connect(({dark, user}) => ({dark, user}))(withRouter(Login));
