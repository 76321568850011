import React from "react";
import Navigator from "App/Navigator.js";
import TableData from "Components/TableData.js";
import Currency from "Helpers/Currency.js";
import mobile from "Helpers/Mobile.js";
import Time from "Helpers/Time.js";
import SpoiPoLink from "../Atoms/SpoiPoLink.js";
import SpoiDomainCaption from "../Components/SpoiDomainCaption.js";
import Details from "../Components/SpoiPurchaseInvoicesTableDetails.js";
import {Box, Typography, withWidth} from "@material-ui/core";

/**
 * Purchase invoices table
 *
 * This must be passed a `SpoiPurchaseInvoiceCollection`.
 * 
 * Please refer to the source for details of all the available props.
 * 
 * @package SEC
 * @subpackage Spoi
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class SpoiPurchaseInvoicesTable extends React.PureComponent {

	/**
	 * PI clicked.
	 *
	 * @param {SpoiPurchaseInvoice} pi
	 * @return {void}
	 */
	handleClick = pi => Navigator.navigate(`/spoi/invoices/${pi.Uuid}`);


	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<TableData
				fields={this.fields}
				info={false}
				loading={this.props.loading}
				pagination={!this.props.disablePagination}
				onChangePage={this.props.onChangePage}
				onChangeRows={this.props.onChangeRows}
				onClick={this.handleClick}
				paginationCount={this.props.count}
				paginationPage={this.props.page}
				paginationRows={this.props.rows}
				values={this.props.values.get?.()}>
			</TableData>
		);
	}


	/**
	 * Render invoice.
	 *
	 * @param {SpoiPurchaseInvoice} pi
	 * @return {ReactNode}
	 */
	renderPi(pi) {
		return (
			<React.Fragment>
				<SpoiDomainCaption
					reference={pi.SupplierInvoiceRef}
					status={pi.Status} />
				{this.mobile ? <Box mt={0.75}><Details pi={pi} /></Box> : null}
			</React.Fragment>
		);
	}


	/**
	 * Render invoice purchase order.
	 * 
	 * @param {SpoiPurchaseInvoice} pi
	 * @return {ReactNode}
	 */
	renderPo(pi) {
		return (
			<Box className="width-fit-content">
				<SpoiPoLink poid={pi?.poid} />
			</Box>
		);
	}


	/**
	 * Render a date value.
	 *
	 * @param {String} date YYYY-MM-DD
	 * @return {ReactNode} [description]
	 */
	renderDate(date) {
		return <Typography>{Time.dmyFromYmd(date, "/")}</Typography>;
	}


	/**
	 * Render total cost.
	 *
	 * @param {SpoiPurchaseInvoice} pi
	 * @return {void}
	 */
	renderTotal(pi) {
		return <Typography>{Currency.format(pi.TotalCurrent)}</Typography>;
	}


	/**
	 * Field definitions.
	 * 
	 * @return {Array}
	 */
	get fields() {
		return [
			{
				label: "Invoice Ref.",
				renderer: this.renderPi.bind(this)
			},
			{
				label: "Order",
				mobile: false,
				renderer: this.renderPo.bind(this)
			},
			{
				label: "Customer",
				mobile: false,
				renderer: pi => (pi.PurchaseOrderDomain.Customer?.Name || "-")
			},
			{
				label: "Project",
				mobile: false,
				renderer: pi => pi.PurchaseOrderDomain.Project.ProjectID
			},
			{
				label: "Raised",
				mobile: false,
				renderer: pi => this.renderDate(pi.ReceivedDate)
			},
			{
				label: "Due",
				mobile: false,
				renderer: pi => this.renderDate(pi.DueDate)
			},
			{
				label: "Total",
				renderer: this.renderTotal.bind(this)
			}
		];
	}


	/**
	 * Get whether we've a mobile viewport.
	 */
	get mobile() {
		return mobile(this.props.width);
	}

}

export default withWidth()(SpoiPurchaseInvoicesTable);
