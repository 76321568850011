import Messenger from "App/Messenger.js";
import DialogAction from "Components/DialogAction.js";
import withSnackbar from "Hoc/withSnackbar.js";
import {MessageConversationDeleteDialog as str} from "Resources/Strings.js";

/**
 * Message conversation deletion dialog
 *
 * @package SEC
 * @subpackage Messages
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class MessageConversationDeleteDialog extends DialogAction {

	/**
	 * Submit.
	 *
	 * @return {Promise}
	 */
	submit() {
		return Messenger.deleteConversation(this.props.contact);
	}


	/**
	 * Strings.
	 *
	 * @return {Object}
	 */
	get strings() {
		return str;
	}

}

export default withSnackbar(MessageConversationDeleteDialog);
