import React from "react";
import Component from "App/Component.js";
import Dialog from "Components/Dialog.js";
import withSnackbar from "Hoc/withSnackbar";
import UserService from "Services/UserService.js";
import {AdminUsersStatusDialog as Strings} from "Resources/Strings.js";

/**
 * User enable/disable dialog
 *
 * @package SEC
 * @subpackage Views
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class AdminUsersStatusDialog extends Component {

	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * State
		 *
		 * @type {Object}
		 */
		this.state = {

			/**
			 * Loading
			 *
			 * @type {Boolean}
			 */
			loading: false

		};

	}


	/**
	 * We're actioning.
	 *
	 * @return {void}
	 */
	onOk() {
		let promise;
		this.setState({loading: true});

		/**
		 * Enable/disable
		 */
		if (this.enabled) {
			promise = UserService.disable(this.props.user.Username);
		}
		else promise = UserService.enable(this.props.user.Username);

		/**
		 * Wait for promise
		 */
		promise.then(() => {
			this.props.snackbar(this.actioned, "success");
			this.props.onDone();
		}).catch(() => {
			this.props.snackbar(this.error, "error");
		}).finally(() => {
			this.props.onClose();
			this.setState({loading: false});
		});
	}


	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Dialog
				title={this.title}
				content={this.content}
				open={this.props.open}
				loading={this.state.loading}
				onClose={() => this.props.onClose()}
				onOk={() => this.onOk()} />
		);
	}


	/**
	 * Get action message.
	 *
	 * @return {String}
	 */
	get actioned() {
		const actioned = Strings.actioned;
		return (this.enabled ? actioned.enabled : actioned.disabled);
	}


	/**
	 * Get whether user is currently enabled.
	 *
	 * @return {Boolean}
	 */
	get enabled() {
		return (this.props.user ? this.props.user.Enabled : false);
	}


	/**
	 * Get error message.
	 *
	 * @return {String}
	 */
	get error() {
		const error = Strings.error;
		return (this.enabled ? error.enabled : error.disabled);
	}


	/**
	 * Get title message.
	 *
	 * @return {String}
	 */
	get title() {
		const title = Strings.title;
		return (this.enabled ? title.enabled : title.disabled);
	}


	/**
	 * Get dialog content.
	 *
	 * @return {String}
	 */
	get content() {
		const content = Strings.content;
		return (this.enabled ? content.enabled : content.disabled);
	}

}

export default withSnackbar(AdminUsersStatusDialog);
