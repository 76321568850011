import React from "react";
import rem from "Helpers/Rem.js";
import withMobile from "Hoc/withMobile.js";
import {Box, Typography} from "@material-ui/core";

/**
 * Action bar
 *
 * A component intended to be used at the top of primary view 
 * screens providing a two-column banner where the left column 
 * is intended to be the view label and the right a set of 
 * action buttons to house call-to-actions relevant to the view.
 *
 * Please refer to the source to obtain details of the available props.
 * 
 * @package SEC
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class ActionBar extends React.PureComponent {

	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Box
				alignItems="center"
				display="flex"
				flexDirection={(this.props.isMobile ? "column" : "row")}
				justifyContent="space-between">

				<div style={this.stylesMain}>
					{this.renderMain()}
				</div>
				<div style={this.stylesActions}>
					{this.props.children}
				</div>
			</Box>
		);
	}


	/**
	 * Render main.
	 *
	 * @return {ReactNode}
	 */
	renderMain() {
		if (this.props.main) return this.props.main;
		else return <Typography>{this.props.label}</Typography>;
	}


	/**
	 * Action styles.
	 *
	 * @return {Object}
	 */
	get stylesActions() {
		return {
			display: "grid",
			gridAutoFlow: "column",
			gridColumnGap: rem(1),
			order: ((this.props.isMobile && this.props.invert) ? -1 : 10)
		};
	}


	/**
	 * Main styles.
	 *
	 * @return {Object}
	 */
	get stylesMain() {
		return {
			flexGrow: 1,
			marginBottom: (this.shouldAddMarginBottom ? rem(2) : 0),
			marginTop: (this.shouldAddMarginTop ? rem(2) : 0),
			maxWidth: (this.props.isMobile ? "100%" : "50%"),
			textAlign: (this.props.isMobile ? "center": "left"),
			width: "100%"
		};
	}


	/**
	 * Get whether to apply a bottom margin.
	 * 
	 * @return {Boolean}
	 */
	get shouldAddMarginBottom() {
		const prop = this.props.noMarginBottom;
		return (this.props.isMobile && !this.props.invert && !prop);
	}


	/**
	 * Get whether to apply a top margin.
	 *
	 * @return {Boolean}
	 */
	get shouldAddMarginTop() {
		const prop = this.props.noMarginTop;
		return (this.props.isMobile && this.props.invert && !prop);
	}

}

export default withMobile(ActionBar);
