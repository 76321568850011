import React from "react";
import Container from "Components/Container.js";
import Label from "Components/Label.js";
import withMobile from "Hoc/withMobile.js";

/**
 * About row
 *
 * @package SEC
 * @subpackage Views
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class AboutRow extends React.PureComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Container
				alignItems="center"
				columns={(!this.props.isMobile ? "12.5rem auto" : "100%")}>
				<Label>{this.props.label}</Label>
				<Container
					alignItems="center"
					columns={(!this.props.isMobile ? "21rem auto" : "100%")}
					justifyItems="flex-start">
					{this.props.children}
				</Container>
			</Container>
		);
	}

}

export default withMobile(AboutRow);
