import React from "react";
import Table from "Components/TableData.js";
import UserAvatar from "Components/UserAvatar.js";
import UserEmailEditable from "Components/UserEmailEditable.js";
import mobile from "Helpers/Mobile.js";
import rem from "Helpers/Rem.js";
import AdminUsersDeleteDialog from "./AdminUsersDeleteDialog.js";
import AdminUsersPwrDialog from "./AdminUsersPwrDialog.js";
import AdminUsersStatusDialog from "./AdminUsersStatusDialog.js";
import * as mui from "@material-ui/core";
import {connect} from "react-redux";

/**
 * User table
 *
 * @package SEC
 * @subpackage Views
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class UserTable extends React.PureComponent {

	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * State
		 *
		 * @type {Object}
		 */
		this.state = {

			/**
			 * Active user
			 *
			 * @type {Object}
			 */
			active: null,

			/**
			 * Delete user dialog
			 *
			 * @type {Boolean}
			 */
			delete: false,

			/**
			 * Display password reset dialog
			 *
			 * @type {Boolean}
			 */
			pwr: false,

			/**
			 * Display status dialog
			 *
			 * @type {Boolean}
			 */
			status: false

		};

	}


	/**
	 * Dialog took action.
	 *
	 * @param {String} dialog
	 * @return {void}
	 */
	dialogDone(dialog) {
		this.setState({[dialog]: false});
		this.props.onWantsRefresh();
	}


	/**
	 * User button clicked.
	 *
	 * @param {Object} user
	 * @param {String} dialog
	 * @return {void}
	 */
	userBtn(user, dialog) {
		this.setState({active: user, [dialog]: true});
	}


	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<React.Fragment>
				<Table
					info={false}
					fields={this.fields}
					loading={this.props.loading}
					values={this.props.users}>
				</Table>

				<AdminUsersDeleteDialog
					user={this.state.active}
					open={this.state.delete}
					onDone={() => this.dialogDone("delete")}
					onClose={() => this.setState({delete: false})} />
				<AdminUsersPwrDialog
					user={this.state.active}
					open={this.state.pwr}
					onDone={() => this.dialogDone("pwr")}
					onClose={() => this.setState({pwr: false})} />
				<AdminUsersStatusDialog
					user={this.state.active}
					open={this.state.status}
					onDone={() => this.dialogDone("status")}
					onClose={() => this.setState({status: false})} />
			</React.Fragment>
		);
	}


	/**
	 * Render avatar.
	 *
	 * @param {Object} user
	 * @return {ReactNode}
	 */
	renderAvatar(user) {
		return <UserAvatar user={user} style={this.getCellStyles(user)} />;
	}


	/**
	 * Render username.
	 *
	 * @param {Object} user
	 * @return {ReactNode}
	 */
	renderUsername(user) {
		return (
			<React.Fragment>
				<mui.Typography
					style={this.getUsernameStyles(user)}>
					{user.Username}
				</mui.Typography>

				{this.mobile ? this.renderUsernameEmail(user) : null}
			</React.Fragment>
		);
	}


	/**
	 * Render username email caption (mobile).
	 *
	 * @param {Object} user
	 * @return {ReactNode}
	 */
	renderUsernameEmail(user) {
		return (
			<mui.Typography
				variant="caption"
				style={this.getCellStyles(user)}>
				{user.EmailAddress}
			</mui.Typography>
		);
	}


	/**
	 * Render email address.
	 *
	 * @param {Object} user
	 * @return {ReactNode}
	 */
	renderEmailAddress(user) {
		return (
			<UserEmailEditable
				disabled={(user.Username === this.props.username)}
				user={user}
				onSave={this.props.onWantsRefresh}
				textStyle={this.getCellStyles(user)} />
		);
	}


	/**
	 * Render actions.
	 *
	 * @param {Object} user
	 * @return {ReactFragment}
	 */
	renderActions(user) {
		return (
			<React.Fragment>
				<mui.ButtonGroup
					orientation={(this.large ? "horizontal" : "vertical")}>
					<mui.Button
						variant="outlined"
						color="primary"
						disabled={user.Username === this.props.username}
						onClick={() => this.userBtn(user, "pwr")}>
						Reset
					</mui.Button>
					<mui.Button
						variant="outlined"
						color="primary"
						disabled={user.Username === this.props.username}
						onClick={() => this.userBtn(user, "delete")}>
						Delete
					</mui.Button>
					<mui.Button
						variant="outlined"
						color="primary"
						disabled={user.Username === this.props.username}
						onClick={() => this.userBtn(user, "status")}>
						{user.Enabled ? "Disable" : "Enable"}
					</mui.Button>
				</mui.ButtonGroup>
			</React.Fragment>
		);
	}


	/**
	 * Get cell styles.
	 *
	 * @param {Object} user
	 * @return {Object}
	 */
	getCellStyles(user) {
		return {opacity: (user.Enabled ? 1 : 0.5)};
	}


	/**
	 * Get username cell styles.
	 *
	 * @param {Object} user
	 * @return {Object}
	 */
	getUsernameStyles(user) {
		return {
			...this.getCellStyles(user),
			marginBottom: (this.mobile ? rem(0.5) : 0)
		};
	}


	/**
	 * Field definitions.
	 *
	 * @return {Array}
	 */
	get fields() {
		return [
			{
				label: "",
				renderer: this.renderAvatar.bind(this),
				style: {
					minWidth: (this.xs ? rem(4) : rem(6)),
					textAlign: "center",
					width: (this.xs ? rem(4) : rem(6))
				}
			},
			{
				label: "Username",
				renderer: this.renderUsername.bind(this)
			},
			...(!this.mobile ? this.fieldsDesktop : []),
			{
				label: "Actions",
				renderer: this.renderActions.bind(this),
				style: {maxWidth: rem(9)}
			}
		];
	}


	/**
	 * Field definitions (desktop).
	 *
	 * @return {Array}
	 */
	get fieldsDesktop() {
		return [
			{
				label: "Email address",
				renderer: this.renderEmailAddress.bind(this)
			}
		];
	}


	/**
	 * Get whether at a large breakpoint.
	 *
	 * @return {Boolean}
	 */
	get large() {
		return (["lg", "xl"].includes(this.props.width));
	}


	/**
	 * Get whether mobile.
	 *
	 * @return {Boolean}
	 */
	get mobile() {
		return mobile(this.props.width);
	}


	/**
	 * Get whether at "xs" breakpoint.
	 * 
	 * @return {Boolean}
	 */
	get xs() {
		return (this.props.width === "xs");
	}


	/**
	 * Map state to props.
	 *
	 * @param {Object} state
	 * @return {Object}
	 */
	static mstp(state) {
		return {username: state.user.Username};
	}

}

export default connect(UserTable.mstp)(mui.withWidth()(UserTable));
