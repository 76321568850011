import React from "react";
import AlertBanner from "Components/AlertBanner.js";
import Strings from "../Resources/SpoiAdminWarningStrings.json";
import {connect} from "react-redux";
import {withWidth} from "@material-ui/core";

/**
 * SPOI admin login warning
 *
 * @package SEC
 * @subpackage Spoi
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class SpoiAdminWarning extends React.PureComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<AlertBanner
				abs={true}
				content={Strings.message}
				open={(this.props.user.CustomerId === null)}
				title={Strings.title}
				severity="warning">
			</AlertBanner>
		);
	}

}

export default connect(({user}) => ({user}))(withWidth()(SpoiAdminWarning));
