import State from "./State.js";
import StatePersistent from "./StatePersistent.js";
import Storage from "./Storage.js";

/**
 * Reducer
 *
 * @package SEC
 * @subpackage App
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class Reducer {

	/**
	 * API URI.
	 *
	 * @param {Object} state
	 * @param {String|null} options.api
	 * @return {Object}
	 */
	static api(state, {api}) {
		return {...state, api};
	}


	/**
	 * Authenticating.
	 *
	 * @param {Object} state
	 * @param {String} options.auth Auth token
	 * @param {Object} options.user User object
	 * @param {Array} options.permissions User permissions
	 * @return {Object}
	 */
	static auth(state, {auth, user, permissions}) {
		return {...state, auth, user, permissions};
	}


	/**
	 * Cache an item overwriting any existing item with the same ID.
	 *
	 * @param {Object} state
	 * @param {String} options.id
	 * @param {mixed} options.data
	 * @return {Object}
	 */
	static cache(state, {id, data}) {
		return {...state, cache: {...state.cache, [id]: data}};
	}


	/**
	 * Clear cached resources.
	 *
	 * @param {Object} state
	 * @return {Object}
	 */
	static cacheClear(state) {
		return {...state, cache: {}};
	}


	/**
	 * Dark mode reducer.
	 * 
	 * @param {Object} state
	 * @param {Boolean} options.dark
	 * @return {Object}
	 */
	static dark(state, {dark}) {
		return {...state, dark};
	}


	/**
	 * F12 dispatcher.
	 *
	 * @param {Object} state
	 * @param {Boolean} options.f12
	 * @return {Object}
	 */
	static f12(state, {f12}) {
		return {...state, f12};
	}


	/**
	 * Messages updated.
	 *
	 * @param {Object} state
	 * @param {Array} options.msgs
	 * @return {Object}
	 */
	static messages(state, {msgs}) {
		return {...state, messages: msgs};
	}


	/**
	 * Messages marked as read.
	 * 
	 * @param {Object} state
	 * @return {Object}
	 */
	static messagesRead(state) {
		return {...state, messages: [], messagesMarkedReadTime: Date.now()};
	}


	/**
	 * Messages received; add them to the state messages.
	 *
	 * @param {Object} state
	 * @param {Array} options.msgs
	 * @return {Object}
	 */
	static messagesReceived(state, {msgs}) {
		return {...state, messages: [...state.messages, ...msgs]};
	}


	/**
	 * Permissions refreshing.
	 *
	 * @param {Object} state
	 * @param {Boolean} options.permr
	 * @return {Object}
	 */
	static permr(state, {permr}) {
		return {...state, permr};
	}


	/**
	 * Polling for messages.
	 *
	 * @param {Object} state
	 * @param {Boolean} options.polling
	 * @return {Object}
	 */
	static polling(state, {polling}) {
		return {...state, polling};
	}


	/**
	 * Postable queue contents updated.
	 *
	 * @param {Object} state
	 * @param {Integer} options.postable
	 * @return {Object}
	 */
	static postable(state, {postable}) {
		return {...state, sync: {...state.sync, pending: postable}};
	}


	/**
	 * Push banner visibility updated.
	 *
	 * @param {Object} state
	 * @param {Boolean} options.pushBanner
	 * @return {void}
	 */
	static pushBanner(state, {pushBanner}) {
		return {...state, pushBanner};
	}


	/**
	 * Syncing.
	 *
	 * @param {Object} state
	 * @param {Boolean} options.syncing
	 * @return {Object}
	 */
	static syncing(state, {syncing}) {
		return {...state, sync: {...state.sync, syncing}};
	}


	/**
	 * Reset the state.
	 *
	 * @param {Object} state
	 * @return {Object}
	 */
	static reset(state) {
		return {...State, ...StatePersistent, api: state.api, dark: state.dark};
	}


	/**
	 * Route key changed.
	 * 
	 * @param {Object} state
	 * @return {Object}
	 */
	static rkey(state) {
		return {...state, rkey: (state.rkey + 1)};
	}


	/**
	 * Reduce the state.
	 *
	 * @param {Object} state
	 * @param {Object} action
	 * @return {Object}
	 */
	static reduce(state, action) {
		if (Reducer.hasOwnProperty(action.type)) {
			state = Reducer[action.type](state, action);
		}
		Storage.save(state);
		return state;
	}

}

export default Reducer.reduce;
