export default {

	/**
	 * F12 UI
	 * 
	 * @type {Boolean}
	 */
	f12: false,

	/**
	 * Permissions refreshing?
	 * 
	 * @type {Boolean}
	 */
	permr: false,

	/**
	 * Polling?
	 * 
	 * @type {Boolean}
	 */
	polling: false,

	/**
	 * Route key
	 *
	 * Used to enable route re-renders.
	 *
	 * @type {Integer}
	 */
	rkey: 0,

	/**
	 * Sync status
	 *
	 * @type {Object}
	 */
	sync: {

		/**
		 * Pending sync
		 *
		 * @type {Integer}
		 */
		pending: 0,

		/**
		 * Currently syncing
		 *
		 * @type {Boolean}
		 */
		syncing: false

	},

	/**
	 * Messages all marked as read time
	 *
	 * @type {Integer}
	 */
	messagesMarkedReadTime: null

};
