import SpoiStatuses from "../Resources/SpoiStatuses.json";

/**
 * SPOI status helper
 *
 * Utility methods for interacting with SPOI status definitions.
 *
 * @package SEC
 * @subpackage Spoi
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class SpoiStatusHelper {

	/**
	 * Resolve a global status name to a local name.
	 *
	 * @param {String} status
	 * @return {String} Local override when set (or the status name)
	 */
	static resolveGlobalToLocal(status) {
		const def = SpoiStatuses.find(s => (s.name === status));
		return (def?.name_local || status);
	}


	/**
	 * Resolve an array of local status names to all global names.
	 *
	 * Any local names will be replaced with their global name.
	 * 
	 * @param {Array} statuses
	 * @return {Array}
	 */
	static resolveLocalToGlobalStatuses(statuses) {
		statuses = [...statuses];
		statuses.forEach(status => {
			const def = SpoiStatuses.find(s => (s.name_local === status));
			if (def) statuses[statuses.indexOf(status)] = def.name;
		});
		return statuses;
	}

}

export default SpoiStatusHelper;
