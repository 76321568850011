import React from "react";
import {Button as ButtonMui} from "@material-ui/core";

/**
 * Button component
 *
 * An abstraction over the default Material button.
 * 
 * @package SEC
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class Button extends React.PureComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<ButtonMui
				color={(this.props.color || "primary")}
				disabled={this.props.disabled}
				onClick={this.props.onClick}
				size={this.props.size}
				type={this.props.type}
				variant={this.props.variant}>
				{this.props.label}
			</ButtonMui>
		);
	}

}

export default Button;
