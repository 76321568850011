import React from "react";
import DialogAlert from "Components/DialogAlert.js";
import Navigator from "App/Navigator.js";

/**
 * Raising a PI against a PO in an invalid status dialog
 *
 * Automatically routes to the POs screen upon close.
 *
 * @package SEC
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class SpoiPoPiRaiseInvalidStatus extends React.PureComponent {

	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<DialogAlert
				open={this.props.open}
				title="Forbidden"
				content="You cannot raise an invoice against this order."
				onClose={() => Navigator.navigate("/spoi/orders")} />
		);
	}

}

export default SpoiPoPiRaiseInvalidStatus;
