import React from "react";
import Routes from "./Routes.js";
import ErrorView from "Views/ErrorView.js";
import History from "./History.js";
import Shell from "Views/Shell.js";
import {connect} from "react-redux";
import {Router as BrowserRouter, Route, Switch} from "react-router-dom";
import * as Sentry from "@sentry/react";

/**
 * Router
 *
 * @package SEC
 * @subpackage App
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class Router extends React.PureComponent {

	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<BrowserRouter history={History}>
				{(this.auth() && <Shell />)}
				<Sentry.ErrorBoundary fallback={<ErrorView isRootView={true} />} key={this.props.rkey}>
					{
						this.universal.map((r, i) => (
							<Route
								key={i}
								path={r.uri}
								component={r.component} />
						))
					}
					<Switch key={this.props.rkey}>
						{
							this.routes.map((r, i) => (
								<Route
									key={i}
									path={r.uri}
									component={r.component} />
							))
						}
					</Switch>
				</Sentry.ErrorBoundary>
			</BrowserRouter>
		);
	}


	/**
	 * Authenticated.
	 *
	 * @return {Boolean}
	 */
	auth() {
		return (this.props.auth !== null);
	}


	/**
	 * Get whether a given permission is available.
	 *
	 * @param {String} permission
	 * @return {Boolean}
	 */
	hasPermission(permission) {
		return (this.props.permissions.includes(permission));
	}


	/**
	 * Verify if a given route can be accessed.
	 *
	 * @param {Object} route
	 * @return {Boolean}
	 */
	verify(route) {
		if (route.permission === false) return true;
		else if (!route.permission && this.auth()) return true;
		else return this.hasPermission(route.permission);
	}


	/**
	 * Get all routes visible to the user, excluding universal routes.
	 *
	 * @return {Array}
	 */
	get routes() {
		return Routes.filter(route => {
			if (route.universal) return false;
			else return this.verify(route);
		});
	}


	/**
	 * Get all universal routes visible to the user.
	 *
	 * @return {Array}
	 */
	get universal() {
		return Routes.filter(route => {
			if (!route.universal) return false;
			else return this.verify(route);
		});
	}

}

export default connect(({auth, permissions, rkey}) => ({auth, permissions, rkey}))(Router);
