import React from "react";
import {TextField} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";

/**
 * Attachments list
 *
 * @package SEC
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class AttachmentsList extends React.PureComponent {

	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * Method binds
		 */
		this.handleChange = this.handleChange.bind(this);
		this.handleClick = this.handleClick.bind(this);

	}


	/**
	 * Value changed.
	 * 
	 * @param {Event} e
	 * @param {Array} values
	 * @return {void}
	 */
	handleChange(e, values) {
		if (!this.props.disabled) this.props.onChange(values);
	}


	/**
	 * Handle chip click.
	 *
	 * Invokes `props.onClick(...)` passing the clicked chip's label.
	 *
	 * @param {Event} e
	 * @return {void}
	 */
	handleClick(e) {
		const validRole = e.target.hasAttribute("role");
		const validLabel = e.target.classList.contains("MuiChip-label");
		const validIcon = (["svg", "path"].includes(e.target.tagName));
		if ((!validRole && !validLabel) || validIcon) return;
		else this.props.onClick(this.constructor.getClickedLabel(e.target));
	}


	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Autocomplete
				ChipProps={{disabled: this.props.disabled, onClick: this.handleClick}}
				disabled={true}
				multiple={true}
				onChange={this.handleChange}
				onClick={this.handleClick}
				options={[]}
				renderInput={this.constructor.renderInput}
				value={this.props.value} />
		);
	}


	/**
	 * Render the input.
	 *
	 * @param {Object} props
	 * @return {ReactNode}
	 */
	static renderInput(props) {
		return <TextField {...props} disabled fullWidth />;
	}


	/**
	 * Get label for clicked item.
	 *
	 * We don't know which element in the chip tree 
	 * was clicked, so we have to search for the label.
	 *
	 * @param {HTMLElement} target
	 * @return {String}
	 */
	static getClickedLabel(target) {
		if (target.classList.contains("MuiChip-label")) {
			return target.innerText;
		}
		else return target.querySelector("span").innerText;
	}

}

export default AttachmentsList;
