import React from "react";
import Container from "Components/Container.js";
import String from "Components/String.js";
import * as mui from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";
import scss from "./MultiSelect.module.scss";

/**
 * Multiselect
 *
 * @package SEC
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class MultiSelect extends React.PureComponent {

	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * Method binds
		 */
		this.getOptionLabel = this.getOptionLabel.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.renderInput = this.renderInput.bind(this);
		this.renderOption = this.renderOption.bind(this);

	}


	/**
	 * Value changed.
	 *
	 * @param {Event} e
	 * @param {mixed} v
	 * @return {void}
	 */
	handleChange(e, v) {
		this.props.onChange((v || []));
	}


	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Autocomplete
				autoComplete={true}
				autoHighlight={true}
				classes={this.constructor.classes}
				disabled={this.props.disabled}
				disableCloseOnSelect={true}
				getOptionLabel={this.getOptionLabel}
				groupBy={this.props.groupBy}
				loading={this.props.loading}
				multiple={true}
				onChange={this.handleChange}
				noOptionsText="No matches"
				options={this.props.options}
				renderInput={this.renderInput}
				renderOption={this.renderOption}
				size="small"
				style={this.constructor.styles}
				value={this.props.value} />
		);
	}


	/**
	 * Render input.
	 * 
	 * @param {Object} props
	 * @return {self}
	 */
	renderInput(props) {
		return (
			<mui.TextField
				{...props}
				fullWidth
				label={this.props.label} />
		);
	}


	/**
	 * Render option.
	 *
	 * @param {Object} option
	 * @param {Boolean} options.selected
	 * @return {ReactNode}
	 */
	renderOption(option, {selected}) {
		return (
			<Container
				alignItems="center"
				columns="max-content 1fr"
				gap={0.5}>
				<mui.Checkbox
					color="primary"
					checked={selected}
					size="small"
					edge="start"
					style={this.constructor.stylesCheckbox} />
				<String
					noParagraph={true}
					str={this.getOptionLabel(option)}
					variant="caption" />
			</Container>
		);
	}


	/**
	 * Get an option's label.
	 *
	 * @param {Object} option
	 * @return {String}
	 */
	getOptionLabel(option) {
		return (option.labelVisible || option.label);
	}


	/**
	 * Classes.
	 * 
	 * @type {Object}
	 */
	static classes = {popper: scss.smallPopper};

	/**
	 * Styles.
	 * 
	 * @type {Object}
	 */
	static styles = {minWidth: 0};

	/**
	 * Checkbox styles.
	 * 
	 * @type {Object}
	 */
	static stylesCheckbox = {height: "1.5rem", minWidth: "1.5rem", padding: "0.5rem", width: "1.5rem"};

}

export default MultiSelect;
