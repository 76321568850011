import React from "react";
import Table from "Components/TableData.js";
import rem from "Helpers/Rem.js";
import TimeHelper from "Helpers/Time.js";
import AuditService from "Services/AuditService.js";
import AdminAuditsDialog from "./AdminAuditsDialog.js";
import {Typography} from "@material-ui/core";

/**
 * Audit log table
 *
 * @package SEC
 * @subpackage Views
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class AdminAuditsTable extends React.PureComponent {

	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<React.Fragment>
				<Table
					fields={this.fields}
					values={this.props.audits}
					loading={this.props.loading}
					infoComponent={AdminAuditsDialog} />
			</React.Fragment>
		);
	}


	/**
	 * Render event field.
	 *
	 * @param {Object} audit
	 * @return {ReactNode}
	 */
	renderEvent(audit) {
		return (
			<React.Fragment>
				<Typography style={{marginBottom: rem(0.5)}}>
					<span style={{fontWeight: "bold"}}>
						{AuditService.getEventName(audit.Event)}
					</span>
				</Typography>
				<Typography>
					{TimeHelper.dmyhmsFromDatetime(audit.EventTime)}
				</Typography>
			</React.Fragment>
		);
	}


	/**
	 * Render username field.
	 *
	 * @param {Object} audit
	 * @return {ReactNode}
	 */
	renderUser(audit) {
		return <Typography>{(audit.EventUser || "(SEC)")}</Typography>;
	}


	/**
	 * Field definitions.
	 *
	 * @return {Array}
	 */
	get fields() {
		return [
			{
				label: "Event",
				renderer: this.renderEvent.bind(this)
			},
			{
				label: "User",
				renderer: this.renderUser.bind(this)
			}
		];
	}

}

export default AdminAuditsTable;
