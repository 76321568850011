import React from "react";
import * as mui from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

/**
 * Details
 *
 * @package SEC
 * @subpackage Views
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class Details extends React.PureComponent {

	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * State
		 *
		 * @type {Object}
		 */
		this.state = {

			/**
			 * Open
			 *
			 * @type {Boolean}
			 */
			open: true

		};

		/**
		 * Icon
		 * 
		 * @type {ReactNode}
		 */
		this.icon = <ExpandMoreIcon />;

		/**
		 * Method binds
		 */
		this.toggle = this.toggle.bind(this);

	}


	/**
	 * Component mounted.
	 *
	 * @return {void}
	 */
	componentDidMount() {
		if (this.props.hasOwnProperty("open")) {
			this.setState({open: this.props.open});
		}
	}


	/**
	 * Toggle visibility state.
	 * 
	 * @return {void}
	 */
	toggle() {
		this.setState({open: !this.state.open});
	}


	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<mui.Accordion
				elevation={2}
				expanded={this.state.open}
				onChange={this.toggle}>

				<mui.AccordionSummary expandIcon={this.icon}>
					<mui.Typography variant="h6">
						{this.props.label}
					</mui.Typography>
				</mui.AccordionSummary>
				<mui.AccordionDetails style={this.constructor.apdStyles}>
					{this.props.children}
				</mui.AccordionDetails>

			</mui.Accordion>
		);
	}


	/**
	 * Accordion details styles.
	 *
	 * @type {Object}
	 */
	static apdStyles = {flexDirection: "column"};

}

export default Details;
