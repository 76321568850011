import React from "react";
import AboutRow from "./AboutRow.js";
import Component from "App/Component.js";
import Post from "App/Post.js";
import View from "App/View.js";
import Container from "Components/Container.js";
import Dialog from "Components/Dialog.js";
import Table from "Components/Table.js";
import Version from "Components/Version.js";
import dispatchAuthenticate from "Dispatchers/Authenticate.js";
import dispatchCacheClear from "Dispatchers/CacheClear.js";
import dispatchPermr from "Dispatchers/Permr.js";
import withMobile from "Hoc/withMobile.js";
import withSnackbar from "Hoc/withSnackbar.js";
import AuthService from "Services/AuthService.js";
import rem from "Helpers/Rem.js";
import {connect} from "react-redux";
import {About as Strings} from "Resources/Strings.js";
import * as mui from "@material-ui/core";

/**
 * About
 *
 * @package SEC
 * @subpackage Views
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class About extends Component {

	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * State
		 *
		 * @type {Object}
		 */
		this.state = {

			/**
			 * Display clear cache dialog
			 *
			 * @type {Boolean}
			 */
			cacheDialog: false,

			/**
			 * Display clear uploads dialog
			 *
			 * @type {Boolean}
			 */
			uploadDialog: false

		};

		/**
		 * Method binds
		 */
		this.authenticate = this.authenticate.bind(this);
		this.clearCache = this.clearCache.bind(this);
		this.clearUploads = this.clearUploads.bind(this);

	}


	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<View>
				<Table style={this.constructor.styles}>
					<mui.TableRow>
						<mui.TableCell>
							<AboutRow label={Strings.appVersion}>
								<Version />
							</AboutRow>
						</mui.TableCell>
					</mui.TableRow>
					<mui.TableRow>
						<mui.TableCell>
							<AboutRow label={Strings.uploadsPending}>
								<Container columns="repeat(2, max-content)">
									<mui.Box mr={1}>
										<mui.Typography>
											{this.props.sync.pending}
										</mui.Typography>
									</mui.Box>
									<mui.Link
										color="primary"
										onClick={() => this.uploadDialog(true)}>
										<mui.Typography>
											{Strings.uploadButton}
										</mui.Typography>
									</mui.Link>
								</Container>
								<mui.Typography color="textSecondary">
									{Strings.help.uploads}
								</mui.Typography>
							</AboutRow>
						</mui.TableCell>
					</mui.TableRow>
					<mui.TableRow>
						<mui.TableCell>
							<AboutRow label={Strings.cacheClear}>
								<mui.Button
									color="primary"
									variant="outlined"
									onClick={() => this.cacheDialog(true)}>
									{Strings.cacheButton}
								</mui.Button>
								<mui.Typography color="textSecondary">
									{Strings.help.cache}
								</mui.Typography>
							</AboutRow>
						</mui.TableCell>
					</mui.TableRow>
					<mui.TableRow>
						<mui.TableCell>
							<AboutRow label={Strings.authenticate}>
								<mui.Button
									color="primary"
									disabled={this.props.permr}
									variant="outlined"
									onClick={this.authenticate}>
									{Strings.authButton}
								</mui.Button>
								<mui.Typography color="textSecondary">
									{Strings.help.authenticate}
								</mui.Typography>
							</AboutRow>
						</mui.TableCell>
					</mui.TableRow>
					<mui.TableRow>
						<mui.TableCell colSpan={2}>
							<mui.Typography>
								App and API built by {this.heron}.
							</mui.Typography>
						</mui.TableCell>
					</mui.TableRow>
					<mui.TableRow>
						<mui.TableCell colSpan={2}>
							<mui.Typography>
								&copy;SEC Group. All rights reserved.
							</mui.Typography>
						</mui.TableCell>
					</mui.TableRow>
				</Table>

				<Dialog
					open={this.state.cacheDialog}
					title={Strings.cacheButton}
					content={Strings.cacheClearWarning}
					onClose={() => this.cacheDialog(false)}
					onOk={this.clearCache}>
				</Dialog>
				<Dialog
					open={this.state.uploadDialog}
					title={Strings.uploadsClear}
					content={Strings.uploadsClearWarning}
					onClose={() => this.uploadDialog(false)}
					onOk={this.clearUploads}>
				</Dialog>
			</View>
		);
	}


	/**
	 * Authenticate.
	 *
	 * @return {void}
	 */
	authenticate() {

		dispatchPermr(true);

		AuthService.getUserPermissions().then(permissions => {
			dispatchAuthenticate({
				permissions,
				auth: this.props.auth,
				user: this.props.user
			});
			this.props.snackbar(Strings.toastAuthSuccess, "success");
		}).catch(() => {
			this.props.snackbar(Strings.toastAuthFailure, "error");
		}).finally(() => {
			dispatchPermr(false);
		});

	}


	/**
	 * Clear cache.
	 *
	 * @return {void}
	 */
	clearCache() {
		dispatchCacheClear();
		this.cacheDialog(false);
		this.props.snackbar(Strings.toastCacheClearSuccess, "success");
	}


	/**
	 * Clear uploads.
	 *
	 * @return {void}
	 */
	clearUploads() {
		this.uploadDialog(false);
		Post.clear().then(() => {
			this.props.snackbar(Strings.toastUploadsClearSuccess, "success");
		}).catch(() => {
			this.props.snackbar(Strings.toastUploadsClearFailure, "error");
		});
	}


	/**
	 * Control cache dialog.
	 *
	 * @param {Boolean} cacheDialog
	 * @return {void}
	 */
	cacheDialog(cacheDialog) {
		this.setState({cacheDialog});
	}


	/**
	 * Control upload dialog.
	 *
	 * @param {Boolean} uploadDialog
	 * @return {void}
	 */
	uploadDialog(uploadDialog) {
		this.setState({uploadDialog});
	}


	/**
	 * Heron link.
	 *
	 * @return {ReactNode}
	 */
	get heron() {
		return (
			<a
				className="a-heron"
				href="https://www.heron-web.com"
				rel="noopener noreferrer"
				target="_blank">
				Heron Web
			</a>
		);
	}


	/**
	 * Base styles.
	 * 
	 * @type {Object}
	 */
	static styles = {maxWidth: rem(72)};


	/**
	 * Redux state.
	 *
	 * @param {Object} state
	 * @return {Object}
	 */
	static mapStateToProps(state) {
		return {
			auth: state.auth,
			user: state.user,
			permissions: state.permissions,
			permr: state.permr,
			sync: state.sync
		};
	}

}

export default connect(About.mapStateToProps)(withMobile(withSnackbar(About)));
