import strftime from "strftime";

/**
 * DocuSign form helper
 *
 * @package SEC
 * @subpackage Views
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class DocuSignFormHelper {

	/**
	 * Get object of initial tab values for the given template.
	 *
	 * @param {Object} form Template
	 * @return {Object} Map of tab name to value
	 */
	static getTemplateTabsValues(form) {
		const tabs = {};
		form.tabs.forEach(tab => {
			if (tab.type === "date") {
				tabs[tab.name] = strftime("%Y-%m-%d");
			}
			else tabs[tab.name] = tab.value || "";
		});
		return tabs;
	}


	/**
	 * Get object of initial recipient definitions for the given template.
	 *
	 * @param {Object} form Template
	 * @return {Object} Map of role name to object with `name`/`email`
	 */
	static getTemplateRecipientsValues(form) {
		const recipients = {};
		form.recipients.forEach(r => {
			const value = r.value ? ({...r.value}) : null;
			recipients[r.role] = value || {name: "", email: ""};
		});
		return recipients;
	}


	/**
	 * Get tabs for submission.
	 *
	 * @param {Object} stateTabs Tabs from state
	 * @param {Object} templateTabs Tabs definition in form template
	 * @return {Object}
	 */
	static resolveTabs(stateTabs, templateTabs) {
		return Object.keys(stateTabs).map(name => {
			return {
				name,
				role: templateTabs.filter(t => t.name === name)[0].role,
				value: stateTabs[name]
			};
		});
	}


	/**
	 * Get recipients for submission.
	 *
	 * @param {Object} recipients Recipients from state
	 * @return {Object}
	 */
	static resolveRecipients(recipients) {
		return Object.keys(recipients).map(role => {
			return {
				role,
				name: recipients[role].name,
				email: recipients[role].email
			};
		});
	}

}

export default DocuSignFormHelper;
