import Collection from "Domain/Collection.js";
import SpoiPurchaseInvoiceRaiseItem from "./SpoiPurchaseInvoiceRaiseItem.js";

/**
 * SPOI Purchase invoice item collection
 * 
 * @package SECGroup
 * @subpackage Spoi
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class SpoiPurchaseInvoiceRaiseItemCollection extends Collection {

	/**
	 * Get the total amount.
	 *
	 * @return {Float}
	 */
	get total() {
		return this.items.map(i => i.Amount).reduce((a, b) => (a + b), 0);
	}


	/**
	 * Get the total VAT amount.
	 * 
	 * @return {Float}
	 */
	get totalVat() {
		return this.items.map(i => (i.Amount * (i.TaxCode?.Value || 1))).reduce((a, b) => (a + b), 0);
	}


	/**
	 * Get all used tax codes (IDs).
	 *
	 * @return {Array} Tax code IDs
	 */
	get usedTaxCodes() {
		return this.items.map(i => i.TaxCode?.Id).filter(t => (!!t));
	}


	/**
	 * Get the collection's type.
	 * 
	 * @return {Object}
	 */
	static get type() {
		return SpoiPurchaseInvoiceRaiseItem;
	}

}

export default SpoiPurchaseInvoiceRaiseItemCollection;
