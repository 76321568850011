/**
 * Health and safety NMPIs search helper
 *
 * @package SEC
 * @subpackage Views
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class HealthSafetyNmpisSearchHelper {

	/**
	 * Get whether filters in current state have changed from 
	 * a given previous state object. Returns `true` if any 
	 * filter value has changed.
	 *
	 * @param {Object} current
	 * @param {Object} previous
	 * @return {Boolean}
	 */
	static filtersChanged(current, previous) {
		for (let i = 0; i < this.stateFilterProps.length; i++) {
			const now = current[this.stateFilterProps[i]];
			const prev = previous[this.stateFilterProps[i]];
			if (now !== prev) return true;
		}
		return false;
	}


	/**
	 * Resolve filters for `NmpiService` from a given state object.
	 *
	 * @param {Object} state
	 * @return {Object}
	 */
	static getFiltersFromState(state) {
		const {
			incidentDate,
			incidentDateMax,
			closed,
			closedBy,
			closedTime,
			createdBy,
			enquiry,
			enquirySec
		} = state;
		const filters = {};
		if (incidentDate) filters.IncidentDateTime = incidentDate;
		if (incidentDateMax) filters.IncidentDateTimeMax = incidentDateMax;
		if (closed !== 0) filters.Closed = ((closed === 1) ? 0 : 1);
		if (closedBy) filters.ClosedBy = closedBy.Username;
		if (closedTime) filters.ClosedTime = closedTime;
		if (createdBy) filters.CreatedBy = createdBy.Username;
		if (enquiry && !enquirySec) filters.Enquiry = enquiry;
		else if (enquirySec) filters.EnquirySec = true;
		return filters;
	}


	/**
	 * State properties which are filters.
	 *
	 * @return {Object}
	 */
	static get stateFilterProps() {
		return [
			"incidentDate",
			"incidentDateMax",
			"closed",
			"closedBy",
			"closedTime",
			"createdBy",
			"enquiry",
			"enquirySec",
			"contractor",
			"contractorType"
		];
	}

}

export default HealthSafetyNmpisSearchHelper;
