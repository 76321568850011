import React from "react";
import LabelledCaption from "Components/LabelledCaption.js";
import {Box} from "@material-ui/core";

/**
 * Domain details
 *
 * A component which provides a small expandable "details" panel to 
 * display customisable details about a domain object, intended for use 
 * within tables and other space-constrained areas.
 *
 * Provide a `details` prop as an array of objects with `label` and `value` 
 * properties - they will be passed respectively to a `LabelledCaption` 
 * instance displayed as a list of details within a `details` element.
 *
 * Please refer to the source for details of the available props.
 * 
 * @package SEC
 * @subpackage Components
 * @author ReactNode
 * @copyright SEC Group
 */
class DomainDetails extends React.PureComponent {

	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * State
		 * 
		 * @type {Object}
		 */
		this.state = {

			/**
			 * Open?
			 * 
			 * @type {Boolean}
			 */
			open: false

		};

		/**
		 * Timer ID
		 *
		 * @type {Integer|null}
		 */
		this.timer = null;

		/**
		 * Method binds
		 */
		this.handleToggle = this.handleToggle.bind(this);
		this.toggle = this.toggle.bind(this);

	}


	/**
	 * Clear our toggle timer.
	 * 
	 * @return {void}
	 */
	clearTimer() {
		if (this.timer) clearTimeout(this.timer);
	}


	/**
	 * Component unmounting.
	 * 
	 * @return {void}
	 */
	componentWillUnmount() {
		this.clearTimer();
	}


	/**
	 * Toggled.
	 *
	 * Timer is used due to weird issues with DOM not updating otherwise.
	 * 
	 * @param {Event} e
	 * @return {void}
	 */
	handleToggle(e) {
		e.stopPropagation();
		this.clearTimer();
		this.timer = setTimeout(this.toggle);
	}


	/**
	 * Toggle the state.
	 * 
	 * @return {void}
	 */
	toggle() {
		this.setState({open: !this.state.open});
	}


	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<details onClick={this.handleToggle} open={this.state.open}>
				<summary>{(this.props.label || "Details")}</summary>
				<Box mt={0.5}>
					{
						this.props.details.map((detail, k) => (
							<LabelledCaption
								key={k}
								label={detail.label}
								value={detail.value}
								variant={(this.props.variant || "body2")} />
						))
					}
				</Box>
			</details>
		);
	}

}

export default DomainDetails;
