import {api, uris} from "api.js";

/**
 * Customer service
 *
 * @package SEC
 * @subpackage Services
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class CustomerService {

	/**
	 * GET cache
	 *
	 * URI => Promise
	 *
	 * @type {Object}
	 */
	static cache = {};


	/**
	 * Get contacts of a customer.
	 *
	 * Responses are cached so all calls to this method will 
	 * return the same Promise (and hence the same results) through 
	 * the lifetime of this session (errors do invalidate the cache).
	 *
	 * @param {String} customer Customer ID
	 * @return {Promise} Resolves to array of contacts
	 */
	static getContacts(customer) {
		const uri = `${uris.customers.get}/${customer}/contacts`;
		if (!(uri in this.cache)) {
			this.cache[uri] = api.call(uri).then(({data}) => data).catch(e => {
				delete this.cache[uri];
				throw e;
			});
		}
		return this.cache[uri];
	}


	/**
	 * Search for customers by query.
	 *
	 * `CustomerID` and `CustomerName` are searched.
	 *
	 * @param {String} query
	 * @return {Promise} Resolves to array of customers
	 */
	static search(query) {
		return api.call(`${uris.customers.search}?query=${query}`).then(({data}) => data);
	}

}

export default CustomerService;
