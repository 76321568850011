import React from "react";
import TabView from "Components/TabView";
import AdminAudits from "./AdminAudits.js";
import AdminInternals from "./AdminInternals.js";
import AdminPermissions from "./AdminPermissions.js";
import AdminUsers from "./AdminUsers.js";

/**
 * Admin view
 *
 * @package SEC
 * @subpackage Views
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class Admin extends React.PureComponent {

	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<TabView
				url={this.props.location.pathname}
				urlBase="/admin"
				history={this.props.history}
				tabs={this.tabs} />
		);
	}


	/**
	 * Tabs.
	 *
	 * @return {Array}
	 */
	get tabs() {
		return [
			{
				label: "Audit",
				url: "/audit",
				component: <AdminAudits />
			},
			{
				label: "Permissions",
				url: "/permissions",
				component: <AdminPermissions />
			},
			{
				label: "Users",
				url: "/users",
				component: <AdminUsers />
			},
			{
				label: "SEC Hub",
				url: "/application",
				component: <AdminInternals />
			}
		];
	}

}

export default Admin;
