import React from "react";
import Checkbox from "Components/Checkbox.js";
import Component from "App/Component.js";
import Container from "Components/Container.js";
import Loader from "Components/Loader.js";
import ProfileService from "Services/ProfileService.js";
import Table from "Components/Table.js";
import withSnackbar from "Hoc/withSnackbar.js";
import * as mui from "@material-ui/core";
import {connect} from "react-redux";
import {ProfileSettings as Strings} from "Resources/Strings.js";

/**
 * Profile settings screen
 *
 * @package SEC
 * @subpackage Views
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class ProfileSettings extends Component {

	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * State
		 *
		 * @type {Object}
		 */
		this.state = {

			/**
			 * Loading
			 *
			 * @type {Boolean}
			 */
			load: true,

			/**
			 * Error
			 *
			 * @type {Boolean}
			 */
			error: false,

			/**
			 * Settings
			 *
			 * @type {Array}
			 */
			settings: [],

			/**
			 * Disabled inputs
			 *
			 * @type {Object}
			 */
			disabled: {}

		};

		/**
		 * Method binds
		 */
		this.change = this.change.bind(this);

	}


	/**
	 * Component mounted.
	 *
	 * @return {void}
	 */
	componentDidMount() {
		ProfileService.settings().then(settings => {
			const values = {};
			const disabled = {};
			settings.forEach(setting => {
				disabled[setting.Name] = false;
				values[setting.Name] = this.mapSettingValue(setting);
			});
			this.setState({settings, ...values, disabled});
		}).catch(() => {
			this.setState({error: true});
		}).finally(() => this.setState({load: false}));
	}


	/**
	 * Update setting value from event.
	 * 
	 * @param {Boolean} checked
	 * @param {String} setting Setting name
	 * @return {void}
	 */
	change(checked, setting) {
		this.setState({[setting.Name]: checked}, () => this.update(setting));
	}


	/**
	 * Change `disabled` status of a named setting.
	 *
	 * @param {String} setting
	 * @param {Boolean} disabled
	 * @return {void}
	 */
	disabled(setting, disabled) {
		this.setState({
			disabled: {
				...this.state.disabled,
				[setting]: disabled
			}
		});
	}


	/**
	 * Update setting value over network.
	 *
	 * @param {Object} setting
	 * @return {void}
	 */
	update(setting) {
		const value = this.state[setting.Name];
		if (setting.value !== value) {
			this.disabled(setting.Name, true);
			ProfileService.setting(setting.Name, value).then(() => {
				this.getSetting(setting.Name).value = value;
				this.props.snackbar(Strings.setDone, "success");
			}).catch(() => {
				this.setState({[setting.Name]: setting.value});
				this.props.snackbar(Strings.setError, "error");
			}).finally(() => this.disabled(setting.Name, false));
		}
	}


	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<mui.Card>
				<mui.CardContent>
					<mui.Box mb={1}>
						<mui.Typography variant="h6">
							{Strings.title}
						</mui.Typography>
					</mui.Box>
					{this.state.load ? this.renderLoad() : this.renderMain()}
				</mui.CardContent>
			</mui.Card>
		);
	}


	/**
	 * Render loader.
	 *
	 * @return {ReactNode}
	 */
	renderLoad() {
		return (
			<mui.Box style={{textAlign: "center"}}>
				<Loader />
			</mui.Box>
		);
	}


	/**
	 * Render main content.
	 *
	 * @return {ReactNode}
	 */
	renderMain() {
		if (this.state.error) {
			return <mui.Typography>{Strings.error}</mui.Typography>;
		}
		else if (this.state.settings.length === 0) {
			return <mui.Typography>{Strings.empty}</mui.Typography>;
		}
		else return this.renderSettings();
	}


	/**
	 * Render all settings.
	 *
	 * @return {ReactNode}
	 */
	renderSettings() {
		return (
			<React.Fragment>
				<Table size="small">
					{this.userSettings.map((s, k) => this.renderSetting(s, k))}
				</Table>
				<mui.Box mt={1}>
					<mui.Typography color="textSecondary">These settings are saved across all your SEC Hub devices.</mui.Typography>
				</mui.Box>
			</React.Fragment>
		);
	}


	/**
	 * Render a setting.
	 *
	 * @param {Object} setting
	 * @param {Integer} key
	 * @return {ReactNode}
	 */
	renderSetting(setting, key) {
		return (
			<mui.TableRow key={key}>
				<mui.TableCell style={this.constructor.cellStyles}>
					<Container alignItems="center" af="column">
						<Checkbox
							disabled={this.state.disabled[setting.Name]}
							checked={this.state[setting.Name]}
							label={setting.Label}
							onChange={this.change}
							size="small"
							value={setting} />
					</Container>
				</mui.TableCell>
			</mui.TableRow>
		);
	}


	/**
	 * Get a named setting from state.
	 *
	 * @param {String} setting
	 * @return {Object}
	 */
	getSetting(setting) {
		return this.state.settings.filter(s => s.Name === setting)[0];
	}


	/**
	 * Map setting value to local state.
	 *
	 * @param {Object} setting
	 * @return {mixed}
	 */
	mapSettingValue(setting) {
		if (setting.value === null) {
			if (setting.Type === "boolean") return false;
			else if (setting.Type === "integer") return 0;
			else return "";
		}
		else return setting.value;
	}


	/**
	 * Get settings available to the user.
	 *
	 * @return {Array}
	 */
	get userSettings() {
		return this.state.settings.filter(setting => {
			if (!setting.permission) return true;
			else return this.props.permissions.includes(setting.permission);
		});
	}


	/**
	 * Cell styles.
	 * 
	 * @type {Object}
	 */
	static cellStyles = {paddingLeft: 0};

}

export default connect(({permissions}) => ({permissions}))(withSnackbar(ProfileSettings));
