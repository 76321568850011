import React from "react";
import Component from "App/Component.js";
import Dialog from "Components/Dialog.js";

/**
 * Dialog for a network action
 *
 * @package SEC
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class DialogAction extends Component {

	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * State
		 *
		 * @type {Object}
		 */
		this.state = {

			/**
			 * Loading
			 *
			 * @type {Boolean}
			 */
			loading: false

		};

		/**
		 * Method binds
		 */
		this.onOk = this.onOk.bind(this);

	}


	/**
	 * Action.
	 *
	 * @return {void}
	 */
	onOk() {

		this.setState({loading: true});

		this.submit().then(() => {
			this.snackbar(this.strings.success, "success");
			this.props.onDone();
		}).catch(() => {
			this.snackbar(this.strings.error, "error");
		}).finally(() => {
			this.props.onClose();
			this.setState({loading: false});
		});

	}


	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Dialog
				content={this.strings.content}
				loading={this.state.loading}
				onClose={this.props.onClose}
				onOk={this.onOk}
				open={this.props.open}
				title={this.strings.title} />
		);
	}


	/**
	 * Snackbar.
	 *
	 * @param {String} msg
	 * @param {String} variant optional (`default`)
	 * @return {void}
	 */
	snackbar(msg, variant="default") {
		if (this.props.snackbar) this.props.snackbar(msg, variant);
	}


	/**
	 * Submit.
	 *
	 * @return {Promise}
	 */
	submit() {
		return new Promise(resolve => {
			resolve();
		});
	}


	/**
	 * Strings.
	 *
	 * @return {Object}
	 */
	get strings() {
		return {

			/**
			 * Dialog title
			 *
			 * @type {String}
			 */
			title: null,

			/**
			 * Dialog content
			 *
			 * @type {String}
			 */
			content: null,

			/**
			 * Error message
			 *
			 * @type {String}
			 */
			error: null,

			/**
			 * Success message
			 *
			 * @type {String}
			 */
			success: null

		};
	}

}

export default DialogAction;
