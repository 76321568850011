import React from "react";
import HealthSafetyNmpisForm from "./HealthSafetyNmpisForm.js";
import HealthSafetyNmpisMain from "./HealthSafetyNmpisMain.js";
import {Route, Switch} from "react-router-dom";

/**
 * Health & safety (NMPIs)
 *
 * @package SEC
 * @subpackage Views
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class HealthSafetyNmpis extends React.PureComponent {

	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Switch>
				<Route
					path="/health-safety/nmpis/post/:id"
					component={HealthSafetyNmpisForm} />
				<Route
					path="/health-safety/nmpis/post"
					component={HealthSafetyNmpisForm} />
				<Route path="/health-safety/nmpis"
					component={HealthSafetyNmpisMain} />
			</Switch>
		);
	}

}

export default HealthSafetyNmpis;
