import {api} from "api.js";

/**
 * Timesheet service
 *
 * @package SEC
 * @subpackage Timesheets
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class TimesheetService {

	/**
	 * Check if a timesheet is submitted for an installer on a date.
	 *
	 * @param {String} SheetDate YYYY-MM-DD
	 * @param {Integer} InstallerID Installer ID
	 * @return {Promise} Resolves to `AM`/`PM` object
	 */
	static checkSubmitted(SheetDate, InstallerID) {
		return api.call({url: `/api/timesheets/date/${SheetDate}/submitted/${InstallerID}`}).then(({data}) => data);
	}


	/**
	 * Get timesheets.
	 *
	 * @param {Object} params optional API query parameters
	 * @return {Promise} Resolves with API timesheets response
	 */
	static get(params) {
		return api.call({url: `/api/timesheets`, params}).then(({data}) => data);
	}


	/**
	 * Submit a timesheet.
	 *
	 * @param {Object} timesheet
	 * @return {Promise}
	 */
	static submit(timesheet) {
		return api.call({
			url: "/api/timesheets",
			method: "POST",
			data: timesheet
		});
	}


	/**
	 * Review a timesheet.
	 *
	 * @param {Integer} SheetId
	 * @param {Boolean} Accepted
	 * @return {Promise}
	 */
	static review(SheetId, Accepted) {
		return api.call({
			url: `/api/timesheets/${SheetId}/review?Accepted=${(Accepted ? "1" : "0")}`,
			method: "POST"
		});
	}

}

export default TimesheetService;
