import React from "react";
import Container from "Components/Container.js";
import String from "Components/String.js";
import SpoiSupportLink from "../Components/SpoiSupportLink.js";
import Strings from "../Resources/SpoiSupportBannerStrings.json";
import {Typography} from "@material-ui/core";

/**
 * SPOI support banner
 *
 * @package SEC
 * @subpackage Spoi
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class SpoiSupportBanner extends React.PureComponent {

	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Container af="row" gap={0.5}>
				<Typography variant={this.constructor.variant}>
					<span style={{fontWeight: "bold"}}>
						{this.renderLink()}
					</span>
					<span> - </span>
					{Strings.caption}
				</Typography>
				<String
					noParagraph={true}
					str={Strings.help}
					variant={this.constructor.variant} />
			</Container>
		);
	}


	/**
	 * Render the link.
	 *
	 * @return {ReactNode}
	 */
	renderLink() {
		return <SpoiSupportLink>{Strings.label}</SpoiSupportLink>;
	}


	/**
	 * Get the typography variant to use.
	 *
	 * @return {String}
	 */
	static get variant() {
		return "caption";
	}

}

export default SpoiSupportBanner;
