import React from "react";
import rem from "Helpers/Rem.js";
import {Box, TableCell as Cell} from "@material-ui/core";

/**
 * Table cell
 * 
 * Please refer to the source for details of all the available props.
 * 
 * @package SEC
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class TableCell extends React.PureComponent {

	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Cell
				{...this.props}
				style={{...this.props.style, padding: `0 ${rem(0.3)}`}}>
				<Box py={1}>
					{this.props.children}
				</Box>
			</Cell>
		);
	}

}

export default TableCell;
