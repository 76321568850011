import React from "react";
import Component from "App/Component.js";
import Caption from "Components/Caption.js";
import ContextMenu from "Components/ContextMenu.js";
import DialogAlert from "Components/DialogAlert.js";
import Loader from "Components/Loader.js";
import Navigator from "App/Navigator.js";
import Table from "Components/TableData.js";
import mobile from "Helpers/Mobile.js";
import withSnackbar from "Hoc/withSnackbar";
import EnquiryRenderer from "Renderers/EnquiryRenderer.js";
import UserDateRenderer from "Renderers/UserDateRenderer.js";
import * as mui from "@material-ui/core";
import withWidth from "@material-ui/core/withWidth";
import DataRequestsContextMenu from "./DataRequestsContextMenu.js";
import DataRequestsDialog from "./DataRequestsDialog.js";
import DataRequestsDeleteDialog from "./DataRequestsDeleteDialog.js";
import DataRequestsSendDialog from "./DataRequestsSendDialog.js";
import {DataRequestsTable as Strings} from "Resources/Strings.js";
import {connect} from "react-redux";

/**
 * Data requests table
 *
 * @package SEC
 * @subpackage Views
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class DataRequestsTable extends Component {

	/**
	 * Constructor.
	 *
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * State
		 *
		 * @type {Object}
		 */
		this.state = {

			/**
			 * Active data request
			 *
			 * @type {Object}
			 */
			active: null,

			/**
			 * Archived dialog
			 *
			 * @type {Boolean}
			 */
			archivedDialog: false,

			/**
			 * Delete dialog
			 *
			 * @type {Boolean}
			 */
			deleteDialog: false,

			/**
			 * Sending dialog
			 *
			 * @type {Boolean}
			 */
			sendDialog: false,

			/**
			 * Render context menu
			 * 
			 * @type {Boolean}
			 */
			ctxMenu: false,

			/**
			 * Context menu event
			 *
			 * @type {Event}
			 */
			ctxMenuE: null

		};

	}


	/**
	 * Hide context menu.
	 *
	 * @return {void}
	 */
	ctxHide() {
		this.setState({ctxMenu: false});
	}


	/**
	 * Handle click.
	 *
	 * @param {Object} r
	 * @return {void}
	 */
	onClick(r) {
		const uri = `/data-requests/${r._apid ? r._apid : r.id}`;
		if (r.archived) this.setState({archivedDialog: true});
		else Navigator.navigate((r._apid ? `${uri}?apid` : uri));
	}


	/**
	 * Clicked a send button.
	 *
	 * @param {Event} e
	 * @param {Object} active
	 * @return {void}
	 */
	onClickSend(e, active) {
		e.stopPropagation();
		this.setState({active, sendDialog: true});
	}


	/**
	 * Handle context menu.
	 *
	 * @param {Object} request
	 * @param {Event} e
	 * @return {void}
	 */
	onContextMenu(request, e) {
		e.persist();
		this.setState({ctxMenu: true, ctxMenuE: e, active: request});
	}


	/**
	 * Handle context menu "delete" action.
	 *
	 * @return {void}
	 */
	onContextMenuDelete() {
		this.ctxHide();
		this.setState({deleteDialog: true});
	}


	/**
	 * Handle context menu "send" action.
	 *
	 * @return {void}
	 */
	onContextMenuSend() {
		this.ctxHide();
		this.setState({sendDialog: true});
	}


	/**
	 * Handle context menu "view" action.
	 *
	 * @return {void}
	 */
	onContextMenuView() {
		this.ctxHide();
		this.onClick(this.state.active);
	}


	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<React.Fragment>
				<Table
					contextMenu={this.renderContextMenu()}
					fields={this.fields}
					values={this.props.requests}
					onClick={r => this.onClick(r)}
					onContextMenu={(r, e) => this.onContextMenu(r, e)}
					infoComponent={DataRequestsDialog}>
				</Table>

				{this.props.requests === null ? <Loader /> : null}

				<DialogAlert
					title={Strings.alerts.archived.title}
					content={Strings.alerts.archived.content}
					open={this.state.archivedDialog}
					onClose={() => this.setState({archivedDialog: false})}>
				</DialogAlert>

				<DataRequestsDeleteDialog
					request={this.state.active || {}}
					open={this.state.deleteDialog}
					onClose={() => this.setState({deleteDialog: false})}
					onDone={() => this.props.onWantsRefresh()} />
				<DataRequestsSendDialog
					request={this.state.active || {}}
					open={this.state.sendDialog}
					onClose={() => this.setState({sendDialog: false})}
					onDone={() => this.props.onWantsRefresh()} />
			</React.Fragment>
		);
	}


	/**
	 * Render context menu for a data request.
	 *
	 * @return {ReactNode}
	 */
	renderContextMenu() {
		return (
			<ContextMenu
				e={this.state.ctxMenuE}
				open={this.state.ctxMenu}
				onClose={() => this.ctxHide()}>
				<DataRequestsContextMenu
					request={this.state.active}
					onDelete={() => this.onContextMenuDelete()}
					onSend={() => this.onContextMenuSend()}
					onView={() => this.onContextMenuView()} />
			</ContextMenu>
		);
	}


	/**
	 * Render enquiry cell.
	 *
	 * @param {Object} r
	 * @return {ReactNode}
	 */
	renderRequestEnquiry(r) {
		return (
			<EnquiryRenderer
				enquiry={r.enquiry}
				childrenMobile={this.renderRequestEnquiryTimestamps(r)} />
		);
	}


	/**
	 * Render paragraphs for timestamps in `Enquiry` field.
	 *
	 * @param {Object} r
	 * @return {ReactNode}
	 */
	renderRequestEnquiryTimestamps(r) {
		return (
			<React.Fragment>
				{r.sent ? <Caption label="Sent" value={r.sent} /> : null}
				<Caption label="Created" value={r.created} />
			</React.Fragment>
		);
	}


	/**
	 * Render created cell.
	 *
	 * @param {Object} r
	 * @return {ReactNode}
	 */
	renderRequestCreated(r) {
		return <UserDateRenderer user={r.createdBy} time={r.created} />;
	}


	/**
	 * Render updated cell.
	 *
	 * @param {Object} r
	 * @return {ReactNode}
	 */
	renderRequestUpdated(r) {
		return <UserDateRenderer user={r.updatedBy} time={r.updated} />;
	}


	/**
	 * Render status cell.
	 * 
	 * @param {Object} r
	 * @return {ReactNode}
	 */
	renderRequestStatus(r) {
		return (
			<div style={{width: "7.5rem"}}>
				{this.renderRequestStatusContent(r)}
			</div>
		);
	}


	/**
	 * Render status cell content.
	 *
	 * @param {Object} r
	 * @return {ReactNode}
	 */
	renderRequestStatusContent(r) {
		if (r._apid) return <mui.Typography>Draft (Local)</mui.Typography>;
		else if (r.sent) return <mui.Typography>Sent</mui.Typography>;
		else if (r.draft) return <mui.Typography>Draft</mui.Typography>;
		else return this.renderSendButton(r);
	}


	/**
	 * Render send button.
	 *
	 * @param {Object} r
	 * @return {ReactNode}
	 */
	renderSendButton(r) {
		return (
			<mui.Button
				color="primary"
				variant="outlined"
				onClick={e => this.onClickSend(e, r)}>
				Send
			</mui.Button>
		);
	}


	/**
	 * Field definitions for the table.
	 *
	 * @return {Array}
	 */
	get fields() {
		return [
			{
				label: "Enquiry",
				renderer: this.renderRequestEnquiry.bind(this)
			},
			...(this.mobile ? [] : this.fieldsDesktop),
			{
				label: "Status",
				renderer: this.renderRequestStatus.bind(this)
			}
		];
	}


	/**
	 * Field definitions for the table (desktop only).
	 *
	 * @return {Array}
	 */
	get fieldsDesktop() {
		return [
			{
				label: "Created",
				renderer: this.renderRequestCreated.bind(this)
			},
			{
				label: "Updated",
				renderer: this.renderRequestUpdated.bind(this)
			}
		];
	}


	/**
	 * Get whether mobile.
	 *
	 * @return {Boolean}
	 */
	get mobile() {
		return mobile(this.props.width);
	}

}

export default connect(({permissions}) => ({permissions}))(
	withWidth()(withSnackbar(DataRequestsTable))
);
