import React from "react";
import Container from "Components/Container.js";
import Navigator from "App/Navigator.js";
import TableData from "Components/TableData.js";
import Currency from "Helpers/Currency.js";
import Mobile from "Helpers/Mobile.js";
import Time from "Helpers/Time.js";
import SpoiDomainCaption from "../Components/SpoiDomainCaption.js";
import Details from "./SpoiPurchaseOrdersTableDetails.js";
import {Box, Typography, withWidth} from "@material-ui/core";

/**
 * Purchase orders table
 * 
 * Please refer to the source for details of all the available props.
 * 
 * @package SEC
 * @subpackage Spoi
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class SpoiPurchaseOrdersTable extends React.PureComponent {

	/**
	 * PO clicked.
	 *
	 * @param {Object} po
	 * @return {void}
	 */
	handleClick = po => Navigator.navigate(`/spoi/orders/${po.Id}`);


	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<TableData
				fields={this.fields}
				info={false}
				loading={this.props.loading}
				pagination={true}
				onChangePage={this.props.onChangePage}
				onChangeRows={this.props.onChangeRows}
				onClick={this.handleClick}
				paginationCount={this.props.count}
				paginationPage={this.props.page}
				paginationRows={this.props.rows}
				values={this.props.values}>
			</TableData>
		);
	}


	/**
	 * Render the purchase order.
	 *
	 * We render only the ID on desktop but all details on mobile.
	 *
	 * @param {Object} po
	 * @return {ReactNode}
	 */
	renderPO(po) {
		return (!this.mobile ? this.renderPOId(po) : this.renderPOMobile(po));
	}


	/**
	 * Render the purchase order ID.
	 *
	 * @param {Object} po
	 * @return {ReactNode}
	 */
	renderPOId(po) {
		return (
			<SpoiDomainCaption
				af="column"
				reference={po.Id}
				status={po.Status} />
		);
	}


	/**
	 * Render Purchase Order when we've a mobile viewport.
	 *
	 * @param {Object} po
	 * @return {ReactNode}
	 */
	renderPOMobile(po) {
		return (
			<Container>
				<Box style={{fontWeight: "bold"}}>{this.renderPOId(po)}</Box>
				<Box><Typography>{Time.dmyFromYmd(po.RaisedDate, "/")}</Typography></Box>
				<Details po={po} />
			</Container>
		);
	}


	/**
	 * Field definitions.
	 *
	 * @return {Array}
	 */
	get fields() {
		return [
			{
				label: "Purchase Order",
				renderer: this.renderPO.bind(this)
			},
			{
				label: "Customer Name",
				mobile: false,
				renderer: po => (po.Customer?.Name || "-")
			},
			{
				label: "Project ID",
				mobile: false,
				renderer: po => po.Project
			},
			{
				label: "Raised",
				mobile: false,
				renderer: po => Time.dmyFromYmd(po.RaisedDate, "/")
			},
			{
				label: "Total",
				renderer: po => Currency.format(po.Total)
			},
			{
				label: "Invoiced",
				mobile: false,
				renderer: po => Currency.format(po.Invoiced)
			}
		];
	}


	/**
	 * Get whether we've a mobile viewport.
	 *
	 * @return {Boolean}
	 */
	get mobile() {
		return Mobile(this.props.width);
	}

}

export default withWidth()(SpoiPurchaseOrdersTable);
