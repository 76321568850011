import React from "react";
import Loader from "Components/Loader.js";
import rem from "Helpers/Rem.js";
import MessageHelper from "./MessageHelper.js";
import MessageThreadChatMessage from "./MessageThreadChatMessage.js";
import {connect} from "react-redux";
import {Box, Button, Divider, Typography} from "@material-ui/core";
import {MessageThreadChat as Strings} from "Resources/Strings.js";

/**
 * Message thread chat
 *
 * @package SEC
 * @subpackage Messages
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class MessageThreadChat extends React.PureComponent {

	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * Container
		 *
		 * @type {ReactRef}
		 */
		this.container = React.createRef();

		/**
		 * Divider
		 *
		 * @type {ReactRef}
		 */
		this.divider = React.createRef();

		/**
		 * Divider (unread)
		 *
		 * @type {ReactRef}
		 */
		this.dividerUnread = React.createRef();

		/**
		 * Timestamp to render load divider at
		 *
		 * @type {Integer}
		 */
		this.dividerTs = null;

		/**
		 * Whether we've rendered the divider yet
		 *
		 * @type {Boolean}
		 */
		this.dividerTsRendered = false;

		/**
		 * Whether we should render the divider again
		 *
		 * This is used in addition to `dividerTsRendered` to prevent 
		 * multiple dividers rendering in some unique scenarios when 
		 * multiple messages have the same properties.
		 *
		 * @type {Boolean}
		 */
		this.dividerShouldRender = true;

		/**
		 * Offset
		 *
		 * We'll pass this to `onWantsMore()` when we want to 
		 * load more messages. It is the count of messages received 
		 * since the last "load more" operation (i.e. sent and received 
		 * messages which weren't fetched over network) to avoid 
		 * inadvertently fetching messages we already have due to the 
		 * potentially overlapping offsets.
		 * 
		 * @type {Integer}
		 */
		this.offset = 0;

	}


	/**
	 * Component mounted.
	 *
	 * @return {void}
	 */
	componentDidMount() {
		this.scroll();
	}


	/**
	 * Component updated.
	 *
	 * @param {Object} prevProps
	 * @return {void}
	 */
	componentDidUpdate(prevProps) {
		this.dividerShouldRender = true;
		if (this.props.contact !== prevProps.contact) {
			this.scroll();
			this.updateTimestamp();
			this.offset = 0;
			this.dividerTsRendered = false;
		}
		else if (this.props.messages.length !== prevProps.messages.length) {
			const cur = MessageHelper.sort(this.props.messages, 0).reverse()[0];
			const prev = MessageHelper.sort(prevProps.messages, 0).reverse()[0];
			this.handleScroll(cur, prev);
		}
	}


	/**
	 * Handle scrolling the view when messages change.
	 *
	 * @param {Message|null} message The current most recent message
	 * @param {previousMessage|null} previousMessage Previous recent message
	 * @return {void}
	 */
	handleScroll(message, previousMessage) {
		setTimeout(() => {
			if (message?.Timestamp > previousMessage?.Timestamp) {
				this.scroll();
				this.offset++;
			}
			else if (this.dividerUnread.current && !this.dividerTsRendered) {
				this.dividerUnread.current.scrollIntoView();
			}
			else if (this.divider.current) {
				this.divider.current.scrollIntoView();
			}
		}, 250);
	}


	/**
	 * We want more messages!
	 *
	 * @return {void}
	 */
	onWantsMore() {
		this.updateTimestamp();
		this.props.onWantsMore(this.offset);
	}


	/**
	 * Scroll the container.
	 *
	 * @return {void}
	 */
	scroll() {
		setTimeout(() => {
			if (this.container.current) {
				this.container.current.scrollTop = 999999;
			}
		}, 250);
	}


	/**
	 * Update divider timestamp.
	 *
	 * @return {void}
	 */
	updateTimestamp() {
		if (!this.hasMessages) this.dividerTs = null;
		else this.dividerTs = this.messages[0].Timestamp;
	}


	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Box
				my={1}
				pr={1}
				ref={this.container}
				style={{flexGrow: 1, overflowY: "auto"}}>

				{!this.props.ready ? this.renderLoader() : null}
				{this.shouldRenderError ? this.renderError() : null}
				{this.shouldRenderMain ? this.renderMain() : null}
			</Box>
		);
	}


	/**
	 * Render the chat.
	 *
	 * @return {ReactNode}
	 */
	renderChat() {
		return (
			<React.Fragment>
				<Box mt={1} mb={4}>
					<Button
						color="primary"
						disabled={this.props.loading || this.props.done}
						onClick={() => this.onWantsMore()}
						style={{display: "block", margin: "auto"}}
						variant="contained">
						{Strings.loadMore}
					</Button>
				</Box>

				{this.renderChatMessages()}
			</React.Fragment>
		);
	}


	/**
	 * Render the chat messages.
	 *
	 * @return {ReactNode}
	 */
	renderChatMessages() {
		return this.messages.map((msg, k) => {
			return (
				<React.Fragment key={k}>
					{((msg.Timestamp === this.dividerTs) ? this.div() : null)}
					{((msg.Id === this.unreadId) ? this.divUnread() : null)}
					<MessageThreadChatMessage msg={msg} />
				</React.Fragment>
			);
		});
	}


	/**
	 * Render empty state.
	 *
	 * @return {ReactNode}
	 */
	renderEmpty() {
		return (
			<Box style={this.constructor.stylesEmpty}>
				<Typography>{Strings.empty}</Typography>
			</Box>
		);
	}


	/**
	 * Render error state.
	 *
	 * @return {ReactNode}
	 */
	renderError() {
		return (
			<Box style={this.constructor.stylesEmpty}>
				<Typography>{Strings.error}</Typography>
			</Box>
		);
	}


	/**
	 * Render loader.
	 *
	 * @return {ReactNode}
	 */
	renderLoader() {
		return (
			<Box style={this.constructor.stylesEmpty}>
				<Loader />
			</Box>
		);
	}


	/**
	 * Render main.
	 *
	 * @return {ReactNode}
	 */
	renderMain() {
		return (this.hasMessages ? this.renderChat() : this.renderEmpty());
	}


	/**
	 * Render divider to delineate individual loads.
	 *
	 * @return {ReactNode}
	 */
	div() {
		if (this.dividerShouldRender) {
			this.dividerTsRendered = true;
			this.dividerShouldRender = false;
			return <Divider ref={this.divider} style={this.stylesDiv} />;
		}
		else return null;
	}


	/**
	 * Render divider to highlight unread messages.
	 *
	 * @return {ReactNode}
	 */
	divUnread() {
		return (
			<Box>
				<Divider ref={this.dividerUnread} />
				<Typography style={this.stylesDivUnread}>
					{Strings.unread}
				</Typography>
				<Divider />
			</Box>
		);
	}


	/**
	 * Get messages sorted to most recent first.
	 *
	 * @return {Array}
	 */
	get messages() {
		if (!this.hasMessages) return [];
		else return MessageHelper.sort(this.props.messages, false);
	}


	/**
	 * Get whether we have messages.
	 *
	 * @return {Boolean}
	 */
	get hasMessages() {
		return (this.props.messages?.length > 0);
	}


	/**
	 * Get whether to render the error state.
	 *
	 * @return {Boolean}
	 */
	get shouldRenderError() {
		return (this.props.ready && this.props.error);
	}


	/**
	 * Get whether to render the main UI.
	 *
	 * @return {Boolean}
	 */
	get shouldRenderMain() {
		return (this.props.ready && !this.props.error);
	}


	/**
	 * Get first unread message ID.
	 *
	 * @return {String|null}
	 */
	get unreadId() {
		return this.messages.find(m => {
			return ((m.Sender === this.props.contact) && (m.Read === false));
		})?.Id;
	}


	/**
	 * Styles for timestamp divider.
	 *
	 * @return {Object}
	 */
	get stylesDiv() {
		return {
			display: (this.props.done ? "none" : "block"),
			visibility: "hidden"
		};
	}


	/**
	 * Styles for unread messages divider.
	 *
	 * @return {Object}
	 */
	get stylesDivUnread() {
		return {
			fontWeight: "bold",
			margin: `${rem()} auto`,
			textAlign: "center"
		};
	}


	/**
	 * Empty styles.
	 *
	 * @return {Object}
	 */
	static get stylesEmpty() {
		return {
			alignItems: "center",
			display: "flex",
			height: "100%",
			justifyContent: "center"
		};
	}

}

export default connect(({user}) => ({user}))(MessageThreadChat);
