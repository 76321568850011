import React from "react";
import Component from "App/Component.js";
import Navigator from "App/Navigator.js";
import mobile from "Helpers/Mobile.js";
import rem from "Helpers/Rem.js";
import Time from "Helpers/Time.js";
import Caption from "Components/Caption.js";
import ContextMenu from "Components/ContextMenu.js";
import Table from "Components/TableData.js";
import EnquiryRenderer from "Renderers/EnquiryRenderer.js";
import HealthSafetyNmpisDialog from "./HealthSafetyNmpisDialog.js";
import HealthSafetyNmpisCloseDialog from "./HealthSafetyNmpisCloseDialog.js";
import HealthSafetyNmpisDelete from "./HealthSafetyNmpisDeleteDialog.js";
import HealthSafetyNmpisContextMenu from "./HealthSafetyNmpisContextMenu.js";
import * as mui from "@material-ui/core";
import {connect} from "react-redux";

/**
 * Health and safety NMPI table
 *
 * @package SEC
 * @subpackage Views
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class HealthSafetyNmpisTable extends Component {

	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * State
		 *
		 * @type {Object}
		 */
		this.state = {

			/**
			 * Active (for close dialog)
			 *
			 * @type {Object}
			 */
			active: null,

			/**
			 * Render close dialog
			 *
			 * @type {Boolean}
			 */
			closeDialog: false,

			/**
			 * Context menu render
			 *
			 * @type {Integer}
			 */
			ctxMenu: false,

			/**
			 * Context menu event
			 *
			 * @type {Event}
			 */
			ctxMenuE: null,

			/**
			 * Render delete dialog
			 *
			 * @type {Boolean}
			 */
			deleteDialog: false

		};

	}


	/**
	 * Context closure.
	 *
	 * @return {void}
	 */
	ctxHide() {
		this.setState({ctxMenu: false});
	}


	/**
	 * Handle click.
	 *
	 * @param {Object} r
	 * @return {void}
	 */
	onClick(r) {
		let uri = `/health-safety/nmpis/post/`;
		if (r.Name) uri += r.Name;
		else uri += `${r._apid}?apid`;
		Navigator.navigate(uri);
	}


	/**
	 * Handle close/open requested.
	 *
	 * @param {Event} e
	 * @param {Object} nmpi
	 * @return {void}
	 */
	onCloseOpen(e, nmpi) {
		e.preventDefault();
		e.stopPropagation();
		this.setState({closeDialog: true, active: nmpi});
	}


	/**
	 * Handle context menu.
	 *
	 * @param {Object} nmpi
	 * @param {Event} e
	 * @return {void}
	 */
	onContextMenu(nmpi, e) {
		e.persist();
		this.setState({ctxMenu: true, ctxMenuE: e, active: nmpi});
	}


	/**
	 * Handle context menu "close"/"open" actions.
	 *
	 * @return {void}
	 */
	onContextMenuCloseOpen() {
		this.ctxHide();
		this.setState({closeDialog: true});
	}


	/**
	 * Handle context menu "delete" action.
	 *
	 * @return {void}
	 */
	onContextMenuDelete() {
		this.ctxHide();
		this.setState({deleteDialog: true});
	}


	/**
	 * Handle context menu "view" action.
	 *
	 * @return {void}
	 */
	onContextMenuView() {
		this.ctxHide();
		this.onClick(this.state.active);
	}


	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<React.Fragment>
				<Table
					contextMenu={this.renderContextMenu()}
					fields={this.fields}
					values={this.props.nmpis}
					onClick={r => this.onClick(r)}
					onContextMenu={(nmpi, e) => this.onContextMenu(nmpi, e)}
					infoComponent={HealthSafetyNmpisDialog}>
				</Table>

				<HealthSafetyNmpisCloseDialog
					nmpi={this.state.active || {}}
					open={this.state.closeDialog}
					onClose={() => this.setState({closeDialog: false})}
					onChange={() => this.props.onWantsRefresh()} />
				<HealthSafetyNmpisDelete
					nmpi={this.state.active || {}}
					open={this.state.deleteDialog}
					onClose={() => this.setState({deleteDialog: false})}
					onDone={() => this.props.onWantsRefresh()} />
			</React.Fragment>
		);
	}


	/**
	 * Render context menu for an NMPI.
	 *
	 * @return {void}
	 */
	renderContextMenu() {
		return (
			<ContextMenu
				e={this.state.ctxMenuE}
				open={this.state.ctxMenu}
				onClose={() => this.ctxHide()}>
				<HealthSafetyNmpisContextMenu
					nmpi={this.state.active}
					onCloseOpen={() => this.onContextMenuCloseOpen()}
					onDelete={() => this.onContextMenuDelete()}
					onView={() => this.onContextMenuView()} />
			</ContextMenu>
		);
	}


	/**
	 * Render contractor.
	 *
	 * @param {Object} value
	 * @return {ReactNode}
	 */
	renderContractor(value) {
		return <mui.Typography>{value.Contractor || "SEC"}</mui.Typography>;
	}


	/**
	 * Render enquiry.
	 *
	 * @param {Object} value
	 * @return {ReactNode}
	 */
	renderEnquiry(value) {
		if (!value.Enquiry) {
			return (
				<React.Fragment>
					<mui.Box mb={0.25}>
						<mui.Typography>(SEC Office Premises)</mui.Typography>
					</mui.Box>
					{(mobile(this.props.width) ? this.renderEnquiryTimestamps(value) : null)}
				</React.Fragment>
			);
		}
		else return this.renderEnquiryRenderer(value);
	}


	/**
	 * Render enquiry renderer.
	 *
	 * @param {Object} value
	 * @return {ReactNode}
	 */
	renderEnquiryRenderer(value) {
		return (
			<EnquiryRenderer
				enquiry={value.Enquiry}
				childrenMobile={this.renderEnquiryTimestamps(value)} />
		);
	}


	/**
	 * Render enquiry timestamps options for mobile.
	 *
	 * @param {String} options.IncidentDateTime
	 * @return {ReactNode}
	 */
	renderEnquiryTimestamps({IncidentDateTime}) {
		const time = Time.dmyhmFromDatetime(IncidentDateTime);
		return <Caption label="Incident date" value={time} />;
	}


	/**
	 * Render incident date time.
	 *
	 * @param {String} options.IncidentDateTime
	 * @return {ReactNode}
	 */
	renderIncidentDateTime({IncidentDateTime}) {
		const time = Time.dmyhmFromDatetime(IncidentDateTime);
		return <mui.Typography>{time}</mui.Typography>;
	}


	/**
	 * Render reported by.
	 *
	 * @param {Object} value
	 * @return {ReactNode}
	 */
	renderReportedBy(value) {
		return <mui.Typography>{value.ReportedBy}</mui.Typography>;
	}


	/**
	 * Render status field.
	 *
	 * @param {Object} value
	 * @return {ReactNode}
	 */
	renderRequestStatus(value) {
		return (
			<div style={{width: "7.5rem"}}>
				{(value._apid && <mui.Typography>Draft</mui.Typography>)}
				{((value.ClosedTime && !value._apid) && this.renderRequestStatusClosed(value))}
				{((!value.ClosedTime && !value._apid) && this.renderRequestStatusCloseBtn(value))}
			</div>
		);
	}


	/**
	 * Render status field when closed.
	 *
	 * @param {Object} value
	 * @return {ReactNode}
	 */
	renderRequestStatusClosed(v) {
		return (
			<React.Fragment>
				<mui.Typography paragraph={this.canOpen}>
					<span style={{marginRight: rem()}}>Closed</span>
				</mui.Typography>
				{this.canOpen ? this.renderRequestReopenLink(v) : null}
			</React.Fragment>
		);
	}


	/**
	 * Render reopen link.
	 *
	 * @param {Object} value
	 * @return {void}
	 */
	renderRequestReopenLink(value) {
		return (
			<mui.Link
				variant="body1"
				style={{cursor: "pointer"}}
				onClick={e => this.onCloseOpen(e, value)}>
				Reopen
			</mui.Link>
		);
	}


	/**
	 * Render status field when open.
	 *
	 * @param {Object} value
	 * @return {ReactNode}
	 */
	renderRequestStatusCloseBtn(value) {
		return (
			<mui.Button
				color="primary"
				variant="outlined"
				disabled={!this.canClose}
				style={{width: mobile(this.props.width) ? rem(2) : "auto"}}
				onClick={e => this.onCloseOpen(e, value)}>
				Close
			</mui.Button>
		);
	}


	/**
	 * Get whether can close.
	 *
	 * @return {Boolean}
	 */
	get canClose() {
		return this.props.permissions.includes("sec.nmpi.close");
	}


	/**
	 * Get whether can open.
	 *
	 * @return {Boolean}
	 */
	get canOpen() {
		return this.props.permissions.includes("sec.nmpi.open");
	}


	/**
	 * Fields.
	 *
	 * @return {Array}
	 */
	get fields() {
		return [
			{
				label: "Enquiry",
				renderer: this.renderEnquiry.bind(this)
			},
			...(mobile(this.props.width) ? [] : this.fieldsDesktop),
			{
				label: "Status",
				renderer: this.renderRequestStatus.bind(this)
			}
		];
	}


	/**
	 * Fields on desktop.
	 *
	 * @return {Array}
	 */
	get fieldsDesktop() {
		return [
			{
				label: "Incident",
				renderer: this.renderIncidentDateTime.bind(this)
			},
			{
				label: "Reporter",
				renderer: this.renderReportedBy.bind(this)
			},
			{
				label: "Contractor",
				renderer: this.renderContractor.bind(this)
			}
		];
	}


	/**
	 * Map state to props.
	 *
	 * @param {Object} state
	 * @return {Object}
	 */
	static mapStateToProps(state) {
		return {permissions: state.permissions};
	}

}

export default connect(HealthSafetyNmpisTable.mapStateToProps)(
	mui.withWidth()(HealthSafetyNmpisTable)
);
