import React from "react";
import Alert from "Components/Alertx.js";
import Button from "Components/Button.js";
import Container from "Components/Containerx.js";
import String from "Components/Stringx.js";

/**
 * Timesheet submission error alert
 * 
 * @package SEC
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class TimesheetSubmissionErrorAlert extends React.PureComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Alert
				title="Submission error"
				severity="error">
				<String str="There was an error submitting your timesheet." />
				<String str="Please check you're online and then retry your submission." />
				<Container columns="max-content" mt={0.5}>
					<Button color="inherit" disabled={this.props.disabled} label="Retry" onClick={this.props.onRetry} variant="outlined" />
				</Container>
			</Alert>
		);
	}

}

export default TimesheetSubmissionErrorAlert;
