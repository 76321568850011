import React from "react";
import Dialog from "./Dialog.js";

export default ({open}) => (
	<Dialog
		fullWidth={true}
		loading={true}
		maxWidth="xs"
		nocancel={true}
		nocontent={true}
		nook={true}
		notitle={true}
		open={open} />
);
