import React from "react";
import {FormControl, InputLabel, MenuItem} from "@material-ui/core";
import {Select as MaterialSelect} from "@material-ui/core";
import {ArrowDropDown as ArrowDropDownIcon} from "@material-ui/icons";

/**
 * Select component
 *
 * An extension of Material UI's `Select` component to natively support 
 * a label and an "Any" option with a `null` value (handled internally as 
 * `false` because Material UI does not support falsey values that are 
 * not `false` as values for the `Select` component).
 *
 * Enable the "Any" option with the `any` prop; label it with `anyLabel`.
 *
 * No dropdown icon is displayed when the component is disabled via 
 * the `disabled` prop; please refer to the source for all prop details.
 *
 * @package SEC
 * @subpackage Spoi
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class Select extends React.PureComponent {

	/**
	 * Constructor.
	 * 
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * Method binds
		 */
		this.handleChange = this.handleChange.bind(this);
		this.renderValue = this.renderValue.bind(this);

	}


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<FormControl>
				<InputLabel shrink>
					{this.props.label}
				</InputLabel>
				<MaterialSelect
					disabled={this.props.disabled}
					MenuProps={this.constructor.MenuProps}
					displayEmpty={true}
					IconComponent={this.icon}
					multiple={this.props.multiple}
					onChange={this.handleChange}
					renderValue={(this.props.multiple ? this.renderValue : undefined)}
					value={this.value}>
					{(this.props.any ? this.renderAny() : null)}
					{this.props.children}
				</MaterialSelect>
			</FormControl>
		);
	}


	/**
	 * Render the "any" option.
	 * 
	 * @return {ReactNode}
	 */
	renderAny() {
		return (
			<MenuItem value={false}>
				{(this.props.anyLabel || "Any")}
			</MenuItem>
		);
	}


	/**
	 * Render the value.
	 *
	 * @param {mixed} value
	 * @return {mixed} Value to display
	 */
	renderValue(value) {
		if (!this.props.multiple) return value;
		else if ((value.length === 0) || (value.length === this.props.children?.length)) return "Any";
		else return value.join(", ");
	}


	/**
	 * Handle a value change.
	 *
	 * @param {Event} e
	 * @return {void}
	 */
	handleChange(e) {
		let value = e.target.value;
		if (value === false) value = null;
		this.props.onChange(value);
	}


	/**
	 * Get the icon component.
	 * 
	 * @return {Object}
	 */
	get icon() {
		if (!this.props.disabled) return ArrowDropDownIcon;
		else return this.constructor.disabledIconComponent;
	}


	/**
	 * Get the value to assign to the Material UI `Select`.
	 * 
	 * @return {mixed}
	 */
	get value() {
		return ((this.props.value !== null) ? this.props.value : false);
	}


	/**
	 * Disabled icon component.
	 *
	 * @type {Function}
	 */
	static disabledIconComponent = () => null;

	/**
	 * `MenuProps`
	 * 
	 * @type {Object}
	 */
	static MenuProps = {disableScrollLock: true};

}

export default Select;
