import React from "react";
import Component from "App/Component.js";
import Post from "App/Post.js";
import Store from "App/Store.js";
import Details from "Components/Details.js";
import HealthSafetyNmpisTable from "./HealthSafetyNmpisTable";

/**
 * Health & safety NMPIs drafts view
 *
 * @package SEC
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class HealthSafetyNmpisDrafts extends Component {

	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * State
		 *
		 * @type {Object}
		 */
		this.state = {

			/**
			 * NMPIs
			 *
			 * @type {Array}
			 */
			nmpis: []

		};

		/**
		 * Pending sync queue length
		 *
		 * We want to be alerted to changes so that 
		 * we can reload if a draft gets uploaded.
		 *
		 * @type {Integer}
		 */
		this.pending = Store.getState().sync.pending;

		/**
		 * Redux unsubscription method
		 *
		 * @type {Function}
		 */
		this.reduxUnsubscribe = null;

	}


	/**
	 * Component mounted.
	 *
	 * @return {void}
	 */
	componentDidMount() {
		this.update();
		this.reduxUnsubscribe = Store.subscribe(() => {
			const {pending} = Store.getState().sync;
			if (pending !== this.pending) {
				this.pending = pending;
				this.update();
			}
		});
	}


	/**
	 * Component unmounting.
	 *
	 * @return {void}
	 */
	componentWillUnmount() {
		super.componentWillUnmount();
		this.reduxUnsubscribe();
	}


	/**
	 * Update.
	 *
	 * @return {void}
	 */
	update() {
		Post.getType("nmpi").then(nmpis => {
			this.setState({nmpis: nmpis.map(n => n.data)});
		});
	}


	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Details
				open={false}
				label={`Drafts / Uploads (${this.state.nmpis.length})`}>
				<HealthSafetyNmpisTable nmpis={this.state.nmpis} />
			</Details>
		);
	}

}

export default HealthSafetyNmpisDrafts;
