import History from "./History.js";
import dRkey from "Dispatchers/Rkey.js";

/**
 * Navigator
 *
 * Can be used to handle navigation changes.
 * 
 * @package SEC
 * @subpackage App
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class Navigator {

	/**
	 * Navigate to the homescreen.
	 * 
	 * @return {void}
	 */
	static home() {
		Navigator.navigate("/");
	}


	/**
	 * Navigate to a new URI.
	 *
	 * This automatically handles reloading the current URI.
	 *
	 * @param {String} uri URI to navigate to
	 * @return {void}
	 */
	static navigate(uri) {
		dRkey();
		if (uri !== this.getCurrentUri()) {
			History.push(uri);
		}
	}


	/**
	 * Get current URI.
	 *
	 * @return {String}
	 */
	static getCurrentUri() {
		return `${History.location.pathname}${History.location.search}`;
	}


	/**
	 * Reload the current URI.
	 * 
	 * @return {void}
	 */
	static reload() {
		this.navigate(this.getCurrentUri());
	}

}

export default Navigator;
