import React from "react";
import DashboardCard from "Components/DashboardCard.js";

/**
 * "My Purchase Invoices" dashboard card
 *
 * @package SEC
 * @subpackage Spoi
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class SpoiPisDashboardCard extends React.PureComponent {

	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<DashboardCard
				buttonLbl="My Purchase Invoices"
				caption="Purchase Invoices"
				text={this.text}
				title="My Purchase Invoices"
				uri="/spoi/invoices" />
		);
	}


	/**
	 * Get our text.
	 *
	 * @return {Array}
	 */
	get text() {
		return [
			"View invoices you've raised with us.",
			"Check the status of your invoices."
		];
	}

}

export default SpoiPisDashboardCard;
