import React from "react";
import Alertx from "Components/Alertx.js";
import Button from "Components/Button.js";
import Component from "App/Component.js";
import Flex from "Components/Flex.js";
import Push from "App/Push.js";
import Stringx from "Components/Stringx.js";
import dPushBanner from "Dispatchers/PushBanner.js";
import withSnackbar from "Hoc/withSnackbar.js";
import {connect} from "react-redux";

/**
 * Push subscription registration banner
 *
 * @package SEC
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class PushBanner extends Component {

	/**
	 * State
	 *
	 * @type {Object}
	 */
	state = {

		/**
		 * Display?
		 *
		 * This gets set on mount based on current subscription state.
		 * 
		 * @type {Boolean}
		 */
		display: false,

		/**
		 * Enabling?
		 *
		 * @type {Boolean}
		 */
		enabling: false

	};


	/**
	 * Component mounted.
	 *
	 * We get the subscription status to determine whether to display.
	 * 
	 * @return {void}
	 */
	componentDidMount() {
		Push.subscribed.then(subscribed => {
			this.setState({display: !subscribed});
		}).catch(() => null);
	}


	/**
	 * Closed.
	 *
	 * @return {void}
	 */
	handleClose = () => dPushBanner(false);


	/**
	 * Enabling push notifications.
	 * 
	 * @return {void}
	 */
	handleEnable = () => {

		this.setState({enabling: true});

		Push.subscribe().then(() => {
			dPushBanner(false);
			this.props.snackbar("Push notifications enabled.", "success");
		}).catch(e => {
			this.props.snackbar(e);
		}).finally(() => {
			this.setState({enabling: false});
		});

	};


	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Alertx
				onClose={this.handleClose}
				severity="info"
				style={this.style}
				title="Push Notifications">
				<Stringx
					gap={0.5}
					str={this.constructor.caption}
					variant="body2" />
				<Flex columnar={true}>
					<Button
						color="inherit"
						disabled={this.state.enabling}
						label="Enable Push Notifications"
						onClick={this.handleEnable}
						size="small"
						variant="outlined" />
				</Flex>
			</Alertx>
		);
	}


	/**
	 * Caption text.
	 *
	 * @type {Array}
	 */
	static caption = [
		"Enabling push notifications lets us send alerts and reminders straight to your device.",
		"You can toggle individual alerts and change this setting at any time on the Profile screen."
	];


	/**
	 * Styles.
	 *
	 * @return {Object}
	 */
	get style() {
		return {
			display: ((!this.props.pushBanner || !this.state.display || !navigator.serviceWorker) ? "none" : undefined),
			...this.props.style
		};
	}

}

export default connect(({pushBanner}) => ({pushBanner}))(withSnackbar(PushBanner));
