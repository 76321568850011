import React from "react";
import String from "Components/String.js";
import {Box} from "@material-ui/core";
import {Alert as MaterialAlert, AlertTitle} from "@material-ui/lab";

/**
 * Alert component
 *
 * @package SEC
 * @subpackage Spoi
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class Alert extends React.PureComponent {

	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<MaterialAlert
				action={this.props.action}
				closeText="Dismiss"
				onClose={this.props.onClose}
				severity={this.props.severity}>

				<Box mb={0.75}>
					<AlertTitle>{this.props.title}</AlertTitle>
				</Box>
				<Box>{this.content}</Box>
			</MaterialAlert>
		);
	}


	/**
	 * Get the alert content.
	 *
	 * We use the children when available, or the string/array prop `content`.
	 *
	 * @return {ReactNode}
	 */
	get content() {
		if (this.props.children) return this.props.children;
		else return <String str={this.props.content} noParagraph={true} variant="body2" gap={0.75} />;
	}

}

export default Alert;
