import DialogAction from "Components/DialogAction.js";
import withSnackbar from "Hoc/withSnackbar.js";
import UserService from "Services/UserService.js";
import {AdminUsersDeleteDialog as Strings} from "Resources/Strings.js";

/**
 * Delete user dialog
 *
 * @package SEC
 * @subpackage Views
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class AdminUsersDeleteDialog extends DialogAction {

	/**
	 * Submit.
	 *
	 * @return {Promise}
	 */
	submit() {
		return UserService.delete(this.props.user.Username);
	}


	/**
	 * Strings.
	 *
	 * @return {Object}
	 */
	get strings() {
		return Strings;
	}

}

export default withSnackbar(AdminUsersDeleteDialog);
