import axios from "axios";
import Store from "App/Store.js";

/**
 * API URIs.
 *
 * @type {Object}
 */
const api = {

	/**
	 * API version URI
	 *
	 * @type {String}
	 */
	api: "/api",

	/**
	 * Audit log URIs
	 *
	 * @type {Object}
	 */
	audit: {

		/**
		 * Get audit events
		 *
		 * @type {String}
		 */
		get: "/api/audit"

	},

	/**
	 * Authentication URIs
	 *
	 * @type {Object}
	 */
	auth: {

		/**
		 * Authenticate
		 *
		 * @type {String}
		 */
		auth: "/api/auth",

		/**
		 * Get permissions
		 *
		 * @type {String}
		 */
		permissions: "/api/auth/permissions",

		/**
		 * Get auth users
		 *
		 * @type {String}
		 */
		users: "/api/auth/users"

	},

	/**
	 * Customers
	 *
	 * @type {Object}
	 */
	customers: {

		/**
		 * Get customers
		 *
		 * @type {String}
		 */
		get: "/api/customers",

		/**
		 * Search customers
		 *
		 * @type {String}
		 */
		search: "/api/customers/search"

	},

	/**
	 * DocuSign
	 *
	 * @type {Object}
	 */
	docusign: {

		/**
		 * DocuSign authentication URI
		 *
		 * @type {String}
		 */
		auth: "/api/docusign/auth",

		/**
		 * DocuSign sending URI
		 *
		 * @type {String}
		 */
		send: "/api/docusign/send"

	},

	/**
	 * Data request URIs
	 *
	 * @type {Object}
	 */
	drequests: {

		/**
		 * Email template URI
		 *
		 * @type {String}
		 */
		email: "/emails/templates/SdrEmail.html",

		/**
		 * Get by enquiry
		 *
		 * @type {String}
		 */
		enquiry: "/api/drequests/enquiry",

		/**
		 * Get a data request
		 *
		 * @type {String}
		 */
		get: "/api/drequests",

		/**
		 * Post a data request
		 *
		 * @type {String}
		 */
		post: "/api/drequests",

		/**
		 * Send a data request
		 *
		 * @type {String}
		 */
		send: "/api/drequests",

		/**
		 * Sent data requests
		 *
		 * @type {String}
		 */
		sent: "/api/drequests",

		/**
		 * Unsent data requests
		 *
		 * @type {String}
		 */
		unsent: "/api/drequests/unsent"

	},

	/**
	 * Enquiries URIs
	 *
	 * @type {Object}
	 */
	enquiries: {

		/**
		 * Get
		 *
		 * @type {String}
		 */
		get: "/api/enquiries",

		/**
		 * Search
		 *
		 * @type {String}
		 */
		search: "/api/enquiries/search"

	},

	/**
	 * Messaging URIs
	 *
	 * @type {Object}
	 */
	messages: {

		/**
		 * Messaging base endpoint
		 *
		 * @type {String}
		 */
		base: "/api/profile/messages",

		/**
		 * Conversations endpoint
		 *
		 * @type {String}
		 */
		conversations: "/api/profile/messages/conversations",

		/**
		 * Mark messages as read
		 *
		 * @type {String}
		 */
		read: "/api/profile/messages/read"

	},

	/**
	 * NMPIs URIs
	 *
	 * @type {Object}
	 */
	nmpis: {

		/**
		 * Get
		 *
		 * @type {String}
		 */
		get: "/api/nmpi",

		/**
		 * Get CSV
		 *
		 * @type {String}
		 */
		getCsv: "/api/nmpi.csv",

		/**
		 * Individual NMPI get
		 *
		 * @type {String}
		 */
		nmpi: "/api/nmpi",

		/**
		 * Incidents enum
		 *
		 * @type {String}
		 */
		incidents: "/api/nmpi/incidents",

		/**
		 * Probables enum
		 *
		 * @type {String}
		 */
		probables: "/api/nmpi/probables",

		/**
		 * Statistics
		 *
		 * @type {String}
		 */
		statistics: "/api/nmpi/statistics",

		/**
		 * Reportees
		 *
		 * @type {String}
		 */
		reportees: "/api/nmpi/reportees"

	},

	/**
	 * Profile URIs
	 *
	 * @type {Object}
	 */
	profile: {

		/**
		 * Permissions
		 *
		 * @type {String}
		 */
		permissions: "/api/profile/permissions",

		/**
		 * Change password
		 *
		 * @type {String}
		 */
		pw: "/api/profile/password",

		/**
		 * Settings
		 *
		 * @type {String}
		 */
		settings: "/api/profile/settings"

	},

	/**
	 * Projects API
	 * 
	 * @type {String}
	 */
	projects: "/api/projects",

	/**
	 * SPOI URIs
	 *
	 * @type {String}
	 */
	spoi: {

		/**
		 * SPOI config
		 *
		 * @type {String}
		 */
		config: "/api/spoi/config",

		/**
		 * Nominal codes
		 *
		 * @type {String}
		 */
		ncs: "/api/spoi/ncs",

		/**
		 * Purchase invoices
		 *
		 * @type {String}
		 */
		pis: "/api/spoi/invoices",

		/**
		 * Purchase orders
		 *
		 * @type {String}
		 */
		pos: "/api/spoi/orders",

		/**
		 * Tax codes
		 *
		 * @type {String}
		 */
		tcs: "/api/spoi/tcs",

		/**
		 * Total approved next payment date
		 * 
		 * @type {String}
		 */
		totalNpd: "/api/spoi/invoices/TotalApprovedForPaymentNextPaymentDate"

	},

	/**
	 * Users URIs
	 *
	 * @type {Object}
	 */
	users: {

		/**
		 * Get users list
		 *
		 * @type {String}
		 */
		get: "/api/users",

		/**
		 * POST for users
		 *
		 * @type {String}
		 */
		post: "/api/users"

	}

};


/**
 * API abstraction layer
 *
 * Wraps around Axios to setup API calls.
 * 
 * @package SEC
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class Api {

	/**
	 * Create a new Axios instance with our configuration.
	 *
	 * This will use the base given by `base` and headers from `headers`.
	 *
	 * We will be authenticated when authentication is available.
	 *
	 * @return {Object} Axios instance
	 */
	static axios() {
		return axios.create({
			baseURL: this.base,
			timeout: 15000,
			headers: this.headers
		});
	}


	/**
	 * Make an Axios request using our configuration.
	 *
	 * @param {Object} request Axios request object
	 * @return {Promise}
	 */
	static call(request) {
		return this.axios().request(request);
	}


	/**
	 * Get our API base URI.
	 *
	 * The origin is given by `originApi`.
	 * 
	 * The scheme always matches our current scheme.
	 *
	 * @return {String}
	 */
	static get base() {
		return `${this.scheme}://${this.originApi}`;
	}


	/**
	 * Get the headers to add to requests.
	 * 
	 * @return {Object}
	 */
	static get headers() {
		return {
			"Authorization": Store.getState().auth,
			"Content-Type": "application/json"
		};
	}


	/**
	 * Get our current origin.
	 *
	 * @return {String}
	 */
	static get origin() {
		return window.origin.split("://")[1];
	}


	/**
	 * Get the API origin to use.
	 * 
	 * This uses use the value stored in the app's state when set.
	 * 
	 * Otherwise, we use the environment value (`REACT_APP_API`) when set.
	 * 
	 * Otherwise, we use the default production API environment.
	 *
	 * @return {String}
	 */
	static get originApi() {
		const stored = Store.getState().api;
		const env = process.env.REACT_APP_API;
		return (stored || env || `api.sec-hub.co.uk`);
	}


	/**
	 * Get our current scheme.
	 *
	 * @return {String}
	 */
	static get scheme() {
		return window.origin.split("://")[0];
	}

}


export {api as uris, Api as api};
export default Api;
