import Domain from "Domain/Domain.js";
import Time from "Helpers/Time.js";

/**
 * SPOI configuration
 *
 * A class to represent the server-side SPOI configuration.
 *
 * @package SEC
 * @subpackage Spoi
 * @author Heron Web Ltd
 * @copyright SEC
 */
class SpoiConfig extends Domain {

	/**
	 * Constructor.
	 *
	 * @param {Object} obj optional
	 * @return {self}
	 */
	constructor(obj={}) {
		super(obj);

		/**
		 * Maximum days from present that an invoice can be dated
		 *
		 * @type {Integer}
		 */
		this.invoice_date_max = null;

		/**
		 * Minimum days from present that an invoice can be dated
		 * 
		 * @type {Integer}
		 */
		this.invoice_date_min = null;

		/**
		 * Tolerance threshold for PI total exceeding PO total
		 * 
		 * @type {Float}
		 */
		this.pi_po_total_tolerance = null;

		/**
		 * Username of default accounts contact for support purposes
		 * 
		 * @type {String}
		 */
		this.spoi_contact_username = null;

		/**
		 * Default tax code ID for CIS invoices
		 *
		 * @type {String}
		 */
		this.taxcode_cis = null;

		/**
		 * Default tax code ID for new invoices
		 * 
		 * @type {String}
		 */
		this.taxcode_default = null;

		/**
		 * Assign values
		 */
		Object.assign(this, obj);
	}


	/**
	 * Get the maximum invoice date for newly raised invoices.
	 *
	 * @return {String} YYYY-MM-DD
	 */
	get InvoiceDateMax() {
		return Time.diffDaysYmd(this.invoice_date_max);
	}


	/**
	 * Get the minimum invoice date for newly raised invoices.
	 *
	 * @return {String} YYYY-MM-DD
	 */
	get InvoiceDateMin() {
		return Time.diffDaysYmd(this.invoice_date_min);
	}

}

export default SpoiConfig;
