import React from "react";
import Component from "App/Component.js";
import Dialog from "Components/Dialog.js";
import Navigator from "App/Navigator.js";
import dispatchReset from "Dispatchers/Reset.js";
import {DialogContentText} from "@material-ui/core";
import {Logout as Strings} from "Resources/Strings.js";

/**
 * Logout
 *
 * @package SEC
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class Logout extends Component {

	/**
	 * State
	 * 
	 * @type {Object}
	 */
	state = {

		/**
		 * Disabled?
		 *
		 * @type {Boolean}
		 */
		disabled: false

	};


	/**
	 * Logout!
	 *
	 * @return {void}
	 */
	handleLogout = () => {

		this.setState({disabled: true});

		dispatchReset().then(() => {
			Navigator.home();
		}).finally(() => {
			this.setState({disabled: false});
		});

	};


	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Dialog
				disabled={this.state.disabled}
				onClose={this.props.onClose}
				onOk={this.handleLogout}
				open={this.props.open}
				title={Strings.title}>
				{
					Strings.content.map((s, i) => (
						<DialogContentText key={i}>
							{s}
						</DialogContentText>
					))
				}
			</Dialog>
		);
	}

}

export default Logout;
