import React from "react";
import Time from "Helpers/Time";
import {Typography} from "@material-ui/core";

const CAPTION_CSS_BOLD = {fontWeight: "bold"};

export default ({display, label, value}) => (
	<Typography variant="caption" style={{display: (display || "block")}}>
		<span style={CAPTION_CSS_BOLD}>
			{label}
		</span>
		<span> </span>
		<span>
			{(Number.isInteger(value) ? Time.dmyFromSec(value) : value)}
		</span>
	</Typography>
);
