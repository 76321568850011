export default {

	/**
	 * Enquiry
	 *
	 * @type {Object}
	 */
	enquiry: null,

	/**
	 * To
	 *
	 * @type {Array}
	 */
	to: [],

	/**
	 * Cc
	 *
	 * @type {Array}
	 */
	cc: ["h.watts@sec-online.co.uk"],

	/**
	 * Bcc
	 *
	 * @type {Array}
	 */
	bcc: [],

	/**
	 * Email
	 *
	 * @type {String}
	 */
	email: "",

	/**
	 * Attachments
	 *
	 * @type {Array}
	 */
	attachments: [],

	/**
	 * Customer logo
	 *
	 * @type {File}
	 */
	clogo: null,

	/**
	 * Doc2
	 *
	 * @type {Boolean}
	 */
	attach_DOC2: true,

	/**
	 * Doc3
	 *
	 * @type {Boolean}
	 */
	attach_DOC3: true,

	/**
	 * Doc4
	 *
	 * @type {Boolean}
	 */
	attach_DOC4: true,

	/**
	 * Data sheets
	 *
	 * @type {Boolean}
	 */
	attach_DataSheets: true,

	/**
	 * Active attachment for attachment viewer
	 *
	 * @type {String}
	 */
	activeAttachment: null,

	/**
	 * Render attachment viewer for active attachment
	 *
	 * @type {Boolean}
	 */
	activeAttachmentView: false

};
