import Text from "Components/Text.js";
import {memo} from "react";

export default memo(props => {

	return (
		<>
			<Text
				bold={true}
				style={props.labelStyle}>
				{props.label}
			</Text>
			{props.children}
		</>
	);

});
