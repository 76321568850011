import React from "react";
import rem from "Helpers/Rem.js";
import {connect} from "react-redux";

const SEC_ICON_STYLES = {margin: "auto", opacity: 0.6, width: rem(4)};

const SEC_ICON_STYLES_DARK = {...SEC_ICON_STYLES, filter: "grayscale(1) brightness(2)"};

export default connect(({dark}) => ({dark}))(({dark}) => (
	<img
		alt="SEC"
		src="/icon512.png"
		style={(!dark ? SEC_ICON_STYLES : SEC_ICON_STYLES_DARK)} />
));
