import React from "react";
import UserAvatar from "Components/UserAvatar.js";
import ProfilePasswordDialog from "./ProfilePasswordDialog.js";
import {Box, Card, CardContent, Link, Typography} from "@material-ui/core";

/**
 * Profile card
 *
 * @package SEC
 * @subpackage Views
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class ProfileCard extends React.PureComponent {

	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * State
		 *
		 * @type {Object}
		 */
		this.state = {

			/**
			 * Display password change prompt
			 *
			 * @type {Boolean}
			 */
			password: false

		};

	}


	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<React.Fragment>
				<Card>
					<CardContent>
						<Box mb={1}>
							<UserAvatar
								style={{margin: 0}}
								user={this.props.user} />
						</Box>
						<Typography paragraph>
							<span style={{fontWeight: "bold"}}>
								{this.props.user.Username}
							</span>
						</Typography>
						<Typography color="textSecondary" paragraph>
							{this.props.user.CustomerId || "SEC Group"}
						</Typography>
						<Typography color="textSecondary" paragraph>
							{this.props.user.EmailAddress}
						</Typography>
						<Box className="width-fit-content" mb={-0.5}>
							<Link onClick={() => this.setState({password: true})}>
								<Typography>
									Change your password...
								</Typography>
							</Link>
						</Box>
					</CardContent>
				</Card>

				<ProfilePasswordDialog
					open={this.state.password}
					onClose={() => this.setState({password: false})} />
			</React.Fragment>
		);
	}

}

export default ProfileCard;
