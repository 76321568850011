import scss from "./RowFw.module.scss";
import {memo} from "react";

export default memo(props => {

	return (
		<div className={scss.row}>
			{props.children}
		</div>
	);

});
