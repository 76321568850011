import React from "react";
import Navigator from "App/Navigator.js";
import {Link as Li} from "@material-ui/core";

/**
 * Link component
 *
 * An extension of Material UI's Link component with automatic 
 * support for in-app navigation via the router and the option 
 * of rendering in a disabled state.
 *
 * Please refer to the source for details of the available props.
 * 
 * @package SEC
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class Link extends React.PureComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Li
				className="width-fit-content"
				onClick={this.handleClick}
				style={this.styles}
				title={this.props.title}
				underline={(this.props.disabled ? "none" : "hover")}>
				{this.props.children}
			</Li>
		);
	}


	/**
	 * Handle click.
	 *
	 * @param {Event} e
	 * @return {void}
	 */
	handleClick = e => {
		e.stopPropagation();
		if (!this.props.disabled) {
			Navigator.navigate(this.props.uri);
		}
	};


	/**
	 * Styles.
	 *
	 * @return {Object}
	 */
	get styles() {
		return {
			opacity: (this.props.disabled ? 0.6 : 1)
		};
	}

}

export default Link;
