import React from "react";
import {connect} from "react-redux";
import {MenuItem} from "@material-ui/core";

/**
 * Health safety NMPIs context menu (items)
 *
 * @package SEC
 * @subpackage Views
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class HealthSafetyNmpisContextMenu extends React.PureComponent {

	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<React.Fragment>
				{(this.canView ? this.renderView() : null)}
				{(this.shouldRenderClose ? this.renderClose() : null)}
				{(this.shouldRenderOpen ? this.renderOpen() : null)}
				{(this.shouldRenderDelete ? this.renderDelete() : null)}
			</React.Fragment>
		);
	}


	/**
	 * Render "View".
	 *
	 * @return {ReactNode}
	 */
	renderView() {
		return (
			<MenuItem onClick={() => this.props.onView(this.nmpi)}>
				View
			</MenuItem>
		);
	}


	/**
	 * Render "Close".
	 *
	 * @return {ReactNode}
	 */
	renderClose() {
		return (
			<MenuItem onClick={() => this.props.onCloseOpen(this.nmpi)}>
				Close
			</MenuItem>
		);
	}


	/**
	 * Render "Open".
	 *
	 * @return {ReactNode}
	 */
	renderOpen() {
		return (
			<MenuItem onClick={() => this.props.onCloseOpen(this.nmpi)}>
				Open
			</MenuItem>
		);
	}


	/**
	 * Render "Delete".
	 *
	 * @extends {ReactNode}
	 */
	renderDelete() {
		return (
			<MenuItem onClick={() => this.props.onDelete(this.nmpi)}>
				Delete
			</MenuItem>
		);
	}


	/**
	 * Get whether the user has permission for the "View" option.
	 *
	 * @return {Boolean}
	 */
	get canView() {
		return this.props.permissions.includes("sec.nmpi.get");
	}


	/**
	 * Get whether we should render the "delete" option.
	 *
	 * Our NMPI must be open and the user must have the "delete" permission.
	 *
	 * @return {Boolean}
	 */
	get shouldRenderDelete() {
		const open = (this.nmpi?.ClosedTime === null);
		return (open && this.props.permissions.includes("sec.nmpi.delete"));
	}


	/**
	 * Get whether we should render the "close" option.
	 *
	 * Our NMPI must be open and the user must have the "close" permission.
	 * 
	 * @return {Boolean}
	 */
	get shouldRenderClose() {
		const open = (this.nmpi?.ClosedTime === null);
		return (open && this.props.permissions.includes("sec.nmpi.close"));
	}


	/**
	 * Get whether we should render the "open" option.
	 *
	 * Our NMPI must be closed and the user must have the "open" permission.
	 *
	 * @return {Boolean}
	 */
	get shouldRenderOpen() {
		const closed = (this.nmpi?.ClosedTime !== null);
		return (closed && this.props.permissions.includes("sec.nmpi.open"));
	}


	/**
	 * Get the active NMPI.
	 *
	 * @return {Object|null}
	 */
	get nmpi() {
		return this.props.nmpi;
	}

}

export default connect(
	({permissions}) => ({permissions}), null, null, {forwardRef: true}
)(HealthSafetyNmpisContextMenu);
