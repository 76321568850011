import Item from "./SpoiPurchaseInvoiceRaiseItem.js";
import ItemCollection from "./SpoiPurchaseInvoiceRaiseItemCollection.js";
import Raise from "./SpoiPurchaseInvoiceRaise.js";

/**
 * SPOI Purchase invoice raise factory
 *
 * @package SECGroup
 * @subpackage Spoi
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class SpoiPurchaseInvoiceRaiseFactory {

	/**
	 * Label to create raises with
	 *
	 * @type {String}
	 */
	static LABEL_DEFAULT = "Invoice Total";

	/**
	 * Label to create raises with (CIS labour)
	 *
	 * @type {String}
	 */
	static LABEL_CIS_LABOUR = "Labour";

	/**
	 * Label to create raises with (CIS materials)
	 * 
	 * @type {String}
	 */
	static LABEL_CIS_MATERIALS = "Materials";


	/**
	 * Construct a new raise.
	 *
	 * Refer to `constructItems()` for arguments.
	 *
	 * @param {Object} TaxCode optional Tax code
	 * @return {SpoiPurchaseInvoiceRaise}
	 */
	static construct(TaxCode) {
		return new Raise({Items: this.constructItems(TaxCode)});
	}


	/**
	 * Construct a new raise item.
	 *
	 * Uses the default label and the given amount and tax code.
	 *
	 * @return {SpoiPurchaseInvoiceRaiseItem}
	 */
	static constructItem(Amount, TaxCode) {
		return new Item({
			Amount,
			Description: this.LABEL_DEFAULT,
			TaxCode
		});
	}


	/**
	 * Construct default items for a raise against a PO.
	 * 
	 * There will be a single item with  a value of 0 and 
	 * the default desription as given by `constructItem()`.
	 * 
	 * The raise item's tax code is set according to `TaxCode`.
	 * 
	 * @param {Object} TaxCode optional Tax code
	 * @return {SpoiPurchaseInvoiceRaiseItemCollection}
	 */
	static constructItems(TaxCode=null) {
		return new ItemCollection([this.constructItem(0, TaxCode)]);
	}


	/**
	 * Construct default items for a CIS raise.
	 *
	 * There will be two 0-value items, one labelled with the 
	 * value of `LABEL_CIS_LABOUR`, and the other labelled 
	 * with the value of `LABEL_CIS_MATERIALS`.
	 *
	 * @param {Object} TaxCode optional Tax code
	 * @return {SpoiPurchaseInvoiceRaiseItemCollection}
	 */
	static constructItemsCis(TaxCode=null) {
		return new ItemCollection([
			new Item({
				Amount: 0,
				Description: this.LABEL_CIS_LABOUR,
				TaxCode
			}),
			new Item({
				Amount: 0,
				Description: this.LABEL_CIS_MATERIALS,
				TaxCode
			})
		]);
	}


	/**
	 * Convert a `SpoiPurchaseInvoice` raise to an API submission.
	 * 
	 * @param {SpoiPurchaseInvoiceRaise} raise
	 * @return {Object}
	 */
	static getApiRaise(raise) {
		const api = {
			SupplierInvoiceRef: raise.SupplierInvoiceRef,
			InvoiceDate: raise.InvoiceDate,
			DueDatePreagreedRequested: raise.DueDatePreagreedRequested
		};

		/**
		 * Get the amounts
		 */
		if (!raise.Items.items.find(i => (i.Description === this.LABEL_DEFAULT))) {
			api.Labour = raise.Items.items.find(i => (i.Description === this.LABEL_CIS_LABOUR)).Amount;
			api.Materials = raise.Items.items.find(i => (i.Description === this.LABEL_CIS_MATERIALS)).Amount;
		}
		else api.Raises = raise.Items.items.map(i => ({Amount: i.Amount, TaxCode: i.TaxCode.Id}));

		return api;
	}

}

export default SpoiPurchaseInvoiceRaiseFactory;
