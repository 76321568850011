import Reducer from "./Reducer.js";
import State from "./State.js";
import StatePersistent from "./StatePersistent.js";
import Storage from "./Storage.js";
import {createStore, applyMiddleware} from "redux";
import {composeWithDevTools} from "redux-devtools-extension";
import {createStateSyncMiddleware, initMessageListener} from "redux-state-sync";

/**
 * `redux-state-sync`
 */
const rss = {
	blacklist: [
		"f12",
		"rkey"
	],
	broadcastChannelOption: {webWorkerSupport: false}
};

/**
 * Create our store
 */
const store = createStore(
	Reducer,
	{...State, ...StatePersistent, ...Storage.get()},
	composeWithDevTools(applyMiddleware(createStateSyncMiddleware(rss)))
);

/**
 * Sync listener
 */
initMessageListener(store);

export default store;
