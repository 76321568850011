import React from "react";
import HealthSafetyNmpisDashboard from "./HealthSafetyNmpisDashboard.js";
import * as mui from "@material-ui/core";
import {Redirect} from "react-router-dom";
import {connect} from "react-redux";

/**
 * Health and safety NMPI main
 *
 * @package SEC
 * @subpackage Views
 * @author Heron Web Ltd
 * @copyright SEC Group
 */
class HealthSafetyNmpisMain extends React.PureComponent {

	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		if (this.canView) return this.renderDashboard();
		else if (this.canCreate) return this.renderFormRedirect();
		else return this.render403();
	}


	/**
	 * Render dashboard.
	 *
	 * @return {ReactNode}
	 */
	renderDashboard() {
		return <HealthSafetyNmpisDashboard history={this.props.history} />;
	}


	/**
	 * Render redirect to form.
	 *
	 * @return {ReactNode}
	 */
	renderFormRedirect() {
		return <Redirect to="/health-safety/nmpis/post" />;
	}


	/**
	 * Render 403 view.
	 *
	 * @return {ReactNode}
	 */
	render403() {
		return (
			<mui.Typography>
				You don't have permission to interact with NMPIs.
			</mui.Typography>
		);
	}


	/**
	 * Get whether the user can view NMPIs.
	 *
	 * @return {Boolean}
	 */
	get canView() {
		return this.props.permissions.includes("sec.nmpi.get");
	}


	/**
	 * Get whether the user can create NMPIs.
	 *
	 * @return {Boolean}
	 */
	get canCreate() {
		return this.props.permissions.includes("sec.nmpi.create");
	}


	/**
	 * Map state to props.
	 *
	 * @param {Object} state
	 * @return {Object}
	 */
	static mapStateToProps(state) {
		return {permissions: state.permissions};
	}

}

export default connect(
	HealthSafetyNmpisMain.mapStateToProps
)(HealthSafetyNmpisMain);
